/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:55
 * @modify date 06-02-2020 12:55
 * @description This file contains the component of customized 404 - PAGE NOT FOUND
 */
import React from 'react';
import Card from "react-bootstrap/Card";
import Title from 'reactjs-title';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css'
const TenantAccessError = () => (
    <React.Fragment>
        <Title render={previousTitle => `${previousTitle} - Accessible to Service Providers only`}/>
        <Card>
        <div className="cover-container py-5">
            <center><FontAwesome className="fa" name="frown-o" size="5x" /></center>
            <center><h3>{"Access to this tab is only available to Service Providers".toUpperCase()}</h3></center>
        </div>
    </Card>
    </React.Fragment>
);
export default TenantAccessError;