/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:52
 * @modify date 06-02-2020 12:52
 * @description Below redux reducer is created for endpoints.
 */
import * as initialState from './initialState';
import * as actionTypes from '../services/types';

export default (state , action) => {
    if(state === undefined){
        state = initialState.assets
    }
    switch (action.type) {
        case actionTypes.SET_ASSET_LIST:
            return {
                ...state,
                asset_list: action.data.response.items,
                // total: action.data.response.pages.total,
                // current_page: action.data.response.pages.current,
                page_size: action.data.response.pages.size,
                // max_page_size: action.data.response.pages.maxSize,
            };
        case actionTypes.SET_RESPONSE_TIME:
            return {
                ...state,
                response_time:action.data.response,
                req_per_hour: action.data.req_per_hour
            };
        case actionTypes.SET_ASSET_LIST_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:action.data
            };
        case actionTypes.CLEAR_ASSET_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:null
            };
        case actionTypes.GET_ASSET_DATA:
            return {...state};
        case actionTypes.CLEAR_ASSET_DATA:
            return {
                ...state,
                asset_list:[],
                total: null,
                current_page: null,
                page_size: null,
                max_page_size:null,
                error_response_message:null,
            }
        case actionTypes.CLEAR_RESPONSE_TIME:
            return {
                ...state,
                response_time:null
            };
        case actionTypes.START_LOADER_ASSETS:
            return {
                ...state,
                is_loading:true
            };
        case actionTypes.STOP_LOADER_ASSETS:
            return {
                ...state,
                is_loading:false
            };
        default:
    }
  
    return state;
  };