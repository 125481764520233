/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 28-02-2020 21:55
 * @modify date 28-02-2020 21:55
 * @description This file contains the component BarChart
 */
import React from 'react';
import c3 from 'c3/c3.js';
import 'c3/c3.min.css'

class BarChart extends React.Component {
    componentDidMount() {
      this._updateChart();
    }
    componentDidUpdate() {
      this._updateChart();
    }
    _updateChart() {
      const chart = c3.generate({
        bindto: '#'+this.props.chartId,
        tooltip: {
          grouped: false
        },
        color: {
          pattern: this.props.colorPattern
        },
        data: {
            x : 'x',
            columns: this.props.columns,
            type: this.props.chartType,
            onclick: (d) => this.props.viewAlertClick( d.id.toLowerCase(),d.x+1),
            groups: [
                ["High", "Medium","Low"]
            ],
            grid: {
                y: {
                    lines: [{value:0}]
                }
            },
            empty: { label: { text: !this.props.isLoading?"No Data Available":"" }   }
        },
        legend: {
          position: 'right'
        },
        axis: {
          x: {
              label: {
                text: this.props.xAxisLabel,
                position: 'outer-center'
              },
              type: 'category',
              tick: {
                  rotate: this.props.rotateXaxisLabel?75:false,
                  multiline: false
              }
          },
          y: {
            padding:{bottom: 0},
            min:0,
            label: {
              text: 'Number of Alerts',
              position: 'outer-middle'
            },
            tick: {
              format: function(x) {
                return (x === Math.floor(x)) ? x : null; //this wil round the number (1.5 will be rounded to 1)
              }
            },
          }
      }
      });
      chart.ygrids.remove();
    }
    render() {
      return <div id={this.props.chartId}></div>;    
    }
  }

  export default BarChart