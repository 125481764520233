import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import _ from "lodash";

let commentInput = "";
let selectedTenantOptionsArray = [];

class UpdateAllowedBlockedItemModal extends React.Component {

    setFields() {

    }

    constructor(props) {
        super(props)
        this.state = {
            item_id: props.update_data && props.update_data.length ? props.update_data[0]["id"] : '',
            tenant_id: props.update_data && props.update_data.length ? props.update_data[0]["tenant"]["id"] : '',
            tenant_name: props.update_data && props.update_data.length ? props.update_data[0]["tenant"]["name"] : '',
            filename: props.update_data && props.update_data.length ? props.update_data[0]["properties"]["fileName"] : '',
            path: props.update_data && props.update_data.length ? props.update_data[0]["properties"]["path"] : '',
            sha256: props.update_data && props.update_data.length ? props.update_data[0]["properties"]["sha256"] : '',
            comment: props.update_data && props.update_data.length ? props.update_data[0]["comment"] : '',
            certificatesigner: props.update_data && props.update_data.length ? props.update_data[0]["properties"]["certificateSigner"] : '',
            validated: false,
            selectedTenantOptionsArray: selectedTenantOptionsArray,
        }
        this.setState({
            comment: props.update_data && props.update_data.length ? props.update_data[0]["comment"] : ''
        })
        this.setFields()
    }


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(prevProps.show)) {
            if (this.props.show === true) {
                if (this.props.update_data && this.props.update_data.length) {
                    selectedTenantOptionsArray = [{
                        value: this.props.update_data[0]["tenant"]["id"],
                        label: this.props.update_data[0]["tenant"]["name"]
                    }];

                }
                this.setState({
                    item_id: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["id"] : '',
                    tenant_id: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["tenant"]["id"] : '',
                    tenant: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["tenant"]["name"] : '',
                    filename: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["properties"]["fileName"] : '',
                    path: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["properties"]["path"] : '',
                    sha256: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["properties"]["sha256"] : '',
                    comment: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["comment"] : '',
                    certificatesigner: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["properties"]["certificateSigner"] : '',
                    selectedTenantOptionsArray: selectedTenantOptionsArray,
                })
            }
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(nextProps.show)) {
            return true
        }
        if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
            return true
        }
        return false
    }


    resetModalOptions = () => {
        this.setState({
            item_id: '',
            tenant_id: '',
            tenant_name: '',
            filename: '',
            path: '',
            sha256: '',
            comment: '',
            certificatesigner: '',
            validated: false,
            selectedTenantOptionsArray: [],
        }, () => this.props.closeModal())
    }


    handleCommentChange = () => {
        this.setState({
            comment: commentInput ? commentInput.value : ''
        }, this.validations)
    }


    validations = () => {
        let validated = true
        validated &= commentInput && commentInput.value.trim().length !== 0
        this.setState({
            validated: validated
        }, () => { })
    }

    checkUpdateData = () => {
        this.validations()
        let data = {
            "comment": this.state.comment.trim(),
            "itemId":this.state.item_id
        }
        if(!this.props.settings.SophosIdTypeIsTenant) {
            data["sophosTenantId"] = this.state.tenant_id
        }
        this.props.confirmModal(data)
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={this.resetModalOptions}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Update Allowed Item</h5>
                </Modal.Header>
                <Modal.Body>
                    {!this.props.settings.SophosIdTypeIsTenant &&
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Tenant</span><span className="text-danger">*</span>
                            <MultiSelectTextbox
                                options={_.orderBy(this.state.selectedTenantOptionsArray, [tenant => tenant.label.toLowerCase()])}
                                customPlaceholder="Select Tenant"
                                filteredSelectedValue={this.state.selectedTenantOptionsArray}
                                isMulti={false}
                                isLoading={this.state.tenantLoading}
                                disabledSelect={true}
                            />
                        </div>
                    </div>
                    }
                    <div className="row" style={{ margin: '0 0 5px 0', display: this.state.path ? "block" : "none" }}>
                        <div className="col">
                            <span>Path</span>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                disabled={true}
                                value={this.state.path}
                                placeholder={this.state.path}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0', display: this.state.sha256 ? "block" : "none" }}>
                        <div className="col">
                            <span>Sha256</span>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                disabled={true}
                                value={this.state.sha256}
                                placeholder={this.state.sha256}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0', display: this.state.certificatesigner ? "block" : "none" }}>
                        <div className="col">
                            <span>Certificate Signer</span>
                            <input
                                className="form-control form-control-sm"
                                disabled={true}
                                type="text"
                                value={this.state.certificatesigner}
                                placeholder={this.state.certificatesigner}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Comment</span><span className="text-danger">*</span>
                            <input
                                className="form-control form-control-sm"
                                ref={n => commentInput = n}
                                type="text"
                                onChange={this.handleCommentChange}
                                value={this.state.comment}
                                placeholder={this.state.comment}
                            />
                            <span className="text-danger">{this.state.commentValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span className="text-danger">* fields are required.</span>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" variant="primary"
                        onClick={this.checkUpdateData}
                        disabled={!this.state.validated}
                    >
                        Update
                    </Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.resetModalOptions}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}



export default UpdateAllowedBlockedItemModal