/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 17-03-2020 14:49
 * @modify date 17-03-2020 14:49
 * @description This file contains the component of MultiCheckboxDropdown for Table column selection.
 */
import React from 'react';
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';

class MultiSelectCheckboxDropdown extends React.Component {

  customContentRenderer = ({ props, state }) => (
    <div style={{ cursor: 'pointer' }}>
      {state.values.length} of {props.options.length} selected
    </div>
  );

  customDropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search, 'i');
    return (
      <div style={{paddingBottom: "10px"}}>
        <SearchAndToggle color={props.color}>
          <Buttons>
            <div>Search and select:</div>
            {methods.areAllSelected() ? (
              ''
            ) : (
                <Button onClick={methods.selectAll}>Select all</Button>
            )}
          </Buttons>
          <input
            type="text"
            value={state.search}
            onChange={(event)=>{
              let value = event.target.value;
              value = value.replace(/[^A-Za-z0-9 ]/gi, "");
              event.target.value=value
              methods.setSearch(event)
            }}
            style={{paddingRight:'22px'}}
            placeholder="Type anything"
          />
           {state.search?<button className="clear-search-icon-dropdown" onClick={(event)=>{
             event.target.value = ""
             methods.setSearch(event)
             }}><i className="fa fa-times fa-lg" style={{"color": "#000"}}></i></button>:null}
        </SearchAndToggle>
        <Items style={{cursor:"initial"}}>
          {props.options
            .filter((item)=>regexp.test(item[props.searchBy] || item.props.labelField))
            .map((option)=>{
              if(option.fixed){
                return (
                  <Item
                    disabled={option.fixed}
                    key={option[props.valueField]}
                    // onClick={option.fixed ? null : () => methods.addItem(option)}
                    className="dropdown-item-cursor"
                    >
                    <input
                      type="checkbox"
                      // onChange={() => (option.fixed ? undefined : methods.addItem(option))}
                      checked={state.values.indexOf(option) !== -1}
                      disabled={true}
                    />
                    <ItemLabel>{option[props.labelField]}</ItemLabel>
                  </Item>
                );
              }
              else{
                return null
              }
            })}
          {props.options.filter((item)=>item.fixed===true && ( regexp.test(item[props.searchBy] || item[props.labelField]))).length>0?<hr/>:''}
          {props.options.filter((item)=>(regexp.test(item[props.searchBy] || item[props.labelField]))).length===0?<ItemLabel className="dropdown-no-match">No matches found.</ItemLabel>:''}
          {props.options
            .filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]))
            .map((option) => {
              if (!props.keepSelectedInList && methods.isSelected(option)) {
                return null;
              }
              if(!option.fixed){
                if(this.props.defaultSelected.length===1 && state.values.indexOf(option) !== -1){
                  return (
                    <Item
                      disabled={option.fixed}
                      key={option[props.valueField]}
                      // onClick={option.fixed ? null : () => methods.addItem(option)}
                      className="dropdown-item-cursor"
                      >
                      <input
                        type="checkbox"
                        // onChange={() => (option.fixed ? undefined : methods.addItem(option))}
                        checked={state.values.indexOf(option) !== -1}
                        disabled={true}
                      />
                      <ItemLabel>{option[props.labelField]}</ItemLabel>
                    </Item>
                  );
                }
                else{
                  return (
                    <Item
                      disabled={option.fixed}
                      key={option[props.valueField]}
                      onClick={option.fixed ? null : () => methods.addItem(option)}
                      className="dropdown-item-cursor"
                    >
                      <input
                        type="checkbox"
                        onChange={() => (option.fixed ? undefined : methods.addItem(option))}
                        checked={state.values.indexOf(option) !== -1}
                        disabled={option.fixed}
                      />
                      <ItemLabel>{option[props.labelField]}</ItemLabel>
                    </Item>
                  );
                }
              }
              else{
                return null
              }
            })}
        </Items>
        <Buttons >
            <div></div>
            <button className="btn btn-primary btn-sm" onClick={()=>methods.dropDown("close")} style={{margin: "0 10px"}}>Close</button>
        </Buttons>
      </div>
    );
  };

  render() {
    return (
      <div>
        <StyledSelect
          placeholder="Select"
          multi
          contentRenderer={this.customContentRenderer}
          dropdownRenderer={this.customDropdownRenderer}
          onChange={(selectedOptions) => {
                this.props.onChange(selectedOptions);
            }
          }
          options={this.props.options}
          searchBy="text"
          valueField="dataField"
          labelField="text"
          values={this.props.defaultSelected}
          backspaceDelete={false}
        />
      </div>
    );
  }
}

const StyledSelect = styled(Select)`
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  min-height:32px;
  .react-dropdown-select-dropdown {
    overflow: initial;
    cursor:initial;
  }
`;

const SearchAndToggle = styled.div`
  display: flex;
  flex-direction: column;

  input {
    margin: 10px 10px 0;
    height: calc(1.5em + .5rem + 2px);
    padding: 0px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    :focus {
      outline: none;
      border: 1px solid deepskyblue;
    }
    ::-ms-clear {
      display: none;
    }
  }
`;

const Items = styled.div`
  overflow: auto;
  margin: 10px 0;
  min-height: 10px;
  max-height: 170px;
  cursor:pointer;
`;

const Item = styled.div`
  display: flex;
  margin: 10px;
  align-items: baseline;
`;

const ItemLabel = styled.div`
  margin: 5px 10px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  & div {
    margin: 10px 0 0 10px;
    font-weight: 600;
  }
`;

const Button = styled.button`
  background: none;
  border: 1px solid #555;
  color: #555;
  border-radius: 3px;
  margin: 10px 10px 0;
  padding: 3px 5px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;

  &.clear {
    color: tomato;
    border: 1px solid tomato;
  }

  :hover {
    border: 1px solid deepskyblue;
    color: deepskyblue;
  }
`;

MultiSelectCheckboxDropdown.propTypes = {};

export default MultiSelectCheckboxDropdown;