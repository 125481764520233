/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 20-04-2020 15:56
 * @modify date 20-04-2020 15:56
 * @description This component is multi file uploader for deployment csv upload with drag and drop feature.
 */
import React from "react";
import  Papa from 'papaparse';
import {ErrorJSONViewerModal} from './ErrorJSONViewerModal'
import '../../assets/css/uploader.scss';

class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: null,
            parsingError: null,
            dragOver: false,
            errorNotification: null
        };
        this.handleAddFile = this.handleAddFile.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleCancelUpload = this.handleCancelUpload.bind(this);
    }
    
    /**
       Drag and Drop Event Handlers
    **/
    handleDragEnter() {
    //    e.preventDefault();
    }
    handleDragOver() {
       if (!this.state.dragOver) {
          this.setState({
             dragOver: true
          });
       }
    }

    handleDragLeave() {
       this.setState({
          dragOver: false
       });
    }

    handleDrop(e) {
        let files = Array.from(e.dataTransfer.files);
        let checkValidFiles=true
        console.log(files,"CHECK FILES ARRAY")
        files.forEach((fileCheck)=>{
            let fileNameArray = fileCheck.name.split(".")
            let fileTypeExist=fileNameArray.lastIndexOf(this.props.acceptableFileType.slice(1))
            console.log("extensionCheck")
            if(fileTypeExist===-1){
                checkValidFiles=false
            }
            else if(this.props.validFileName){
                if(this.props.validFileName!==fileNameArray[0]){
                    checkValidFiles=false
                }
            }
        })
        
        // Validate file is of type csvFile
        // let fileType = file.type.split("/")[0];
        if (!checkValidFiles) {
            console.log(this.props.validationMessage,"CHECK VALID");
            this.setState({
                files: null,
                errorNotification: this.props.validationMessage,
                dragOver: false
            });
            return setTimeout(() => {
                this.setState({
                    errorNotification: null
                });
            }, 5000);
        }
        this.refs.csvFile.files = e.dataTransfer.files;
        this.setState({
            files:files,
            dragOver: false
        });
    }

    validateDataAndUpload=(result)=> {
        let data = result;
        if(result.errors.length>0){
            this.setState({
                parsingError: data.errors
            })
        }
        else{
            this.setState({
                files:null
            },()=>this.props.uploadFile(data.data))
        }
    }

    handleOnClick(e){
        e.target.value=""
    }
    
    /**
       Handle Manually (File Input) Added Files
    **/
    handleAddFile(e) {
        e.preventDefault();
        
        let files = Array.from(this.refs.csvFile.files);
        let checkValidFiles=true
        files.forEach((fileCheck)=>{
            let fileNameArray = fileCheck.name.split(".")
            let fileTypeExist=fileNameArray.lastIndexOf(this.props.acceptableFileType.slice(1))
            if(fileTypeExist===-1){
                checkValidFiles=false
            }
            else if(this.props.validFileName){
                if(this.props.validFileName!==fileNameArray[0]){
                    checkValidFiles=false
                }
            }
        })

        // Validate file is of type csvFile
        // let fileType = extensionType;
        // console.log(file,"CHECK filetype")
        if (!checkValidFiles) {
            console.log(this.props.validationMessage,"CHECK VALID");
            this.setState({
                files: null,
                errorNotification: this.props.validationMessage,
                dragOverClass: ""
            });
            return setTimeout(() => {
                this.setState({
                    errorNotification: null
                });
            }, 3000);
        }
            
        this.setState({
            files:files,
        });
    }
    
    /**
       Handle Upload after Upload Button Clicked
    **/
    handleUploadFile() {
        //    e.preventDefault();
        if (this.state.files) {
            let files=this.state.files
            Papa.parse(files[0], {
                complete: this.validateDataAndUpload,
                header: true,
                delimiter:",",
                skipEmptyLines: "greedy"
            });
        }
    }
    handleCancelUpload () {
        // e.preventDefault();
        this.setState({
            files: null
        });
    }

    closeParsingErrorModal =()=>{
        this.setState({
            files: null,
            parsingError: null
        });
    }
    
    
    render() {
        
        // Match drag over css to hover css
        let dragOverClass = this.state.dragOver
            ? `${!this.props.disabledUpload ? "display-box" : "disable-upload"} "drag-over"`
            : !this.props.disabledUpload ? "display-box" : "disable-upload";
        
        console.log(this.state.files,"CHECK BUTTONS")
        // If file is set, change upload box text to file name
        let uploadText = this.state.files?
            this.state.files.length>0?
            <div>
                    {this.state.files?
                    this.state.files.map((singleFile, index)=>{
                        return(<h6 key={index}>{singleFile.name}</h6>)
                    })
                    :''}
                    <button 
                        className="cancel-upload-button btn btn-warning"
                        onClick={this.handleCancelUpload}
                    >
                        Cancel
                    </button>
                    <button
                        className="upload-button btn btn-primary"
                        onClick={this.handleUploadFile}
                    >
                        {this.props.alreadyUploaded?"Update":"Upload"}
                    </button>
                </div>
                :
                <div>
                    <h6>Drop CSV for {this.props.fileFor} here</h6>
                    <small>or click to select files.</small>
                </div> 
            :
            <div>
                <h6>Drop CSV for {this.props.fileFor} here</h6>
                <small>or click to select files.</small>
            </div>;
        
        // Show Error message if file type is not an csvFile
        let errorNotification = this.state.errorNotification
            ? <div className="error-notification">
                <p>{this.state.errorNotification}</p>
                </div>
            : null;
        
        
        return (
            <>
            <ErrorJSONViewerModal 
                show={this.state.parsingError!==null} 
                closeModal={this.closeParsingErrorModal}
                modalMessage={this.state.parsingError}
                errorFor={this.props.fileFor}
            />
            <div className="file-uploader-wrapper">
                <div className={dragOverClass}>
                    <div className="icon-text-box">
                        <div className="upload-icon">
                            <i className="fa fa-upload" aria-hidden="true" />
                        </div>
                        <div className="upload-text">
                            {uploadText}
                        </div>
                        {errorNotification}
                    </div>
                    <div>
                        <input
                            type="file"
                            // multiple
                            // maxLength={2}
                            ref="csvFile"
                            id="upload-file-input"
                            className="upload-file-input"
                            accept={this.props.acceptableFileType?this.props.acceptableFileType:this.props.acceptableTypes}
                            onDrop={this.handleDrop}
                            onDragEnter={this.handleDragEnter}
                            onDragOver={this.handleDragOver}
                            onDragLeave={this.handleDragLeave}
                            onChange={this.handleAddFile}
                            onClick={this.handleOnClick}
                            disabled={this.props.disabledUpload}
                            title={this.state.files?
                                Array.from(this.state.files).map((elementFile)=>{
                                    return(elementFile.name)
                                })
                            :"No file(s) selected."}
                        />
                    </div>
                </div>
            </div>
            </>
        );
    }
}

 export default FileUploader