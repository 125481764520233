/**
 * @author Priyansh Patel
 * @email priyansh.patel@crestdatasys.com
 * @create date 28-11-2021 12:50
 * @modify date 28-11-2021 12:50
 * @description Below redux reducer is created for Scanning Exclusion.
 */
import * as initialState from './initialState';
import * as actionTypes from '../services/types';

export default (state , action) => {
    if(state === undefined){
        state = initialState.intrusion_prevention_items
    }
    switch (action.type) {
        case actionTypes.SET_INTRUSION_PREVENTION_LIST:
            return {
                ...state,
                intrusion_prevention_list: action.data.response.items,
                total: action.data.response.items.length,
                current_page: 1,
                page_size: action.data.response.pages.size,
                max_page_size: 100,
                req_per_hour: action.data.req_per_hour,
                last_updated_times: action.data.response.last_updated_times
            };
        case actionTypes.SET_RESPONSE_TIME:
            return {
                ...state,
                force_limit_reached: action.data.force_limit_reached,
                last_force_update_time: action.data.last_force_update_time,
                scheduled: action.data.scheduled,
                queued_job_exist: action.data.queued_job_exist,
                req_per_hour: action.data.req_per_hour,
            }
        case actionTypes.CLEAR_RESPONSE_TIME:
            return {
                ...state,
                force_limit_reached: null,
                last_force_update_time: null,
                scheduled: null,
                queued_job_exist: null,
            };
        case actionTypes.SET_INTRUSION_PREVENTION_LIST_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:action.data
            };
        case actionTypes.CLEAR_INTRUSION_PREVENTION_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:null
            };
        case actionTypes.GET_INTRUSION_PREVENTION_DATA:
            return {...state};
        case actionTypes.CLEAR_INTRUSION_PREVENTION_DATA:
            return {
                ...state,
                intrusion_prevention_list:[],
                total: null,
                current_page: null,
                page_size: null,
                max_page_size:null,
                error_response_message:null
            }
        case actionTypes.START_LOADER_INTRUSION_PREVENTION:
            return {
                ...state,
                is_loading:true
            };
        case actionTypes.STOP_LOADER_INTRUSION_PREVENTION:
            return {
                ...state,
                is_loading:false
            };
        default:
    }
  
    return state;
};
