/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 20-04-2020 15:55
 * @modify date 20-04-2020 15:55
 * @description This component has file uploader component which upload the deployment csv file to in deployment Upload tenants step.
 */
import React from "react";
import moment from 'moment';
import Alert from "react-bootstrap/Alert";
import Spinner from 'react-bootstrap/Spinner';
import FileUploader from "../common/FileUploader";
import {senduploadCSVData, checkDeploymentExist} from "../../services/apiOperations";

const VALID_FILENAME={WINDOWS_CSV_FILENAME:"managed_users_windows_deployment_info",MAC_CSV_FILENAME:"managed_users_mac_deployment_info"}

class UploadTenants extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tenantList:[],
      mac_uploadSuccess: false,
      mac_uploadDataExist:false,
      mac_uploadLoading:false,
      mac_uploadFail:false,
      mac_uploadFailResponse:"",
      mac_getDataLoading:false,
      mac_currentDataMessage:null,
      mac_lastUpdated:null,
      windows_uploadSuccess: false,
      windows_uploadDataExist:false,
      windows_uploadLoading:false,
      windows_uploadFail:false,
      windows_uploadFailResponse:"",
      windows_getDataLoading:false,
      windows_currentDataMessage:null,
      windows_lastUpdated:null
    };
  }

  componentDidMount(){
    // if(this.props.fieldValues!==null){
    //   if(this.props.fieldValues.mac_uploadSuccess || this.props.fieldValues.windows_uploadSuccess){
    //     let macUploadStatus,windowsUploadStatus=false
    //     macUploadStatus=this.props.fieldValues.mac_uploadSuccess?this.props.fieldValues.mac_uploadSuccess:false
    //     windowsUploadStatus=this.props.fieldValues.windows_uploadSuccess?this.props.fieldValues.windows_uploadSuccess:false
    //     this.setState({
    //       mac_uploadSuccess: macUploadStatus,
    //       windows_uploadSuccess: windowsUploadStatus
    //     })
    //   }
      // else{
        this.checkDeploymentFunction("windows")
        this.checkDeploymentFunction("mac")
      // }
    // }
    // else{
    //   this.checkDeploymentFunction("windows")
    //   this.checkDeploymentFunction("mac")
    // }
  }

  checkDeploymentFunction=(type)=>{
    this.setState({
      [`${type}_getDataLoading`]:true,
      [`${type}_uploadFail`]:false,
      [`${type}_uploadFailResponse`]: ""
    },()=>checkDeploymentExist(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),this.props.location.search,type)
      .then((res)=>{
        let machineGroupsNameArray=[]
        if(Array.isArray(res.data.response.machineGroups)){
          res.data.response.machineGroups.forEach((elementMachine)=>{
            machineGroupsNameArray.push({"value":elementMachine.id,"label":elementMachine.name,"disabled":false})
          })
        }

        let organizationNameArray=[]
        if(Array.isArray(res.data.response.orgs)){
          res.data.response.orgs.forEach((elementOrg)=>{
            organizationNameArray.push({"value":elementOrg.id,"label":elementOrg.name,"disabled":false})
          })
        }

        let tenantListArray=[]
        if(Array.isArray(res.data.response.tenants)){
          res.data.response.tenants.forEach((elementTenant)=>{
            tenantListArray.push({"value":elementTenant.tenantId,"label":elementTenant.tenantName})
          })
        }

        let lastUpdateTime=res.data.response.lastUpdated?res.data.response.lastUpdated:null
        let mergedTenantList=[...this.state.tenantList,...tenantListArray]
        let uniqueTenantList=Array.from(new Set(mergedTenantList.map(s=>s.value))).map(value=>{
          return{
            value: value,
            label: mergedTenantList.find(s=>s.value===value).label
          }
        })
        this.setState({
          machineGroups: machineGroupsNameArray,
          organizationGroups: organizationNameArray,
          tenantList: uniqueTenantList,
          [`${type}_getDataLoading`]: false,
          [`${type}_uploadDataExist`]: true,
          [`${type}_lastUpdated`]: lastUpdateTime,
          [`${type}_currentDataMessage`]: "CSV already exist. If you want to overwrite the CSV data then please reupload it, otherwise you can click next."
        },()=>{
          this.props.saveValues(this.state)
          this.props.uploadSuccess()
        })
        console.log(res,"CHECK")
      })
      .catch((err)=>{
        let errMessage=""
        if(err.response){
          if(err.response.status!==404){
            if(err.response){
              if(err.response.data.message!==undefined)
                errMessage=err.response.data.message
              else
                errMessage="Something went wrong; Please try again later."
                  
            }
            else{  
                errMessage="Something went wrong; Please try again later."
            }
            this.setState({
              [`${type}_getDataLoading`]: false,
              [`${type}_uploadFail`]:true,
              [`${type}_uploadFailResponse`]: errMessage
            })
          }
          else{
            this.setState({
              [`${type}_getDataLoading`]: false
            })
          }
          console.log(err.response.status,"CHECK")
        }
        else{
          errMessage="Something went wrong; Please try again later."
          this.setState({
            [`${type}_getDataLoading`]: false,
            [`${type}_uploadFail`]:true,
            [`${type}_uploadFailResponse`]: errMessage
          })
        }
      })
    )
    
  }

  uploadFile=(data,type)=>{
    console.log(this.props,"CHECK PARENT PROPS")
    let requestBody={ "items" : data}
    this.setState({
      [`${type}_uploadLoading`]:true,
      [`${type}_uploadFail`]:false,
      [`${type}_uploadFailResponse`]:"",
      [`${type}_uploadSuccess`]:false,
      [`${type}_currentDataMessage`]:null
    },()=>{
      senduploadCSVData(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),type,requestBody,this.props.location.search)
      .then((res)=>{
        let machineGroupsNameArray=[]
        if(Array.isArray(res.data.response.machineGroups)){
          res.data.response.machineGroups.forEach((elementMachine)=>{
            machineGroupsNameArray.push({"value":elementMachine.id,"label":elementMachine.name,"disabled":false})
          })
        }

        let organizationNameArray=[]
        if(Array.isArray(res.data.response.orgs)){
          res.data.response.orgs.forEach((elementOrg)=>{
            organizationNameArray.push({"value":elementOrg.id,"label":elementOrg.name,"disabled":false})
          })
        }

        let tenantListArray=[]
        if(Array.isArray(res.data.response.tenants)){
          res.data.response.tenants.forEach((elementTenant)=>{
            tenantListArray.push({"value":elementTenant.tenantId,"label":elementTenant.tenantName})
          })
        }

        let lastUpdateTime=res.data.response.lastUpdated?res.data.response.lastUpdated:null
        let mergedTenantList=[...this.state.tenantList,...tenantListArray]
        let uniqueTenantList=Array.from(new Set(mergedTenantList.map(s=>s.value))).map(value=>{
          return{
            value: value,
            label: mergedTenantList.find(s=>s.value===value).label
          }
        })

        this.setState({
          machineGroups: machineGroupsNameArray,
          organizationGroups: organizationNameArray,
          tenantList: uniqueTenantList,
          [`${type}_uploadLoading`]: false,
          [`${type}_currentDataMessage`]:null,
          [`${type}_lastUpdated`]: lastUpdateTime,
          [`${type}_uploadSuccess`]: true
        },()=>{
          this.props.saveValues(this.state)
          this.props.uploadSuccess()
        })
        console.log('SUCCESS!!');
      })
      .catch((err)=>{
        let errMessage=""
        if(err.response){
          if(err.response.data.message!==undefined)
            errMessage=err.response.data.message
          else
            errMessage="Something went wrong; Please try again later."
              
        }
        else{
            // if(err.message==="Network Error")
            //     dispatch(setDashboardEndpointErrorResponse("Network Error"))
            // else    
            errMessage="Something went wrong; Please try again later."
        }
        this.setState({
          [`${type}_uploadLoading`]:false,
          [`${type}_uploadSuccess`]: false,
          [`${type}_uploadFail`]:true,
          [`${type}_uploadFailResponse`]: errMessage
        })
        console.log('FAILURE!!');
      });
    })
  }

  render() {
    return (
        <div className="row mx-0">
            <div className="col-md-10 offset-md-1 text-center">
              <h4>Upload Tenant CSV from Sophos</h4>
              <div className="row">
                <div className="col-md-6">
                  <label>Upload Windows CSV from Sophos with exact filename: <br/><strong>{VALID_FILENAME.WINDOWS_CSV_FILENAME}.csv</strong></label>
                  
                  <FileUploader disabledUpload={this.state.windows_uploadLoading || this.state.windows_getDataLoading || !this.props.settings.settings_data} acceptableFileType=".csv" acceptableTypes=".csv" validationMessage="Not a valid CSV file OR invalid filename"  uploadFile={(data)=>this.uploadFile(data,"windows")} fileFor={"Windows"} validFileName={VALID_FILENAME.WINDOWS_CSV_FILENAME} alreadyUploaded={this.state.windows_lastUpdated!==null || this.state.windows_currentDataMessage!==null}/>
                  
                  {this.state.windows_getDataLoading?<center><Spinner animation="border" /></center>:""}
                  {this.state.windows_uploadLoading?<label>Please wait file is uploading .....</label>:""}
                  {(this.state.windows_currentDataMessage || (this.state.windows_uploadSuccess && this.state.windows_currentDataMessage===null)) ? this.state.windows_lastUpdated!==null ? <label><strong>Last updated time:</strong> {moment(this.state.windows_lastUpdated).startOf("minute").format("DD-MMM-YYYY HH:mm")}</label>:<label><strong>Last updated time:</strong> NA</label>:""}
                  
                  <Alert show={this.state.windows_currentDataMessage!==null} variant={"info"}>{this.state.windows_currentDataMessage}</Alert>
                  <Alert show={this.state.windows_uploadSuccess && this.state.windows_currentDataMessage===null} variant={"success"}><center>{/*<div dangerouslySetInnerHTML={ { __html: this.props.settings.response_message } }></div>*/}CSV uploaded successfully</center></Alert>
                  <Alert show={this.state.windows_uploadFail} variant={"danger"}><center><div dangerouslySetInnerHTML={ { __html: this.state.windows_uploadFailResponse } }></div></center></Alert>
                </div>
                <div className="col-md-6">
                  <label>Upload MAC CSV from Sophos with exact filename: <br/><strong>{VALID_FILENAME.MAC_CSV_FILENAME}.csv</strong></label>
                  
                  <FileUploader disabledUpload={this.state.mac_uploadLoading || this.state.mac_getDataLoading || !this.props.settings.settings_data} acceptableFileType=".csv" acceptableTypes=".csv" validationMessage="Not a valid CSV file OR invalid filename"  uploadFile={(data)=>this.uploadFile(data,"mac")} fileFor={"MAC"} validFileName={VALID_FILENAME.MAC_CSV_FILENAME}  alreadyUploaded={this.state.mac_lastUpdated!==null || this.state.mac_currentDataMessage!==null}/>
                  
                  {this.state.mac_getDataLoading?<center><Spinner animation="border" /></center>:""}
                  {this.state.mac_uploadLoading?<label>Please wait file is uploading .....</label>:""}
                  {(this.state.mac_currentDataMessage || (this.state.mac_uploadSuccess && this.state.mac_currentDataMessage===null)) ? this.state.mac_lastUpdated!==null ? <label><strong>Last updated time:</strong> {moment(this.state.mac_lastUpdated).startOf("minute").format("DD-MMM-YYYY HH:mm")}</label>:<label><strong>Last updated time:</strong> NA</label>:""}
                  
                  <Alert show={this.state.mac_currentDataMessage!==null} variant={"info"}>{this.state.mac_currentDataMessage}</Alert>
                  <Alert show={this.state.mac_uploadSuccess && this.state.mac_currentDataMessage===null} variant={"success"}><center>{/*<div dangerouslySetInnerHTML={ { __html: this.props.settings.response_message } }></div>*/}CSV uploaded successfully</center></Alert>
                  <Alert show={this.state.mac_uploadFail} variant={"danger"}><center><div dangerouslySetInnerHTML={ { __html: this.state.mac_uploadFailResponse } }></div></center></Alert>
                </div>
              </div>
              {/* <label>Filename examples: <a href="#" className="text-muted" data-tip data-for="actionDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></label>
              <ReactTooltip id="actionDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-300">
                Supported files are:
                  <ol className="m-0">
                    {VALID_FILENAME.map((elementName)=><li>{elementName+".csv"}</li>)}
                  </ol>
              </ReactTooltip> */}
            </div>
        </div>        
    )
  }
}

export default UploadTenants