/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:26
 * @modify date 06-02-2020 12:26
 * @description This file contains actions types of Redux store.
 */

export const START_LOADER_SETTINGS = "START_LOADER_SETTINGS";
export const STOP_LOADER_SETTINGS = "STOP_LOADER_SETTINGS";

export const START_LOADER_TENANTS = "START_LOADER_TENANTS";
export const STOP_LOADER_TENANTS = "STOP_LOADER_TENANTS";

export const START_LOADER_ENDPOINTS = "START_LOADER_ENDPOINTS";
export const STOP_LOADER_ENDPOINTS = "STOP_LOADER_ENDPOINTS";

export const START_LOADER_ASSETS = "START_LOADER_ASSETS";
export const STOP_LOADER_ASSETS = "STOP_LOADER_ASSETS";

export const START_LOADER_ALERTS = "START_LOADER_ALERTS";
export const STOP_LOADER_ALERTS = "STOP_LOADER_ALERTS";

export const START_LOADER_ALLOWED_BLOCKED = "START_LOADER_ALLOWED_BLOCKED";
export const STOP_LOADER_ALLOWED_BLOCKED = "STOP_LOADER_ALLOWED_BLOCKED";

export const START_LOADER_SCANNING_EXCLUSION = "START_LOADER_SCANNING_EXCLUSION";
export const STOP_LOADER_SCANNING_EXCLUSION = "STOP_LOADER_SCANNING_EXCLUSION";

export const START_LOADER_EXPLOIT_MITIGATION = "START_LOADER_EXPLOIT_MITIGATION";
export const STOP_LOADER_EXPLOIT_MITIGATION = "STOP_LOADER_EXPLOIT_MITIGATION";

export const START_LOADER_WEBSITE_MANAGEMENT = "START_LOADER_WEBSITE_MANAGEMENT";
export const STOP_LOADER_WEBSITE_MANAGEMENT = "STOP_LOADER_WEBSITE_MANAGEMENT";

export const START_LOADER_DASHBOARD_ALERT = "START_LOADER_DASHBOARD_ALERT";
export const STOP_LOADER_DASHBOARD_ALERT = "STOP_LOADER_DASHBOARD_ALERT";
export const START_LOADER_DASHBOARD_ENDPOINT = "START_LOADER_DASHBOARD_ENDPOINT";
export const STOP_LOADER_DASHBOARD_ENDPOINT = "STOP_LOADER_DASHBOARD_ENDPOINT";

export const START_LOADER_AUDIT_LOGS = "START_LOADER_AUDIT_LOGS";
export const STOP_LOADER_AUDIT_LOGS = "STOP_LOADER_AUDIT_LOGS"

export const START_LOADER_INTRUSION_PREVENTION = "START_LOADER_INTRUSION_PREVENTION";
export const STOP_LOADER_INTRUSION_PREVENTION = "STOP_LOADER_INTRUSION_PREVENTION";

export const SET_RESPONSE_MESSAGE = "SET_RESPONSE_MESSAGE";
export const SET_RESPONSE_TIME = "SET_RESPONSE_TIME";
export const SAVE_SETTINGS_RESPONSE_MESSAGE = "SAVE_SETTINGS_RESPONSE_MESSAGE";
export const GET_SETTINGS_ERROR_RESPONSE = "GET_SETTINGS_ERROR_RESPONSE";
export const GET_SETTINGS_RESPONSE = "GET_SETTINGS_RESPONSE";

export const SET_TENANT_LIST = "GET_TENANT_LIST";
export const GET_TENANT_DATA = "GET_TENANT_DATA";
export const CLEAR_TENANTS_DATA = "CLEAR_TENANTS_DATA";
export const SET_TENANT_LIST_ERROR_RESPONSE = "SET_TENANT_LIST_ERROR_RESPONSE";
export const CLEAR_TENANT_ERROR_RESPONSE = "CLEAR_TENANT_ERROR_RESPONSE"

export const SET_COMPUTERS_LIST = "GET_COMPUTERS_LIST";
export const GET_COMPUTERS_DATA = "GET_COMPUTERS_DATA";
export const CLEAR_COMPUTERS_DATA = "CLEAR_COMPUTERS_DATA";
export const SET_ENDPOINT_LIST_ERROR_RESPONSE = "SET_ENDPOINT_LIST_ERROR_RESPONSE";
export const CLEAR_ENDPOINT_ERROR_RESPONSE = "CLEAR_ENDPOINT_ERROR_RESPONSE";

export const SET_ASSET_LIST = "SET_ASSET_LIST";
export const GET_ASSET_DATA = "GET_ASSET_DATA";
export const CLEAR_ASSET_DATA = "CLEAR_ASSET_DATA";
export const SET_ASSET_LIST_ERROR_RESPONSE = "SET_ASSET_LIST_ERROR_RESPONSE";
export const CLEAR_ASSET_ERROR_RESPONSE = "CLEAR_ASSET_ERROR_RESPONSE";

export const SET_ALERTS_LIST = "SET_ALERTS_LIST";
export const GET_ALERT_DATA = "GET_ALERT_DATA";
export const CLEAR_ALERTS_DATA = "CLEAR_ALERTS_DATA";
export const SET_ALERT_LIST_ERROR_RESPONSE = "SET_ALERT_LIST_ERROR_RESPONSE";
export const CLEAR_ALERT_ERROR_RESPONSE = "CLEAR_ALERT_ERROR_RESPONSE";

export const SET_DASHBOARD_ALERT_DATA = "SET_DASHBOARD_ALERT_DATA";
export const CLEAR_DASHBOARD_ALERT_DATA = "CLEAR_DASHBOARD_ALERT_DATA";
export const SET_DASHBOARD_ENDPOINT_DATA = "SET_DASHBOARD_ENDPOINT_DATA";
export const CLEAR_DASHBOARD_ENDPOINT_DATA = "CLEAR_DASHBOARD_ENDPOINT_DATA";
export const SET_DASHBOARD_ALERT_DATA_ERROR_RESPONSE = "SET_DASHBOARD_DATA_ERROR_RESPONSE";
export const CLEAR_DASHBOARD_ALERT_ERROR_RESPONSE = "CLEAR_DASHBOARD_ERROR_RESPONSE";
export const SET_DASHBOARD_ENDPOINT_DATA_ERROR_RESPONSE = "SET_DASHBOARD_ENDPOINT_DATA_ERROR_RESPONSE";
export const CLEAR_DASHBOARD_ENDPOINT_ERROR_RESPONSE = "CLEAR_DASHBOARD_ENDPOINT_ERROR_RESPONSE";


export const SET_AUDIT_LOG_LIST = "SET_AUDIT_LOG_DATA";
export const GET_AUDIT_LOG_DATA = "GET_AUDIT_LOG_DATA";
export const CLEAR_AUDIT_LOG_DATA = "CLEAR_AUDIT_LOG_DATA";
export const SET_AUDIT_LOG_LIST_ERROR_RESPONSE = "SET_AUDIT_LOG_LIST_ERROR_RESPONSE";
export const CLEAR_AUDIT_LOG_ERROR_RESPONSE = "CLEAR_AUDIT_LOG_ERROR_RESPONSE";

export const SET_ALLOWED_BLOCKED_LIST = "SET_ALLOWED_BLOCKED_LIST";
export const GET_ALLOWED_BLOCKED_DATA = "GET_ALLOWED_BLOCKED_DATA";
export const CLEAR_ALLOWED_BLOCKED_DATA = "CLEAR_ALLOWED_BLOCKED_DATA";
export const SET_ALLOWED_BLOCKED_LIST_ERROR_RESPONSE = "SET_AUDIT_LOG_LIST_ERROR_RESPONSE";
export const CLEAR_ALLOWED_BLOCKED_ERROR_RESPONSE = "CLEAR_ALLOWED_BLOCKED_ERROR_RESPONSE";

export const SET_EXPLOIT_MITIGATION_LIST = "SET_EXPLOIT_MITIGATION_LIST";
export const GET_EXPLOIT_MITIGATION_DATA = "GET_EXPLOIT_MITIGATION_DATA";
export const CLEAR_EXPLOIT_MITIGATION_DATA = "CLEAR_EXPLOIT_MITIGATION_DATA";
export const SET_EXPLOIT_MITIGATION_LIST_ERROR_RESPONSE = "SET_AUDIT_LOG_LIST_ERROR_RESPONSE";
export const CLEAR_EXPLOIT_MITIGATION_ERROR_RESPONSE = "CLEAR_EXPLOIT_MITIGATION_ERROR_RESPONSE";

export const SET_SCANNING_EXCLUSION_LIST = "SET_SCANNING_EXCLUSION_LIST";
export const GET_SCANNING_EXCLUSION_DATA = "GET_SCANNING_EXCLUSION_DATA";
export const CLEAR_SCANNING_EXCLUSION_DATA = "CLEAR_SCANNING_EXCLUSION_DATA";
export const SET_SCANNING_EXCLUSION_LIST_ERROR_RESPONSE = "SET_SCANNING_EXCLUSION_LIST_ERROR_RESPONSE";
export const CLEAR_SCANNING_EXCLUSION_ERROR_RESPONSE = "CLEAR_SCANNING_EXCLUSION_ERROR_RESPONSE";

export const SET_WEBSITE_MANAGEMENT_LIST = "SET_WEBSITE_MANAGEMENT_LIST";
export const GET_WEBSITE_MANAGEMENT_DATA = "GET_WEBSITE_MANAGEMENT_DATA";
export const CLEAR_WEBSITE_MANAGEMENT_DATA = "CLEAR_WEBSITE_MANAGEMENT_DATA";
export const SET_WEBSITE_MANAGEMENT_LIST_ERROR_RESPONSE = "SET_AUDIT_LOG_LIST_ERROR_RESPONSE";
export const CLEAR_WEBSITE_MANAGEMENT_ERROR_RESPONSE = "CLEAR_WEBSITE_MANAGEMENT_ERROR_RESPONSE";

export const SET_INTRUSION_PREVENTION_LIST = "SET_INTRUSION_PREVENTION_LIST";
export const GET_INTRUSION_PREVENTION_DATA = "GET_INTRUSION_PREVENTION_DATA";
export const CLEAR_INTRUSION_PREVENTION_DATA = "CLEAR_INTRUSION_PREVENTION_DATA";
export const SET_INTRUSION_PREVENTION_LIST_ERROR_RESPONSE = "SET_INTRUSION_PREVENTION_LIST_ERROR_RESPONSE";
export const CLEAR_INTRUSION_PREVENTION_ERROR_RESPONSE = "CLEAR_INTRUSION_PREVENTION_ERROR_RESPONSE";

export const SET_QUERY_LIST = "SET_QUERY_LIST";
export const GET_QUERY_DATA = "GET_QUERY_DATA";
export const CLEAR_QUERY_DATA = "CLEAR_QUERY_DATA";
export const SET_QUERY_LIST_ERROR_RESPONSE = "SET_AUDIT_LOG_LIST_ERROR_RESPONSE";
export const CLEAR_QUERY_ERROR_RESPONSE = "CLEAR_QUERY_ERROR_RESPONSE";

export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";

export const CLEAR_SETTINGS_RESPONSE = "CLEAR_SETTINGS_RESPONSE";
export const CLEAR_RESPONSE_TIME = "CLEAR_RESPONSE_TIME";

export const SET_FEATURE_FLAGS = "SET_FEATURE_FLAGS";
export const SET_FEATURE_FLAGS_ERROR_RESPONSE = "SET_FEATURE_FLAGS_ERROR_RESPONSE";