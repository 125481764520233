/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:54
 * @modify date 06-02-2020 12:54
 * @description Below redux reducer is created for settings.
 */
import * as initialState from './initialState';
import * as actionTypes from '../services/types';

export default (state, action) => {
    if (state === undefined) {
        state = initialState.settings
    }
    switch (action.type) {
        case actionTypes.SAVE_SETTINGS_RESPONSE_MESSAGE:
            return {
                ...state,
                response_message: action.data,
                response_status: action.status
            };
        case actionTypes.GET_SETTINGS_RESPONSE:
            return {
                ...state,
                settings_data: action.data.settings,
                tamper_protection_hrs: action.data.tamper_protection_hrs,
                current_version: action.data.current_version,
                latest_version: action.data.latest_version,
                notification_html: action.data.notification_html,
                SophosIdTypeIsTenant: action.data.SophosIdTypeIsTenant,
                SophosTenantId: action.data.SophosTenantId,
                ssl_error_html: action.data.ssl_error_html,
                sophosClientId: action.data.settings && action.data.settings.clientId ? action.data.settings.clientId : '',
                assets_last_updated: action.data.assets_last_updated,
                show_global_settings: action.data.show_global_settings,
                is_new_plugin_version_available: action.data.is_new_plugin_version_available,
                latest_plugin_version: action.data.latest_plugin_version
            };
        case actionTypes.GET_SETTINGS_ERROR_RESPONSE:
            return {
                ...state,
                get_settings_response_message: action.data.message
            };
        case actionTypes.START_LOADER_SETTINGS:
            return {
                ...state,
                is_loading: true
            };
        case actionTypes.STOP_LOADER_SETTINGS:
            return {
                ...state,
                is_loading: false
            };
        case actionTypes.CLEAR_SETTINGS_RESPONSE:
            return {
                ...state,
                response_message: null,
                response_status: null
            }
        default:
    }

    return state;
};