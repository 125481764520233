/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:51
 * @modify date 06-02-2020 12:51
 * @description Below redux reducer is created for customers.
 */
import * as initialState from './initialState';
import * as actionTypes from '../services/types';

export default (state , action) => {
    if(state === undefined){
        state = initialState.customers
    }
    switch (action.type) {
        case actionTypes.SET_TENANT_LIST:
            return {
                ...state,
                tenant_list: action.data.response.items,
                total: action.data.response.pages.total,
                current_page: action.data.response.pages.current,
                page_size: action.data.response.pages.size,
                max_page_size: action.data.response.pages.maxSize,
                req_per_hour: action.data.req_per_hour,
                last_updated_times: action.data.response.last_updated_times
            };
        case actionTypes.SET_RESPONSE_TIME:
            return {
                ...state,
                response_time:action.data.response,
                req_per_hour: action.data.req_per_hour,
                force_limit_reached: action.data.force_limit_reached, 
                last_force_update_time: action.data.last_force_update_time, 
                scheduled: action.data.scheduled, 
                queued_job_exist: action.data.queued_job_exist,
            };
        case actionTypes.SET_TENANT_LIST_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:action.data
            };
        case actionTypes.CLEAR_TENANT_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:null
            };
        case actionTypes.CLEAR_RESPONSE_TIME:
            return {
                ...state,
                force_limit_reached:null, 
                last_force_update_time:null, 
                scheduled:null, 
                queued_job_exist:null,
                response_time:null
            };
        case actionTypes.CLEAR_TENANTS_DATA:
            return {
                ...state,
                tenant_list:[],
                total: null,
                current_page: null,
                page_size: null,
                max_page_size:null,
                req_per_hour: null
            }
        case actionTypes.GET_TENANT_DATA:
            return {...state};
        case actionTypes.START_LOADER_TENANTS:
            return {
                ...state,
                is_loading:true
            };
        case actionTypes.STOP_LOADER_TENANTS:
            return {
                ...state,
                is_loading:false
            };
        default:
    }
  
    return state;
  };