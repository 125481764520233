/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 04-03-2020 15:07
 * @modify date 19-05-2020 13:54
 * @description This component is modal for displaying JSON Viewer error.
 */

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import Accordion from 'react-bootstrap/Accordion';
// import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table'
import { Scrollbars } from 'react-custom-scrollbars';
// import ReactJson from 'react-json-view';

const ERROR_MESSAGE_MAPPING = {
    MissingQuotes:"Quotes are missing at respective row. Some fields containing special characters require wrapping in double quotes.",
    UndetectableDelimiter:"Commas not detected at respective row.",
    TooFewFields:"Not all delimitating commas were detected at respective row. Some columns might be missing.",
    TooManyFields:"Extra commas were detected in respective row. Please wrap the field with double quotes.",
    InvalidQuotes:"Trailing quote on field is missing or malformed.",
}
/** Return the row number while considering header as a row */
const FIND_EXACT_ROW_COUNT=(row,objectCode)=> {
    /** While csv compile issue */
    if(["MissingQuotes","UndetectableDelimiter","InvalidQuotes"].includes(objectCode)){
        return row + 1
    }
    /** While csv data issue */
    else if(["TooFewFields","TooManyFields"].includes(objectCode)){
        return row + 2
    }
}

export const ErrorJSONViewerModal=(props)=>(
        <Modal
            size="lg"
            show={props.show}
            onHide={props.closeModal}
            centered
            backdrop="static"
        >
            <Modal.Header className="hide-bottom-border-modal-header">
                <h6 className="text-danger" style={{marginTop:'0.5rem',marginBottom:'0'}}>CSV Validation Error for {props.errorFor} CSV</h6>
            </Modal.Header>
            <Modal.Body>
                <Scrollbars style={{ width: 'auto', height: 'calc(100vh - 470px)' }}>
                <Table striped bordered hover size="sm" className="json-error-viewer">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Row</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        props.modalMessage?
                            props.modalMessage.map((messageObject,index)=>{
                                return(
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{FIND_EXACT_ROW_COUNT(messageObject.row,messageObject.code)}</td>
                                        <td>{ERROR_MESSAGE_MAPPING[messageObject.code]}</td>
                                    </tr>
                                )
                            })
                        :""
                    }
                    </tbody>
                </Table>
                {/* <ReactJson src={props.modalMessage} theme="monokai" enableClipboard={false} displayDataTypes={false}/> */}
                </Scrollbars>
            </Modal.Body>
            <Modal.Footer className="hide-top-border-modal-footer">
                <Button className="btn-sm" variant="secondary" onClick={props.closeModal}>
                Okay
                </Button>
            </Modal.Footer>
        </Modal>
    )