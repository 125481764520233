export const settings = {
    is_loading: true,
    response_message: null,
    response_status: null,
    get_settings_response_message: null,
    tamper_protection_hrs: null,
    settings_data: null,
    current_version: null,
    latest_version: null,
    notification_html: null,
    SophosIdTypeIsTenant: false,
    sophosClientId: null,
    assets_last_updated: null,
    show_global_settings: null,
    is_new_plugin_version_available:false,
    latest_plugin_version:null
}

export const customers = {
    tenant_list: [],
    req_per_hour: null,
    total: null,
    current_page: null,
    page_size: null,
    max_page_size: null,
    is_loading: false,
    response_message: null,
    error_response_message: null,
    response_time: null
}

export const endpoints = {
    endpoint_list: [],
    req_per_hour: null,
    total: null,
    current_page: null,
    page_size: null,
    max_page_size: null,
    is_loading: false,
    response_message: null,
    error_response_message: null,
    response_time: null
}

export const alerts = {
    alert_list: [],
    total: null,
    current_page: null,
    page_size: null,
    max_page_size: null,
    is_loading: false,
    response_message: null,
    error_response_message: null,
}

export const assets = {
    asset_list: [],
    total: null,
    current_page: null,
    page_size: null,
    max_page_size: null,
    is_loading: false,
    response_message: null,
    error_response_message: null,
    response_time: null
}

export const dashboard = {
    dashboard_alert_data: [],
    dashboard_endpoint_data: {},
    is_loading_alert: false,
    is_loading_endpoint: false,
    response_message: null,
    alert_error_response_message: null,
    endpoint_error_response_message: null,
}

export const audit = {
    audit_log_list: [],
    total: null,
    current_page: null,
    page_size: null,
    max_page_size: null,
    is_loading: false,
    response_message: null,
    error_response_message: null,
    response_time: null
}

export const allowed_blocked_items = {
    allowed_blocked_list: [],
    total: null,
    current_page: null,
    page_size: null,
    max_page_size: null,
    is_loading: false,
    response_message: null,
    error_response_message: null,
}

export const scanning_exclusion_items = {
    scanning_exclusion_list: [],
    total: null,
    current_page: null,
    page_size: null,
    max_page_size: null,
    is_loading: false,
    response_message: null,
    error_response_message: null,
}

export const exploit_mitigation_items = {
    exploit_mitigation_list: [],
    total: null,
    current_page: null,
    page_size: null,
    max_page_size: null,
    is_loading: false,
    response_message: null,
    error_response_message: null,
}

export const website_management_items = {
    website_management_list: [],
    total: null,
    current_page: null,
    page_size: null,
    max_page_size: null,
    is_loading: false,
    response_message: null,
    error_response_message: null,
}

export const intrusion_prevention_items = {
    intrusion_prevention_list: [],
    total: null,
    current_page: null,
    page_size: null,
    max_page_size: null,
    is_loading: false,
    response_message: null,
    error_response_message: null,
}

export const query = {
    query_list: [],
    total: null,
    current_page: null,
    page_size: null,
    max_page_size: null,
    is_loading: false,
    response_message: null,
    error_response_message: null,
}

export const feature_flags = {
    flags: {},
    error: null
}