/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 04-03-2020 15:07
 * @modify date 04-03-2020 15:07
 * @description This component is modal for displaying error.
 */

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const ErrorModal=(props)=>(
        <Modal
            // size="sm"
            show={props.show}
            onHide={props.closeModal}
            centered
            backdrop="static"
        >
            <Modal.Header className="hide-bottom-border-modal-header">
                <h6 className="text-danger" style={{marginTop:'0.5rem',marginBottom:'0'}} dangerouslySetInnerHTML={ { __html:
                    props.modalMessage
                } }></h6>
            </Modal.Header>
            <Modal.Footer className="hide-top-border-modal-footer">
                <Button className="btn-sm" variant="secondary" onClick={props.closeModal}>
                Okay
                </Button>
            </Modal.Footer>
        </Modal>
    )