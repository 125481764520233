import React from 'react';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import _ from "lodash";
import { getTenantsList } from '../../services/apiOperations';

let pathInput;

class AddExploitMitigationItemModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tenantLoading: false,
            selectedTenant: '',
            selectedTenantValidation: '',
            selectedTenantOptionsArray: [],
            paths: '',
            pathValidation: '',
            validated: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(prevProps.show)) {
            if (this.props.show === true) {
                this.setState({
                    selectedTenant: '',
                    selectedTenantValidation: '',
                    selectedTenantOptionsArray: [],
                    paths: '',
                    pathValidation: '',
                    validated: false,
                    tenantLoading: true,
                }, () => this.getTenantList())
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(nextProps.show)) {
            return true
        }
        if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
            return true
        }
        return false
    }

    getTenantList = () => {
        if(this.props.settings.SophosIdTypeIsTenant) {
            let tenantListArray=[{"value":this.props.settings.sophosClientId,"label":this.props.settings.sophosClientId}]
            this.setState({
                tenantLoading:false,
                selectedTenantOptionsArray:tenantListArray,
                selectedTenant: this.props.settings.sophosClientId
            })
        } else {
            getTenantsList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search, this.props.SophosTenantId)
                .then((res) => {
                    let tenantListArray = []
                    res.data.response.items.forEach((elementTenant) => {
                        tenantListArray.push({ "value": elementTenant.id, "label": elementTenant.name })
                    })
                    this.setState({
                        tenantLoading: false,
                        selectedTenantOptionsArray: tenantListArray
                    })
                })
                .catch((err) => {
                    let errMessage = ""
                    if (err.response) {
                        if (err.response.data.message !== undefined)
                            errMessage = err.response.data.message
                        else
                            errMessage = "Something went wrong while fetching tenant list."
                    }
                    else {
                        errMessage = "Something went wrong while fetching tenant list."
                    }

                    this.setState({
                        tenantLoading: false,
                        selectedTenantValidation: errMessage
                    })
                })
        }
    }

    resetModalOptions = () => {
        this.setState({
            selectedTenant: '',
            selectedTenantValidation: '',
            selectedTenantOptionsArray: [],
            paths: '',
        }, () => this.props.closeModal())
    }

    handleTenantChange = (e) => {
        this.setState({
            selectedTenant: e,
            selectedTenantValidation: ''
        }, this.validations)
    }

    handlePathChange = () => {
        this.setState({
            paths: pathInput ? pathInput.value : ''
        }, this.validations)
    }

    validations = () => {
        let validated = true
        validated &= this.state.selectedTenant.length !== 0
        validated &= pathInput && pathInput.value.length !== 0
        this.setState({
            validated: validated
        }, () => { })
    }

    checkMandatorySelection = () => {
        this.validations()
        if (this.state.selectedTenant === "") {
            this.setState({
                selectedTenantValidation: 'Please select tenant.'
            })
            return
        }
        else {
            this.setState({
                selectedTenantValidation: ''
            })
        }
        let data = {
            "paths": [this.state.paths.trim().toString()]
        }

        if(!this.props.settings.SophosIdTypeIsTenant) {
            data["sophosTenantId"] = this.state.selectedTenant
        }
        this.props.confirmModal(data)
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={this.resetModalOptions}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Add Exploit Mitigation</h5>
                </Modal.Header>
                <Modal.Body>
                    {!this.props.settings.SophosIdTypeIsTenant &&
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Tenant</span><span className="text-danger">*</span>
                            <MultiSelectTextbox
                                options={_.orderBy(this.state.selectedTenantOptionsArray, [tenant => tenant.label.toLowerCase()])}
                                customPlaceholder="Select Tenant"
                                filteredSelectedValue={[]}
                                isMulti={false}
                                onSelectOption={(value) => this.handleTenantChange(value)}
                                isLoading={this.state.tenantLoading}
                            />
                            <span className="text-danger">{this.state.selectedTenantValidation}</span>
                        </div>
                    </div>
                    }
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Path<span className="text-danger">*</span> <a href className="text-muted" data-tip data-for="pathDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="pathDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                                Absolute path to an application file to exclude.
                            </ReactTooltip>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                ref={n => pathInput = n}
                                onChange={this.handlePathChange}
                                placeholder="Enter Path"
                            />
                            <span className="text-danger">{this.state.pathValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span className="text-danger">* fields are required.</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" variant="primary"
                        onClick={this.checkMandatorySelection}
                        disabled={!this.state.validated}
                    >
                        Add
                    </Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.resetModalOptions}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AddExploitMitigationItemModal

