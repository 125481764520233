import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import _ from "lodash";
import { CommonLayout } from '../common/CommonLayout';
import { setInitStorage } from '../common/InitLocalStore';
import { sortingData } from '../common/Util';
import { ErrorModal } from '../common/ErrorModal';
import { ActionLoadingModal } from '../common/ActionLoadingModal';
import AddScanningExclusionItemModal from './AddScanningExclusionItemModal';
import UpdateScanningExclusionItemModal from './UpdateScanningExclusionItemModal';
import GlobalSettingsResponseModal from '../common/GlobalSettingsResponseModal';
import { getTenantList, getScanningExclusionList, clearScanningExclusionTable, clearTenantErrorResponseMessage, clearScanningExclusionErrorResponseMessage, sendScanningExclusionItem, sendRequestScanningExclusionDelete, updateScanningExclusionItem, clearNextPossibleResponseMessage } from '../../services/apiOperations';
import TableGrid from '../common/TableGrid';
import { SCANNING_TYPE_OPTIONS, SCAN_MODE_ON_DEMAND_ON_ACCESS_OPTION } from '../common/Constants';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import ConfirmationModal from '../common/ConfirmationModal';
import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/tableGrid.scss';
import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

if (process.env.REACT_APP_HOST_ENV === "production") {
    console.log = function () { }
}

const CONSTANT_TYPE = {
    selectedTenant: "selectedTenant",
    type: "type",
    scanMode:"scanMode"
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getScanningExclusionList: (referrer, queryFilterString, force) => dispatch(getScanningExclusionList(referrer, queryFilterString, force)),
    clearNextPossibleResponseMessage:()=>dispatch(clearNextPossibleResponseMessage()),
    getTenantList: (referrer, queryFilterString) => dispatch(getTenantList(referrer, queryFilterString)),
    clearScanningExclusionTable: () => dispatch(clearScanningExclusionTable()),
    clearScanningExclusionErrorResponseMessage: () => dispatch(clearScanningExclusionErrorResponseMessage()),
    clearTenantErrorResponseMessage: () => dispatch(clearTenantErrorResponseMessage()),
})

const NULL_UNDEFINED_EMPTY_CHECK = [null, undefined, ""]

class ScanningExclusionItems extends React.Component {
    constructor(props) {
        super(props);
        this.addScanningExclusionModalRef = React.createRef();
        this.updateScanningExclusionModalRef = React.createRef();
        setInitStorage(props.referrerHostname, queryString.parse(props.location.search))
        this.state = {
            queryParams: queryString.parse(props.location.search),
            kaseyaParams: queryString.parse(props.location.search),
            tenantList: [],
            filtered: [],
            rowData: [],
            stopInterval: false,
            updateInterval: false,
            clearFilterBool: false,
            clearSearchBox: false,
            selectedAction: '',
            showActionScanningExclusion: false,
            showErrorActionScanningExclusion: false,
            actionScanningExclusionMessage: '',
            columnDefs: [
                {
                    text: "Tenant Name", dataField: "tenant.name", sort: true, onSort: this.onSortChange, formatter: (cell) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(cell))
                            return cell
                        else
                            return "-"
                    }
                },
                {
                    text: "Value", dataField: "value", sort: true, onSort: this.onSortChange, width: 200, formatter: (cell) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(cell))
                            return cell
                        else
                            return "-"
                    }
                },
                {
                    text: "Type", dataField: "typeHR", sort: true, onSort: this.onSortChange, width: 200, formatter: (cell) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(cell))
                            return cell
                        else
                            return "-"
                    }
                },
                {
                    text: "Scan Mode", dataField: "scanModeHR", sort: true, onSort: this.onSortChange, width: 200, formatter: (cell) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(cell))
                            return cell
                        else
                            return "-"
                    }
                },
                {
                    text: "Comment", dataField: "comment", sort: true, onSort: this.onSortChange, width: 200, formatter: (cell) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(cell))
                            return cell
                        else
                            return "-"
                    }
                }
            ],
            defaultSorted: [{
                dataField: 'tenant.name',
                order: 'desc'
            }],
            currentPage: 1,
            pageWiseSelectedRows: [],
            loadingAction: false,
            sortChangeBool: false,
            successCount: 0,
            failCount: 0,
            clearSelection: false,
            selectedScanningExclusionBody: [],
            showDeleteConfirmationModal: false,
            DeleteConfirmationModalMessage: '',
            showAddScanningExclusionItem: false,
            showGlobalSettingsResponseModal: false,
            showUpdateScanningExclusionItemModal: false,
            messageGlobalSettingsResponseModal: '',
            machineName: "",
        }
        this.componentWillMountFunction()
    }

    componentWillMountFunction = () => {
        let queryFitlerString = queryString.parse(this.props.location.search)
        if (queryFitlerString !== "") {
            if (this.props.location.filtersObject) {
                let filters = this.state.filtered
                if (this.props.location.state) {
                    let newPropsSelectedTenantId = { value: this.props.location.state.id, type: CONSTANT_TYPE.selectedTenant }
                    filters.push(newPropsSelectedTenantId)
                }
                this.setState({
                    filtered: filters
                })
            }
            this.props.getTenantList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search);
        }
        else {
            this.props.getTenantList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.scanning_exclusion.force_limit_reached || this.props.scanning_exclusion.queued_job_exist || this.props.scanning_exclusion.scheduled){
            this.showToast();
            this.props.scanning_exclusion.queued_job_exist = null
            this.props.scanning_exclusion.force_limit_reached = null
            this.props.scanning_exclusion.scheduled = null
            this.clearErrorResponseToast()
        }
        if (JSON.stringify(this.props.scanning_exclusion.scanning_exclusion_list) !== JSON.stringify(prevState.rowData)) {
            this.setState({
                rowData: this.props.scanning_exclusion.scanning_exclusion_list
            })
            this.clearErrorResponseToast()
        }
        if (JSON.stringify(this.props.scanning_exclusion.error_response_message) !== JSON.stringify(prevProps.scanning_exclusion.error_response_message)) {
            this.setState({
                scanningExclusionErrorResponse: this.props.scanning_exclusion.error_response_message
            })
        }
        if (JSON.stringify(this.props.tenants.tenant_list) !== JSON.stringify(prevProps.tenants.tenant_list)) {
            let createTenantListPair = []
            this.props.tenants.tenant_list.forEach((element) => {
                createTenantListPair.push({ value: element.id, label: element.showAs })
            })
            this.setState({
                tenantList: createTenantListPair
            })
            if (this.props.location.state === undefined) {
                this.getScanningExclusionData(false, false)
            }
        }
        if (
            this.props.settings.SophosIdTypeIsTenant &&
            JSON.stringify(this.props.settings.SophosIdTypeIsTenant) !==
            JSON.stringify(prevProps.settings.SophosIdTypeIsTenant)
        ) {
            this.getScanningExclusionData();
            const columDefs = [...this.state.columnDefs];
            const withoutTenantColumnDefs = _.remove(
                columDefs,
                (cols) => cols.dataField !== "tenant.name"
            );
            this.setState({ columnDefs: withoutTenantColumnDefs });
        }
    }

    componentWillUnmount() {
        this.clearErrorResponseToast()
        this.clearTenantErrorResponseToast()
    }

    onSortChange = () => {
        this.setState({
            sortChangeBool: !this.state.sortChangeBool
        })
    }

    clearNextpossibleToast=()=>{
        this.props.clearNextPossibleResponseMessage()
    }

    showToast=()=>{
        if(this.props.scanning_exclusion.force_limit_reached){
            let last_sync_time=new Date(this.props.scanning_exclusion.last_force_update_time*1000)
            let req_per_hour_diff=Math.round(60/this.props.scanning_exclusion.req_per_hour)
            let next_response_time=new Date((this.props.scanning_exclusion.last_force_update_time*1000)+(1000*60*req_per_hour_diff))
            toast.error(`Last sync ran at ${last_sync_time.toLocaleTimeString()}. Next available sync at ${next_response_time.toLocaleTimeString()}`,{containerId: 'A', onClose: () => this.clearNextpossibleToast()});
        }
        else if(this.props.scanning_exclusion.queued_job_exist)
        {
            toast.error("Job already exist in queue",{containerId: 'A', onClose: () => this.clearNextpossibleToast()});
        }
        else if(this.props.scanning_exclusion.scheduled)
        {
            toast.success("Force sync job scheduled successfuly",{containerId: 'A', onClose: () => this.clearNextpossibleToast()});
        }
    }

    filterData = (data) => {
        let result = []
        let appliedFilterLength = this.state.filtered.length;
        if (appliedFilterLength > 0) {
            for (let i = 0; i < data.length; i++) {
                let matchedCount = 0
                for (let j = 0; j < appliedFilterLength; j++) {
                    if (this.state.filtered[j].type === CONSTANT_TYPE.selectedTenant && data[i]["tenant"] && data[i]["tenant"]["id"].includes(this.state.filtered[j].value)) {
                        matchedCount++;
                    }
                    if (this.state.filtered[j].type === CONSTANT_TYPE.type && ((this.state.filtered[j].value && this.state.filtered[j].value.toLowerCase() === "all") || (data[i]["type"] && data[i]["type"].toLowerCase() === this.state.filtered[j].value.toLowerCase()))) {
                        matchedCount++;
                    }
                    if (this.state.filtered[j].type === CONSTANT_TYPE.scanMode && ((this.state.filtered[j].value && this.state.filtered[j].value.toLowerCase() === "all") || (data[i]["scanMode"] && data[i]["scanMode"].toLowerCase() === this.state.filtered[j].value.toLowerCase()))) {
                        matchedCount++;
                    }
                }
                if (matchedCount === appliedFilterLength) {
                    result.push(data[i])
                }
            }
        } else {
            result = [...data]
        }
        return result
    }

    getPageWiseSelectedRowsFromTable = (currentPage, selectedRowData) => {
        let selectedScanningExclusionBody = []
        selectedRowData.forEach((eachPage) => {
            eachPage.selectedRow.forEach((eachRow) => {
                selectedScanningExclusionBody.push(eachRow)
            })
        })
        this.setState({
            currentPage: currentPage,
            pageWiseSelectedRows: selectedRowData,
            selectedScanningExclusionBody: selectedScanningExclusionBody
        })
    }

    setTablePage = (page) => {
        this.setState({
            currentPage: page,
        })
    }

    findFilterValueByType(arrayValue, searchType) {
        return arrayValue.type === searchType
    }

    selectFilters = (value, type, intervalInit = false) => {
        let filters = this.state.filtered
        let newfilterObj = { value: value, type: type }
        const obj = filters.find((arrayValue) => this.findFilterValueByType(arrayValue, newfilterObj.type))
        if (obj !== undefined) {
            const index = filters.indexOf(obj)
            filters.splice(index, 1)
        }
        if (value !== '')
            filters.push(newfilterObj)
        this.setState({
            filtered: filters,
            clearSearchBox: !this.state.clearSearchBox
        })
    }

    clearFilter() {
        let filters = []
        this.setState({ filtered: filters, clearFilterBool: !this.state.clearFilterBool, updateInterval: !this.state.updateInterval, stopInterval: false, selectedAction: "" })
    }

    getScanningExclusionData(fromOtherFilter = false, intervalInit = false, force=false) {
        if (this.props.location.search !== "") {
            if(!force)
            {
                this.props.clearScanningExclusionTable()
            }
            const queryParams = queryString.parse(this.props.location.search)
            if(force){
                let filters = this.state.filtered;
                let filteredObject=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant));
                if(filteredObject){
                    queryParams.sophosTenantId=filteredObject.value
                }
            }
            const queryFilter = queryString.stringify(queryParams)
            this.props.getScanningExclusionList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), "?" + queryFilter, force)
        }
        else {
            this.props.getScanningExclusionList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), force)
        }
    }

    getScanningExclusionSelectedId = (selectedId) => {
        this.setState({
            singleSelectedScanningExclusionId: selectedId
        })
    }

    tablegridMessage() {
        if (!this.props.scanning_exclusion.is_loading) {
            let filteredObject = this.state.filtered.find((arrayValue) => this.findFilterValueByType(arrayValue, CONSTANT_TYPE.selectedTenant))
            if (filteredObject !== undefined) {
                let checkTenantDetails = this.props.tenants.tenant_list.find((tenantValue) => tenantValue.id === filteredObject.value)
                if (checkTenantDetails) {
                    if (checkTenantDetails.apiHost === null || checkTenantDetails.apiHost === "" || checkTenantDetails.apiHost === "NULL" || checkTenantDetails.apiHost === "null" || checkTenantDetails.apiHost === undefined) {
                        return { message: 'This account has not been activated; <br/>Please activate from <a target="_blank" href="https://cloud.sophos.com/">Sophos Central Administration Console</a>', type: "error" }
                    }
                    else {
                        return { message: "No Scanning Exclusion Items found to match your search.", type: "info" }
                    }
                }
                else {
                    return { message: "No Scanning Exclusion Items found to match your search.", type: "info" }
                }

            }
            else {
                return { message: "No Scanning Exclusion Items found to match your search.", type: "info" }
            }
        }
        else {
            return { message: "Loading....", type: "info" }
        }
    }

    clearErrorResponseToast = () => {
        this.props.clearScanningExclusionErrorResponseMessage()
    }

    clearTenantErrorResponseToast = () => {
        this.props.clearTenantErrorResponseMessage()
    }

    getNonSelectableRows = (allRows) => {
        let allNonselectableRowIds = []
        if (this.state.selectedAction !== "") {
            allRows.forEach((element) => {
                if (element.scanningExclusionAction) {
                    allNonselectableRowIds.push(element.id)
                }
            })
        }
        else {
            allNonselectableRowIds = []
        }
        return allNonselectableRowIds
    }

    closeActionScanningExclusion = () => {
        if (this.state.pageWiseSelectedRows.length === 0 || this.state.pageWiseSelectedRows[0].selectedRow.length === 0) {
            this.setState({
                showErrorActionScanningExclusion: false,
                showActionScanningExclusion: false,
                actionScanningExclusionMessage: ""
            })
        }
        else {
            this.setState({
                showActionScanningExclusion: false,
                showErrorActionScanningExclusion: false,
                actionScanningExclusionMessage: "",
                clearSearchBox: !this.state.clearSearchBox,
                selectedAction: ""
            }, () => {
                this.getScanningExclusionData()
            })
        }
    }

    AddScanningExclusionActionHandler = (ItemData) => {
        this.setState({
            loadingAction: true,
            actionScanningExclusionMessage: ""
        }, () => {
            let requestBody = ItemData
            sendScanningExclusionItem(requestBody, this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search)
                .then((res) => {
                    this.setState({
                        loadingAction: false,
                        showAddScanningExclusionItem: false,
                        showGlobalSettingsResponseModal: true,
                        messageGlobalSettingsResponseModal: "Scanning exclusion added successfully."
                    }, this.getScanningExclusionData)
                    console.log(res)
                })
                .catch((err) => {
                    let errMessage = ""
                    if (err.response) {
                        if (err.response.data.message !== undefined)
                            errMessage = err.response.data.message
                        else
                            errMessage = "Something went wrong; Please try again later."
                    }
                    else {
                        errMessage = "Something went wrong; Please try again later."
                    }
                    this.setState({
                        loadingAction: false,
                        showErrorActionScanningExclusion: true,
                        actionScanningExclusionMessage: errMessage
                    })
                })
        })
    }

    AddScanningExclusionAction = () => {
        if (this.addScanningExclusionModalRef.current) {
            this.addScanningExclusionModalRef.current.handleTypeChange("amsi");
        }
        this.setState({
            showAddScanningExclusionItem: true,
        });
    }

    CloseScanningExclusionAction = () => {
        this.setState({
            showAddScanningExclusionItem: false,
        })
    }

    CloseGlobalSettingsResponseModal=()=>{
        this.setState({
            showGlobalSettingsResponseModal: false,
        })   
    }

    ShowDeleteConfirmationModal = () => {
        this.setState({
            loadingAction:false,
            showDeleteConfirmationModal:true,
            DeleteConfirmationModalMessage: `Are you sure you want to delete <strong>${this.state.pageWiseSelectedRows[0].selectedRow.length}</strong> selected scanning exclusion item(s)?<small class="form-text text-muted pt-3"><strong>Note: </strong>Delete action may take some time to reflect in our systems.</small>`
        })
    }
    
    DeleteScanningExclusionHandler = () =>{
        let selectedItemsBody=[]
        this.state.pageWiseSelectedRows.forEach((eachPage)=>{
            eachPage.selectedRow.forEach((eachRow)=>{
                selectedItemsBody.push({"itemId":eachRow.id,"tenantId":eachRow.tenant.id,value:eachRow.value, "dataRegion": eachRow.tenant.dataRegion })
            })
        })
        let requestBody={
            "items":selectedItemsBody,
        }
        this.setState({
            loadingAction:false,
            showDeleteConfirmationModal:false,
        },()=>{
            sendRequestScanningExclusionDelete(
                this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),
                requestBody,
                this.props.location.search
            )
            .then(res=>{
                this.setState({
                    loadingAction:false,
                    clearSelection: !this.state.clearSelection,
                    showGlobalSettingsResponseModal:true,
                    messageGlobalSettingsResponseModal: "Deletion request received successfully."
                })
                setTimeout(()=>{
                    this.getScanningExclusionData()
                }, 1000)
            })
            .catch((err)=>{
                let errMessage=""
                if(err.response){
                    if(err.response.data.message!==undefined)
                        errMessage=err.response.data.message
                    else
                        errMessage="Something went wrong; Please try again later."
                }
                else{    
                    errMessage="Something went wrong; Please try again later."
                }
                this.setState({
                    loadingAction:false,
                    showErrorActionScanningExclusion:true,
                    actionScanningExclusionMessage:errMessage,
                })
            })
        })
    }

    CloseDeleteConfirmationModal = () => {
        this.setState({
            showDeleteConfirmationModal: false
        })
    }

    UpdateScanningExclusionActionHandler = (ItemData) => {
        this.setState({
            loadingAction: true,
            actionScanningExclusionMessage: ""
        }, () => {
            let requestBody = ItemData
            updateScanningExclusionItem(requestBody, this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search)
                .then((res) => {
                    this.setState({
                        loadingAction: false,
                        showUpdateScanningExclusionItemModal: false,
                        showGlobalSettingsResponseModal: true,
                        messageGlobalSettingsResponseModal: "Scanning exclusion updated successfully."
                    }, this.getScanningExclusionData)
                })
                .catch((err) => {
                    let errMessage = ""
                    if (err.response) {
                        if (err.response.data.message !== undefined)
                            errMessage = err.response.data.message
                        else
                            errMessage = "Something went wrong; Please try again later."
                    }
                    else {
                        errMessage = "Something went wrong; Please try again later."
                    }
                    this.setState({
                        loadingAction: false,
                        showErrorActionScanningExclusion: true,
                        actionScanningExclusionMessage: errMessage
                    })
                })
        })
    }

    UpdateScanningExclusionAction = () => {
        if (this.updateScanningExclusionModalRef.current) {
            this.updateScanningExclusionModalRef.current.resetModal();
        }
        this.setState({
            showUpdateScanningExclusionItemModal: true,
        })
    }

    CloseUpdateScanningExclusionAction = () => {
        this.setState({
            showUpdateScanningExclusionItemModal: false,
        })
    }

    render() {
        let tenantCheck=this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))
        let checkTenantDetails;
        if(tenantCheck)
            checkTenantDetails=this.props.tenants.tenant_list.find((tenantValue)=>tenantValue.id===tenantCheck.value)

        const tenantsWithDataRegionNull = [];
        let clearTenantSelect = false;
        this.props.tenants.tenant_list.forEach((tenant) => {
            if (
                NULL_UNDEFINED_EMPTY_CHECK.includes(tenant.dataRegion) ||
                NULL_UNDEFINED_EMPTY_CHECK.includes(tenant.apiHost)
              ) {
                tenantsWithDataRegionNull.push(tenant.id);
              }
        });
        let selectedTenantId = this.state.filtered.find(
            (arrayValue) => arrayValue.type === CONSTANT_TYPE.selectedTenant
        );
        if (
            selectedTenantId &&
            tenantsWithDataRegionNull.length &&
            tenantsWithDataRegionNull.filter(
                (item) => item === selectedTenantId.value
            ).length
        ) {
            clearTenantSelect = true;
        }

        let messageForForceSync = this.props.settings?.SophosIdTypeIsTenant ? this.props.scanning_exclusion && this.props.scanning_exclusion.last_updated_times && this.props.scanning_exclusion.last_updated_times[this.props.settings?.SophosTenantId] ? 'Last Force Sync Time: ' + moment.unix(this.props.scanning_exclusion.last_updated_times[this.props.settings?.SophosTenantId]).format('YYYY-MM-DD HH:mm:ss') : '' : selectedTenantId && selectedTenantId.value && this.props.scanning_exclusion.last_updated_times && this.props.scanning_exclusion.last_updated_times[selectedTenantId.value] ? 'Last Force Sync Time: ' + moment.unix(this.props.scanning_exclusion.last_updated_times[selectedTenantId.value]).format('YYYY-MM-DD HH:mm:ss') : ''

        return (
            <CommonLayout title="Scanning Exclusion Items" displayTitle={true}>
                <ErrorModal
                    show={this.props.tenants.error_response_message !== null || this.props.scanning_exclusion.error_response_message !== null}
                    closeModal={() => {
                        this.props.tenants.error_response_message !== null ?
                            this.clearTenantErrorResponseToast()
                            :
                            this.clearErrorResponseToast()
                    }}
                    modalMessage={
                        this.props.tenants.error_response_message !== null && this.props.scanning_exclusion.error_response_message === null ?
                            this.props.tenants.error_response_message
                            :
                            this.props.scanning_exclusion.error_response_message}
                />
                <ErrorModal
                    show={this.state.showErrorActionScanningExclusion}
                    closeModal={this.closeActionScanningExclusion}
                    modalMessage={this.state.actionScanningExclusionMessage}
                />
                <ActionLoadingModal show={this.state.loadingAction} />
                <ConfirmationModal
                    show={this.state.showDeleteConfirmationModal}
                    confirmAction={() => this.DeleteScanningExclusionHandler()}
                    closeModal={this.CloseDeleteConfirmationModal}
                    modalMessage={this.state.DeleteConfirmationModalMessage}
                />
                <AddScanningExclusionItemModal
                    {...this.props}
                    show={this.state.showAddScanningExclusionItem}
                    closeModal={this.CloseScanningExclusionAction}
                    confirmModal={this.AddScanningExclusionActionHandler}
                    ref={this.addScanningExclusionModalRef}
                />
                <UpdateScanningExclusionItemModal
                    {...this.props}
                    show={this.state.showUpdateScanningExclusionItemModal}
                    update_data={this.state.selectedScanningExclusionBody}
                    closeModal={this.CloseUpdateScanningExclusionAction}
                    confirmModal={this.UpdateScanningExclusionActionHandler}
                    ref={this.updateScanningExclusionModalRef}
                />
                <GlobalSettingsResponseModal
                    {...this.props}
                    show={this.state.showGlobalSettingsResponseModal}
                    closeModal={this.CloseGlobalSettingsResponseModal}
                    message={this.state.messageGlobalSettingsResponseModal}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={7000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable={false}
                    pauseOnHover={false}
                    enableMultiContainer 
                    containerId={'A'}
                />
                <div className="row mt-2 mx-1">
                    <div className="col-6">
                        <div className="input-group-append row">
                            {!this.props.settings.SophosIdTypeIsTenant && 
                                <div className="col px-0 mx-1 text-left">
                                    <div className="col-sm-12 px-0 mx-1 text-left">
                                        <span>Tenants</span>
                                        <MultiSelectTextbox
                                            options={this.state.tenantList.sort(sortingData)}
                                            isMulti={false}
                                            filteredSelectedValue={
                                                this.state.filtered.find((arrayValue) =>
                                                    this.findFilterValueByType(arrayValue, CONSTANT_TYPE.selectedTenant)) ?
                                                    this.state.tenantList.filter((element) => element.value === this.state.filtered.find((arrayValue) =>
                                                        this.findFilterValueByType(arrayValue, CONSTANT_TYPE.selectedTenant)).value)
                                                    : []
                                            }
                                            onSelectOption={(value) => this.selectFilters(value, CONSTANT_TYPE.selectedTenant)}
                                            clearValue={this.state.clearFilterBool}
                                            clearable={true}
                                            isLoading={this.props.tenants.is_loading}
                                            disabledSelect={this.props.scanning_exclusion.is_loading}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="col px-0 mx-1 text-left">
                                <span>Type</span>
                                <MultiSelectTextbox
                                    options={SCANNING_TYPE_OPTIONS}
                                    isMulti={false}
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue) =>
                                            this.findFilterValueByType(arrayValue, CONSTANT_TYPE.type))
                                    }
                                    onSelectOption={(value) => this.selectFilters(value, CONSTANT_TYPE.type)}
                                    clearable={true}
                                    clearValue={this.state.clearFilterBool}
                                />
                            </div>
                            <div className="col px-0 mx-1 text-left">
                                <span>Scan Mode</span>
                                <MultiSelectTextbox
                                    options={SCAN_MODE_ON_DEMAND_ON_ACCESS_OPTION}
                                    isMulti={false}
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue) =>
                                            this.findFilterValueByType(arrayValue, CONSTANT_TYPE.scanMode))
                                    }
                                    onSelectOption={(value) => this.selectFilters(value, CONSTANT_TYPE.scanMode)}
                                    clearable={true}
                                    clearValue={this.state.clearFilterBool}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-1 text-left mt-3">
                        <button type="button" className="btn btn-secondary btn-sm ml-2" disabled={this.props.scanning_exclusion.is_loading} onClick={this.clearFilter.bind(this)}>Clear</button>
                    </div>
                    <div className="col-5 justify-content-end flex pt-3 pr-0" style={{ display: "flex" }}>

                        <button style={{ maxHeight: "29px" }} type="button" className="btn btn-primary btn-sm mr-2 " onClick={this.AddScanningExclusionAction}>
                            Add
                        </button>
                        <button style={{ maxHeight: "29px" }} disabled={!(this.state.selectedScanningExclusionBody && this.state.selectedScanningExclusionBody.length === 1)} type="button" className="btn btn-primary btn-sm mr-2" onClick={this.UpdateScanningExclusionAction}>
                            Update
                        </button>
                        <button style={{ maxHeight: "29px" }} disabled={!(this.state.selectedScanningExclusionBody && this.state.selectedScanningExclusionBody.length > 0)} type="button" className="btn btn-primary btn-sm mr-2" onClick={this.ShowDeleteConfirmationModal}>
                            Delete
                        </button>
                        <button type="button" style={{ maxHeight: "29px" }} className="btn btn-outline-primary btn-sm" disabled={((tenantCheck===undefined || checkTenantDetails===undefined) && !this.props.settings.SophosIdTypeIsTenant) || clearTenantSelect} onClick={this.getScanningExclusionData.bind(this,false,false,true)}><i className="fa fa-refresh"/> Force Sync</button>
                        <button type="button" style={{ maxHeight: "29px" }} className="btn btn-primary round-help-btn ml-2 btn-sm" data-tip={this.props.scanning_exclusion.req_per_hour!==null?`Maximum of ${this.props.scanning_exclusion.req_per_hour}x per hour allowed. ${!this.props.settings.SophosIdTypeIsTenant ? "Select a tenant to enable Force Sync." : ""}`: `Maximum of 4x per hour allowed. ${!this.props.settings.SophosIdTypeIsTenant ? "Select a tenant to enable Force Sync." : ""}`} data-for='helpTime'><i className="fa fa-question"/></button>
                        <ReactTooltip id="helpTime" effect="solid" type="info" place="left"/>
                    </div>
                </div>
                <div className="row mt-2 mx-1">
                    <div className="col-12 text-right">
                        <b>{messageForForceSync}</b>
                    </div>
                </div>
                <div className="row m-1 card-content-height">
                    <div className="col-md-12 pr-1">
                        <TableGrid
                            clearValue={this.state.clearFilterBool}
                            defaultSortedCol={this.state.defaultSorted}
                            columnStructure={this.state.columnDefs}
                            responseData={this.filterData(this.state.rowData)}
                            loading={this.props.scanning_exclusion.is_loading}
                            stateObject={this.props.scanning_exclusion}
                            pageSize={this.filterData(this.state.rowData).length < this.props.scanning_exclusion.page_size ? this.filterData(this.state.rowData).length : this.props.scanning_exclusion.page_size}
                            filtered={this.state.filtered}
                            noDataText={this.tablegridMessage()}
                            keyField="_id"
                            moduleName="scanningExclusion"
                            clearSearchBox={this.state.clearSearchBox}
                            note="Scanning Exclusion Items will be displayed in some time if you have configured settings recently."
                            singleSelectedRow={(selectedId) => this.getScanningExclusionSelectedId(selectedId)}
                            pageWiseSelectedRowsFromTable={(currentPage, selectedRowData) => this.getPageWiseSelectedRowsFromTable(currentPage, selectedRowData)}
                            onPageChangeEvent={(page) => this.setTablePage(page)}
                            nonSelectableRows={this.getNonSelectableRows(this.filterData(this.state.rowData))}
                            onSortChange={this.state.sortChangeBool}
                            onPerformAction={this.state.clearSelection}
                        />
                    </div>
                </div>
            </CommonLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanningExclusionItems)