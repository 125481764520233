/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 13:00
 * @modify date 06-02-2020 13:00
 * @description This file contains the component of MultiSelectTextbox which creates a dropdown selection box.
 */
import React, { Component } from "react";
import Select from "react-dropdown-select";
import styled from '@emotion/styled';
// import { Typeahead, Menu, MenuItem, Highlighter } from "react-bootstrap-typeahead";

const dropdownHeight = "150px";

class MultiSelectTextbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: props.filteredSelectedValue?props.filteredSelectedValue:[],
      options: props.options
    };
    // this.componentWillMountFunction()
  }

  // componentWillMountFunction=()=>{
  //   if(this.props.filteredSelectedValue){
  //       this.setState({
  //         values:this.props.filteredSelectedValue
  //       })
  //   }
  // }

  componentDidUpdate(prevProps){
    if(JSON.stringify(prevProps)!==JSON.stringify(this.props)){
      if(JSON.stringify(this.props.clearValue)!==JSON.stringify(prevProps.clearValue)){
        if(this.props.filteredSelectedValue)  
          this.setState({
            values:this.props.filteredSelectedValue,
          })
        else
          this.setState({
            values:[],
          })
      }
      if(JSON.stringify(this.props.options)!==JSON.stringify(prevProps.options)){
        this.setState({
          options: this.props.options,
          values:this.props.filteredSelectedValue
        })
      }
    }
  }

  // UNSAFE_componentWillReceiveProps(nextProps){
  //   if(JSON.stringify(nextProps)!==JSON.stringify(this.props)){
  //     if(nextProps.clearValue){
  //       this.setState({
  //         value:""
  //       })
  //     }
  //     if(JSON.stringify(this.props.options)!==JSON.stringify(nextProps.options)){
  //       this.setState({
  //         options: nextProps.options
  //       })
  //     }
  //   }
  // }

  handleInputChange=(obj)=> {
        this.setState({ values:obj });
        let filter = [];
        try{
          filter = obj.map((item)=>{return item.value;})
          this.props.onSelectOption(filter.toString())
        }
        catch(err){
          
        this.props.onSelectOption(obj.value.toString())
        }
  }

  customNoDataRenderer = () => (
    <ItemLabel style={{cursor:"initial"}}>
      No matches found.
    </ItemLabel>
  );

  render() {
    return (
      <React.Fragment>
        <Select
          key={this.state.values}
          style={this.props.style}
          multi={this.props.isMulti}
          placeholder={this.props.customPlaceholder?this.props.customPlaceholder:"All"}
          className={`react-dropdown-select-content-custom ${this.props.className ? this.props.className: ''}`}
          loading={this.props.isLoading}
          autoFocus={false}
          dropdownHandle={false}
          values={this.state.values}
          noDataRenderer={this.customNoDataRenderer}
          // value={this.state.value}
          onChange={selectedOptions => {
                this.handleInputChange(selectedOptions);
              }}
            // contentRenderer={this.customContentRenderer}
          clearable={this.props.clearable && this.state.values.length>0?true:false}
          options={this.state.options}
          disabled={this.props.disabledSelect}
          disabledLabel={this.props.customDisabledLabel?this.props.customDisabledLabel:"disabled"}
          dropdownHeight={dropdownHeight}
        />
      </React.Fragment>
    );
  }
}

const ItemLabel = styled.div`
  margin: 5px 10px;
`;

export default MultiSelectTextbox;
