import React from 'react';
import {setInitStorage} from '../common/InitLocalStore';
import queryString from 'query-string';
import _ from "lodash";
import {CommonLayout} from '../common/CommonLayout';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import {getAuditlogList, clearAuditLogListErrorResponseMessage} from '../../services/apiOperations';
import TableGrid from '../common/TableGrid';
import { connect } from 'react-redux';
import DateTimeRangeTextbox from '../common/DateTimeRangeTextbox';
import {ErrorModal} from '../common/ErrorModal'
import moment from 'moment';


const NULL_UNDEFINED_EMPTY_CHECK = [null, undefined, ""]

const tabOptions=[
    { value:"ALERT",label:"Alerts"},
    { value:"DEPLOYMENT", label:"Deployments"},
    { value:"ENDPOINT",label:"Endpoints"},
    { value:"SETTING",label:"Settings"},
    { value:"INTRUSION_PREVENTIONS",label:"Intrusion Prevention"},
    { value:"ALLOWED_BLOCKED",label: "Allowed And Blocked Items"},
    { value:"SCANNING_EXCLUSIONS",label:"Scanning Exclusions"},
    { value:"WEBSITE_MANAGEMENT",label:"Website Management"},
    { value:"EXPLOIT_MITIGATION",label:"Exploit Mitigation"},
];

const typeOptions=[
    { value:"ERROR",label:"error"},
    { value:"INFO",label:"info"},
];


const CONSTANT_TYPE={
    tab: "tab",
    description: "description",
    timestamp: "datetime",
    type: "type",
    fromDate:"fromDate",
    toDate:"toDate",
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getAuditlogList: (referrer,queryFilterString) => dispatch(getAuditlogList(referrer,queryFilterString)),
    clearAuditLogListErrorResponseMessage:()=>dispatch(clearAuditLogListErrorResponseMessage()),
})

class Auditlogs extends React.Component{
    constructor(props){
        super(props);
        setInitStorage(props.referrerHostname, queryString.parse(props.location.search))
        this.state = {
            queryParams:queryString.parse(props.location.search),
            kaseyaParams:queryString.parse(props.location.search),
            rowData:[],
            filtered: [],
            stopInterval:false,
            updateInterval:false,
            clearFilterBool:false,
            clearSearchBox:false,
            dateRangeValue:"",
            columnDefs: [
                { text: "Tab", dataField: CONSTANT_TYPE.tab , sort: true,
                        headerStyle: () => { return { width: '90px'};}, fixed:true},
                { text: "Timestamp", dataField: CONSTANT_TYPE.timestamp, sort: true,
                        headerStyle: () => { return { width: '150px'};},
                        formatter: (cell,row) =>
                            {
                            if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                                if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.datetime))
                                    return(moment(row.datetime).format("DD-MMM-YYYY HH:mm"))
                                else
                                    return "-"
                            else
                                return "-"
                            }

                },
                { text: "Type", dataField: CONSTANT_TYPE.type, sort: true,
                        headerStyle: () => { return { width: '80px'};} },
                { text: "Description", dataField: CONSTANT_TYPE.description, sort: false},

            ],
            defaultSorted : [{
                dataField: CONSTANT_TYPE.timestamp,
                order: 'desc'
            }],
            defaultColDef: {
                sortable: true,
                resizable: true,
                enablePivot: true,
                enableValue: true,
            },
        }
        this.componentWillMountFunction()
    }

    componentWillMountFunction=()=>{
        let queryFitlerString=queryString.parse(this.props.location.search)
        if(queryFitlerString!==""){
            if(this.props.location.filtersObject){
                let filters=this.state.filtered
                    let newPropsSelectedTab = { value: this.props.location.filtersObject.value, type: CONSTANT_TYPE.tab}
                    filters.push(newPropsSelectedTab)
                    this.setState({
                        filtered:filters
                    })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.audit.audit_log_list!==this.props.audit.audit_log_list){
          this.setState({rowData: this.props.audit.audit_log_list, clearSearchBox: !this.state.clearSearchBox});
        }
    }

    componentWillUnmount(){
        this.clearErrorResponseModal()
    }

    refreshData=()=>{
        this.setState({
            updateInterval:!this.state.updateInterval
        })
    }

    findFilterValueByType(arrayValue,searchType){
        return arrayValue.type===searchType
    }

    selectFilters=(value,type)=>{
        let filters=this.state.filtered
        if(type!==CONSTANT_TYPE.dateRange){
            let newfilterObj={value:value,type:type}

            const obj =filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,newfilterObj.type))
            if(obj!==undefined){
                const index=filters.indexOf(obj)
                filters.splice(index,1)
            }
            if(value!=='')
                filters.push(newfilterObj)
            this.setState({
                filtered:filters
            })
        }
        else{
            let fromDateObj={value:value.fromDate.toISOString(),type:CONSTANT_TYPE.fromDate}
            const fromDateObjFilterExist =filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,fromDateObj.type))
            if(fromDateObjFilterExist!==undefined){
                const index=filters.indexOf(fromDateObjFilterExist)
                filters.splice(index,1)
            }
            filters.push(fromDateObj)

            let toDateObj={value:value.toDate.toISOString(),type:CONSTANT_TYPE.toDate}
            const toDateObjFilterExist =filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,toDateObj.type))
            if(toDateObjFilterExist!==undefined){
                const index=filters.indexOf(toDateObjFilterExist)
                filters.splice(index,1)
            }
            filters.push(toDateObj)
            this.setState({
                filtered:filters,
                clearSearchBox: !this.state.clearSearchBox,
                dateRangeValue: value.datepickerTextValue?value.datepickerTextValue:""
            })

        }
        this.getAuditlogData()
    }

    clearFilter(){
        this.setState({filtered:[],clearFilterBool:!this.state.clearFilterBool, updateInterval: !this.state.updateInterval, stopInterval:false,})
    }

    getAuditlogData(){
        if(this.props.location.search!==""){
            const queryParams=queryString.parse(this.props.location.search)
            let filters=this.state.filtered
            if(filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.fromDate))!==undefined && filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.toDate))!==undefined)
            {
                let filteredObjectFromDate=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.fromDate))
                queryParams.fromDate=filteredObjectFromDate.value
                let filteredObjectToDate=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.toDate))
                queryParams.toDate=filteredObjectToDate.value
            }
            if(filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.tab))!==undefined)
            {
                let filteredObject=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.tab))
                queryParams.tab=filteredObject.value
            }
            if(filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.type))!==undefined)
            {
                let filteredObject=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.type))
                queryParams.type=filteredObject.value
            }
            const queryFilter=queryString.stringify(queryParams)
            this.props.getAuditlogList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),"?"+queryFilter)
        }
        else{
            this.props.getAuditlogList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"))
        }
    }

    addDateRangeFilters(range,intervalInit){
        this.selectFilters(range,CONSTANT_TYPE.dateRange,intervalInit)
    }

    clearErrorResponseModal=()=>{
        this.props.clearAuditLogListErrorResponseMessage()
    }

    tablegridMessage(){
        if(!this.props.audit.is_loading){
            return {message:"No audit logs found to match your search",type:"info"}
        }
        else{
            return {message:"Loading....",type:"info"}
        }
    }

    render(){
        this.props.settings.SophosIdTypeIsTenant && _.remove(tabOptions, tab => tab.value === "DEPLOYMENT")
        return(
            <CommonLayout title="Audit Logs" displayTitle={true}>
                <ErrorModal
                    show={this.props.audit.error_response_message!==null}
                    closeModal={()=>{
                            this.clearErrorResponseModal()
                        }}
                    modalMessage={this.props.audit.error_response_message}
                />

                <div className="row mt-2 mx-1">
                    <div className="col-6">
                        <div className="input-group-append row">
                            <div className="col px-0 mx-1 text-left">
                                <span>Tab</span>
                                <MultiSelectTextbox
                                    options={tabOptions}
                                    isMulti={false}
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.tab))?
                                            tabOptions.filter((element)=>element.value=== this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.tab)).value)
                                        :""
                                    }
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.tab)}
                                    disabledSelect={this.props.audit.is_loading}
                                    clearValue={this.state.clearFilterBool}
                                    clearable={true} 
                                />
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-6 col px-0 mx-1 text-left">
                                <span>Time Range</span>
                                <DateTimeRangeTextbox isDays={true} disabledBox={this.props.audit.is_loading} clearValue={this.state.clearFilterBool} dateRange={(range,fromInterval=false)=>this.addDateRangeFilters(range,fromInterval)} updateRangeAtInterval={this.state.updateInterval}/>
                            </div>
                            <div className="col px-0 mx-1 text-left">
                                <span>Type</span>
                                <MultiSelectTextbox
                                    options={typeOptions}
                                    isMulti={false}
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.type))
                                    }
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.type)}
                                    disabledSelect={this.props.audit.is_loading}
                                    clearValue={this.state.clearFilterBool}
                                    clearable={true} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-2 pl-1 text-left mt-3">
                        {/* <button type="button" className="btn btn-primary btn-sm" onClick={this.setFiltersParams.bind(this)}>Filter</button> */}
                        <button type="button" className="btn btn-secondary btn-sm ml-2" 
                            disabled={this.props.audit.is_loading || (this.state.filtered.length===2 && this.state.dateRangeValue==='Today')} 
                            onClick={this.clearFilter.bind(this)}>Clear</button>
                    </div>
                    <div className="col-4 text-right mt-3">
                        <button type="button" className="btn btn-outline-primary btn-sm" onClick={this.refreshData}><i className="fa fa-refresh"/>  Refresh</button>
                    </div>

                </div>
                <div className="row m-1 card-content-height">
                    <div className="col-md-12 auditlogs">
                        <TableGrid
                            clearValue={this.state.clearFilterBool}
                            defaultSortedCol={this.state.defaultSorted}
                            columnStructure={this.state.columnDefs}
                            responseData={this.state.rowData}
                            loading={this.props.audit.is_loading}
                            stateObject={this.props.audit}
                            pageSize={this.state.rowData.length<this.props.audit.page_size?this.state.rowData.length:this.props.audit.page_size}
                            noDataText={this.tablegridMessage()}
                            nonClickableRows
                            keyField="datetime"
                            moduleName="auditlogs"
                            clearSearchBox={this.state.clearSearchBox}
                        />

                    </div>
                </div>
            </CommonLayout>
        )
    }


}


export default connect(mapStateToProps, mapDispatchToProps)(Auditlogs)