/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 17:52
 * @modify date 06-02-2020 17:52
 * @description This file contains the component Overview and its layout.
 */
import React from 'react';
import { connect } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert'
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import { CommonLayout } from '../common/CommonLayout';
import './overview.scss';
import kaseyaAsssetIcon from '../../assets/images/image-asset.png';

const pluginVersion = process.env.REACT_APP_PLUGIN_VERSION

const mapStateToProps = state => ({
    ...state
})

class Overview extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showSSLErrorAlert: true,
        }
    }

    setShowSSLErrorAlert = (boolValue) => {
        this.setState({
            showSSLErrorAlert: boolValue
        })
    }

    render() {
        return (
            <CommonLayout title="Overview">
                <div className="p-2">
                    {this.props.settings.ssl_error_html && this.state.showSSLErrorAlert &&
                        <Alert className="py-1" variant="danger" onClose={() => this.setShowSSLErrorAlert(false)} dismissible>
                            <div dangerouslySetInnerHTML={{ __html: this.props.settings.ssl_error_html }}></div>
                        </Alert>
                    }

                    <div className="col py-2 mx-2">
                        <h6 className="m-2"><a href="/files/Kaseya_Sophos_User_Guide.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-circle rounded-circle btn-sm"><i className="fa fa-lg fa-question" aria-hidden="true"></i></a> <span>Click to view Sophos plugin help</span></h6>
                        <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example" className="nav nav-tabs m-2" style={{ borderBottom: '1px solid #dee2e6' }}>

                            <Tab eventKey="overview" title="Overview">
                                <div className="row m-2">
                                    <div className="col px-0">
                                        <Jumbotron fluid className="mb-1">
                                            <Container>
                                                <div className="row">
                                                    <div className="col text-center card m-2 p-2">
                                                        <div>
                                                            <h1 className="icon-color"><i className="fa fa-tachometer fa-2x"></i></h1>
                                                            <h4>Dashboard</h4>
                                                        </div>
                                                        <div className="px-3 text-left mt-2">
                                                            <h6>Displays threat history for alerts, and various panels that provides details for endpoints and servers.</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col text-center card m-2 p-2">
                                                        <div>
                                                            <h1 className="icon-color">
                                                                <i className="fa fa-exclamation-triangle fa-2x"></i>
                                                            </h1>
                                                            <h4>Alerts</h4>
                                                        </div>
                                                        <div className="px-3 text-left mt-2">
                                                            <h6>Displays alerts' data for the tenants. User can click on an alert to view more details and can filter alerts by tenant, time range, severity, category and product.</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col text-center card m-2 p-2">
                                                        <div>
                                                            <h1 className="icon-color">
                                                                <i className="fa fa-2x">
                                                                    <img alt="kaseya icon" src={kaseyaAsssetIcon}
                                                                        style={{ width: "1.25em", height: "100%" }} />
                                                                </i></h1>
                                                            <h4>Assets</h4>
                                                        </div>
                                                        <div className="px-3 text-left mt-2">
                                                            <h6>Displays Kaseya assets' information. User can click on an asset to view more details and can filter assets by machine group and Sophos status.</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col text-center card m-2 p-2">
                                                        <div>
                                                            <h1 className="icon-color"><i className="fa fa-laptop fa-2x"></i></h1>
                                                            <h4>Endpoints</h4>
                                                        </div>
                                                        <div className="px-3 text-left mt-2">
                                                            <h6>Displays endpoints' data for the tenants. User can click on an endpoint to view more details and can filter endpoints by tenant, type, OS, health status, service status, tamper protection and offline status.</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col text-center card m-2 p-2">
                                                        <div>
                                                            <h1 className="icon-color"><i className="fa fa-users fa-2x"></i></h1>
                                                            <h4>Tenants</h4>
                                                        </div>
                                                        <div className="px-3 text-left mt-2">
                                                            <h6>Displays tenants' data for the configured partner. User can click on a tenant to view the corresponding endpoints. User can filter tenants by location, region, billing type and auto deploy.</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col text-center card m-2 p-2">
                                                        <div>
                                                            <h1 className="icon-color"><i className="fa fa-history fa-2x"></i></h1>
                                                            <h4>Audit Logs</h4>
                                                        </div>
                                                        <div className="px-3 text-left mt-2">
                                                            <h6>Displays audit logs for the plugin. User can filter logs by tab, time range and type.</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col text-center card m-2 p-2">
                                                        <div>
                                                            <h1 className="icon-color"><i className="fa fa-cogs fa-2x"></i></h1>
                                                            <h4>Deployment Settings</h4>
                                                        </div>
                                                        <div className="px-3 text-left mt-2">
                                                            <h6>Displays the configuration page where the user can upload CSV files for Sophos Installers and define auto deployment strategy for Sophos Endpoint Agent installation.</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col text-center card m-2 p-2">
                                                        <div>
                                                            <h1 className="icon-color"><i className="fa fa-cog fa-2x"></i></h1>
                                                            <h4>Sophos Settings</h4>
                                                        </div>
                                                        <div className="px-3 text-left mt-2">
                                                            <h6>Displays the configuration page where the user needs to provide the assigned Client ID and Client Secret using which Sophos Central APIs can be accessed.</h6>
                                                        </div>
                                                    </div>
                                                    {
                                                        (this.props.settings && true === this.props.settings.show_global_settings) ?
                                                            <><div className="col text-center card m-2 p-2">
                                                                <div>
                                                                    <h1 className="icon-color"><i className="fa fa-cog fa-2x"></i></h1>
                                                                    <h4>Global Settings</h4>
                                                                </div>
                                                                <div className="px-3 text-left mt-2">
                                                                    <h6>Manage Allowed And Blocked Items, Exploit Mitigation, Intrusion Prevention, Scanning Exclusions and Website Management for tenants.</h6>
                                                                </div>
                                                            </div></>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </Container>
                                        </Jumbotron>
                                        <div className="row p-0 m-0">
                                            <div className="col p-0 m-0">
                                                <label className="m-0"><strong>Note: </strong> Tenants, Assets and Deployment Settings tab would be accessible only to Partner.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="sophosReleaseNotes" title="Release Notes">
                                {
                                    (this.props.settings && true === this.props.settings.show_global_settings) ?
                                        <><div className="row m-2">
                                            <div className="col">
                                                <h4>Beta v2.1.0</h4>
                                            </div>
                                        </div>
                                            <div className="row m-2">
                                                <div className="col">
                                                    <h4>Features:</h4>
                                                    <ul>
                                                        <li>Added following Global Settings:</li>
                                                        <ul>
                                                            <li>Allowed And Blocked Items</li>
                                                            <li>Exploit Mitigation</li>
                                                            <li>Intrusion Prevention</li>
                                                            <li>Scanning Exclusions</li>
                                                            <li>Website Management</li>
                                                        </ul>
                                                        <li>Added Isolation, De-Isolation action support for Endpoints.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row m-2">
                                                <div className="col">
                                                    <h4>Improvements:</h4>
                                                    <ul>
                                                        <li>All the force sync job are asynchronous, so user can continue using plugin.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row m-2">
                                                <div className="col">
                                                    <h4>Notes:</h4>
                                                    <ul>
                                                        <li>Users can trigger force sync once an hour for each tenant in Global Settings.</li>
                                                    </ul>
                                                </div>
                                            </div></>
                                        :
                                        null
                                }
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>v2.2.0</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Features:</h4>
                                        <ul>
                                            <li>Deployment page layout is updated for the VSAZ version v2.1.0 and above.</li>
                                            <li>Deployment configuration no longer requires upload of CSV files.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>v2.1.0</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Features:</h4>
                                        <ul>
                                            <li>Added "XDR only" in deployment menu</li>
                                            <li>Added a notification to show when a new version is available.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>v2.0.4</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Notes:</h4>
                                        <ul>
                                            <li>Migrated Intercept X Advanced with MTR to Sophos MDR.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>v2.0.3</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Fixes:</h4>
                                        <ul>
                                            <li>Bug fixes and performance improvement.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>v2.0.2</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Features:</h4>
                                        <ul>
                                            <li>Added "Intercept X Advanced with XDR" in deployment menu</li>
                                            <li>Added "Intercept X Essentials" in deployment menu</li>
                                            <li>Removed "Central Endpoint Protection" product from deployment menu</li>
                                            <li>Removed "Central Server Protection" product from deployment menu</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Improvements:</h4>
                                        <ul>
                                            <li>Added "VSAZ Version" in Overview tab to distinguish between regular updates and vsaz updates</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>v2.0.1</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Features:</h4>
                                        <ul>
                                            <li>Multi-tenancy support: Partners that allow customer admins to log into their Kaseya solution for specific views of their organization can low also log into the Sophos Kaseya plugin using Tenant level API credentials, and view Endpoint and Alert details specifically, and only, for their organization.</li>
                                            <li>Implemented Logz.io to Sophos backend to allow for faster triage and resolution should an incident arise.</li>
                                            <li>Auto-deployment policies now allows for search, sort and filter to quickly locate an deployment policy.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Improvements:</h4>
                                        <ul>
                                            <li>CSV file import for deployments now validates:
                                                <ul>
                                                    <li>Customer Name must contains UTF-8 characters.</li>
                                                    <li>Customer Token must be in GUID format.</li>
                                                    <li>Management Server must be hostname/URL without protocol.</li>
                                                </ul>
                                            </li>
                                            <li>Implemented minor view changes for improved user experience.</li>
                                            <li>Improved scaling with load balancer.</li>
                                            <li>Improved architecture to support scaling horizontally and vertically.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Beta 1.4.2 {/*<small className="text-muted">October 5th, 2020</small>*/}</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Fixes:</h4>
                                        <ul>
                                            <li>Bug fixes and performance improvement.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Beta 1.4.1 {/*<small className="text-muted">September 7th, 2020</small>*/}</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Fixes:</h4>
                                        <ul>
                                            <li>Bug fixes for CSV upload.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Beta 1.4.0 {/*<small className="text-muted">July 10th, 2020</small>*/}</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Improvements:</h4>
                                        <ul>
                                            <li>Update Sophos Endpoint if Auto deployment settings are changed.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Beta 1.3.0 {/*<small className="text-muted">March 25th, 2020</small>*/}</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Improvements:</h4>
                                        <ul>
                                            <li>Time range for Threat history in Dashboard now displays Last 24 hours, Last 1 hour, Last 7 days, Last 30 days instead of  Today, This hour, This week, This month.</li>
                                            <li>Time range for Alerts now displays Last 24 hours, Last 1 hour, Last 7 days, Last 30 days instead of Today, This hour, This week, This month.</li>
                                            <li>View Management Server and edit Customer Token from Tenants tab.</li>
                                            <li>Separate product selection in deployment based on OS.</li>
                                            <li>Enable/Disable device encryption for deployments.</li>
                                            <li>Enable/Disable removal of competitive products for deployments.</li>
                                            <li>In browser CSV validation to prevent malformed CSV upload.</li>
                                        </ul>
                                        <h4>Fixes:</h4>
                                        <ul>
                                            <li>Bug fixes for CSV upload.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Beta 1.2.0 {/*<small className="text-muted">March 25th, 2020</small>*/}</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h5>Fixes:</h5>
                                        <ul>
                                            <li>Bug fixes and Optimization.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Beta 1.1.0 {/*<small className="text-muted">March 25th, 2020</small>*/}</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h5>Fixes:</h5>
                                        <ul>
                                            <li>Support for IE 11, Edge.</li>
                                            <li>Cross site cookie issue resolved for Safari and Chrome v80</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Beta 1.0.0 {/*<small className="text-muted">March 25th, 2020</small>*/}</h4>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h5>Features:</h5>
                                        <ul>
                                            <li>Sync tenants, endpoints and alerts from Sophos Central.</li>
                                            <li>Dashboard to view threat history for alerts, and details for endpoints and servers.</li>
                                            <li>Map Sophos tenants with Kaseya machine groups/organizations.</li>
                                            <li>Define auto deploy strategy for Sophos endpoint agents.</li>
                                            <li>Deploy Sophos endpoint agents on Kaseya assets.</li>
                                            <li>Audit logs to view the action logs from Sophos settings, endpoints, alerts, assets and deployment settings.</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="row m-2">
                                    <div className="col">
                                        <h4>Fixes</h4>
                                        <ul>
                                            <li>Dashboard Bar graph count issue</li>
                                            <li>Dashboard Tenant selection issue</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <div className="col">
                                        <h4>Improvements</h4>
                                        <ul>
                                            <li>Table UI improvement in all the pages</li>
                                        </ul>
                                    </div>
                                </div> */}
                            </Tab>
                            {
                                (this.props.settings && this.props.settings.current_version) ?
                                    <Tab
                                        title={<div><strong>Sophos Plugin Application Version:</strong> <span className="badge badge-info">{pluginVersion}</span>&nbsp; <span class="pipe-color">|</span> <strong>VSAZ Version:</strong> <span className="badge badge-info">{this.props.settings.current_version}</span></div>}
                                        tabClassName="ml-auto" disabled  >
                                    </Tab>
                                    :
                                    <Tab
                                        title={<div><strong>Sophos Plugin Application Version:</strong> <span className="badge badge-info">{pluginVersion}</span></div>}
                                        tabClassName="ml-auto" disabled  >
                                    </Tab>
                            }
                            {
                                this.props.settings?.is_new_plugin_version_available &&
                                <Tab
                                    title={<div><span className="badge badge-danger oval">New!</span> <span>v{this.props.settings?.latest_plugin_version} Available</span></div>}
                                    tabClassName="alignment-update" disabled  >
                                </Tab>
                            }

                        </Tabs>
                    </div>
                </div>
            </CommonLayout>
        )
    }
}

export default connect(mapStateToProps, null)(Overview)