import React from 'react';
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import _ from "lodash";
import {CommonLayout} from '../common/CommonLayout';
import {setInitStorage} from '../common/InitLocalStore';
import {sortingData, getDataFromJson} from '../common/Util';
import {ErrorModal} from '../common/ErrorModal';
import {ActionLoadingModal} from '../common/ActionLoadingModal';
// import {MessageModal} from '../common/MessageModal';
import ConfirmationAuditLogModal from '../common/ConfirmationAuditLogModal';
import { getTenantList, getAlertsList,clearAlertsErrorResponseMessage,clearTenantErrorResponseMessage,clearAlertsTable, sendRequestAlertAction } from '../../services/apiOperations';
import TableGrid from '../common/TableGrid';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import DateTimeRangeTextbox from '../common/DateTimeRangeTextbox';
import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/tableGrid.scss';

if(process.env.REACT_APP_HOST_ENV==="production"){
    console.log=function(){}
}

const CONSTANT_TYPE={
    product:"product",
    category:"category",
    severity:"severity",
    type:"type",
    dateRange:"dateRange",
    fromDate:"fromDate",
    toDate:"toDate",
    selectedTenant:"tenant_id",
    allowedActions:"allowedActions"
}

const ALERT_ACTIONS=[
    {value:"",label:"--Select action--",askPermission:false},
    {value:"acknowledge",label:"acknowledge",askPermission:false},
    {value:"cleanVirus",label:"cleanVirus",askPermission:false},
    {value:"clearThreat",label:"clearThreat",askPermission:false},
    {value:"cleanPua",label:"cleanPua",askPermission:false},
    // {value:"clearHmpa",label:"clearHmpa",askPermission:false},
    {value:"authPua",label:"authPua",askPermission:false},
    // {value:"sendMsgPua",label:"sendMsgPua",askPermission:false},
    // {value:"sendMsgThreat",label:"sendMsgThreat",askPermission:false},
]

const NULL_UNDEFINED_EMPTY_CHECK = [null, undefined, ""]

const categoryOptions=[
    {value:"adSync",label:"adSync"},
    {value:"applicationControl",label:"applicationControl"}, 
    {value:"appReputation",label:"appReputation"}, 
    {value:"azure",label:"azure"},
    {value:"blockListed",label:"blockListed"}, 
    {value:"connectivity",label:"connectivity"}, 
    {value:"cwg",label:"cwg"}, 
    {value:"denc",label:"denc"}, 
    {value:"downloadReputation",label:"downloadReputation"}, 
    {value:"endpointFirewall",label:"endpointFirewall"}, 
    {value:"fenc",label:"fenc"}, 
    {value:"forensicSnapshot",label:"forensicSnapshot"}, 
    {value:"general",label:"general"}, 
    {value:"iaas",label:"iaas"}, 
    {value:"iaasAzure",label:"iaasAzure"}, 
    {value:"isolation",label:"isolation"}, 
    {value:"malware",label:"malware"}, 
    {value:"mobiles",label:"mobiles"}, 
    {value:"mtr",label:"mtr"}, 
    {value:"policy",label:"policy"}, 
    {value:"protection",label:"protection"}, 
    {value:"pua",label:"pua"}, 
    {value:"runtimeDetections",label:"runtimeDetections"}, 
    {value:"security",label:"security"}, 
    {value:"smc",label:"smc"}, 
    {value:"systemHealth",label:"systemHealth"}, 
    {value:"uncategorized",label:"uncategorized"}, 
    {value:"updating",label:"updating"}, 
    {value:"utm",label:"utm"}, 
    {value:"virt",label:"virt"}, 
    {value:"wireless",label:"wireless"}, 
    {value:"xgEmail",label:"xgEmail"},
    { value:"-",label:"not defined"}
];

const productOptions=[
    { value:"emailGateway",label:"emailGateway"},
    { value:"encryption",label:"encryption"}, 
    { value:"endpoint",label:"endpoint"},
    { value:"firewall",label:"firewall"},
    { value:"iaas",label:"iaas"}, 
    { value:"mobile",label:"mobile"}, 
    { value:"other",label:"other"}, 
    { value:"phishThreat",label:"phishThreat"}, 
    { value:"server",label:"server"}, 
    { value:"webGateway",label:"webGateway"}, 
    { value:"wireless",label:"wireless"}, 
    { value:"-",label:"not defined"}
];

// const typeOptions=[
//     { value:"mobile",label:"mobile"},
//     { value:"computer",label:"computer"},
//     { value:"server",label:"server"},
//     { value:"securityVm",label:"securityVm"},
//     { value:"utm",label:"utm"}, 
//     { value:"accessPoint",label:"accessPoint"}, 
//     { value:"wirelessNetwork",label:"wirelessNetwork"}, 
//     { value:"mailbox",label:"mailbox"}, 
//     { value:"slec",label:"slec"}, 
//     { value:"xgFirewall", label:"xgFirewall"}
// ];

const severityOptions=[
    { value:"high",label:"high"},
    { value:"medium",label:"medium"},
    { value:"low",label:"low"},
    { value:"-",label:"not defined"}
];

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getAlertsList: (referrer,queryFilterString) => dispatch(getAlertsList(referrer,queryFilterString)),
    getTenantList: (referrer,queryFilterString) => dispatch(getTenantList(referrer,queryFilterString)),
    clearAlertsErrorResponseMessage:()=>dispatch(clearAlertsErrorResponseMessage()),
    clearTenantErrorResponseMessage:()=>dispatch(clearTenantErrorResponseMessage()),
    clearAlertsTable:()=>dispatch(clearAlertsTable())
})

class Alerts extends React.Component {
    constructor(props){
        super(props);
        setInitStorage(props.referrerHostname,queryString.parse(props.location.search))
        this.state={
            queryParams:queryString.parse(props.location.search),
            kaseyaParams:queryString.parse(props.location.search),
            tenantList:[],
            filtered: [],
            rowData:[],
            stopInterval:false,
            updateInterval:false,
            clearFilterBool:false,
            clearSearchBox:false,
            selectedAction:'',
            showActionAlert:false,
            showErrorActionAlert:false,
            actionAlertMessage:'',
            columnDefs: [
                { text: "Alert ID", dataField: "id", sort:true, onSort:this.onSortChange, fixed:true},
                { text: "Raised At", dataField: "raisedAt", sort:true, 
                onSort:this.onSortChange,
                formatter: (cell,row) => {
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.raisedAt))
                            return(moment(row.raisedAt).format("DD-MMM-YYYY HH:mm"))
                        else
                            return "-"
                    else
                        return "-"
                    },width: 200
                },
                { text: "Severity", dataField: 'severity',sort:true, 
                onSort:this.onSortChange,
                formatter: (cell,row) => {
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.severity))
                            return <div style={{ textAlign: "center" }}>{this.returnSeverityLabel(row,row.severity)}</div>
                        else
                            return "-"
                    else
                        return "-"
                    }, width:80
                },
                { text: "Category", dataField: "category", sort:true, 
                onSort:this.onSortChange,
                formatter: (cell, row) => {
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.category))
                            return row.category
                        else
                            return "-"
                    else
                        return "-"
                }, width: 150},
                { text: "Product", dataField: "product", sort:true, 
                onSort:this.onSortChange,
                formatter: (cell, row) => {
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.product))
                            return row.product
                        else
                            return "-"
                    else
                        return "-"
                }, width: 150},
                { text: "Tenant", dataField: "tenant.name", sort:true, 
                onSort:this.onSortChange,
                formatter: (cell, row) => {
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.tenant))
                            if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.tenant.name))
                                return row.tenant.name
                            else
                                return "-"
                        else 
                            return "-"
                    else
                        return "-"
                }, width: 200},
                { text: "Managed Agent Type", dataField: "managedAgent.type", sort:true, 
                onSort:this.onSortChange,
                formatter: (cell, row) => {
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.managedAgent))
                            if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.managedAgent.type))
                                return row.managedAgent.type
                            else
                                return "-"
                        else
                            return "-"
                    else
                        return "-"
                }, width: 150}
            ],
            defaultSorted : [{
                dataField: 'raisedAt',
                order: 'desc'
            }],
            singleSelectedAlertId:'',
            currentPage:1,
            pageWiseSelectedRows:[],
            loadingAction:false,
            alertErrorResponse:this.props.alerts.error_response_message,
            sortChangeBool:false,
            successCount : 0,
            failCount : 0,
            clearSelection:false
        }
        this.componentWillMountFunction()
    }

    componentWillMountFunction=()=>{
        let queryFitlerString=queryString.parse(this.props.location.search)
        if(queryFitlerString!==""){
            if(this.props.location.filtersObject){
                let filters=this.state.filtered
                    if(this.props.location.state){
                        let newPropsSelectedTenantId={ value: this.props.location.state.id, type: CONSTANT_TYPE.selectedTenant }
                        filters.push(newPropsSelectedTenantId)
                    }
                    let newPropsSelectedSeverity={ value: this.props.location.filtersObject.severity, type: CONSTANT_TYPE.severity}
                    filters.push(newPropsSelectedSeverity)
                    this.setState({
                        filtered:filters
                    })
            }
            else{
                this.selectFilters("high",CONSTANT_TYPE.severity)
            }
            this.props.getTenantList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),this.props.location.search);
            // this.props.getAlertsList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),this.props.location.search);
        }
        else{
            this.props.getTenantList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"));
            // this.props.getAlertsList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"));
        }
    }

    componentDidMount(){
        // if(this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))!==undefined){
        this.interval = setInterval(
            this.updateTime.bind(this)
        , 1000*60*5);  
        // }
    }

    componentDidUpdate(prevProps, prevState){
        if(JSON.stringify(this.props.alerts.alert_list)!==JSON.stringify(prevState.rowData)){
            this.setState({
                rowData : this.props.alerts.alert_list
            })
            this.clearErrorResponseToast()
        }
        if(JSON.stringify(this.props.alerts.error_response_message)!==JSON.stringify(prevProps.alerts.error_response_message)){
            this.setState({
                alertErrorResponse:this.props.alerts.error_response_message
            })        
        }
        if(JSON.stringify(this.props.tenants.tenant_list)!==JSON.stringify(prevProps.tenants.tenant_list)){
            let createTenantListPair=[]
            this.props.tenants.tenant_list.forEach((element)=>{
                createTenantListPair.push({ value: element.id, label:element.showAs})
            })
            this.setState({
                tenantList:createTenantListPair
            })
            if(this.props.location.state===undefined){
                this.getAlertsData(false,false)
            }
        }
        if (
          this.props.settings.SophosIdTypeIsTenant &&
          JSON.stringify(this.props.settings.SophosIdTypeIsTenant) !==
            JSON.stringify(prevProps.settings.SophosIdTypeIsTenant)
        ) {
          this.getAlertsData();
          const columDefs = [...this.state.columnDefs];
          const withoutTenantColumnDefs = _.remove(
            columDefs,
            (cols) => cols.dataField !== "tenant.name"
          );
          this.setState({ columnDefs: withoutTenantColumnDefs });
        }
    }

    componentWillUnmount(){
        this.clearErrorResponseToast()
        this.clearTenantErrorResponseToast()
    }

    onSortChange=()=>{
        this.setState({
            sortChangeBool:!this.state.sortChangeBool
        })
    }

    returnSeverityLabel(row,severity){
        switch(severity){
            case 'high':
                return(
                    <React.Fragment>
                        <Badge variant="danger services" data-tip="High" data-for={"severity_"+row.id}><i className="fa fa-exclamation"/></Badge>
                        <ReactTooltip id={"severity_"+row.id} effect="solid" type="dark"/>
                    </React.Fragment>
                )
            case 'medium':
                return(
                <React.Fragment>
                    <i data-tip="Medium" data-for={"severity_"+row.id} className="fa fa-lg fa-exclamation-triangle services-warning"/>
                    <ReactTooltip id={"severity_"+row.id} effect="solid" type="dark"/>
                </React.Fragment> )
            case 'low':
                return(
                <React.Fragment>
                    <Badge variant="secondary services" data-tip="Low" data-for={"severity_"+row.id}><i className="fa fa-info"/></Badge>
                    <ReactTooltip id={"severity_"+row.id} effect="solid" type="dark"/>
                </React.Fragment> )
            default:
        }
    }

    updateTime(){
        this.setState({
            updateInterval:!this.state.updateInterval
        })
    }

    filterData = (data)=>{
        
        let result = []
        for(let i = 0; i<data.length; i++){
          let flag = true
          for(let j=0;j<this.state.filtered.length;j++){
           if(this.state.filtered[j].type!==CONSTANT_TYPE.selectedTenant && this.state.filtered[j].type!==CONSTANT_TYPE.fromDate && this.state.filtered[j].type!==CONSTANT_TYPE.toDate){
                let apiValue;
                try{
                apiValue = getDataFromJson(data[i],this.state.filtered[j].type.split("_")).toString()
                }
                catch(err){
                apiValue = "-"
                }

                if(this.state.filtered[j].value !== "all" && this.state.filtered[j].value.split(",").indexOf(apiValue) === -1 && apiValue.split(",").indexOf(this.state.filtered[j].value)===-1){
                flag = false
                break
                }
            }
            
          }
          if(flag){
            result.push(data[i])
          }
          
        }
        return result
    }

    findFilterValueByType(arrayValue,searchType){
        return arrayValue.type===searchType
    }

    getPageWiseSelectedRowsFromTable=(currentPage,selectedRowData)=>{
        this.setState({
            currentPage: currentPage,
            pageWiseSelectedRows: selectedRowData
        })
    }

    setTablePage=(page)=>{
        this.setState({
            currentPage: page,
        })
    }

    selectFilters=(value,type,intervalInit=false)=>{
        let filters=this.state.filtered
        if(type!==CONSTANT_TYPE.dateRange && type!==CONSTANT_TYPE.selectedTenant){
            let newfilterObj={value:value,type:type}
            const obj =filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,newfilterObj.type))
            if(obj!==undefined){
                const index=filters.indexOf(obj)
                filters.splice(index,1)
            }
            if(value!=='')
                filters.push(newfilterObj)
            this.setState({
                filtered:filters
            })
            // this.getAlertsData(true,false)
        }
        else if(type===CONSTANT_TYPE.selectedTenant){
            let newfilterObj={value:value,type:type}
            const obj =filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,newfilterObj.type))
            if(obj!==undefined){
                const index=filters.indexOf(obj)
                filters.splice(index,1)
            }
            if(value!=='')
                filters.push(newfilterObj)
            
            this.setState({
                filtered:filters,
                clearSearchBox: !this.state.clearSearchBox
            })
            this.getAlertsData(true,false)
        }
        else{
            let fromDateObj={value:value.fromDate.startOf("minute").toISOString(),type:CONSTANT_TYPE.fromDate}
            const fromDateObjFilterExist =filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,fromDateObj.type))
            if(fromDateObjFilterExist!==undefined){
                const index=filters.indexOf(fromDateObjFilterExist)
                filters.splice(index,1)
            }
            filters.push(fromDateObj)

            let toDateObj={value:value.toDate.endOf("minute").toISOString(),type:CONSTANT_TYPE.toDate}
            const toDateObjFilterExist =filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,toDateObj.type))
            if(toDateObjFilterExist!==undefined){
                const index=filters.indexOf(toDateObjFilterExist)
                filters.splice(index,1)
            }
            filters.push(toDateObj)
            this.setState({
                filtered:filters,
                clearSearchBox: !this.state.clearSearchBox
            })
            if(this.state.tenantList.length>0 || this.props.settings.SophosIdTypeIsTenant  || (this.props.location && this.props.location.state && this.props.location.state.id))
                this.getAlertsData(false,intervalInit)
        }
    }

    clearFilter(){
        let filters=[]
        filters.push({type:CONSTANT_TYPE.severity,value:"high"})
        this.setState({filtered: filters, clearFilterBool:!this.state.clearFilterBool, updateInterval: !this.state.updateInterval,stopInterval:false, selectedAction:""})
        // this.props.clearAlertsTable()
        // this.props.getAlertsList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),this.props.location.search);
    }

    getAlertsData(fromOtherFilter=false,intervalInit=false){
        if(this.props.location.search!==""){
            let isApiHostNotNull=false
            this.props.clearAlertsTable()
            const queryParams=queryString.parse(this.props.location.search)
            let filters=this.state.filtered
            if(filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))!==undefined)
            {
                let filteredObject=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))
                if(this.props.tenants.tenant_list.length>0){
                    let checkTenantDetails=this.props.tenants.tenant_list.find((tenantValue)=>tenantValue.id===filteredObject.value)
                    if(checkTenantDetails){
                        if(checkTenantDetails.apiHost!==null && checkTenantDetails.apiHost!=="" && checkTenantDetails.apiHost!=="NULL" && checkTenantDetails.apiHost!=="null" && checkTenantDetails.apiHost!==undefined){
                            queryParams.sophosTenantId=filteredObject.value
                            isApiHostNotNull=true
                        }
                    }
                } else if(this.props.location.state.id) {
                    queryParams.sophosTenantId=this.props.location.state.id
                    isApiHostNotNull=true
                }
            }
            else{
                isApiHostNotNull=true
            }
            if(filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.fromDate))!==undefined && filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.toDate))!==undefined)
            {
                let filteredObjectFromDate=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.fromDate))
                queryParams.fromDate=filteredObjectFromDate.value
                let filteredObjectToDate=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.toDate))
                queryParams.toDate=filteredObjectToDate.value
            }
            const queryFilter=queryString.stringify(queryParams)
            if(isApiHostNotNull && !intervalInit && (!this.state.stopInterval || fromOtherFilter))
                this.props.getAlertsList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),"?"+queryFilter)
            else if(isApiHostNotNull && (intervalInit || fromOtherFilter===false))
                this.props.getAlertsList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),"?"+queryFilter)
        }
        else {
            this.props.getAlertsList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"))
        }

    }

    getAlertSelectedId=(selectedId)=>{
        this.setState({
            singleSelectedAlertId:selectedId
        })
    }

    findAlert(alerts,selectedAlert) {
        return alerts.id === selectedAlert;
    }

    addDateRangeFilters(range,intervalInit){
            // console.log(range ,"FROM DATE")
            if(range.startInterval===true){
                this.setState({
                    stopInterval:false
                })
            }
            else if(range.startInterval===false){
                this.setState({
                    stopInterval:true
                })
            }
            this.selectFilters(range,CONSTANT_TYPE.dateRange,intervalInit)
    }

    clearErrorResponseToast=()=>{
        this.props.clearAlertsErrorResponseMessage()
    }

    clearTenantErrorResponseToast=()=>{
        this.props.clearTenantErrorResponseMessage()
    }

    tablegridMessage(){
        if(!this.props.alerts.is_loading){
            let filteredObject=this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))
            if(filteredObject!==undefined){
                let checkTenantDetails=this.props.tenants.tenant_list.find((tenantValue)=>tenantValue.id===filteredObject.value)
                if(checkTenantDetails){
                    if(checkTenantDetails.apiHost===null || checkTenantDetails.apiHost==="" || checkTenantDetails.apiHost==="NULL" || checkTenantDetails.apiHost==="null" || checkTenantDetails.apiHost===undefined){
                        return {message:'This account has not been activated; <br/>Please activate from <a target="_blank" href="https://cloud.sophos.com/">Sophos Central Administration Console</a>',type:"error"}
                    }
                    else{
                        return {message:"No alerts found to match your search",type:"info"}
                    }
                }
                else{
                    return {message:"No alerts found to match your search",type:"info"}
                }
                
            }
            else{
                return {message:"No alerts found to match your search",type:"info"}
            }
        }
        else{
            return {message:"Loading....",type:"info"}
        }
    }

    sendAlertAction=()=>{
        if(this.state.pageWiseSelectedRows.length===0 || this.state.pageWiseSelectedRows[0].selectedRow.length===0){
            this.setState({
                showErrorActionAlert: true,
                loadingAction:false,
                actionAlertMessage:"No alert selected; Please select atleast one alert."
            })
        }
        else{
            let selectedAlertsBody=[]
            this.state.pageWiseSelectedRows.forEach((eachPage)=>{
                eachPage.selectedRow.forEach((eachRow)=>{
                    selectedAlertsBody.push({"alertId":eachRow.id,"tenantId":eachRow.tenant.id,"raisedAt":eachRow.raisedAt})
                })
            })
            let requestBody={
                "sophosAlertIds":selectedAlertsBody,
                "allowedAction":this.state.selectedAction
            }
            this.setState({
                loadingAction:true
            },()=>{
                sendRequestAlertAction(
                    this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),
                    requestBody,
                    this.props.location.search
                )
                .then(res=>{
                    this.setState({
                        loadingAction:false,
                        showActionAlert:true,
                        successCount:res.data.response.succeed.count,
                        failCount:res.data.response.failed.count,
                        clearSelection:!this.state.clearSelection
                    })
                })
                .catch(err=>{
                    let messageStringForAlerts=""
                    if(err.response){
                        if(err.response.data.message!==undefined)
                            messageStringForAlerts=err.response.data.message
                        else
                            messageStringForAlerts="Something went wrong; Please try again later."
                    }
                    else
                        messageStringForAlerts="Something went wrong; Please try again later."
                    this.setState({
                        loadingAction:false,
                        showErrorActionAlert:true,
                        actionAlertMessage:messageStringForAlerts,
                        clearSelection:!this.state.clearSelection
                    })
                    console.log(err,"ERROR")
                })
            })
            
        }
    }

    closeActionAlert=()=>{
        if(this.state.pageWiseSelectedRows.length===0 || this.state.pageWiseSelectedRows[0].selectedRow.length===0){
            this.setState({
                showErrorActionAlert: false,
                showActionAlert: false,
                actionAlertMessage:""
            })
        }
        else{
            this.setState({
                showActionAlert: false,
                showErrorActionAlert: false,
                actionAlertMessage:"",
                clearSearchBox:!this.state.clearSearchBox,
                selectedAction:""
            },()=>{
                this.getAlertsData()
                this.selectFilters("",CONSTANT_TYPE.allowedActions)
            })
        }
    }

    handleActionChangeFilter=(value)=>{
        if(value===""){
            this.setState({
                selectedAction:"",
                clearSelection:!this.state.clearSelection
            },()=>this.selectFilters("",CONSTANT_TYPE.allowedActions))
        }
        else{
            this.setState({
                selectedAction:value,
                clearSelection:!this.state.clearSelection
            },()=>this.selectFilters(value,CONSTANT_TYPE.allowedActions))
        }
    }

    getNonSelectableRows=(allRows)=>{
        let allNonselectableRowIds=[]
        if(this.state.selectedAction!==""){
            allRows.forEach((element)=>{
                if(element.alertAction){
                    allNonselectableRowIds.push(element.id)
                }
            })
        }
        else{
            allNonselectableRowIds=[]
        }
        return allNonselectableRowIds
    }

    goToAuditLog=()=>{
        this.props.history.push({
            pathname:"/auditlogs",
            search:this.props.location.search,
            filtersObject:{value:"ALERT"}
        })
    }

    render(){
        let selectedAlert=this.props.alerts.alert_list.find((value)=>this.findAlert(value,this.state.singleSelectedAlertId))
        // let selectedAlert=this.state.rowData.find((value)=>this.findAlert(value,this.state.singleSelectedAlertId))
        return(
            <CommonLayout title="Alerts" displayTitle={true}>
                <ErrorModal 
                    show={this.props.tenants.error_response_message!==null || this.props.alerts.error_response_message!==null} 
                    closeModal={()=>{
                        this.props.tenants.error_response_message!==null?
                            this.clearTenantErrorResponseToast()
                        :
                            this.clearErrorResponseToast()
                        }}
                    modalMessage={
                    this.props.tenants.error_response_message!==null && this.props.alerts.error_response_message===null ?
                        this.props.tenants.error_response_message
                        :
                        this.props.alerts.error_response_message}
                />
                <ConfirmationAuditLogModal
                    show={this.state.showActionAlert}
                    selectedAction={this.state.selectedAction!==""?ALERT_ACTIONS.find((elementAction)=>elementAction.value===this.state.selectedAction).label:""}
                    viewLog={this.goToAuditLog}
                    success={this.state.successCount}
                    fail={this.state.failCount}
                    closeModal={this.closeActionAlert}
                />
                <ErrorModal 
                    show={this.state.showErrorActionAlert} 
                    closeModal={this.closeActionAlert}
                    modalMessage={this.state.actionAlertMessage}
                />
                <ActionLoadingModal
                    show={this.state.loadingAction} 
                />
                <div className="row mt-2 mx-1 justify-content-between">
                    <div className="col-10">
                        <div className="input-group-append row">
                            {!this.props.settings.SophosIdTypeIsTenant && <div className="col px-0 mx-1 text-left">
                                <span>Tenants</span>
                                <MultiSelectTextbox 
                                    options={this.state.tenantList.sort(sortingData)} 
                                    isMulti={false} 
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))?
                                        this.state.tenantList.filter((element)=>element.value=== this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant)).value)
                                        :[]
                                    } 
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.selectedTenant)} 
                                    clearValue={this.state.clearFilterBool}
                                    clearable={true}
                                    isLoading={this.props.tenants.is_loading}
                                    disabledSelect={this.props.alerts.is_loading}
                                />
                            </div>}
                            <div className="col-lg-4 col-md-5 col-sm-6 col px-0 mx-1 text-left">
                                <span>Time Range</span>
                                <DateTimeRangeTextbox disabledBox={this.props.alerts.is_loading} clearValue={this.state.clearFilterBool} dateRange={(range,fromInterval=false)=>this.addDateRangeFilters(range,fromInterval)} updateRangeAtInterval={this.state.updateInterval} rangeFromDashboard={this.props.location.filtersObject}/>
                            </div>
                            <div className="col px-0 mx-1 text-left">
                                <span>Severity</span>
                                <MultiSelectTextbox 
                                    options={severityOptions} 
                                    isMulti={true} 
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.severity))?
                                            severityOptions.filter((element)=>element.value=== this.state.filtered.find((arrayValue)=>
                                                this.findFilterValueByType(arrayValue,CONSTANT_TYPE.severity)).value)
                                            :[]
                                    } 
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.severity)} 
                                    clearValue={this.state.clearFilterBool}
                                />
                            </div>
                            {/* <div className="col px-0 mx-1 text-left">
                                <span>Type</span>
                                <MultiSelectTextbox 
                                    options={typeOptions} 
                                    isMulti={false} 
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.type))
                                    } 
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.type)} 
                                    clearValue={this.state.filtered.length===0?true:false}
                                />
                            </div> */}
                            <div className="col px-0 mx-1 text-left">
                                <span>Category</span>
                                <MultiSelectTextbox 
                                    options={categoryOptions} 
                                    isMulti={true} 
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.category))
                                    } 
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.category)} 
                                    clearValue={this.state.clearFilterBool}
                                />
                            </div>
                            <div className="col px-0 mx-1 text-left">
                                <span>Product</span>
                                <MultiSelectTextbox 
                                    options={productOptions} 
                                    isMulti={true} 
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.product))
                                    } 
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.product)} 
                                    clearValue={this.state.clearFilterBool}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-2 text-left mt-3 pl-1">
                        <button type="button" className="btn btn-secondary btn-sm ml-2" disabled={this.props.alerts.is_loading || ((this.state.filtered.length === 3 && ((Date.parse(this.state.filtered.find((item)=>(item.type === CONSTANT_TYPE.toDate))?this.state.filtered.find((item)=>(item.type === CONSTANT_TYPE.toDate)).value:null) - Date.parse(this.state.filtered.find((item)=>(item.type === CONSTANT_TYPE.fromDate))?this.state.filtered.find((item)=>(item.type === CONSTANT_TYPE.fromDate)).value:null))/3600000) === 24) && this.state.filtered.find((item)=>(item.type === CONSTANT_TYPE.severity && item.value === "high")))} onClick={this.clearFilter.bind(this)}>Clear</button>
                    </div>
                </div>
                {/* <small id="defaultPageHelp" className="form-text text-muted ml-4"><strong>Note: </strong>Click on a row to view alerts details.</small> */}
                <div className="row m-1 card-content-height">
                    <div className="col-md-8 pr-1">
                        <TableGrid 
                            clearValue={this.state.clearFilterBool}
                            defaultSortedCol={this.state.defaultSorted}
                            columnStructure={this.state.columnDefs} 
                            responseData={this.filterData(this.state.rowData)}  
                            loading={this.props.alerts.is_loading} 
                            stateObject={this.props.alerts} 
                            pageSize={this.filterData(this.state.rowData).length<this.props.alerts.page_size?this.filterData(this.state.rowData).length:this.props.alerts.page_size}
                            filtered={this.state.filtered}
                            noDataText={this.tablegridMessage()} 
                            keyField="id"
                            moduleName="alerts"
                            // rowClickEvent={(row)=>this.getAlertSelectedId(row.id)}
                            clearSearchBox={this.state.clearSearchBox}
                            note="Alerts will be displayed in some time if you have configured settings recently."
                            singleSelectedRow={(selectedId)=>this.getAlertSelectedId(selectedId)}
                            pageWiseSelectedRowsFromTable={(currentPage, selectedRowData)=>this.getPageWiseSelectedRowsFromTable(currentPage,selectedRowData)}
                            onPageChangeEvent={(page)=>this.setTablePage(page)}
                            nonSelectableRows={this.getNonSelectableRows(this.filterData(this.state.rowData))}
                            onSortChange={this.state.sortChangeBool}
                            onPerformAction={this.state.clearSelection}
                        />
                    </div>
                    <div className="col-md-4 pl-1">
                        <div className="row mr-2">
                            <div className="col pr-1">
                                    <span>Action <a href className="text-muted" data-tip data-for="actionDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                                    <ReactTooltip id="actionDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                                        <ol className="m-0">
                                            <li>Select an action to filter the alerts according to selected action.</li>
                                            <li>Select an alert(s) for which action needs to be taken.</li>
                                            <li>Click on submit.</li>
                                        </ol>
                                    </ReactTooltip>
                                    <div className="row">
                                        <div className="col pr-1">
                                            <select className="form-control form-control-sm" 
                                                disabled={this.state.loadingAction}
                                                onChange={(e)=>this.handleActionChangeFilter(e.target.value)} 
                                                value={this.state.selectedAction}>
                                                {ALERT_ACTIONS.map((element)=>{
                                                    return(<option key={element.value} value={element.value}>{element.label}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className="col pl-1">
                                            <button type="button" className="btn btn-primary btn-sm" disabled={this.state.selectedAction==="" || this.state.loadingAction} onClick={this.sendAlertAction}>
                                                Submit { this.state.loadingAction?
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                :''}
                                            </button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col">
                                <Card>
                                    <Card.Header className="p-1">{!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert)?"Alert ID: "+selectedAlert.id:"Click on a row to view details"}</Card.Header>
                                    <Scrollbars style={{ width: 'auto', height: 'calc(100vh - 266px)' }}>
                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert)?
                                    <Card.Body>

                                    <h5 className="card-title">Alert Details</h5>
                                    <div className="row">
                                        <div className="col-4">
                                            <strong>Severity:</strong>
                                        </div>
                                        <div className="col-6">
                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.severity)?selectedAlert.severity:"-"}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <strong>Raised At:</strong>
                                        </div>
                                        <div className="col-6">
                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.raisedAt)?moment(selectedAlert.raisedAt).format("DD-MMM-YYYY HH:mm"):"-"}
                                        </div>
                                    </div>

                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.tenant)?
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-4">
                                                    <strong>Tenant ID:</strong>
                                                </div>
                                                <div className="col-6">
                                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.tenant.id)?selectedAlert.tenant.id:"-"}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <strong>Tenant Name:</strong>
                                                </div>
                                                <div className="col-6">
                                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.tenant.name)?selectedAlert.tenant.name:"-"}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    :""}
                                    
                                    <div className="row">
                                        <div className="col-4">
                                            <strong>Category:</strong>
                                        </div>
                                        <div className="col-6">
                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.category)?selectedAlert.category:"-"}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <strong>Product:</strong>
                                        </div>
                                        <div className="col-6">
                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.product)?selectedAlert.product:"-"}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <strong>Type:</strong>
                                        </div>
                                        <div className="col-6">
                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.type)?selectedAlert.type:"-"}
                                        </div>
                                    </div>

                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.person)?
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-4">
                                                    <strong>Person ID:</strong>
                                                </div>
                                                <div className="col-6">
                                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.person.id)?selectedAlert.person.id:"-"}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    :""}

                                    <div className="row">
                                        <div className="col-4">
                                            <strong>Description:</strong>
                                        </div>
                                        <div className="col-6">
                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.description)?selectedAlert.description:"-"}
                                        </div>
                                    </div>
                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.allowedActions)?
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-4">
                                                        <strong>Allowed Actions:</strong>
                                                </div>
                                                <div className="col-6">
                                                    <ul style={{listStyleType:'none', padding:'0',marginBottom:'0'}} >
                                                    {selectedAlert.allowedActions.map((item, index)=>{
                                                    
                                                    return (<li key={index}>{item}</li>)
                                                
                                                    })}
                                                    </ul>
                                                </div>
                                            </div> 
                                            <br/>
                                        </React.Fragment>
                                        :<br/>
                                    }

                                    <h5 className="card-title">Group</h5>
                                    <div className="row">
                                        <div className="col-4">
                                            <strong>Group Key:</strong>
                                        </div>
                                        <div className="col-6">
                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.groupKey)?selectedAlert.groupKey:"-"}
                                        </div>
                                    </div>
                                    <br/>

                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.managedAgent)?
                                        <React.Fragment>
                                            <h5 className="card-title">Managed Agent</h5>
                                            <div className="row">
                                                <div className="col-4">
                                                    <strong>Managed Agent ID:</strong>
                                                </div>
                                                <div className="col-6">
                                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.managedAgent.id)?selectedAlert.managedAgent.id:"-"}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <strong>Managed Agent Type:</strong>
                                                </div>
                                                <div className="col-6">
                                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.managedAgent.type)?selectedAlert.managedAgent.type:"-"}
                                                </div>
                                            </div>
                                            <br/>
                                        </React.Fragment>
                                    :""}
                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAlert.alertAction)?
                                        <React.Fragment>
                                            <h5 className="card-title">Alert Action Performed</h5>
                                            <div className="row">
                                                <div className="col-4">
                                                    <strong>Actions</strong>
                                                </div>
                                                <div className="col-6">
                                                    <strong>Requested At</strong>
                                                </div>
                                            </div>
                                            {selectedAlert.alertAction.map((item, index)=>{
                                                return (
                                                <div className="row" key={index}>
                                                    <div className="col-4">
                                                        {item.action}
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(item.requestedAt)?moment(item.requestedAt).format("DD-MMM-YYYY HH:mm"):"-"}
                                                    </div>
                                                </div>
                                                )
                                            })}
                                        </React.Fragment>
                                    :""}
                                    
                                </Card.Body>
                                :''}

                                </Scrollbars>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts)