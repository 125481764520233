/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:20
 * @modify date 06-02-2020 12:20
 * @description This file contains the App class, Routes and Settings Auth for Kaseya OAuth redirect.
 */
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { BrowserRouter as Router, Switch, Route, /*Link*/ } from 'react-router-dom';
import Title from 'reactjs-title';
import Container from "react-bootstrap/Container";
import Jumbotron from 'react-bootstrap/Jumbotron';
import Spinner from 'react-bootstrap/Spinner';
import FontAwesome from 'react-fontawesome';
import { setInitStorage, clearInitStorage } from './components/common/InitLocalStore';
import { CommonLayout } from './components/common/CommonLayout';
import { getSettingsAuth, setSettingsData, setFlagsData } from './services/apiOperations';
import { setFeatureFlags, clearFlagsAPIInterval, isDeploymentAutotmationEnabled } from './services/flagOperations';
import NotFound from './components/NotFound';
import Settings from './components/settings/Settings';
import Customers from './components/customers/Customers';
import Overview from './components/overview/Overview';
import Endpoints from './components/endpoints/Endpoints';
import KaseyaAssets from './components/kaseyaAssets/KaseyaAssets';
import Alerts from './components/alerts/Alerts';
import Dashboard from './components/dashboard/Dashboard';
import DeploymentSettings from './components/deploymentSettings/DeploymentSettings';
import DeploymentSettingsV2 from './components/deploymentSettingsV2/DeploymentSettingsV2';
import Auditlogs from './components/auditlogs/Auditlogs';
import { ErrorModal } from './components/common/ErrorModal';
import AllowedBlockedItems from './components/globalSettings/AllowedBlockedItems';
import ScanningExclusionItems from './components/globalSettings/ScanningExclusionItems';
import ExploitMitigationItems from './components/globalSettings/ExploitMitigationItems';
import IntrusionPreventionItems from './components/globalSettings/IntrusionPreventionItems';
import WebsiteManagementItems from './components/globalSettings/WebsiteManagementItems';

import './App.css';

const environment = process.env.REACT_APP_HOST_ENV

if (process.env.REACT_APP_HOST_ENV === "production") {
  console.log = function () { }
}

const mapDispatchToProps = dispatch => ({
  setSettingsData: (data) => dispatch(setSettingsData(data)),
  setFlagsData: (data) => dispatch(setFlagsData(data)),
});

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      settingsCheckAuth: false,
      is_loading: true,
      flags_loaded: false,
      showErrorMessage: '',
      errorShow: false,
      current_version: null,
      isSettingsFailed: false
    }
  }

  componentWillUnmount() {
    clearFlagsAPIInterval();
  }

  handleStateUpdate = (data) => {
    const state = { ...this.state, ...data };
    this.setState(state);
  };

  handlePropUpdate = (data) => {
    this.props.setFlagsData(data);
  };

  componentDidMount() {
    let httpReferrerHostname = ""
    let documentHostname = ""
    documentHostname = new URL(document.URL).host
    if (document.referrer !== "" || environment === "development") {

      if (document.referrer !== "" && environment === "production")
        httpReferrerHostname = new URL(document.referrer).hostname
      // else//NTC - The commented code is needed for the local development testing
      //   httpReferrerHostname = process.env.REACT_APP_DEV_REFERRER

      let ancestorHostname = ""
      try {
        ancestorHostname = new URL(document.location.ancestorOrigins[0]).hostname
      } catch (error) {
        if (sessionStorage.getItem("current_kaseya_hostname") === null) {
          sessionStorage.setItem("current_kaseya_hostname", httpReferrerHostname)
        }
        ancestorHostname = sessionStorage.getItem("current_kaseya_hostname")
      }
      // if (environment === "development") ancestorHostname = httpReferrerHostname;//NTC

      if (documentHostname !== httpReferrerHostname || documentHostname !== ancestorHostname) {
        let queryParams = ""
        setInitStorage(httpReferrerHostname, queryString.parse(document.location.search))
        if (queryString.parse(document.location.search).code) {
          let createParams = {}
          createParams = queryString.parse(document.location.search)
          createParams.http_referrer = ancestorHostname
          createParams.kaseya_tenant_id = localStorage.getItem(ancestorHostname + "_kaseya_tenant_id")
          queryParams = "?" + queryString.stringify(createParams)
        }
        else {
          queryParams = document.location.search
        }
        getSettingsAuth(httpReferrerHostname + "_" + localStorage.getItem(httpReferrerHostname + "_kaseya_tenant_id"), queryParams)
          .then(res => {
            if (res.status === 200) {
              this.setState({ settingsCheckAuth: true, is_loading: false, current_version: res.data.current_version, isSettingsFailed: false });
              this.props.setSettingsData(res.data);
              setFeatureFlags(httpReferrerHostname, queryParams, this.handleStateUpdate, this.handlePropUpdate)
            }
          })
          .catch(err => {
            this.props.setSettingsData(err.response.data)
            this.setState({ isSettingsFailed: true });
            let errMessage = err.response.data.message
            if (err.response) {
              let pathName = new URL(document.URL).pathname;
              let errorIgnorePage = ['/tenants', '/customers', '/endpoints', '/alerts', '/dashboard', '/auditlogs', '/assets', '/settings', '/overview', '/allowed-blocked-items', '/exploit-mitigations', '/intrusion-preventions', '/scanning-exclusions', '/website-management', '/query']
              if (err.response.status === 308) {
                window.location = err.response.data.url;
              }
              else if ([401, 404, 500].indexOf(err.response.status) >= 0) {
                if (err.response.status === 401) {
                  clearInitStorage(httpReferrerHostname);
                }
                if (errorIgnorePage.indexOf(pathName) < 0) {
                  this.setState({
                    is_loading: false,
                    showErrorMessage: errMessage,
                    errorModalShow: true
                  })
                } else {
                  this.setState({ is_loading: false })
                }
              }
              else {
                this.setState({ is_loading: false })
              }
            }
            else {
              this.setState({ is_loading: false })
            }
          })

      }
      else {
        this.setState({ is_loading: false })
      }
    }
    else {
      this.setState({ is_loading: false })
    }
  }

  allowAuth = () => {
    this.setState({
      settingsCheckAuth: true
    })
  }

  closeModal = () => {
    this.setState({
      errorModalShow: false,
      showErrorMessage: ""
    })
  }

  render() {
    const referrer = document.referrer
    let httpReferrerHostname = ""
    let documentHostname = new URL(document.URL).host

    if (referrer !== "" && environment === "production")
      httpReferrerHostname = new URL(referrer).hostname
    // else//NTC - The commented code is needed for the local development testing
    // httpReferrerHostname = process.env.REACT_APP_DEV_REFERRER

    let ancestorExist = false
    try {
      ancestorExist = document.location.ancestorOrigins.length > 0
    } catch (error) {
      if (sessionStorage.getItem("current_kaseya_hostname")) {
        ancestorExist = true
      }
    }
    // if (environment === "development") //NTC - The commented code is needed for the local development testing
    //   ancestorExist = true;
    if (!this.state.flags_loaded && !this.state.isSettingsFailed) {
      return <div id="empty"></div>;
    }
    else {
      return (
        <Router>
          <Title render="Kaseya Sophos Plugin" />
          <Container fluid={true} className="px-1">
            <ErrorModal
              show={this.state.errorModalShow}
              closeModal={this.closeModal}
              modalMessage={this.state.showErrorMessage}
            />
            {
              this.state.is_loading === false ?
                (ancestorExist && ((documentHostname !== httpReferrerHostname && referrer !== "") || queryString.parse(document.location.search).code !== undefined || environment === "development")) ?
                  <Switch>
                    <Route path='/settings' component={(props) => <Settings {...props} referrer={referrer} referrerHostname={httpReferrerHostname} authCheckFunction={this.allowAuth} />} />
                    <Route path='/tenants' component={(props) => <Customers {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path='/customers' component={(props) => <Customers {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path='/overview' component={(props) => <Overview {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path='/endpoints' component={(props) => <Endpoints {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path='/assets' component={(props) => <KaseyaAssets {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path='/alerts' component={(props) => <Alerts {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path='/dashboard' component={(props) => <Dashboard {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path="/deployment-settings" render={(props) => {
                      return isDeploymentAutotmationEnabled(this.state.current_version)
                        ? <DeploymentSettingsV2 {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />
                        : <DeploymentSettings {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />
                    }
                    } />
                    <Route path="/auditlogs" component={(props) => <Auditlogs {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path="/allowed-blocked-items" component={(props) => <AllowedBlockedItems {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path="/exploit-mitigations" component={(props) => <ExploitMitigationItems {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path="/intrusion-preventions" component={(props) => <IntrusionPreventionItems {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path="/scanning-exclusions" component={(props) => <ScanningExclusionItems {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path="/website-management" component={(props) => <WebsiteManagementItems {...props} referrer={referrer} referrerHostname={httpReferrerHostname} />} />
                    <Route path='*' component={NotFound} />
                  </Switch>
                  :
                  <CommonLayout title="Sorry">
                    <div className="cover-container py-5">
                      <div className="col">
                        <center><FontAwesome className="fa" name="frown-o" size="5x" /></center>
                        <center>Error in communicating with Kaseya. Please contact Sophos's administrator by clicking <a href="https://developer.sophos.com/getting-started" rel="noopener noreferrer" target="_blank">here</a>.</center>
                      </div>
                    </div>
                  </CommonLayout>
                :
                <CommonLayout title="Loading">
                  <Jumbotron fluid className="m-0">
                    <Container>
                      <center><Spinner animation="border" /></center>
                    </Container>
                  </Jumbotron>
                </CommonLayout>}
          </Container>
        </Router>
      );
    }
  }
}

export default connect(null, mapDispatchToProps)(App)
