/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 28-02-2020 21:49
 * @modify date 28-02-2020 21:49
 * @description Number Loader for Dashboard content
 */
import React from 'react';
import ContentLoader from 'react-content-loader';

export const NumberLoader = (props) =>
    (
        <div className={props.className}>
            <ContentLoader width="75" height="75" backgroundColor="#e3dddd8c" speed={1}>
                    <rect x="20" y="20" rx="5" ry="5" width="40" height="30" />
            </ContentLoader>
        </div>
    )