/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 28-02-2020 21:55
 * @modify date 28-02-2020 21:55
 * @description This file contains the component Kaseya Assets and its layout.
 */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Card from "react-bootstrap/Card";
import moment from 'moment';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from "lodash";
import { getAssetList, clearAssetErrorResponseMessage, clearAssetsTable, getMachinegroupList, sendAssetAction } from '../../services/apiOperations';
import { CommonLayout } from '../common/CommonLayout';
import { sortingData } from '../common/Util';
import { setInitStorage } from '../common/InitLocalStore';
import TableGrid from '../common/TableGrid';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import { ErrorModal } from '../common/ErrorModal';
import { ActionLoadingModal } from '../common/ActionLoadingModal';
import ConfirmationInstallSophosModal from '../common/ConfirmationInstallSophosModal';
import ConfirmationInstallSophosResponseModal from '../common/ConfirmationInstallSophosResponseModal';
import { getDataFromJson } from '../common/Util';
import { MAC_PRODUCTS_OPTIONS, WINDOWS_DESKTOP_PRODUCTS_OPTIONS, WINDOWS_SERVER_PRODUCTS_OPTIONS } from "../common/Constants";
import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/tableGrid.scss';
import 'react-toastify/dist/ReactToastify.css';
import TenantAccessError from '../multiTenancy/TenantAccessError';

import SophosInstalled from '../../assets/images/sophos.png';
import SophosNotInstalled from '../../assets/images/Sophos-disabled-icon.png';
import { isDeploymentAutotmationEnabled } from '../../services/flagOperations';

if (process.env.REACT_APP_HOST_ENV === "production") {
    console.log = function () { }
}

const CONSTANT_TYPE = {
    health_overall: "health_overall",
    health_services_status: "health_services_status",
    tamperProtectionEnabled: "tamperProtectionEnabled",
    os_platform: "os_platform",
    type: "type",
    selectedTenant: "tenant_id",
    MachineGroup: "MachineGroupId",
    SophosEndpointId: "SophosEndpointId"
}

// const BOOLEAN_CONSTANT_ARRAY = [true, false]

const NULL_UNDEFINED_EMPTY_CHECK = [null, undefined, ""]

const sophosStatusOptions = [
    { value: true, label: "Installed" },
    { value: false, label: "Not Installed" },
];

const computerTypeOptions = [
    { value: "computer", label: "computer" },
    { value: "securityVm", label: "securityVm" },
    { value: "server", label: "server" },
    { value: "-", label: "not defined" }
];

const ASSET_ACTIONS = [
    { value: "", label: "--Select action--", askPermission: false },
    { value: "install", label: "Install Sophos", askPermission: true },
    // {value:"update",label:"Update Sophos",askPermission:true},
]

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getAssetList: (referrer, queryFilterString) => dispatch(getAssetList(referrer, queryFilterString)),
    clearAssetErrorResponseMessage: () => dispatch(clearAssetErrorResponseMessage()),
    clearAssetsTable: () => dispatch(clearAssetsTable())
})

class KaseyaAssets extends React.Component {
    constructor(props) {
        super(props);
        setInitStorage(props.referrerHostname, queryString.parse(props.location.search))
        this.state = {
            queryParams: queryString.parse(props.location.search),
            kaseyaParams: queryString.parse(props.location.search),
            tenantList: [],
            rowData: [],
            filtered: [],
            clearFilterBool: false,
            clearSearchBox: false,
            columnDefs: [
                { text: "Asset ID", dataField: "AssetId", sort: true, onSort: this.onSortChange, fixed: true },
                {
                    text: "Asset Name", dataField: "AssetName", sort: true,
                    onSort: this.onSortChange,
                    width: 150,
                    formatter: (cell, row) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                            if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row.AssetName))
                                return row.AssetName
                            else
                                return "-"
                        else
                            return "-"
                    }
                },
                {
                    text: "Display Name", dataField: "DisplayName", sort: true,
                    onSort: this.onSortChange,
                    width: 150,
                    formatter: (cell, row) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                            if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row.DisplayName))
                                return row.DisplayName
                            else
                                return "-"
                        else
                            return "-"
                    }
                },
                {
                    text: "Machine Group", dataField: "MachineGroup", sort: true,
                    onSort: this.onSortChange,
                    formatter: (cell, row) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                            if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row.MachineGroup)) {
                                return row.MachineGroup
                            }
                            else
                                return "-"
                        else
                            return "-"
                    }
                },
                {
                    text: "OS Type", dataField: "OSType", sort: true,
                    onSort: this.onSortChange,
                    formatter: (cell, row) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                            if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row.OSType)) {
                                return row.OSType
                            }
                            else
                                return "-"
                        else
                            return "-"

                    }
                },
                {
                    text: "Sophos Status", dataField: "SophosEndpointId", sort: true,
                    onSort: this.onSortChange,
                    align: "center",
                    formatter: (cell, row) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row)) {
                            if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row.SophosEndpointId)) {
                                return (<React.Fragment>
                                    <img data-tip="Installed" data-for={"sophos_" + row.AssetId} alt="Installed" className="sophos-icon-size" src={SophosInstalled} />
                                    <ReactTooltip id={"sophos_" + row.AssetId} effect="solid" type="dark" />
                                </React.Fragment>)
                            }
                            else {
                                return (<React.Fragment>
                                    <img data-tip="Not Installed" data-for={"sophos_" + row.AssetId} alt="Not Installed" className="sophos-icon-size" src={SophosNotInstalled} />
                                    <ReactTooltip id={"sophos_" + row.AssetId} effect="solid" type="dark" />
                                </React.Fragment>)
                            }
                        }
                        else
                            return "-"
                    },
                    sortValue: (cell, row) => NULL_UNDEFINED_EMPTY_CHECK.includes(row.SophosEndpointId) ? "Not Installed" : "Installed",
                },
            ],
            defaultSorted: [{
                dataField: 'AssetName',
                order: 'asc'
            }],
            singleSelectedAsset: '',
            machineGroupList: [],
            machineGroupLoading: false,
            currentPage: 1,
            pageWiseSelectedRows: [],
            assetsErrorResponse: this.props.assets.error_response_message,
            sortChangeBool: false,
            showErrorActionAlert: false,
            loadingAction: false,
            actionAssetMessage: "",
            selectedAction: "",
            showConfirmationInstallSophosModal: false,
            showAssetActionAlert: false,
            successCount: 0,
            failCount: 0,
            SophosTenantId: "",
        }
    }

    componentWillMountFunction = () => {
        let queryFitlerString = queryString.parse(this.props.location.search)
        if (queryFitlerString !== "") {
            this.getAssetsData()
            this.setState({
                machineGroupLoading: true
            }, this.getMachineGroupFunction())
        }
    }

    getMachineGroupFunction = () => {
        let machineGroupArray = []
        getMachinegroupList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search)
            .then(res => {
                res.data.response.items.forEach((row) => {
                    machineGroupArray.push({ value: row.MachineGroupId, label: row.MachineGroupName })
                })
                this.setState({
                    machineGroupLoading: false,
                    machineGroupList: machineGroupArray
                })
            })
            .catch(err => {
                this.setState({
                    machineGroupLoading: false
                })
            })
    }

    componentDidMount() {
        if (this.props.settings.SophosIdTypeIsTenant === undefined) {
            let queryFitlerString = queryString.parse(this.props.location.search)
            if (queryFitlerString !== "") {
                this.getAssetsData();
                this.setState({
                    machineGroupLoading: true
                }, this.getMachineGroupFunction())
            };
        }
        if (isDeploymentAutotmationEnabled(this.props.settings.current_version)) {
            const newValue = {
                "label": "Automatic (license based)",
                "value": "all"
            }
            MAC_PRODUCTS_OPTIONS.splice(MAC_PRODUCTS_OPTIONS.length - 1, 0, newValue);
            WINDOWS_DESKTOP_PRODUCTS_OPTIONS.splice(WINDOWS_DESKTOP_PRODUCTS_OPTIONS.length - 1, 0, newValue);
            WINDOWS_SERVER_PRODUCTS_OPTIONS.splice(WINDOWS_SERVER_PRODUCTS_OPTIONS.length - 1, 0, newValue);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevState.rowData) !== JSON.stringify(this.props.assets.asset_list)) {
            this.setState({
                rowData: this.props.assets.asset_list
            })
            this.clearErrorResponseToast()
        }
        if (JSON.stringify(this.props.assets.error_response_message) !== JSON.stringify(prevProps.assets.error_response_message)) {
            this.setState({
                assetsErrorResponse: this.props.assets.error_response_message,
            })
        }
        if (!this.props.settings.SophosIdTypeIsTenant && JSON.stringify(prevProps.settings.SophosIdTypeIsTenant) !== JSON.stringify(this.props.settings.SophosIdTypeIsTenant)) {
            this.componentWillMountFunction();
        }
    }

    componentWillUnmount() {
        this.clearErrorResponseToast()
    }

    onSortChange = () => {
        this.setState({
            sortChangeBool: !this.state.sortChangeBool
        })
    }

    filterData = (data) => {

        let result = []

        const selectedRowData =
            this.state.pageWiseSelectedRows.length &&
            this.state.pageWiseSelectedRows[0].selectedRow.length;
        const filteredData = this.state.selectedAction !== ""
            ? this.state.selectedAction === "update"
                ? _.filter(data, (row) => {
                    return row.SophosTenantId;
                })
                : _.filter(data, (row) => {
                    return !row.SophosTenantId;
                })
            : data;
        if (selectedRowData) {
            const rowWithSophosTenantId = _.find(this.state.pageWiseSelectedRows[0].selectedRow, row => {
                return row.SophosTenantId
            });
            const selectedRowTenantId = rowWithSophosTenantId && rowWithSophosTenantId.SophosTenantId;
            if (selectedRowTenantId) {
                data = _.filter(filteredData, (row) => {
                    return row.SophosTenantId === selectedRowTenantId || !row.SophosTenantId;
                });
            } else data = filteredData;
        } else data = filteredData;
        for (let i = 0; i < data.length; i++) {
            let flag = true
            for (let j = 0; j < this.state.filtered.length; j++) {
                if (this.state.filtered[j].type !== CONSTANT_TYPE.SophosEndpointId) {
                    let apiValue;
                    try {
                        apiValue = getDataFromJson(data[i], this.state.filtered[j].type.toString().split("_")).toString()
                    }
                    catch (err) {
                        apiValue = "-"
                    }

                    if (this.state.filtered[j].value !== "all" && this.state.filtered[j].value.toString().split(",").indexOf(apiValue) === -1) {
                        flag = false
                        break
                    }
                }
                else {
                    /** CHECK for Sophos Installed */
                    if (NULL_UNDEFINED_EMPTY_CHECK.includes(data[i].SophosEndpointId) && this.state.filtered[j].value.toString().split(",").length === 1 && this.state.filtered[j].value.toString().split(",")[0] !== "false") {
                        flag = false
                        break
                    }
                    /** CHECK for Sophos NOT Installed */
                    if (!NULL_UNDEFINED_EMPTY_CHECK.includes(data[i].SophosEndpointId) && this.state.filtered[j].value.toString().split(",").length === 1 && this.state.filtered[j].value.toString().split(",")[0] !== "true") {
                        flag = false
                        break
                    }
                }

            }
            if (flag) {
                result.push(data[i])
            }

        }
        return result
    }

    findFilterValueByType(arrayValue, searchType) {
        return arrayValue.type === searchType
    }

    getPageWiseSelectedRowsFromTable = (currentPage, selectedRowData) => {
        if (this.state.selectedAction === "update") {
            let selectedRow = selectedRowData.length && selectedRowData[0].selectedRow;
            if (selectedRow) {
                const rowWithSophosTenantId = _.find(selectedRow, (row) => {
                    return row.SophosTenantId;
                });
                const selectedRowTenantId =
                    rowWithSophosTenantId && rowWithSophosTenantId.SophosTenantId;
                if (selectedRowTenantId) {
                    selectedRow = _.filter(selectedRow, (row) => {
                        return (
                            row.SophosTenantId === selectedRowTenantId ||
                            !row.SophosTenantId
                        );
                    });
                }
                selectedRowData[0].selectedRow = selectedRow;
            }
        }
        this.setState({
            currentPage: currentPage,
            pageWiseSelectedRows: selectedRowData
        })
    }

    setTablePage = (page) => {
        this.setState({
            currentPage: page,
        })
    }

    selectFilters = (value, type) => {
        let filters = this.state.filtered
        let newfilterObj = { value: value, type: type }
        const obj = filters.find((arrayValue) => this.findFilterValueByType(arrayValue, newfilterObj.type))
        if (obj !== undefined) {
            const index = filters.indexOf(obj)
            filters.splice(index, 1)
        }
        if (value !== '') {
            filters.push(newfilterObj)
        }

        /**Specific for clearing search textbox of Table */
        let singleTenantSelectedBool = false
        if (this.state.clearSearchBox) {
            singleTenantSelectedBool = true
        }
        if (type === CONSTANT_TYPE.selectedTenant && value !== '')
            singleTenantSelectedBool = true
        else if (!this.state.clearSearchBox)
            singleTenantSelectedBool = false
        else if (type === CONSTANT_TYPE.selectedTenant && value === '')
            singleTenantSelectedBool = false

        this.setState({
            filtered: filters,
            clearSearchBox: singleTenantSelectedBool
        })
        // this.getAssetsData()
    }

    clearFilter() {
        this.setState({ filtered: [], clearFilterBool: !this.state.clearFilterBool })
        // this.props.clearAssetsTable()
        // this.getAssetsData()
    }

    getAssetsData() {
        if (this.props.location.search !== "") {
            // if(!forceBoolean)
            // this.props.clearAssetsTable()
            const queryParams = queryString.parse(this.props.location.search)
            const queryFilter = queryString.stringify(queryParams)
            this.props.getAssetList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), "?" + queryFilter)
        }
        else {
            this.props.getAssetList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"))
        }

    }

    getAssetSelectedId = (selectedId) => {
        this.setState({
            singleSelectedAsset: selectedId
        })
    }

    findAsset(assets, selectedAsset) {
        return assets.AssetId === selectedAsset;
    }

    backToTenant = () => {
        this.props.history.push({
            pathname: "/tenants",
            search: this.props.location.search
        })
    }
    clearNextpossibleToast = () => {
        this.props.clearNextPossibleResponseMessage()
    }

    clearErrorResponseToast = () => {
        this.props.clearAssetErrorResponseMessage()
    }

    tablegridMessage() {
        if (!this.props.assets.is_loading) {
            let filteredObject = this.state.filtered.find((arrayValue) => this.findFilterValueByType(arrayValue, CONSTANT_TYPE.selectedTenant))
            if (filteredObject !== undefined) {
                let checkTenantDetails = this.props.tenants.tenant_list.find((tenantValue) => tenantValue.id === filteredObject.value)
                if (checkTenantDetails) {
                    if (checkTenantDetails.apiHost === null || checkTenantDetails.apiHost === "" || checkTenantDetails.apiHost === "NULL" || checkTenantDetails.apiHost === "null" || checkTenantDetails.apiHost === undefined) {
                        return { message: 'This account has not been activated; <br/>Please activate from <a target="_blank" href="https://cloud.sophos.com/">Sophos Central Administration Console</a>', type: "error" }
                    }
                    else {
                        return { message: "No assets found to match your search", type: "info" }
                    }
                }
                else {
                    return { message: "No assets found to match your search", type: "info" }
                }
            }
            else {
                return { message: "No assets found to match your search", type: "info" }
            }
        }
        else {
            return { message: "Loading....", type: "info" }
        }
    }

    getSelectedType = () => {
        if (this.state.filtered.find((arrayValue) => this.findFilterValueByType(arrayValue, CONSTANT_TYPE.type))) {
            let selectedTypeArray = this.state.filtered.find((arrayValue) => this.findFilterValueByType(arrayValue, CONSTANT_TYPE.type)).value.split(",")
            let selecteFinalValues = []
            selectedTypeArray.forEach((selectElement) => {
                selecteFinalValues.push(computerTypeOptions.find((element) => element.value === selectElement))
            })
            return selecteFinalValues

        }
        else {
            return []
        }
    }

    handleActionChangeFilter = (value) => {
        if (value === "") {
            this.setState({
                selectedAction: "",
                pageWiseSelectedRows: []
            })
        }
        else {
            let selectedRow = [];
            const selectedRowData =
                this.state.pageWiseSelectedRows.length &&
                this.state.pageWiseSelectedRows[0].selectedRow.length;

            if (selectedRowData) {
                if (value === "update") {
                    selectedRow = _.filter(
                        this.state.pageWiseSelectedRows[0].selectedRow,
                        (row) => {
                            return row.SophosTenantId;
                        }
                    );
                } else {
                    selectedRow = _.filter(
                        this.state.pageWiseSelectedRows[0].selectedRow,
                        (row) => {
                            return !row.SophosTenantId;
                        }
                    );
                }
            }
            this.setState({
                selectedAction: value,
                pageWiseSelectedRows: selectedRowData
                    ? [
                        {
                            ...this.state.pageWiseSelectedRows[0],
                            pageNumber:
                                this.state.pageWiseSelectedRows[0] &&
                                this.state.pageWiseSelectedRows[0].pageNumber,
                            selectedRow,
                        },
                    ]
                    : [],
            });
        }
    }

    getNonSelectableRows = () => {
        let allNonselectableRowIds = []
        return allNonselectableRowIds
    }

    submitAssetAction = () => {
        if (this.state.pageWiseSelectedRows.length === 0 || this.state.pageWiseSelectedRows[0].selectedRow.length === 0) {
            this.setState({
                showErrorActionAlert: true,
                loadingAction: false,
                actionAssetMessage: "No assets selected; Please select atleast one asset."
            })
        }
        else {
            const selectedRowData = this.state.pageWiseSelectedRows[0].selectedRow[0];
            this.setState({
                showConfirmationInstallSophosModal: true,
                actionAssetMessage: `Number of selected asset(s): <strong>${this.state.pageWiseSelectedRows[0].selectedRow.length}</strong>`,
                SophosTenantId: selectedRowData.SophosTenantId ? selectedRowData.SophosTenantId : "",
            });
        }
    }

    closeActionErrorModal = () => {
        this.setState({
            showErrorActionAlert: false,
            actionAssetMessage: ""
        })
    }

    closeInstallSophosModal = () => {
        this.setState({
            showConfirmationInstallSophosModal: false,
            actionAssetMessage: ""
        })
    }

    sendAssetsActionHandler = (confirmedObj) => {
        this.setState({
            loadingAction: true,
            showConfirmationInstallSophosModal: false,
            actionAssetMessage: ""
        }, () => {
            let finalAssetIdsArray = []
            this.state.pageWiseSelectedRows[0].selectedRow.forEach((elementAsset) => {
                finalAssetIdsArray.push(elementAsset.AssetId)
            })
            let requestBody = {
                assetIds: finalAssetIdsArray,
                macProducts: confirmedObj.macProductsSelected,
                windowsDesktopProducts: confirmedObj.windowsDesktopProductsSelected,
                windowsServerProducts: confirmedObj.windwosServerProductsSelected,
                sophosTenantId: confirmedObj.tenantSelected,
                deviceEncryption: confirmedObj.deviceEncryption,
                removeCompetitiveProducts: confirmedObj.removeCompetitiveProducts
            }
            sendAssetAction(requestBody, this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search)
                .then((res) => {
                    this.setState({
                        loadingAction: false,
                        showAssetActionAlert: true,
                        successCount: res.data.response.success.count,
                        failCount: res.data.response.failed.count,
                    })
                })
                .catch((err) => {
                    let errMessage = ""
                    if (err.response) {
                        if (err.response.data.message !== undefined)
                            errMessage = err.response.data.message
                        else
                            errMessage = "Something went wrong; Please try again later."

                    }
                    else {
                        errMessage = "Something went wrong; Please try again later."
                    }
                    this.setState({
                        loadingAction: false,
                        showErrorActionAlert: true,
                        actionAssetMessage: errMessage
                    })
                })
        })
    }

    goToAuditLog = () => {
        this.props.history.push({
            pathname: "/auditlogs",
            search: this.props.location.search,
            filtersObject: { value: "DEPLOYMENT" }
        })
    }

    closeAssetActionAlert = () => {
        this.setState({
            showAssetActionAlert: false,
            successCount: 0,
            failCount: 0,
            selectedAction: ""
        })
    }

    // showToast=()=>{
    //     let next_response_time=new Date(this.state.nextPossibleForce.next_possible_force*1000)
    //     let req_per_hour_diff=Math.round(60/this.props.assets.req_per_hour)
    //     let last_sync_time=new Date((this.state.nextPossibleForce.next_possible_force*1000)-(1000*60*req_per_hour_diff))
    //     toast.error(`Last sync ran at ${last_sync_time.toLocaleTimeString()}. Next available sync at ${next_response_time.toLocaleTimeString()}`,{containerId: 'A',onClose: () => this.clearNextpossibleToast()});
    // }

    render() {
        let selectedAsset = this.props.assets.asset_list.find((value) => this.findAsset(value, this.state.singleSelectedAsset))
        let messageForForceSync = this.props.settings.assets_last_updated ? 'Last Assets Sync Time: ' + moment.unix(this.props.settings.assets_last_updated).format('YYYY-MM-DD HH:mm:ss') : '';
        return (
            this.props.settings.SophosIdTypeIsTenant ?
                <TenantAccessError />
                : <CommonLayout title="Assets" displayTitle={true}>
                    <ErrorModal
                        show={this.props.assets.error_response_message !== null}
                        closeModal={() => {
                            this.clearErrorResponseToast()
                        }}
                        modalMessage={
                            this.props.assets.error_response_message}
                    />
                    <ErrorModal
                        show={this.state.showErrorActionAlert}
                        closeModal={this.closeActionErrorModal}
                        modalMessage={this.state.actionAssetMessage}
                    />
                    <ActionLoadingModal
                        show={this.state.loadingAction}
                    />
                    <ConfirmationInstallSophosModal
                        {...this.props}
                        show={this.state.showConfirmationInstallSophosModal}
                        selectedAction={this.state.selectedAction}
                        SophosTenantId={this.state.SophosTenantId}
                        confirmAction={(confirmedObject) => this.sendAssetsActionHandler(confirmedObject)}
                        closeModal={this.closeInstallSophosModal}
                        modalMessage={this.state.actionAssetMessage}
                        selectMacProductOptions={MAC_PRODUCTS_OPTIONS}
                        selectWindowsDesktopProductOptions={WINDOWS_DESKTOP_PRODUCTS_OPTIONS}
                        selectWindowsServerProductOptions={WINDOWS_SERVER_PRODUCTS_OPTIONS}
                    />
                    <ConfirmationInstallSophosResponseModal
                        show={this.state.showAssetActionAlert}
                        selectedAction={this.state.selectedAction !== "" ? ASSET_ACTIONS.find((elementAction) => elementAction.value === this.state.selectedAction).label : ""}
                        closeModal={this.closeAssetActionAlert}
                        viewLog={this.goToAuditLog}
                        success={this.state.successCount}
                        fail={this.state.failCount}
                    />
                    {/* <ToastContainer
                    position="top-right"
                    autoClose={7000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable={false}
                    pauseOnHover={false}
                    enableMultiContainer 
                    containerId={'A'}
                /> */}

                    {/** FILTER SECTION START TODO */}

                    <div className="row mt-2 mx-1 justify-content-between">
                        <div className="col-4">
                            <div className="input-group-append row">
                                <div className="col px-0 mx-1 text-left">
                                    <span>Machine Groups</span>
                                    <MultiSelectTextbox
                                        options={this.state.machineGroupList.sort(sortingData)}
                                        isLoading={this.state.machineGroupLoading}
                                        isMulti={true}
                                        filteredSelectedValue={
                                            this.state.filtered.find((arrayValue) =>
                                                this.findFilterValueByType(arrayValue, CONSTANT_TYPE.MachineGroup)) ?
                                                this.state.machineGroupList.filter((element) => element.value === this.state.filtered.find((arrayValue) =>
                                                    this.findFilterValueByType(arrayValue, CONSTANT_TYPE.MachineGroup)).value)
                                                : []
                                        }
                                        onSelectOption={(value) => this.selectFilters(value, CONSTANT_TYPE.MachineGroup)}
                                        clearValue={this.state.clearFilterBool}
                                    />
                                </div>
                                <div className="col px-0 mx-1 text-left">
                                    <span>Sophos Status</span>
                                    <MultiSelectTextbox
                                        options={sophosStatusOptions}
                                        isMulti={true}
                                        filteredSelectedValue={
                                            this.state.filtered.find((arrayValue) =>
                                                this.findFilterValueByType(arrayValue, CONSTANT_TYPE.SophosEndpointId)) ?
                                                sophosStatusOptions.filter((element) => element.value === this.state.filtered.find((arrayValue) =>
                                                    this.findFilterValueByType(arrayValue, CONSTANT_TYPE.SophosEndpointId)).value)
                                                : []
                                        }
                                        onSelectOption={(value) => this.selectFilters(value, CONSTANT_TYPE.SophosEndpointId)}
                                        clearValue={this.state.clearFilterBool}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col pl-1 text-left mt-3">
                            <button type="button" className="btn btn-secondary btn-sm ml-2" disabled={this.props.assets.is_loading || this.state.filtered.length === 0} onClick={this.clearFilter.bind(this)}>Clear</button>
                        </div>
                    </div>

                    <div className="row mt-2 mx-1">
                        <div className="col-12 text-right">
                            <b>{messageForForceSync}</b>
                        </div>
                    </div>
                    {/** FILTER SECTION END TODO */}

                    <div className="row m-1 card-content-height">
                        <div className="col-md-8 pr-1">
                            <TableGrid
                                clearValue={this.state.clearFilterBool}
                                defaultSortedCol={this.state.defaultSorted}
                                columnStructure={this.state.columnDefs}
                                responseData={this.filterData(this.state.rowData)}
                                loading={this.props.assets.is_loading}
                                stateObject={this.props.assets}
                                pageSize={this.filterData(this.state.rowData).length < this.props.assets.page_size ? this.filterData(this.state.rowData).length : this.props.assets.page_size}
                                filtered={this.state.filtered}
                                noDataText={this.tablegridMessage()}
                                keyField="AssetId"
                                moduleName="assets"
                                // rowClickEvent={(row)=>this.getAssetSelectedId(row.id)}
                                clearSearchBox={this.state.clearSearchBox}
                                singleSelectedRow={(selectedId) => this.getAssetSelectedId(selectedId)}
                                pageWiseSelectedRowsFromTable={(currentPage, selectedRowData) => this.getPageWiseSelectedRowsFromTable(currentPage, selectedRowData)}
                                onPageChangeEvent={(page) => this.setTablePage(page)}
                                onSortChange={this.state.sortChangeBool}
                                nonSelectableRows={this.getNonSelectableRows(this.filterData(this.state.rowData))}
                                keepSelectedRow={this.state.pageWiseSelectedRows}
                            />
                        </div>
                        <div className="col-md-4 pl-1">
                            <div className="row mr-2">
                                <div className="col pr-1">
                                    <span>Action <a href className="text-muted" data-tip data-for="actionDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                                    <ReactTooltip id="actionDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                                        <ol className="m-0">
                                            <li>Select an action.</li>
                                            <li>Select an asset(s) for which action needs to be taken.</li>
                                            <li>Click on submit.</li>
                                        </ol>
                                    </ReactTooltip>
                                    <div className="row">
                                        <div className="col pr-1">
                                            <select className="form-control form-control-sm"
                                                disabled={this.state.loadingAction}
                                                onChange={(e) => this.handleActionChangeFilter(e.target.value)}
                                                value={this.state.selectedAction}>
                                                {ASSET_ACTIONS.map((element) => {
                                                    return (<option key={element.value} value={element.value}>{element.label}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className="col pl-1">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm mb-2"
                                                disabled={this.state.selectedAction === "" || this.state.loadingAction}
                                                onClick={this.submitAssetAction}>
                                                Submit {this.state.loadingAction ?
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    : ''}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <Card>
                                        <Card.Header className="p-1">{!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset) ? !NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.DisplayName) ? "Asset Name: " + selectedAsset.DisplayName : "Click on a row to view details" : "Click on a row to view details"}</Card.Header>
                                        <Scrollbars style={{ width: 'auto', height: 'calc(100vh - 266px)' }}>
                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset) ?
                                                <Card.Body>
                                                    <h5 className="card-title">Asset Summary</h5>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>Asset ID:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.AssetId) ? selectedAsset.AssetId : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>Agent ID:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.AgentId) ? selectedAsset.AgentId : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>Display Name:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.DisplayName) ? selectedAsset.DisplayName : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>Hostname:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.HostName) ? selectedAsset.HostName : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>Asset Name:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.AssetName) ? selectedAsset.AssetName : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>Machine Group:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.MachineGroup) ? selectedAsset.MachineGroup : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>Last Seen At:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.LastSeenDate) ? moment(selectedAsset.LastSeenDate).format("DD-MMM-YYYY HH:mm") : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>IP Addresses:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.IPAddresses) ? selectedAsset.IPAddresses : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>MAC Addresses:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.MACAddresses) ? selectedAsset.MACAddresses : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>OS Name:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.OSName) ? selectedAsset.OSName : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>OS Type:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.OSType) ? selectedAsset.OSType : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>OS Family:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.OSFamily) ? selectedAsset.OSFamily : "-"}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <strong>OS Generation:</strong>
                                                        </div>
                                                        <div className="col-6">
                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.OSGeneration) ? selectedAsset.OSGeneration : "-"}
                                                        </div>
                                                    </div>
                                                    {/* <div className="row">
                                            <div className="col-5">
                                                <strong>Sophos Status:</strong>
                                            </div>
                                            <div className="col-6">
                                                {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedAsset.SophosEndpointId)?
                                                <React.Fragment>
                                                    <img data-tip="Installed" data-for={"sophos_"+selectedAsset.AssetId} alt="Installed" className="sophos-icon-size" src={SophosInstalled} />
                                                    <ReactTooltip id={"sophos_"+selectedAsset.AssetId} effect="solid" type="dark"/>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <img data-tip="Not Installed" data-for={"sophos_"+selectedAsset.AssetId} alt="Not Installed" className="sophos-icon-size" src={SophosNotInstalled}/>
                                                    <ReactTooltip id={"sophos_"+selectedAsset.AssetId} effect="solid" type="dark"/>
                                                </React.Fragment>
                                                }
                                            </div>
                                        </div> */}
                                                </Card.Body>
                                                : ''}
                                        </Scrollbars>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </CommonLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KaseyaAssets)