/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 20-04-2020 15:51
 * @modify date 20-04-2020 15:51
 * @description This component is a row component of deploymnet select table.
 */
import React from "react";
import Card from 'react-bootstrap/Card';
import ToggleButton from 'react-toggle-button';
import ReactTooltip from 'react-tooltip';
import _ from "lodash";
import MultiSelectTextbox from '../common/MultiSelectTextbox';

export const ROW_TYPE={
    MachineGroupOrganization:"machineGroupOrganizationId",
    Tenant:"tenantId",
    MacProducts:"macProducts",
    WindowsDesktopProducts:"windowsDesktopProducts",
    WindowsServerProducts:"windowsServerProducts",
    DeviceEncryption:"deviceEncryption",
    RemoveCompetitiveProducts:"removeCompetitiveProducts"
}

class DeploymentSelectionRow extends React.Component {

    onDelEvent() {
      this.props.onDelEvent(this.props.selectionRow);
    }

    getSelectedProduct=(currentDropdownType)=>{
        if(currentDropdownType===ROW_TYPE.MacProducts){
            if(this.props.selectionRow[ROW_TYPE.MacProducts]!==null){
                let selectedProduct=this.props.selectionRow[ROW_TYPE.MacProducts]
                let selecteFinalValues=[]
                
                if(this.props.macProductsOptions.find((element)=>element.value===selectedProduct))
                    selecteFinalValues.push(this.props.macProductsOptions.find((element)=>element.value===selectedProduct))
                
                return selecteFinalValues
            }
            else{
                return []
            }
        }
        else if(currentDropdownType===ROW_TYPE.WindowsDesktopProducts){
            if(this.props.selectionRow[ROW_TYPE.WindowsDesktopProducts]!==null){
                let selectedProduct=this.props.selectionRow[ROW_TYPE.WindowsDesktopProducts]
                let selecteFinalValues=[]
                
                if(this.props.windowsDesktopProductsOptions.find((element)=>element.value===selectedProduct))
                    selecteFinalValues.push(this.props.windowsDesktopProductsOptions.find((element)=>element.value===selectedProduct))
                            
                return selecteFinalValues
            }
            else{
                return []
            }
        }
        else if(currentDropdownType===ROW_TYPE.WindowsServerProducts){
            if(this.props.selectionRow[ROW_TYPE.WindowsServerProducts]!==null){
                let selectedProduct=this.props.selectionRow[ROW_TYPE.WindowsServerProducts]
                let selecteFinalValues=[]

                if(this.props.windowsServerProductsOptions.find((element)=>element.value===selectedProduct))
                    selecteFinalValues.push(this.props.windowsServerProductsOptions.find((element)=>element.value===selectedProduct))
                
                return selecteFinalValues
            }
            else{
                return []
            }
        }
        else{
            return []
        }
    }

    onChangeEventHandler=(rowId,rowType,value)=>{
        this.props.onProductTableUpdate(rowId,rowType,value)
    }

    render() {
      return (
            <Card className="mb-2 mr-2 shadow-sm rounded-0">
                <div className="row">
                    <Card.Body className="p-2">
                    <div className="col pr-1">
                    <label>#{this.props.indexNumner+1}</label>
                        <div className="row pb-2 pl-2">
                            <div className={this.props.selectionRow.validRow || this.props.selectionRow[ROW_TYPE.MachineGroupOrganization]?"col-md-4":"col-md-4 border border-danger"}>
                                <span>Select {this.props.tenantSelectionMode}*</span>
                                <MultiSelectTextbox 
                                    onSelectOption={(value)=>this.onChangeEventHandler(this.props.selectionRow.id,ROW_TYPE.MachineGroupOrganization,value)} 
                                    options={_.orderBy(this.props.machineGroupOrganizationData,[element => element.label.toLowerCase()])}
                                    filteredSelectedValue={
                                        this.props.selectionRow[ROW_TYPE.MachineGroupOrganization]!==null?
                                        this.props.machineGroupOrganizationData.filter((element)=>element.value===this.props.selectionRow[ROW_TYPE.MachineGroupOrganization])
                                        :
                                        []
                                    }
                                    customPlaceholder="Select"
                                    customDisabledLabel="used"
                                />
                            </div>
                            <div className={this.props.selectionRow.validRow || this.props.selectionRow[ROW_TYPE.Tenant]?"col-md-4":"col-md-4 border border-danger"}>
                                <span>Select Tenant*</span>
                                <MultiSelectTextbox 
                                    onSelectOption={(value)=>this.onChangeEventHandler(this.props.selectionRow.id,ROW_TYPE.Tenant,value)}
                                    options={_.orderBy(this.props.tenantNameOptions,[tenant => tenant.label.toLowerCase()])}
                                    filteredSelectedValue={
                                        this.props.selectionRow[ROW_TYPE.Tenant]!==null?
                                        this.props.tenantNameOptions.filter((element)=>element.value===this.props.selectionRow[ROW_TYPE.Tenant])
                                        :
                                        []
                                    } 
                                    customPlaceholder="Select" 
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-5">
                                        <span>Device Encryption</span>
                                        <ToggleButton value={ this.props.selectionRow[ROW_TYPE.DeviceEncryption] }
                                            onToggle={(value) => {
                                                this.onChangeEventHandler(this.props.selectionRow.id,ROW_TYPE.DeviceEncryption,!value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-7">
                                        <span>Remove Competitive Product <a href className="text-muted" data-tip data-for="infoDetails"><i className="fa fa-info-circle" aria-hidden="true"></i></a></span>
                                        <ReactTooltip id="infoDetails" place="top" effect="solid" type="dark" className="tooltip-fix-width-200">
                                            Only applicable for windows machines.
                                        </ReactTooltip>
                                        <ToggleButton value={ this.props.selectionRow[ROW_TYPE.RemoveCompetitiveProducts] }
                                            onToggle={(value) => {
                                                this.onChangeEventHandler(this.props.selectionRow.id,ROW_TYPE.RemoveCompetitiveProducts,!value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-2 pl-2">
                            {/* <div className={this.productsValidCell(ROW_TYPE.WindowsDesktopProducts)?"col-md-4 border border-danger":"col-md-4"}> */}
                            <div className="col-md-4">
                                <span>Select Windows Desktop Products</span>
                                <MultiSelectTextbox 
                                    onSelectOption={(value)=>this.onChangeEventHandler(this.props.selectionRow.id,ROW_TYPE.WindowsDesktopProducts,value)}
                                    filteredSelectedValue={this.getSelectedProduct(ROW_TYPE.WindowsDesktopProducts)}
                                    options={this.props.windowsDesktopProductsOptions}
                                    customPlaceholder="Select"
                                />
                            </div>
                            {/* <div className={this.productsValidCell(ROW_TYPE.WindowsServerProducts)?"col-md-4 border border-danger":"col-md-4"}> */}
                            <div className="col-md-4">
                                <span>Select Windows Server Products</span>
                                <MultiSelectTextbox 
                                    onSelectOption={(value)=>this.onChangeEventHandler(this.props.selectionRow.id,ROW_TYPE.WindowsServerProducts,value)}
                                    filteredSelectedValue={this.getSelectedProduct(ROW_TYPE.WindowsServerProducts)}
                                    options={this.props.windowsServerProductsOptions}
                                    customPlaceholder="Select"
                                />
                            </div>
                            {/* <div className={this.productsValidCell(ROW_TYPE.MacProducts)?"col-md-4 border border-danger":"col-md-4"}> */}
                            <div className="col-md-4">
                                <span>Select Mac Products</span>
                                <MultiSelectTextbox 
                                    onSelectOption={(value)=>this.onChangeEventHandler(this.props.selectionRow.id,ROW_TYPE.MacProducts,value)}
                                    filteredSelectedValue={this.getSelectedProduct(ROW_TYPE.MacProducts)}
                                    options={this.props.macProductsOptions}
                                    customPlaceholder="Select"
                                />
                            </div>
                        </div>
                    </div>
                    </Card.Body> 
                    <button className="btn-sm shadow-sm btn-danger rounded-0" onClick={this.onDelEvent.bind(this)}><i className="fa fa-trash"></i></button>
                </div>
            </Card>
      );
    }
}

export default DeploymentSelectionRow