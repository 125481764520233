/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:50
 * @modify date 06-02-2020 12:50
 * @description Below redux reducer is created for alerts.
 */
import * as initialState from './initialState';
import * as actionTypes from '../services/types';

export default (state , action) => {
    if(state === undefined){
        state = initialState.alerts
    }
    switch (action.type) {
        case actionTypes.SET_ALERTS_LIST:
            return {
                ...state,
                alert_list: action.data.response.items,
                total: action.data.response.pages.total,
                current_page: action.data.response.pages.current,
                page_size: action.data.response.pages.size,
                max_page_size: action.data.response.pages.maxSize,
            };
        case actionTypes.SET_ALERT_LIST_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:action.data
            };
        case actionTypes.CLEAR_ALERT_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:null
            };
        case actionTypes.GET_ALERT_DATA:
            return {...state};
        case actionTypes.CLEAR_ALERTS_DATA:
            return {
                ...state,
                alert_list:[],
                total: null,
                current_page: null,
                page_size: null,
                max_page_size:null,
                error_response_message:null
            }
        case actionTypes.START_LOADER_ALERTS:
            return {
                ...state,
                is_loading:true
            };
        case actionTypes.STOP_LOADER_ALERTS:
            return {
                ...state,
                is_loading:false
            };
        default:
    }
  
    return state;
  };