/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:53
 * @modify date 06-02-2020 12:53
 * @description This file combines all the reducer in single Redux store.
 */
import { combineReducers } from 'redux';
import settings from './settingsReducers';
import tenants from './customersReducers';
import endpoints from './endpointsReducers';
import assets from './assetsReducers';
import alerts from './alertsReducers';
import dashboard from './dashboardReducers';
import audit from './auditReducers';
import allowed_blocked from './allowedBlockedReducers';
import scanning_exclusion from './scanningExclusionReducers';
import website_management from './websiteManagementReducers';
import exploit_mitigation from './exploitMitigationReducers';
import intrusion_prevention from './intrusionPreventionReducers';
import feature_flags from './featureflagsReducers';
const rootReducer = combineReducers({ tenants, settings, endpoints, assets, alerts, dashboard, audit, allowed_blocked, scanning_exclusion, website_management, exploit_mitigation, intrusion_prevention, feature_flags });
export default rootReducer;