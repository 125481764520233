/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 04-03-2020 15:07
 * @modify date 04-03-2020 15:07
 * @description This component is modal for displaying error.
 */

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-toggle-button';
import ReactTooltip from 'react-tooltip';
import _ from "lodash";
import { getAssetTenantsList, getTenantsList } from '../../services/apiOperations';
import MultiSelectTextbox from './MultiSelectTextbox';
import { isDeploymentAutotmationEnabled } from '../../services/flagOperations';

const EVENT_TYPE = {
    Mac: "Mac",
    WindowsDesktop: "WindowsDesktop",
    WindowsServer: "WindowsServer",
    DeviceEncryption: "deviceEncryption",
    RemoveCompetitiveProducts: "removeCompetitiveProducts"
}
class ConfirmationInstallSophosModal extends React.Component {

    constructor(props) {
        super(props);
        const defaultValue = isDeploymentAutotmationEnabled(this.props.settings.current_version) ? 'all' : 'intercept,antivirus';
        this.state = {
            tenantLoading: false,
            selectedTenant: '',
            selectedMacProducts: defaultValue,
            selectedWindowsDesktopProducts: defaultValue,
            selectedWindowsServerProducts: defaultValue,
            deviceEncryption: true,
            removeCompetitiveProducts: false,
            selectedTenantValidation: '',
            selectedTenantOptionsArray: [],
        }
    }

    getTenantList = () => {
        if (isDeploymentAutotmationEnabled(this.props.settings.current_version)) {
            getTenantsList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search, this.props.SophosTenantId)
                .then((res) => {
                    let tenantListArray = []
                    res.data.response.items.forEach((elementTenant) => {
                        tenantListArray.push({ "value": elementTenant.id, "label": elementTenant.showAs })
                    })
                    this.setState({
                        tenantLoading: false,
                        selectedTenantOptionsArray: tenantListArray
                    })
                })
                .catch((err) => {
                    let errMessage = ""
                    if (err.response) {
                        if (err.response.data.message !== undefined)
                            errMessage = err.response.data.message
                        else
                            errMessage = "Something went wrong while fetching tenant list."
                    }
                    else {
                        errMessage = "Something went wrong while fetching tenant list."
                    }

                    this.setState({
                        tenantLoading: false,
                        selectedTenantValidation: errMessage
                    })
                });
        } else {
            getAssetTenantsList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search, this.props.SophosTenantId)
                .then((res) => {
                    let tenantListArray = []
                    res.data.response.tenants.forEach((elementTenant) => {
                        tenantListArray.push({ "value": elementTenant.tenantId, "label": elementTenant.tenantName })
                    })
                    this.setState({
                        tenantLoading: false,
                        selectedTenantOptionsArray: tenantListArray
                    })
                })
                .catch((err) => {
                    let errMessage = ""
                    if (err.response) {
                        if (err.response.data.message !== undefined)
                            errMessage = err.response.data.message
                        else
                            errMessage = "Something went wrong while fetching tenant list."
                    }
                    else {
                        errMessage = "Something went wrong while fetching tenant list."
                    }

                    this.setState({
                        tenantLoading: false,
                        selectedTenantValidation: errMessage
                    })
                });

        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(prevProps.show)) {
            if (this.props.show === true) {
                const defaultValue = isDeploymentAutotmationEnabled(this.props.settings.current_version) ? 'all' : 'intercept,antivirus';
                this.setState({
                    selectedMacProducts: defaultValue,
                    selectedWindowsDesktopProducts: defaultValue,
                    selectedWindowsServerProducts: defaultValue,
                    deviceEncryption: true,
                    removeCompetitiveProducts: false,
                    selectedTenant: '',
                    selectedTenantOptionsArray: [],
                    tenantLoading: true
                }, () => this.getTenantList())
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(nextProps.show)) {
            return true
        }
        if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
            return true
        }
        return false
    }

    handleTenantChange = (e) => {
        this.setState({
            selectedTenant: e,
            selectedTenantValidation: ''
        })
    }

    handleProductsChange = (e, eventType) => {
        this.setState({
            [`selected${eventType}Products`]: e,
        })
    }

    checkMandatorySelection = () => {
        if (this.props.selectedAction === "install" && this.state.selectedTenant === "") {
            this.setState({
                selectedTenantValidation: 'Please select tenant.'
            })
        }
        else {
            let returnObj = {
                tenantSelected: this.state.selectedTenant,
                macProductsSelected: this.state.selectedMacProducts,
                windowsDesktopProductsSelected: this.state.selectedWindowsDesktopProducts,
                windwosServerProductsSelected: this.state.selectedWindowsServerProducts,
                deviceEncryption: this.state.deviceEncryption,
                removeCompetitiveProducts: this.state.removeCompetitiveProducts
            }
            this.props.confirmAction(returnObj)
            const defaultValue = isDeploymentAutotmationEnabled(this.props.settings.current_version) ? 'all' : 'intercept,antivirus';
            this.setState({
                selectedTenant: '',
                selectedMacProducts: defaultValue,
                selectedWindowsDesktopProducts: defaultValue,
                selectedWindowsServerProducts: defaultValue,
                selectedTenantValidation: '',
                deviceEncryption: true,
                removeCompetitiveProducts: false,
            })
        }
    }

    onChangeToggleEventHandler = (eventType, value) => {
        this.setState({
            [eventType]: value
        })
    }

    resetModalOptions = () => {
        const defaultValue = isDeploymentAutotmationEnabled(this.props.settings.current_version) ? 'all' : 'intercept,antivirus';
        this.setState({
            selectedTenant: '',
            selectedMacProducts: defaultValue,
            selectedWindowsDesktopProducts: defaultValue,
            selectedWindowsServerProducts: defaultValue,
            selectedTenantValidation: '',
            deviceEncryption: true,
            removeCompetitiveProducts: false,
        }, () => this.props.closeModal())
    }

    render() {
        return (
            <Modal
                // size="sm"
                show={this.props.show}
                onHide={this.resetModalOptions}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Confirmation</h5>
                </Modal.Header>
                <Modal.Body>
                    <p dangerouslySetInnerHTML={{ __html: this.props.modalMessage }}></p>
                    {this.props.selectedAction === "install" ?
                        <div className="row">
                            <div className="col">
                                <span>Select Tenant*</span>
                                <MultiSelectTextbox
                                    options={_.orderBy(this.state.selectedTenantOptionsArray, [tenant => tenant.label.toLowerCase()])}
                                    customPlaceholder="Select"
                                    filteredSelectedValue={[]}
                                    isMulti={false}
                                    onSelectOption={(value) => this.handleTenantChange(value)}
                                    isLoading={this.state.tenantLoading}
                                />
                                <span className="text-danger">{this.state.selectedTenantValidation}</span>
                            </div>
                        </div> :
                        <>
                            <p className="mb-0"> Selected Tenant : {this.state.selectedTenantOptionsArray[0] && <b>{this.state.selectedTenantOptionsArray[0].label}</b>}</p>
                            <span className="text-danger">{this.state.selectedTenantValidation}</span>
                        </>
                    }
                    <div className="row py-2">
                        <div className="col-5">
                            <span>Device Encryption</span>
                            <ToggleButton value={this.state[EVENT_TYPE.DeviceEncryption]}
                                onToggle={(value) => {
                                    this.onChangeToggleEventHandler(EVENT_TYPE.DeviceEncryption, !value)
                                }}
                            />
                        </div>
                        <div className="col-7">
                            <span>Remove Competitive Product <a href className="text-muted" data-tip data-for="infoDetails"><i className="fa fa-info-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="infoDetails" place="top" effect="solid" type="dark" className="tooltip-fix-width-200">
                                Only applicable for windows machines.
                            </ReactTooltip>
                            <ToggleButton value={this.state[EVENT_TYPE.RemoveCompetitiveProducts]}
                                onToggle={(value) => {
                                    this.onChangeToggleEventHandler(EVENT_TYPE.RemoveCompetitiveProducts, !value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="row py-1">
                        <div className="col">
                            <label><span>Select Windows Desktop Products</span></label>
                            <MultiSelectTextbox
                                options={this.props.selectWindowsDesktopProductOptions}
                                filteredSelectedValue={
                                    this.props.selectWindowsDesktopProductOptions.find((elementOption) =>
                                        elementOption.value === this.state.selectedWindowsDesktopProducts
                                    ) ?
                                        this.props.selectWindowsDesktopProductOptions.filter((elementOption) =>
                                            elementOption.value === this.state.selectedWindowsDesktopProducts
                                        )
                                        : []
                                }
                                onSelectOption={(value) => this.handleProductsChange(value, EVENT_TYPE.WindowsDesktop)}
                                customPlaceholder="Select"
                            />
                        </div>
                    </div>
                    <div className="row py-1">
                        <div className="col">
                            <label><span>Select Windows Server Products</span></label>
                            <MultiSelectTextbox
                                options={this.props.selectWindowsServerProductOptions}
                                filteredSelectedValue={
                                    this.props.selectWindowsServerProductOptions.find((elementOption) =>
                                        elementOption.value === this.state.selectedWindowsServerProducts
                                    ) ?
                                        this.props.selectWindowsServerProductOptions.filter((elementOption) =>
                                            elementOption.value === this.state.selectedWindowsServerProducts
                                        )
                                        : []
                                }
                                onSelectOption={(value) => this.handleProductsChange(value, EVENT_TYPE.WindowsServer)}
                                customPlaceholder="Select"
                            />
                        </div>
                    </div>
                    <div className="row py-1">
                        <div className="col">
                            <label><span>Select Mac Products</span></label>
                            <MultiSelectTextbox
                                options={this.props.selectMacProductOptions}
                                filteredSelectedValue={
                                    this.props.selectMacProductOptions.find((elementOption) =>
                                        elementOption.value === this.state.selectedMacProducts
                                    ) ?
                                        this.props.selectMacProductOptions.filter((elementOption) =>
                                            elementOption.value === this.state.selectedMacProducts
                                        )
                                        : []
                                }
                                onSelectOption={(value) => this.handleProductsChange(value, EVENT_TYPE.Mac)}
                                customPlaceholder="Select"
                            />
                        </div>
                    </div>
                    <div className="row py-1">
                        <div className="col">
                            <label><strong>Notes:</strong></label>
                            <ul className="m-0 pl-4">
                                <li>Sophos product's installation will be based on the machine's OS.</li>
                                <li>Ensure that the Kaseya asset is online in order to deploy the Sophos products.</li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span data-tip="Please select atleast one product to enable the button" data-for='noProductSelected'>
                        <Button className="btn-sm" variant="primary" disabled={(this.props.selectedAction === "install" && this.state.selectedTenant === "") || (this.state.selectedWindowsDesktopProducts === "" && this.state.selectedMacProducts === "" && this.state.selectedWindowsServerProducts === "")}
                            onClick={this.checkMandatorySelection}>
                            Confirm
                        </Button>
                        {(this.state.selectedWindowsDesktopProducts === "" && this.state.selectedMacProducts === "" && this.state.selectedWindowsServerProducts === "") ?
                            <ReactTooltip place="top" effect="solid" type="dark" id="noProductSelected" /> : ""}
                    </span>
                    <Button className="btn-sm" variant="secondary" onClick={this.resetModalOptions}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ConfirmationInstallSophosModal