/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 04-03-2020 15:07
 * @modify date 04-03-2020 15:07
 * @description This component is modal for displaying error.
 */

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ConfirmationSelectTimeModal extends React.Component{

    constructor(props){
        super(props)
        this.state={
            selectedTime:'',
            selectedTimeValidation:'',
            selectedTimeOptionArray: props.selectTimeOptions,
        }
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(this.props.selectTimeOptions)!==JSON.stringify(prevProps.selectTimeOptions)){
            this.setState({
                selectedTimeOptionArray: this.props.selectTimeOptions
            })
        }
    }

    shouldComponentUpdate(nextProps,nextState){
        if(JSON.stringify(this.props.selectTimeOptions)!==JSON.stringify(nextProps.selectTimeOptions)){
            return true
        }
        if(JSON.stringify(this.props.show)!==JSON.stringify(nextProps.show)){
            return true
        }
        if(JSON.stringify(nextState)!==JSON.stringify(this.state)){
            return true
        }
        return false
    }

    handleSelectChange=(e)=>{
        console.log(e,"LOOK CHANGE")
        this.setState({
            selectedTime:e,
            selectedTimeValidation:''
        })
    }

    checkMandatorySelection=()=>{
        if(this.state.selectedTime==="")
            this.setState({
                selectedTimeValidation:'Please select time.'
            })
        else{
            this.props.confirmAction(this.state.selectedTime)
            this.setState({
                selectedTime:'',
                selectedTimeValidation:''
            })
        }
    }

    resetModalOptions=()=>{
        this.setState({
            selectedTime:'',
            selectedTimeValidation:''
        },()=>this.props.closeModal())
    }

    render(){
        let selectTimeOptionsArray=[{"value":"","label":"-Select-"}]
        this.state.selectedTimeOptionArray.forEach((element)=>{
            selectTimeOptionsArray.push({"value": element,"label": element})
        })
        return(
            <Modal
                // size="sm"
                show={this.props.show}
                onHide={this.resetModalOptions}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Confirmation</h5>  
                </Modal.Header>
                <Modal.Body>
                    <p dangerouslySetInnerHTML={ { __html:this.props.modalMessage } }></p>
                    <p>Select time till you want to disable tamper protection.(in Hours)</p>
                    <select className="form-control form-control-sm" 
                        onChange={(e)=>this.handleSelectChange(e.target.value)} 
                        value={this.state.selectedTime}
                    >
                        {selectTimeOptionsArray.map((element)=>{
                            return(<option key={element.value} value={element.value}>{element.label}</option>)
                        })}
                    </select>
                    <span className="text-danger">{this.state.selectedTimeValidation}</span>
                    <small id="defaultPageHelp" className="form-text text-muted"><strong>Note: </strong>Please note tamper protection on selected endpoint(s) will be automatically enabled after selected time.</small>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" variant="primary" onClick={this.checkMandatorySelection}>
                    Confirm
                    </Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.resetModalOptions}>
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ConfirmationSelectTimeModal