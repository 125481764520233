/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 11:36
 * @modify date 03-03-2020 11:36
 * @description This file contains the Unit function used in application
 */
export const VERSION_REQ_FOR_DEPLOYMENT_AUTOMATION = "2.1.0";
export function sortingData(a, b) {
  // Use toUpperCase() to ignore character casing
  const bandA = a.label.toUpperCase();
  const bandB = b.label.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

export function getDataFromJson(initObj, keys) {
  let ansObj = initObj;

  for (const key of keys) {
    ansObj = ansObj[key];
  }
  return ansObj;
}

export function compareVersions(v1, v2) {
  const version1 = v1.split('.').map(Number);
  const version2 = v2.split('.').map(Number);

  for (let i = 0; i < Math.max(version1.length, version2.length); i++) {
    const part1 = version1[i] || 0;
    const part2 = version2[i] || 0;

    if (part1 > part2) {
      return 1;
    }
    if (part1 < part2) {
      return -1;
    }
  }
  return 0;
}