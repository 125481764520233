/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 13:04
 * @modify date 06-02-2020 13:04
 * @description This file contains the component of NoTableData when any data is not present for table.
 */
import React from 'react';

export const NoTableData = (messageObject) => {
    return (
      <div
        style={messageObject.type==="error"?{ textAlign: "center",color:"#d5404e"}:{ textAlign: "center"}}
      >
        <h5 dangerouslySetInnerHTML={{__html:messageObject.message}}></h5>
      </div>
    );
};