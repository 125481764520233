
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


class GlobalSettingsResponseModal extends React.Component{

    constructor(props){
        super(props)
        this.state={
            
        }
    }
    render(){
        let messageString = this.props.message;
        return(
            <Modal
                // size="sm"
                show={this.props.show}
                onHide={this.props.closeModal}
                centered
                backdrop="static"
            >
                <Modal.Header className="hide-bottom-border-modal-header">
                    <h6 style={{marginTop:'0.5rem', marginBottom:'0', color:'green'}} dangerouslySetInnerHTML={ { __html:
                        messageString
                    } }></h6>
                </Modal.Header>

                <Modal.Footer className="hide-top-border-modal-footer">
                    <Button className="btn-sm" variant="secondary" onClick={this.props.closeModal}>
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default GlobalSettingsResponseModal
