/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:52
 * @modify date 06-02-2020 12:52
 * @description Below redux reducer is created for endpoints.
 */
import * as initialState from './initialState';
import * as actionTypes from '../services/types';

export default (state, action) => {
    if(state === undefined){
        state = initialState.endpoints
    }
    switch (action.type) {
        case actionTypes.SET_COMPUTERS_LIST:
            return {
                ...state,
                endpoint_list: action.data.response.items,
                total: action.data.response.pages.total,
                current_page: action.data.response.pages.current,
                page_size: action.data.response.pages.size,
                max_page_size: action.data.response.pages.maxSize,
                req_per_hour: action.data.req_per_hour,
                last_updated_times: action.data.response.last_updated_times 
            };
        case actionTypes.SET_RESPONSE_TIME:
            return {
                ...state,
                response_time:action.data.response,
                force_limit_reached: action.data.force_limit_reached, 
                last_force_update_time: action.data.last_force_update_time, 
                scheduled: action.data.scheduled, 
                queued_job_exist: action.data.queued_job_exist,
                req_per_hour: action.data.req_per_hour 
            };
        case actionTypes.SET_ENDPOINT_LIST_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:action.data
            };
        case actionTypes.CLEAR_ENDPOINT_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:null
            };
        case actionTypes.GET_COMPUTERS_DATA:
            return {...state};
        case actionTypes.CLEAR_COMPUTERS_DATA:
            return {
                ...state,
                endpoint_list:[],
                total: null,
                current_page: null,
                page_size: null,
                max_page_size:null,
                error_response_message:null,
                req_per_hour: null
            }
        case actionTypes.CLEAR_RESPONSE_TIME:
            return {
                ...state,
                force_limit_reached:null, 
                last_force_update_time:null, 
                scheduled:null, 
                queued_job_exist:null, 
                response_time:null 
            };
        case actionTypes.START_LOADER_ENDPOINTS:
            return {
                ...state,
                is_loading:true
            };
        case actionTypes.STOP_LOADER_ENDPOINTS:
            return {
                ...state,
                is_loading:false
            };
        default:
    }
  
    return state;
  };