/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:56
 * @modify date 06-02-2020 12:56
 * @description This file contains the component of Common Layout for the Application
 */

import React from 'react';
import Card from "react-bootstrap/Card";
// import Title from 'reactjs-title';

export function CommonLayout(props) {
    return (
        <React.Fragment>
            {/* <Title render={previousTitle => {return previousTitle+" - "+props.title}}/> */}
            <Card>
                {props.displayTitle?<Card.Header>{props.title}</Card.Header>:''}
                {props.children}
            </Card>
        </React.Fragment>
    );
  }