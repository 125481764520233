/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:51
 * @modify date 06-02-2020 12:51
 * @description Below redux reducer is created for customers.
 */
import * as initialState from './initialState';
import * as actionTypes from '../services/types';

export default (state , action) => {
    if(state === undefined){
        state = initialState.audit
    }
    switch (action.type) {

        case actionTypes.SET_AUDIT_LOG_LIST:
            return {
                ...state,
                audit_log_list: action.data.items,
                total: action.data.pages.total,
                current_page: action.data.pages.current,
                page_size: action.data.pages.size,
                max_page_size: action.data.pages.maxSize,
            };
        case actionTypes.SET_AUDIT_LOG_LIST_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:action.data
            };
        case actionTypes.CLEAR_AUDIT_LOG_ERROR_RESPONSE:
            return {
                ...state,
                error_response_message:null
            };
        case actionTypes.CLEAR_AUDIT_LOG_DATA:
            return {
                ...state,
                audit_log_list:[],
                total: null,
                current_page: null,
                page_size: null,
                max_page_size:null,
            }
        case actionTypes.GET_AUDIT_LOG_DATA:
            return {...state};
        case actionTypes.START_LOADER_AUDIT_LOGS:
            return {
                ...state,
                is_loading:true
            };
        case actionTypes.STOP_LOADER_AUDIT_LOGS:
            return {
                ...state,
                is_loading:false
            };
        default:
    }

    return state;
  };