export const MAC_PRODUCTS_OPTIONS = [
    {
        "label": "Intercept X Essentials",
        "value": "antivirus,intercept"
    },
    {
        "label": "Intercept X Advanced",
        "value": "intercept,antivirus"
    },
    {
        "label": "Intercept X Advanced with XDR",
        "value": "intercept,antivirus,xdr"
    },
    {
        "label": "Sophos MDR",
        "value": "antivirus,intercept,xdr,mdr"
    },
    {
        "label": "Intercept X Only",
        "value": "intercept"
    },
    {
        "label": "XDR only",
        "value": "xdr"
    },
    {
        "label": "None",
        "value": ""
    }
]

export const WINDOWS_DESKTOP_PRODUCTS_OPTIONS = [
    {
        "label": "Intercept X Essentials",
        "value": "antivirus,intercept"
    },
    {
        "label": "Intercept X Advanced",
        "value": "intercept,antivirus"
    },
    {
        "label": "Intercept X Advanced with XDR",
        "value": "intercept,antivirus,xdr"
    },
    {
        "label": "Sophos MDR",
        "value": "antivirus,intercept,xdr,mdr"
    },
    {
        "label": "Intercept X Only",
        "value": "intercept"
    },
    {
        "label": "XDR only",
        "value": "xdr"
    },
    {
        "label": "None",
        "value": ""
    }
]

export const WINDOWS_SERVER_PRODUCTS_OPTIONS = [
    {
        "label": "Intercept X Essentials",
        "value": "antivirus,intercept"
    },
    {
        "label": "Intercept X Advanced",
        "value": "intercept,antivirus"
    },
    {
        "label": "Intercept X Advanced with XDR",
        "value": "intercept,antivirus,xdr"
    },
    {
        "label": "Sophos MDR",
        "value": "antivirus,intercept,xdr,mdr"
    },
    {
        "label": "XDR only",
        "value": "xdr"
    },
    {
        "label": "None",
        "value": ""
    }
]

export const ALLOWED_AND_BLOCKED_OPTIONS = [
    {
        "label": "Allowed",
        "value": "allowed"
    },
    {
        "label": "Blocked",
        "value": "blocked"
    }
]

export const ALLOWED_TYPE_OPTIONS = [
    {
        "label": "Path",
        "value": "path"
    },
    {
        "label": "SHA256",
        "value": "sha256"
    },
    {
        "label": "Certificate Signer",
        "value": "certificateSigner"
    }
]

export const BLOCKED_TYPE_OPTION = [
    {
        "label": "SHA256",
        "value": "sha256"
    }
]

export const SCANNING_TYPE_OPTIONS = [
    {
        "label": "AMSI",
        "value": "amsi"
    },
    {
        "label": "Web",
        "value": "web"
    },
    {
        "label": "Path",
        "value": "path"
    },
    {
        "label": "Posix Path",
        "value": "posixPath"
    },
    {
        "label": "Process",
        "value": "process"
    },
    {
        "label": "Pua",
        "value": "pua"
    },
    {
        "label": "Virtual Path",
        "value": "virtualPath"
    }
]

export const SCAN_MODE_ON_ACCESS_OPTION = [
    {
        "label": "On Access",
        "value": "onAccess"
    }
]

export const SCAN_MODE_ON_DEMAND_ON_ACCESS_OPTION = [
    {
        "label": "On Demand & On Access",
        "value": "onDemandAndOnAccess"
    },
    {
        "label": "On Demand",
        "value": "onDemand"
    },
    {
        "label": "On Access",
        "value": "onAccess"
    }
]

export const WEBSITE_CATEGORY_OPTIONS = [
    {
        "value": 0,
        "label": "Uncategorized"
    },
    {
        "value": 1,
        "label": "Adult/Sexually Explicit"
    },
    {
        "value": 2,
        "label": "Advertisements & Pop-Ups"
    },
    {
        "value": 3,
        "label": "Alcohol & Tobacco"
    },
    {
        "value": 4,
        "label": "Arts"
    },
    {
        "value": 5,
        "label": "Blogs & Forums"
    },
    {
        "value": 6,
        "label": "Business"
    },
    {
        "value": 7,
        "label": "Chat"
    },
    {
        "value": 8,
        "label": "Computing & Internet"
    },
    {
        "value": 9,
        "label": "Criminal Activity"
    },
    {
        "value": 10,
        "label": "Downloads"
    },
    {
        "value": 11,
        "label": "Education"
    },
    {
        "value": 12,
        "label": "Entertainment"
    },
    {
        "value": 13,
        "label": "Fashion & Beauty"
    },
    {
        "value": 14,
        "label": "Finance & Investment"
    },
    {
        "value": 15,
        "label": "Food & Dining"
    },
    {
        "value": 16,
        "label": "Gambling"
    },
    {
        "value": 17,
        "label": "Games"
    },
    {
        "value": 18,
        "label": "Government"
    },
    {
        "value": 19,
        "label": "Hacking"
    },
    {
        "value": 20,
        "label": "Health & Medicine"
    },
    {
        "value": 21,
        "label": "Hobbies & Recreation"
    },
    {
        "value": 22,
        "label": "Hosting Sites"
    },
    {
        "value": 23,
        "label": "Illegal Drugs"
    },
    {
        "value": 24,
        "label": "Infrastructure"
    },
    {
        "value": 25,
        "label": "Intimate Apparel & Swimwear"
    },
    {
        "value": 26,
        "label": "Intolerance & Hate"
    },
    {
        "value": 27,
        "label": "Job Search & Career Development"
    },
    {
        "value": 28,
        "label": "Kid's Sites"
    },
    {
        "value": 29,
        "label": "Motor Vehicles"
    },
    {
        "value": 30,
        "label": "News"
    },
    {
        "value": 31,
        "label": "Peer-to-Peer"
    },
    {
        "value": 32,
        "label": "Personals and Dating"
    },
    {
        "value": 33,
        "label": "Philanthropic & Professional Orgs."
    },
    {
        "value": 34,
        "label": "Phishing & Fraud"
    },
    {
        "value": 35,
        "label": "Photo Searches"
    },
    {
        "value": 36,
        "label": "Politics"
    },
    {
        "value": 37,
        "label": "Proxies & Translators"
    },
    {
        "value": 38,
        "label": "Real Estate"
    },
    {
        "value": 39,
        "label": "Reference"
    },
    {
        "value": 40,
        "label": "Religion"
    },
    {
        "value": 41,
        "label": "Ringtones/Mobile Phone Downloads"
    },
    {
        "value": 42,
        "label": "Search Engines"
    },
    {
        "value": 43,
        "label": "Sex Education"
    },
    {
        "value": 44,
        "label": "Shopping"
    },
    {
        "value": 45,
        "label": "Society & Culture"
    },
    {
        "value": 46,
        "label": "Spam URLs"
    },
    {
        "value": 47,
        "label": "Sports"
    },
    {
        "value": 48,
        "label": "Spyware"
    },
    {
        "value": 49,
        "label": "Streaming Media"
    },
    {
        "value": 50,
        "label": "Tasteless & Offensive"
    },
    {
        "value": 51,
        "label": "Travel"
    },
    {
        "value": 52,
        "label": "Violence"
    },
    {
        "value": 53,
        "label": "Weapons"
    },
    {
        "value": 54,
        "label": "Web-based E-mail"
    },
    {
        "value": 55,
        "label": "Custom"
    },
    {
        "value": 56,
        "label": "Anonymizing Proxies"
    },
    {
        "value": 57,
        "label": "Translation Services"
    }
]

export const DIRECTION_OPTION = [
    { value: "inbound", label: "Inbound" },
    { value: "outbound", label: "Outbound" },
    { value: "both", label: "Both" }
]