/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 28-02-2020 21:55
 * @modify date 28-02-2020 21:55
 * @description This file contains the component DonutChart
 */
import React from 'react';
import c3 from 'c3/c3.js';
import 'c3/c3.min.css'

class DonutChart extends React.Component {
    componentDidMount() {
      this._updateChart();
    }
    componentDidUpdate() {
      this._updateChart();
    }
    _updateChart() {
      const chart = c3.generate({
        bindto: '#'+this.props.chartId,
        color: {
          pattern: this.props.colorPattern
        },
        data: {
            columns: this.props.columns,
            type: this.props.chartType,
            onclick: (d)=> this.props.onClickEvent(d.name),
        },
        donut: {
          title: this.props.totalTitle,
          label: {
            format: function (value) {
              return value;
            }
          }
      }
      });
      chart.ygrids.remove();
    }
    render() {
      return <div id={this.props.chartId}></div>;    
    }
  }

  export default DonutChart