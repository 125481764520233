import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import _ from "lodash";
import {ALLOWED_AND_BLOCKED_OPTIONS, ALLOWED_TYPE_OPTIONS, BLOCKED_TYPE_OPTION} from '../common/Constants';
import {getTenantsList} from '../../services/apiOperations';

let pathInput;
let certificateSignerInput;
let commentInput;
let sha256Input;
const sha256RegexExp = new RegExp('^[a-fA-F0-9]{64}$');
let inputItemTypeOptions = BLOCKED_TYPE_OPTION
let displayFileAndCertificate = "none"

class AddAllowedBlockedItemModal extends React.Component{

    constructor(props){
        super(props)
        this.state={
            tenantLoading:false,
            selectedTenant:'',
            selectedTenantValidation:'',
            selectedTenantOptionsArray: [],
            selectedItemType: '',
            selectedItemTypeValidation:'',
            inputItemType:'',
            selectedInputTypeValidation:'',
            filename:'',
            filenameValidation:'',
            path:'',
            pathValidation:'',
            pathStar:'',
            sha256:'',
            sha256Validation:'',
            sha256Star:'',
            comment:'',
            commentValidation:'',
            certificateSigner:'',
            certificateSignerValidation:'',
            certificateSignerStar:'',
            validated: false,
        }
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(this.props.show)!==JSON.stringify(prevProps.show)){
            if(this.props.show===true){
                this.setState({
                    selectedTenant:'',
                    selectedTenantOptionsArray:[],
                    tenantLoading:true,
                    selectedTenantValidation:'',
                    selectedItemType:ALLOWED_AND_BLOCKED_OPTIONS[0].value,
                    selectedItemTypeValidation:'',
                    inputItemType:inputItemTypeOptions[0].value,
                    selectedInputTypeValidation:'',
                    filename:'',
                    filenameValidation:'',
                    path:'',
                    pathValidation:'',
                    pathStar:'*',
                    sha256:'',
                    sha256Validation:'',
                    sha256Star:'',
                    comment:'',
                    commentValidation:'',
                    certificateSigner:'',
                    certificateSignerValidation:'',
                    certificateSignerStar:'',
                    validated: false,
                },()=>this.getTenantList())
            }
        }
    }

    shouldComponentUpdate(nextProps,nextState){
        if(JSON.stringify(this.props.show)!==JSON.stringify(nextProps.show)){
            return true
        }
        if(JSON.stringify(nextState)!==JSON.stringify(this.state)){
            return true
        }
        return false
    }

    getTenantList=()=>{
        if(this.props.settings.SophosIdTypeIsTenant) {
            let tenantListArray=[{"value":this.props.settings.sophosClientId,"label":this.props.settings.sophosClientId}]
            this.setState({
                tenantLoading:false,
                selectedTenantOptionsArray:tenantListArray,
                selectedTenant: this.props.settings.sophosClientId
            })
        } else {
            getTenantsList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"), this.props.location.search, this.props.SophosTenantId)
            .then((res)=>{
                let tenantListArray=[]
                res.data.response.items.forEach((elementTenant)=>{
                    tenantListArray.push({"value":elementTenant.id,"label":elementTenant.name})
                })
                this.setState({
                    tenantLoading:false,
                    selectedTenantOptionsArray:tenantListArray
                })
            })
            .catch((err)=>{
                let errMessage=""
                if(err.response){
                    if(err.response.data.message!==undefined)
                        errMessage=err.response.data.message
                    else
                        errMessage="Something went wrong while fetching tenant list."
                }
                else{
                    errMessage="Something went wrong while fetching tenant list."
                }

                this.setState({
                    tenantLoading:false,
                    selectedTenantValidation: errMessage
                })
            })
        }
    }

    resetModalOptions=()=>{
        this.setState({
            selectedTenant:'',
            selectedTenantValidation:'',
            selectedTenantOptionsArray: [],
            path: '',
            fileName: '',
            sha256: '',
            certificateSigner: '',
            comment: '',
        }, ()=>this.props.closeModal())
    }

    handleItemTypeChange=(e)=>{
        if(e === "allowed")
        {
            inputItemTypeOptions = ALLOWED_TYPE_OPTIONS
            displayFileAndCertificate = "block"
        }
        else
        {
            inputItemTypeOptions = BLOCKED_TYPE_OPTION
            displayFileAndCertificate = "none"
        }
        this.setState({
            selectedItemType:e,
            selectedItemTypeValidation:'',
        }, this.validations)
    }

    handlePathChange=()=>{
        this.setState({
            path:pathInput ? pathInput.value : ''
        }, this.validations)
    }

    handleSha256Change=()=>{
        this.setState({
            sha256:sha256Input ? sha256Input.value : ""
        }, this.validations)
    }

    handleCertificateSignerChange=()=>{
        this.setState({
            certificateSigner:certificateSignerInput ? certificateSignerInput.value : ''
        }, this.validations)
    }

    handleCommentChange=()=>{
        this.setState({
            comment:commentInput ? commentInput.value : ''
        }, this.validations)
    }

    handleTenantChange=(e)=>{
        this.setState({
            selectedTenant:e,
            selectedTenantValidation:''
        }, this.validations)
    }

    handleTypeChange=(e)=>{
        if (e === "path")
        {
            this.setState(
                {
                    pathStar:'*',
                    sha256Star:'',
                    certificateSignerStar:'',
                }
            )
        }
        else if (e === "sha256")
        {
            this.setState(
                {
                    pathStar:'',
                    sha256Star:'*',
                    certificateSignerStar:'',
                }
            )
        }
        else if (e === "certificateSigner")
        {
            this.setState(
                {
                    pathStar:'',
                    sha256Star:'',
                    certificateSignerStar:'*',
                }
            )
        }
        else{
            this.setState(
                {
                    pathStar:'',
                    sha256Star:'',
                    certificateSignerStar:'',
                }
            )
        }
        this.setState({
            inputItemType:e,
        }, this.validations)
    }

    validations=()=>{
        let validated = true
        validated &= (this.state.selectedItemType === "allowed" || this.state.selectedItemType === "blocked")
        let inputValidations = false
        inputValidations |= (this.state.inputItemType === 'path' && pathInput && pathInput.value.trim().length !== 0)
        inputValidations |= (this.state.inputItemType === 'certificateSigner' && certificateSignerInput && certificateSignerInput.value.trim().length !== 0)
        inputValidations |= (this.state.inputItemType === 'sha256' && sha256Input && sha256Input.value.length !== 0)
        this.setState({
            sha256Validation: '',
        })

        validated &= inputValidations
        validated &= commentInput && commentInput.value.trim().length !== 0
        validated &= this.state.selectedTenant.length !== 0
        this.setState({
            validated : validated
        },()=>{})
    }

    checkMandatorySelection=()=>{
        this.validations()
        if(this.state.selectedTenant===""){
            this.setState({
                selectedTenantValidation:'Please select tenant.'
            })
            return
        }
        else
        {
            this.setState({
                selectedTenantValidation:''
            })
        }
        if(this.state.inputItemType === 'sha256' || (sha256Input && sha256Input.value.length !== 0))
        {
            let text = sha256Input ? sha256Input.value : ""
            var validSha256 = sha256RegexExp.test(text)
            let sValidationText = ''
            if(!validSha256)
            {
                sValidationText = 'Enter valid SHA256 hash'
            }
            this.setState({
                sha256Validation: sValidationText,
                validated: validSha256,
            })
            if(!validSha256)
                return
        }
        else
        {
            this.setState({
                sha256Validation:''
            })
        }
        let data = {
            "allowed": this.state.selectedItemType === "allowed",
            "type": this.state.inputItemType.trim(),
            "sha256": this.state.sha256.trim(),
            "comment": this.state.comment.trim()
        }
        if(!this.props.settings.SophosIdTypeIsTenant) {
            data["sophosTenantId"] = this.state.selectedTenant
        }
        if(data.allowed)
        {
            data["certificateSigner"] = this.state.certificateSigner.trim()
            data["fileName"] = this.state.filename.trim()
            data["path"] = this.state.path.trim()
        }
        this.props.confirmModal(data)
    }

    render(){
        return(
            <Modal
                size="lg"
                show={this.props.show}
                onHide={this.resetModalOptions}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Add Allowed/Blocked Item</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row" style={{margin : '0 0 5px 0'}}>
                        <div className="col">
                            <span>Item Type</span><span className="text-danger">*</span>
                            <MultiSelectTextbox
                                options={ALLOWED_AND_BLOCKED_OPTIONS}
                                customPlaceholder="Select Item Type"
                                filteredSelectedValue={[ALLOWED_AND_BLOCKED_OPTIONS[0]]}
                                isMulti={false}
                                onSelectOption={(value)=>this.handleItemTypeChange(value)}
                            />
                            <span className="text-danger">{this.state.selectedItemTypeValidation}</span>
                        </div>
                    </div>
                    {!this.props.settings.SophosIdTypeIsTenant &&
                    <div className="row" style={{margin : '0 0 5px 0'}}>
                        <div className="col">
                            <span>Tenant</span><span className="text-danger">*</span>
                            <MultiSelectTextbox
                                options={_.orderBy(this.state.selectedTenantOptionsArray,[tenant => tenant.label.toLowerCase()])}
                                customPlaceholder="Select Tenant"
                                filteredSelectedValue={[]}
                                isMulti={false}
                                onSelectOption={(value)=>this.handleTenantChange(value)}
                                isLoading={this.state.tenantLoading}
                            />
                            <span className="text-danger">{this.state.selectedTenantValidation}</span>
                        </div>
                    </div>
                    }
                    <div className="row" style={{margin : '0 0 5px 0'}}>
                        <div className="col">
                            <span>Type</span><span className="text-danger">*</span>
                            <MultiSelectTextbox
                                options={inputItemTypeOptions}
                                customPlaceholder="Select Type"
                                filteredSelectedValue={[inputItemTypeOptions[0]]}
                                isMulti={false}
                                onSelectOption={(value)=>this.handleTypeChange(value)}
                            />
                        </div>
                    </div>
                    <div className="row" style={{margin : '0 0 5px 0', display : this.state.pathStar === "*" ? "block": "none" || displayFileAndCertificate}}>
                        <div className="col">
                            <span>Path</span><span className="text-danger">{this.state.pathStar}</span>
                            <input
                                className="form-control form-control-sm"
                                ref={ n => pathInput = n }
                                type="text"
                                onChange={this.handlePathChange}
                                placeholder="Enter Path"
                            />
                            <span className="text-danger">{this.state.pathValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{margin : '0 0 5px 0', display : this.state.sha256Star === "*" ? "block" : "none"}}>
                        <div className="col">
                            <span>SHA256</span><span className="text-danger">{this.state.sha256Star}</span>
                            <input
                                className="form-control form-control-sm"
                                ref={ n => sha256Input = n }
                                type="text"
                                onChange={this.handleSha256Change}
                                placeholder="Enter SHA256"
                            />
                            <span className="text-danger">{this.state.sha256Validation}</span>
                        </div>
                    </div>
                    <div className="row" style={{margin : '0 0 5px 0',  display : this.state.certificateSignerStar === "*" ? "block" : "none" || displayFileAndCertificate}}>
                        <div className="col">
                            <span>Certificate Signer</span><span className="text-danger">{this.state.certificateSignerStar}</span>
                            <input
                                className="form-control form-control-sm"
                                ref={ n => certificateSignerInput = n }
                                type="text"
                                onChange={this.handleCertificateSignerChange}
                                placeholder="Enter Certificate Signer"
                            />
                            <span className="text-danger">{this.state.certificateSignerValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{margin : '0 0 5px 0'}}>
                        <div className="col">
                            <span>Comment</span><span className="text-danger">*</span>
                            <input
                                className="form-control form-control-sm"
                                ref={ n => commentInput = n }
                                type="text"
                                onChange={this.handleCommentChange}
                                placeholder="Enter Comment"
                            />
                            <span className="text-danger">{this.state.commentValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{margin : '0 0 5px 0'}}>
                        <div className="col">
                            <span className="text-danger">* fields are required.</span>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" variant="primary"
                            onClick={this.checkMandatorySelection}
                            disabled={!this.state.validated}
                            >
                            Add
                    </Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.resetModalOptions}>
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AddAllowedBlockedItemModal
