/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 21-05-2020 18:51
 * @modify date 21-05-2020 18:51
 * @description This component is modal for displaying action loader.
 */

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'

export const ActionLoadingModal=(props)=>(
        <Modal
            // size="sm"
            show={props.show}
            onHide={props.closeModal}
            centered
            backdrop="static"
        >
            <Modal.Body className="text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                <h6 className="text-info" style={{marginTop:'0.5rem',marginBottom:'0'}}>
                    Performing action please wait...
                </h6>
            </Modal.Body>
        </Modal>
    )