/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 28-02-2020 21:55
 * @modify date 28-02-2020 21:55
 * @description This file contains the component Endpoints and its layout.
 */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
// import Dropdown from 'react-bootstrap/Dropdown'
import { ToastContainer, toast } from 'react-toastify';
// import Breadcrumb from "react-bootstrap/Breadcrumb";
import moment from 'moment';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from "lodash";
import {getTenantList,getEndpointList,clearNextPossibleResponseMessage,clearEndpointErrorResponseMessage,clearTenantErrorResponseMessage,clearEndpointsTable,sendRequestEndpointAction} from '../../services/apiOperations';
import {CommonLayout} from '../common/CommonLayout';
import {sortingData} from '../common/Util'
import {setInitStorage} from '../common/InitLocalStore';
import ConfirmationAuditLogModal from '../common/ConfirmationAuditLogModal';
import {ErrorModal} from '../common/ErrorModal';
import {ActionLoadingModal} from '../common/ActionLoadingModal';
import ConfirmationSelectTimeModal from '../common/ConfirmationSelectTimeModal';
import ConfirmationModal from '../common/ConfirmationModal';
import TableGrid from '../common/TableGrid';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import {getDataFromJson} from '../common/Util';
import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/tableGrid.scss';
import 'react-toastify/dist/ReactToastify.css';

if(process.env.REACT_APP_HOST_ENV==="production"){
    console.log=function(){}
}

const CONSTANT_TYPE={
    health_overall:"health_overall",
    health_services_status:"health_services_status",
    tamperProtectionEnabled:"tamperProtectionEnabled",
    offlineStatus:"offlineStatus",
    os_platform:"os_platform",
    type:"type",
    selectedTenant:"tenant_id"
}

const ENDPOINT_ACTIONS=[
    {value:"",label:"--Select action--",askPermission:false},
    {value:"scan",label:"Force scan",askPermission:false},
    {value:"update-checks",label:"Force definitions update",askPermission:false},
    {value:"tamper-protection-enable",label:"Tamper protection enable",askPermission:false},
    {value:"tamper-protection-disable",label:"Tamper protection disable",askPermission:true},
    {value:"isolate",label:"Isolate Endpoint(s)",askPermission:true},
    {value:"de-isolate",label:"DeIsolate Endpoint(s)",askPermission:true},
    {value:"delete",label:"Delete endpoint(s)",askPermission:true}
]

const BOOLEAN_CONSTANT_ARRAY = [true, false]

const NULL_UNDEFINED_EMPTY_CHECK = [null, undefined, ""]

const healthStatusOptions=[
    { value:"bad",label:"bad"},
    { value:"good",label:"good"},
    { value:"suspicious",label:"suspicious"},
    { value:"-",label:"not defined"}
];

const serviceStatusOptions=[
    { value:"bad",label:"bad"},
    { value:"good",label:"good"},
    { value:"suspicious",label:"suspicious"},
    { value:"-",label:"not defined"}
];

const computerTypeOptions=[
    { value:"computer",label:"computer"},
    { value:"securityVm",label:"securityVm"},
    { value:"server",label:"server"},
];

const tamperProtectionOptions=[
    { value:false,label:"disabled"},
    { value:true,label:"enabled"},
    { value:"-",label:"not defined"}
];

const offlineOptions=[
    { value:"+7",label:"Offline 7+ days"},
    { value:"+15",label:"Offline 15+ days"},
    { value:"+30",label:"Offline 30+ days"}
];

const osPlatformOptions=[
    {value:"linux", label:"linux"},
    {value:"macOS",label:"macOS"},
    {value:"windows",label:"windows"},
]

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getEndpointList: (referrer,queryFilterString,force) => dispatch(getEndpointList(referrer,queryFilterString,force)),
    getTenantList: (referrer,queryFilterString,force) => dispatch(getTenantList(referrer,queryFilterString,force)),
    clearNextPossibleResponseMessage:()=>dispatch(clearNextPossibleResponseMessage()),
    clearEndpointErrorResponseMessage:()=>dispatch(clearEndpointErrorResponseMessage()),
    clearTenantErrorResponseMessage:()=>dispatch(clearTenantErrorResponseMessage()),
    clearEndpointsTable:()=>dispatch(clearEndpointsTable())
})

class Endpoints extends React.Component {
    constructor(props) {
        super(props);
        setInitStorage(props.referrerHostname,queryString.parse(props.location.search))
        this.state={
            queryParams:queryString.parse(props.location.search),
            kaseyaParams:queryString.parse(props.location.search),
            showAdvancedFilter:false,
            tenantList:[],
            rowData:[],
            filtered: [],
            clearFilterBool:false,
            clearSearchBox:false,
            selectedAction:null,
            showActionModal:false,
            showErrorActionAlert:false,
            actionEndpointMessage:'',
            showConfirmationModal:false,
            showConfirmationSelectTimeModal:false,
            ConfirmationSelectTimeModalMessage:'',
            columnDefs: [
                { text: "Endpoint ID", dataField: "id", sort:true, onSort:this.onSortChange, fixed:true},
                { text: "Endpoint Name", dataField: "hostname", sort:true, 
                onSort:this.onSortChange,width: 150,
                formatter: (cell, row) => {
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                            if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.hostname))
                                return row.hostname
                            else
                                return "-"
                        else
                            return "-"
                    }
                },
                { text: "Tenant Name", dataField: "tenant.name",sort:true,
                onSort:this.onSortChange,
                formatter: (cell, row) => {
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.tenant)){
                            if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.tenant.name)){
                                return row.tenant.name
                            }
                            else    
                                return "-"
                        }
                        else
                            return "-"
                    else
                        return "-"
                }},
                { text: "Type", dataField: "type",sort:true,
                onSort:this.onSortChange,
                formatter: (cell, row) => {
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.type))
                            return row.type
                        else
                            return "-"
                    else
                        return "-"
                }},
                { text: "OS Platform", dataField: "os.platform",sort:true,
                onSort:this.onSortChange,
                formatter: (cell, row) => {
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.os)){
                            if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.os.platform))
                                return row.os.platform
                            else
                                return "-"
                        }
                        else    
                            return "-"
                    else
                        return "-"
                        
                }},
                { text: "Health Status", dataField:"health.overall",sort:true, align:"center",
                onSort:this.onSortChange,
                formatter: (cell, row) => {
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.health)){
                            if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.health.overall))  
                                switch(row.health.overall){
                                    case 'bad':
                                        return(
                                            <React.Fragment>
                                                <Badge variant="danger health" data-tip="Bad" data-for={"health_"+row.id}><i className="fa fa-times"/></Badge>
                                                <ReactTooltip id={"health_"+row.id} effect="solid" type="dark"/>
                                            </React.Fragment>
                                            );
                                    case 'good':
                                        return(
                                            <React.Fragment>
                                                <Badge variant="success health" data-tip="Good" data-for={"health_"+row.id}><i className="fa fa-check"/></Badge>
                                                <ReactTooltip id={"health_"+row.id} effect="solid" type="dark"/>
                                            </React.Fragment>
                                            );
                                    case 'suspicious':
                                        return(
                                            <React.Fragment>
                                                <i data-tip="Suspicious" data-for={"health_"+row.id} className="fa fa-exclamation-triangle fa-lg health-warning"/>
                                                <ReactTooltip id={"health_"+row.id} effect="solid" type="dark"/>
                                            </React.Fragment>
                                            );
                                    default:
                                }
                            else
                                return "-"
                        }
                        else
                            return "-"
                    else
                        return "-"
                }, 
                    width: 100},
                { text: "Service Status", dataField: "health.services.status", align:"center",sort:true,
                onSort:this.onSortChange,
                formatter: (cell,row) => {  
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.health)){
                            if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row.health.services))
                                switch(row.health.services.status){
                                    case 'bad':
                                        return(
                                                <React.Fragment>
                                                    <Badge variant="danger services" data-tip="Bad" data-for={"service_"+row.id}><i className="fa fa-times"/></Badge>
                                                    <ReactTooltip id={"service_"+row.id} effect="solid" type="dark"/>
                                                </React.Fragment>
                                            );
                                    case 'good':
                                        return(
                                                <React.Fragment>
                                                    <Badge variant="success services" data-tip="Good" data-for={"service_"+row.id}><i className="fa fa-check"/></Badge>
                                                    <ReactTooltip id={"service_"+row.id} effect="solid" type="dark"/>
                                                </React.Fragment>
                                            );
                                    case 'suspicious':
                                        return(
                                                <React.Fragment>
                                                    <i data-tip="Suspicious" data-for={"service_"+row.id} className="fa fa-exclamation-triangle fa-lg services-warning"/>
                                                    <ReactTooltip id={"service_"+row.id} effect="solid" type="dark"/>
                                                </React.Fragment>
                                            );
                                    default:
                                }
                            else 
                                return "-"
                        }
                        else
                            return "-"
                    else
                        return "-"
                },
                    width:150
                },
                { text: "Tamper Protection", dataField: 'tamperProtectionEnabled', align:"center",sort:true,
                onSort:this.onSortChange,
                formatter: (cell,row) => { 
                    if(!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if(BOOLEAN_CONSTANT_ARRAY.includes(row.tamperProtectionEnabled))
                            return row.tamperProtectionEnabled ? 
                                <Badge variant="success">enabled</Badge>
                                : 
                                <Badge pill variant="danger">disabled</Badge>
                        else
                            return "-" 
                    else    
                        return "-" 
                }, 
                sortValue: (cell, row) =>NULL_UNDEFINED_EMPTY_CHECK.includes(row.tamperProtectionEnabled)?"":row.tamperProtectionEnabled.toString(),
                width:150
                },
            ],
            defaultSorted : [{
                dataField: 'hostname',
                order: 'asc'
            }],
            singleSelectedEndpointId:'',
            currentPage:1,
            successCount : 0,
            failCount : 0,
            pageWiseSelectedRows:[],
            loadingAction:false,
            nextPossibleForce:null,
            endpointErrorResponse:this.props.endpoints.error_response_message, 
            sortChangeBool:false,
            clearSelection:false
        }
    }

    UNSAFE_componentWillMount(){
        let queryFitlerString=queryString.parse(this.props.location.search)
        if(queryFitlerString!==""){
            let filters=this.state.filtered
            if(this.props.location.state){
                let newPropsSelectedTenantId={ value: this.props.location.state.id, type: CONSTANT_TYPE.selectedTenant }
                filters.push(newPropsSelectedTenantId)
            }
            if(this.props.location.filtersObject){
                if(this.props.location.filtersObject.type===CONSTANT_TYPE.health_overall){
                    let newPropsSelectedHealthOverall={ value: this.props.location.filtersObject.value, type: CONSTANT_TYPE.health_overall}
                    filters.push(newPropsSelectedHealthOverall)

                    let newPropsSelectedType={ value: this.props.location.filtersObject.computerType.toString(), type: CONSTANT_TYPE.type}
                    filters.push(newPropsSelectedType)
                }
                else if(this.props.location.filtersObject.type===CONSTANT_TYPE.tamperProtectionEnabled){
                    let newPropsSelectedTamper={ value: this.props.location.filtersObject.value, type: CONSTANT_TYPE.tamperProtectionEnabled}
                    filters.push(newPropsSelectedTamper)

                    let newPropsSelectedType={ value: this.props.location.filtersObject.computerType.toString(), type: CONSTANT_TYPE.type}
                    filters.push(newPropsSelectedType)
                }
                else if(this.props.location.filtersObject.type===CONSTANT_TYPE.os_platform){
                    let newPropsSelectedOSPlatform={ value: this.props.location.filtersObject.value, type: CONSTANT_TYPE.os_platform}
                    filters.push(newPropsSelectedOSPlatform)

                    let newPropsSelectedType={ value: this.props.location.filtersObject.computerType.toString(), type: CONSTANT_TYPE.type}
                    filters.push(newPropsSelectedType)
                }
                else if(this.props.location.filtersObject.type===CONSTANT_TYPE.offlineStatus){
                    let newPropsSelectedOfflineStatus={ value: this.props.location.filtersObject.value, type: CONSTANT_TYPE.offlineStatus}
                    filters.push(newPropsSelectedOfflineStatus)

                    let newPropsSelectedType={ value: this.props.location.filtersObject.computerType.toString(), type: CONSTANT_TYPE.type}
                    filters.push(newPropsSelectedType)
                }
            }
            if(this.props.location.filtersObject){
                if(this.props.location.filtersObject.type===CONSTANT_TYPE.offlineStatus){
                    this.setState({
                        filtered:filters,
                        showAdvancedFilter:true
                    })
                }
                else{
                    this.setState({
                        filtered:filters
                    })
                }
            }
            else{
                this.setState({
                    filtered:filters
                })
            }
            
            this.props.getTenantList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),this.props.location.search);
            // this.props.getEndpointList(this.props.referrerHostname+"_"+localStorage.getItem("kaseya_tenant_id"),sophosTenantId,this.props.location.search);
        }
        else{
            this.props.getTenantList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"));
            // this.props.getEndpointList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),sophosTenantId);
        }
        if(this.props.settings.SophosIdTypeIsTenant && this.props.history.action === "PUSH"){
            this.onSophosIdTypeIsTenant();
        }
    }

    componentDidMount() {
        if(this.props.location.state) {
            this.selectFilters(this.props.location.state.id, CONSTANT_TYPE.selectedTenant);
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.endpoints.force_limit_reached || this.props.endpoints.queued_job_exist || this.props.endpoints.scheduled){
            this.showToast();
            this.props.endpoints.queued_job_exist = null
            this.props.endpoints.force_limit_reached = null
            this.props.endpoints.scheduled = null
            this.clearErrorResponseToast()
        }
        if(JSON.stringify(prevState.rowData)!==JSON.stringify(this.props.endpoints.endpoint_list)){
            this.setState({
                rowData:this.props.endpoints.endpoint_list
            })
            this.clearErrorResponseToast()
        }
        if(JSON.stringify(this.props.endpoints.response_time)!==JSON.stringify(prevProps.endpoints.response_time) && this.props.endpoints.response_time!==null){
            this.setState({
                nextPossibleForce:this.props.endpoints.response_time,
                endpointErrorResponse:null
            })
            this.clearErrorResponseToast()
        }
        if(JSON.stringify(this.props.endpoints.error_response_message)!==JSON.stringify(prevProps.endpoints.error_response_message)){
            this.setState({
                endpointErrorResponse:this.props.endpoints.error_response_message,
                nextPossibleForce:null
            })   
            this.clearNextpossibleToast()       
        }
        if(JSON.stringify(this.props.tenants.tenant_list)!==JSON.stringify(prevProps.tenants.tenant_list)){
            let createTenantListPair=[]
            this.props.tenants.tenant_list.forEach((element)=>{
                createTenantListPair.push({ value: element.id, label:element.showAs})
            })
            this.setState({
                tenantList:createTenantListPair
            })
            if(this.props.location.state===undefined){
                this.getEndpointsData()
            }
        }
        if (
            this.props.settings.SophosIdTypeIsTenant &&
            JSON.stringify(this.props.settings.SophosIdTypeIsTenant) !==
            JSON.stringify(prevProps.settings.SophosIdTypeIsTenant)
        ) {
            this.onSophosIdTypeIsTenant();
        }
    }

    clearNextpossibleToast=()=>{
        this.props.clearNextPossibleResponseMessage()
    }

    showToast=()=>{
        if(this.props.endpoints.force_limit_reached){
            let last_sync_time=new Date(this.props.endpoints.last_force_update_time*1000)
            let req_per_hour_diff=Math.round(60/this.props.endpoints.req_per_hour)
            let next_response_time=new Date((this.props.endpoints.last_force_update_time*1000)+(1000*60*req_per_hour_diff))
            toast.error(`Last sync ran at ${last_sync_time.toLocaleTimeString()}. Next available sync at ${next_response_time.toLocaleTimeString()}`,{containerId: 'A', onClose: () => this.clearNextpossibleToast()});
        }
        else if(this.props.endpoints.queued_job_exist)
        {
            toast.error("Job already exist in queue",{containerId: 'A', onClose: () => this.clearNextpossibleToast()});
        }
        else if(this.props.endpoints.scheduled)
        {
            toast.success("Force sync job scheduled successfuly",{containerId: 'A', onClose: () => this.clearNextpossibleToast()});
        }
    }

    componentWillUnmount(){
        this.clearNextpossibleToast()
        this.clearErrorResponseToast()
        this.clearTenantErrorResponseToast()
    }

    onSophosIdTypeIsTenant = () => {
        this.getEndpointsData();
        const columDefs = [...this.state.columnDefs];
        const withoutTenantColumnDefs = _.remove(
            columDefs,
            (cols) => cols.dataField !== "tenant.name"
        );
        this.setState({ columnDefs: withoutTenantColumnDefs });
    }

    onSortChange=()=>{
        this.setState({
            sortChangeBool:!this.state.sortChangeBool
        })
    }
    
    filterData = (data)=>{
        
        let result = []
        for(let i = 0; i<data.length; i++){
          let flag = true
          for(let j=0;j<this.state.filtered.length;j++){
           if(this.state.filtered[j].type!==CONSTANT_TYPE.selectedTenant && this.state.filtered[j].type!==CONSTANT_TYPE.offlineStatus){
                let apiValue;
                try{
                apiValue = getDataFromJson(data[i], this.state.filtered[j].type.toString().split("_")).toString()
                }
                catch(err){
                apiValue = "-"
                }
                
                if(this.state.filtered[j].value !== "all" && this.state.filtered[j].value.toString().split(",").indexOf(apiValue) === -1 ){
                flag = false
                break
                }
            }

            /** Offline status filter login as per +7,+15+30 days offline */
            if(this.state.filtered[j].type===CONSTANT_TYPE.offlineStatus){
                let daysPrev30days=moment().subtract(30,'days')
                let daysPrev7days=moment().subtract(7,'days')
                let daysPrev15days=moment().subtract(15,'days')
                

                if(this.state.filtered[j].value.toString().split(",").includes("+7") && this.state.filtered[j].value.toString().split(",").length>=1){
                    if(moment(data[i].lastSeenAt).diff(daysPrev7days,"days")>=1){
                        flag=false
                        break
                    }
                }
                else if(this.state.filtered[j].value.toString().split(",").includes("+15") && this.state.filtered[j].value.toString().split(",").length>=1 && this.state.filtered[j].value.toString().split(",").length<3 ){
                    if(moment(data[i].lastSeenAt).diff(daysPrev15days,"days")>=1){
                        flag=false
                        break
                    }
                }
                else if(this.state.filtered[j].value.toString().split(",").includes("+30") && this.state.filtered[j].value.toString().split(",").length===1){
                    if(moment(data[i].lastSeenAt).diff(daysPrev30days,"days")>=1){
                        flag=false
                        break
                    }
                }
            }

          }
          if(flag){
            result.push(data[i])
          }
          
        }
        return result
    }

    goToAuditLog=()=>{
        this.props.history.push({
            pathname:"/auditlogs",
            search:this.props.location.search,
            filtersObject:{value:"ENDPOINT"}
        })

    }

    getTenantName=(tenantId)=>{
        return this.props.tenants.tenant_list.find((element)=>element.id===tenantId).name
    }

    findFilterValueByType(arrayValue,searchType){
        return arrayValue.type===searchType
    }

    getPageWiseSelectedRowsFromTable=(currentPage,selectedRowData)=>{
        this.setState({
            currentPage: currentPage,
            pageWiseSelectedRows: selectedRowData
        })
    }

    setTablePage=(page)=>{
        this.setState({
            currentPage: page,
        })
    }

    selectFilters=(value,type)=>{
        let filters=this.state.filtered
        let newfilterObj={value:value,type:type}
        const obj =filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,newfilterObj.type))
        if(obj!==undefined){
            const index=filters.indexOf(obj)
            filters.splice(index,1)
        }
        if(value!==''){
            filters.push(newfilterObj)
        }

        /**Specific for clearing search textbox of Table */
        let singleTenantSelectedBool=false
        if(this.state.clearSearchBox){
            singleTenantSelectedBool=true
        }
        if(type===CONSTANT_TYPE.selectedTenant && value!=='')
            singleTenantSelectedBool=true
        else if(!this.state.clearSearchBox)
            singleTenantSelectedBool=false
        else if(type===CONSTANT_TYPE.selectedTenant && value==='')
            singleTenantSelectedBool=false
        
        this.setState({
            filtered:filters,
            clearSearchBox: singleTenantSelectedBool
        })
        if(type===CONSTANT_TYPE.selectedTenant){this.getEndpointsData()}
        // this.getEndpointsData()
    }

    clearFilter(){
        this.setState({filtered:[],clearFilterBool:!this.state.clearFilterBool,showAdvancedFilter:false, selectedAction:null}, () => {
            this.props.getEndpointList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),this.props.location.search);
        })
    }

    getEndpointsData(forceBoolean=false){
        if(this.props.location.search!==""){
            let isApiHostNotNull=false
            if(!forceBoolean)
                this.props.clearEndpointsTable()
            const queryParams=queryString.parse(this.props.location.search)
            let filters=this.state.filtered
            if(filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))!==undefined)
            {
                let filteredObject=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))
                // queryParams.sophosTenantId=filteredObject.value
                if(forceBoolean){
                    if(filteredObject){
                        queryParams.sophosTenantId=filteredObject.value
                    }
                }
                if(this.props.tenants.tenant_list.length>0){
                    let checkTenantDetails=this.props.tenants.tenant_list.find((tenantValue)=>tenantValue.id===filteredObject.value)
                    if(checkTenantDetails){
                        if(checkTenantDetails.apiHost!==null && checkTenantDetails.apiHost!=="" && checkTenantDetails.apiHost!=="NULL" && checkTenantDetails.apiHost!=="null" && checkTenantDetails.apiHost!==undefined){
                            queryParams.sophosTenantId=filteredObject.value
                            isApiHostNotNull=true
                        }
                    }
                }
            }
            else{
                isApiHostNotNull=true
            }
            const queryFilter=queryString.stringify(queryParams)
            if(isApiHostNotNull)
                this.props.getEndpointList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),"?"+queryFilter,forceBoolean)
        }
        else{
            this.props.getEndpointList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),forceBoolean)
        }
        
    }

    getEndpointSelectedId=(selectedId)=>{
        this.setState({
            singleSelectedEndpointId:selectedId
        })
    }

    findEndpoint(endpoints,selectedEndpoint) {
        return endpoints.id === selectedEndpoint;
    }

    backToTenant=()=>{
        this.props.history.push({
            pathname:"/tenants",
            search:this.props.location.search
        })
    }
    clearNextpossibleToast=()=>{
        this.props.clearNextPossibleResponseMessage()
    }

    clearErrorResponseToast=()=>{
        this.props.clearEndpointErrorResponseMessage()
    }

    clearTenantErrorResponseToast=()=>{
        this.props.clearTenantErrorResponseMessage()
    }

    tablegridMessage(){
        if(!this.props.endpoints.is_loading){
            let filteredObject=this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))
            if(filteredObject!==undefined){
                let checkTenantDetails=this.props.tenants.tenant_list.find((tenantValue)=>tenantValue.id===filteredObject.value)
                if(checkTenantDetails){
                    if(checkTenantDetails.apiHost===null || checkTenantDetails.apiHost==="" || checkTenantDetails.apiHost==="NULL" || checkTenantDetails.apiHost==="null" || checkTenantDetails.apiHost===undefined){
                        return {message:'This account has not been activated; <br/>Please activate from <a target="_blank" href="https://cloud.sophos.com/">Sophos Central Administration Console</a>',type:"error"}
                    }
                    else{
                        return {message:"No endpoints found to match your search",type:"info"}   
                    }
                }
                else{
                    return {message:"No endpoints found to match your search",type:"info"}   
                }
            }
            else{
                return {message:"No endpoints found to match your search",type:"info"}
            }
        }
        else{
            return {message:"Loading....",type:"info"}
        }
    }

    handleActionChange=(target)=>{
        console.log(target.value,"CHECK TARGET")
        if(target.value===""){
            this.setState({
                selectedAction:null
            })    
        }
        else{
            this.setState({
                selectedAction:ENDPOINT_ACTIONS.find((elementAction)=>elementAction.value===target.value)
            })
        }
    }

    sendEndpointAction=()=>{
        if(this.state.pageWiseSelectedRows.length===0 || this.state.pageWiseSelectedRows[0].selectedRow.length===0){
            this.setState({
                showErrorActionAlert: true,
                loadingAction:false,
                actionEndpointMessage:"No endpoints selected; Please select atleast one endpoint."
            })
        }
        else{
            let selectedEndpointsBody=[]
            this.state.pageWiseSelectedRows.forEach((eachPage)=>{
                eachPage.selectedRow.forEach((eachRow)=>{
                    selectedEndpointsBody.push({"endpointId":eachRow.id,"tenantId":eachRow.tenant.id})
                })
            })
            let requestBody={
                "endpointIds":selectedEndpointsBody,
                "action":this.state.selectedAction.value
            }
            if(this.state.selectedAction.askPermission){
                if(this.state.selectedAction.value==="tamper-protection-enable" || this.state.selectedAction.value==="tamper-protection-disable"){
                    this.setState({
                        showConfirmationSelectTimeModal:true,
                        actionEndpointMessage:`Number of selected endpoint(s): <strong>${this.state.pageWiseSelectedRows[0].selectedRow.length}</strong>`
                    })
                }
                if(this.state.selectedAction.value==="delete"){
                    this.setState({
                        showConfirmationModal:true,
                        actionEndpointMessage:`Are you sure you want to delete <strong>${this.state.pageWiseSelectedRows[0].selectedRow.length}</strong> selected endpoint(s)?<small class="form-text text-muted pt-3"><strong>Note: </strong>Delete action may take some time to reflect in our systems.</small>`
                    })
                }
                if(this.state.selectedAction.value==="isolate" || this.state.selectedAction.value==="de-isolate"){
                    let actionName = this.state.selectedAction.value==="isolate" ? "Isolation" : "DeIsolation"
                    this.setState({
                        showConfirmationModal:true,
                        actionEndpointMessage:`Number of selected endpoint(s): <strong>${this.state.pageWiseSelectedRows[0].selectedRow.length}</strong> for ${actionName} action`,
                    });
                }
            }
            else{
                this.setState({
                    loadingAction:true
                },()=>{
                    sendRequestEndpointAction(
                        this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),
                        requestBody,
                        this.props.location.search
                    )
                    .then(res=>{
                        this.setState({
                            loadingAction:false,
                            showActionModal:true,
                            successCount:res.data.response.success.count,
                            failCount:res.data.response.failed.count,
                            clearSelection: !this.state.clearSelection
                        })
                    })
                    .catch(err=>{
                        let messageStringForEndpoints=""
                        if(err.response){
                            if(err.response.data.message!==undefined)
                                messageStringForEndpoints=err.response.data.message
                            else
                                messageStringForEndpoints="Something went wrong; Please try again later."
                        }
                        else
                            messageStringForEndpoints="Something went wrong; Please try again later."
                        this.setState({
                            loadingAction:false,
                            showErrorActionAlert:true,
                            actionEndpointMessage:messageStringForEndpoints,
                            clearSelection: !this.state.clearSelection
                        })
                        console.log(err.response,"ERROR")
                    })
                })
            }
        }
    }

    confirmTamperProtectionDisabledAction=(selectedTime)=>{
        let selectedEndpointsBody=[]
        this.state.pageWiseSelectedRows.forEach((eachPage)=>{
            eachPage.selectedRow.forEach((eachRow)=>{
                selectedEndpointsBody.push({"endpointId":eachRow.id,"tenantId":eachRow.tenant.id})
            })
        })
        let requestBody={
            "endpointIds":selectedEndpointsBody,
            "action":"tamper-protection-disable",
            "selectedTime":selectedTime
        }
        this.setState({
            loadingAction:true,
            showConfirmationSelectTimeModal:false
        },()=>{
            sendRequestEndpointAction(
                this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),
                requestBody,
                this.props.location.search
            )
            .then(res=>{
                console.log(res.data,"CHECK RESPONSE DATA")
                this.setState({
                    loadingAction:false,
                    showActionModal:true,                    
                    successCount:res.data.response.success.count,
                    failCount:res.data.response.failed.count,
                    clearSelection: !this.state.clearSelection
                })
            })
            .catch(err=>{
                let messageStringForEndpoints=""
                if(err.response){
                    if(err.response.data.message!==undefined)
                        messageStringForEndpoints=err.response.data.message
                    else
                        messageStringForEndpoints="Something went wrong; Please try again later."
                }
                else
                    messageStringForEndpoints="Something went wrong; Please try again later."
                this.setState({
                    loadingAction:false,
                    showErrorActionAlert:true,
                    actionEndpointMessage:messageStringForEndpoints,
                    clearSelection: !this.state.clearSelection
                })
                console.log(err.response,"ERROR")
            })
        })
    }

    confirmationModalAction=()=>{
        if(this.state.selectedAction.value==="delete" || this.state.selectedAction.value==="isolate" || this.state.selectedAction.value==="de-isolate")
        {
            this.confirmEndpointAction(this.state.selectedAction.value);
        }
    }

    confirmEndpointAction=(action)=>{
        let selectedEndpointsBody=[]
        this.state.pageWiseSelectedRows.forEach((eachPage)=>{
            eachPage.selectedRow.forEach((eachRow)=>{
                selectedEndpointsBody.push({"endpointId":eachRow.id,"tenantId":eachRow.tenant.id})
            })
        })
        let requestBody={
            "endpointIds":selectedEndpointsBody,
            "action":action,
        }
        this.setState({
            loadingAction:true,
            showConfirmationModal:false
        },()=>{
            sendRequestEndpointAction(
                this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),
                requestBody,
                this.props.location.search
            )
            .then(res=>{
                this.setState({
                    loadingAction:false,
                    showActionModal:true,
                    successCount:res.data.response.success.count,
                    failCount:res.data.response.failed.count,
                    clearSelection: !this.state.clearSelection
                })
            })
            .catch(err=>{
                let messageStringForEndpoints=""
                if(err.response){
                    if(err.response.data.message!==undefined)
                        messageStringForEndpoints=err.response.data.message
                    else
                        messageStringForEndpoints="Something went wrong; Please try again later."
                }
                else
                    messageStringForEndpoints="Something went wrong; Please try again later."
                this.setState({
                    loadingAction:false,
                    showErrorActionAlert:true,
                    actionEndpointMessage:messageStringForEndpoints,
                    clearSelection: !this.state.clearSelection
                })
                console.log(err.response,"ERROR")
            })
        })
    }

    closeActionModal=()=>{
        if(this.state.pageWiseSelectedRows.length===0 || this.state.pageWiseSelectedRows[0].selectedRow.length===0){
            this.setState({
                showErrorActionAlert: false,
                showActionModal: false,
                actionEndpointMessage:""
            })
        }
        else{
            this.setState({
                showActionModal: false,
                showErrorActionAlert: false,
                actionEndpointMessage:"",
                selectedAction:null,
                clearSearchBox: !this.state.clearSearchBox
            },()=>{
                this.getEndpointsData()
            })
        }
    }

    closeConfirmationSelectTimeModal=()=>{
        this.setState({
            showConfirmationSelectTimeModal:false
        })
    }

    closeConfirmationModal=()=>{
        this.setState({
            showConfirmationModal:false
        })
    }

    getSelectedType=()=>{
        if(this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.type))){
            let selectedTypeArray=this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.type)).value.split(",")
            let selecteFinalValues=[]
            selectedTypeArray.forEach((selectElement)=>{
                selecteFinalValues.push(computerTypeOptions.find((element)=>element.value===selectElement))
            })
            return selecteFinalValues
            
        }
        else{
            return []
        }
    }

    changeEventAdvancedFilter=()=>{
        this.setState({
            showAdvancedFilter:!this.state.showAdvancedFilter
        })
    }


    render(){
        let selectedEndpoint=this.props.endpoints.endpoint_list.find((value)=>this.findEndpoint(value,this.state.singleSelectedEndpointId))
        // let next_response_time=this.state.nextPossibleForce!==null?new Date(this.state.nextPossibleForce.next_possible_force*1000):new Date(1577974553*1000)
        // let req_per_hour_diff=this.props.endpoints.req_per_hour!==null?Math.round(60/this.props.endpoints.req_per_hour):15;
        // let last_sync_time=this.state.nextPossibleForce!==null?new Date((this.state.nextPossibleForce.next_possible_force*1000)-(1000*60*req_per_hour_diff)):new Date(1577974553*1000)
        let tenantCheck=this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))
        let checkTenantDetails;
        if(tenantCheck)
            checkTenantDetails=this.props.tenants.tenant_list.find((tenantValue)=>tenantValue.id===tenantCheck.value)

        const tenantsWithDataRegionNull = [];
        let clearTenantSelect = false;
        this.props.tenants.tenant_list.forEach((tenant) => {
            if (
                NULL_UNDEFINED_EMPTY_CHECK.includes(tenant.dataRegion) ||
                NULL_UNDEFINED_EMPTY_CHECK.includes(tenant.apiHost)
              ) {
                tenantsWithDataRegionNull.push(tenant.id);
              }
        });
        let selectedTenantId = this.state.filtered.find(
            (arrayValue) => arrayValue.type === CONSTANT_TYPE.selectedTenant
        );
        if (
            selectedTenantId &&
            tenantsWithDataRegionNull.length &&
            tenantsWithDataRegionNull.filter(
                (item) => item === selectedTenantId.value
            ).length
        ) {
            clearTenantSelect = true;
        }

        let messageForForceSync = this.props.settings?.SophosIdTypeIsTenant ? this.props.endpoints && this.props.endpoints.last_updated_times && this.props.endpoints.last_updated_times[this.props.settings?.SophosTenantId] ? 'Last Force Sync Time: ' + moment.unix(this.props.endpoints.last_updated_times[this.props.settings?.SophosTenantId]).format('YYYY-MM-DD HH:mm:ss') : '' : selectedTenantId && selectedTenantId.value && this.props.endpoints.last_updated_times && this.props.endpoints.last_updated_times[selectedTenantId.value] ? 'Last Force Sync Time: ' + moment.unix(this.props.endpoints.last_updated_times[selectedTenantId.value]).format('YYYY-MM-DD HH:mm:ss') : ''

        return(
            <CommonLayout title="Endpoints" displayTitle={true}>
                <ErrorModal 
                    show={this.props.tenants.error_response_message!==null || this.props.endpoints.error_response_message!==null} 
                    closeModal={()=>{
                        this.props.tenants.error_response_message!==null?
                            this.clearTenantErrorResponseToast()
                        :
                            this.clearErrorResponseToast()
                        }}
                    modalMessage={
                    this.props.tenants.error_response_message!==null && this.props.endpoints.error_response_message===null ?
                        this.props.tenants.error_response_message
                        :
                        this.props.endpoints.error_response_message}
                />
                <ConfirmationAuditLogModal
                    show={this.state.showActionModal}
                    selectedAction={this.state.selectedAction?this.state.selectedAction.label:""}
                    closeModal={this.closeActionModal}
                    viewLog={this.goToAuditLog}
                    success={this.state.successCount}
                    fail={this.state.failCount}
                />
                <ErrorModal 
                    show={this.state.showErrorActionAlert} 
                    closeModal={this.closeActionModal}
                    modalMessage={this.state.actionEndpointMessage}
                />
                <ActionLoadingModal
                    show={this.state.loadingAction} 
                />
                <ConfirmationModal
                    show={this.state.showConfirmationModal}
                    confirmAction={()=>this.confirmationModalAction()} 
                    closeModal={this.closeConfirmationModal}
                    modalMessage={this.state.actionEndpointMessage}
                />
                <ConfirmationSelectTimeModal 
                    show={this.state.showConfirmationSelectTimeModal}
                    confirmAction={(timeSelected)=>this.confirmTamperProtectionDisabledAction(timeSelected)} 
                    closeModal={this.closeConfirmationSelectTimeModal}
                    modalMessage={this.state.actionEndpointMessage}
                    selectTimeOptions={this.props.settings.tamper_protection_hrs?this.props.settings.tamper_protection_hrs:[]}
                />
                {/* <div className="mt-2">
                    <Breadcrumb>
                        <Breadcrumb.Item href="#" onClick={this.backToTenant}>Customers</Breadcrumb.Item>
                        <Breadcrumb.Item active>{this.props.location.state.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </div> */}
                <ToastContainer
                    position="top-right"
                    autoClose={7000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable={false}
                    pauseOnHover={false}
                    enableMultiContainer 
                    containerId={'A'}
                />

                <div className="row mt-2 mx-1 justify-content-between">
                    <div className="col-9">
                        <div className="input-group-append row">
                            {!this.props.settings.SophosIdTypeIsTenant && <div className="col px-0 mx-1 text-left">
                                <span>Tenants</span>
                                <MultiSelectTextbox 
                                    disabledSelect={this.props.endpoints.is_loading}
                                    options={this.state.tenantList.sort(sortingData)} 
                                    isMulti={false} 
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))?
                                        this.state.tenantList.filter((element)=>element.value=== this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant)).value)
                                        :[]
                                    } 
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.selectedTenant)} 
                                    clearValue={this.state.clearFilterBool} 
                                    clearable={true}
                                    isLoading={this.props.tenants.is_loading}         
                                />
                            </div>}
                            <div className="col px-0 mx-1 text-left">
                                <span>Type</span>
                                <MultiSelectTextbox 
                                    options={computerTypeOptions} 
                                    isMulti={true} 
                                    filteredSelectedValue={
                                        this.getSelectedType()
                                    } 
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.type)} 
                                    clearValue={this.state.clearFilterBool}
                                />
                            </div>
                            <div className="col px-0 mx-1 text-left">
                                <span>OS Platform</span>
                                <MultiSelectTextbox 
                                    options={osPlatformOptions} 
                                    isMulti={true} 
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.os_platform))?
                                        osPlatformOptions.filter((element)=>element.value=== this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.os_platform)).value)
                                        :[]
                                    } 
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.os_platform)} 
                                    clearValue={this.state.clearFilterBool}
                                />
                            </div>
                            <div className="col px-0 mx-1 text-left">
                                <span>Health Status</span>
                                <MultiSelectTextbox 
                                    options={healthStatusOptions} 
                                    isMulti={true} 
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.health_overall))?
                                        healthStatusOptions.filter((element)=>element.value=== this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.health_overall)).value)
                                        :[]
                                    } 
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.health_overall)} 
                                    clearValue={this.state.clearFilterBool}
                                />
                            </div>
                            <div className="col px-0 mx-1 text-left">
                                <span>Service Status</span>
                                <MultiSelectTextbox 
                                    options={serviceStatusOptions} 
                                    isMulti={true} 
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.health_services_status))?
                                        serviceStatusOptions.filter((element)=>element.value=== this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.health_services_status)).value)
                                        :[]
                                    } 
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.health_services_status)} 
                                    clearValue={this.state.clearFilterBool}
                                />
                            </div>
                            <div className="col px-0 mx-1 text-left">
                                <span>Tamper Protection</span>
                                <MultiSelectTextbox 
                                    options={tamperProtectionOptions} 
                                    isMulti={true} 
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.tamperProtectionEnabled))?
                                        tamperProtectionOptions.filter((element)=>element.value=== this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.tamperProtectionEnabled)).value)
                                        :[]}
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.tamperProtectionEnabled)} 
                                    clearValue={this.state.clearFilterBool}
                                />
                            </div>
                        </div>
                        <div className="input-group-append row">
                            {this.state.showAdvancedFilter?
                            <div className="col px-0 mx-1 my-2 text-left">
                                <span>Offline Status</span>
                                <MultiSelectTextbox 
                                    options={offlineOptions} 
                                    isMulti={false} 
                                    filteredSelectedValue={
                                        this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.offlineStatus))?
                                            offlineOptions.filter((element)=>element.value=== this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.offlineStatus)).value)
                                        :[]}
                                    onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.offlineStatus)} 
                                    clearable={true}
                                    clearValue={this.state.clearFilterBool}
                                />
                            </div>
                            :
                            ""
                            }
                            {!this.state.showAdvancedFilter?
                            <div className="col px-0 mx-1 mb-2 text-right">
                                    <a onClick={this.changeEventAdvancedFilter} className="link-style" href>Show advanced filter</a>
                            </div>
                            :
                            <div className="col-9 px-0 mx-1 mb-2 text-right">
                                    <a onClick={this.changeEventAdvancedFilter} className="link-style" href>Hide advanced filter</a>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="col-1 pl-1 text-left mt-3">
                        <button type="button" className="btn btn-secondary btn-sm ml-2" disabled={this.props.endpoints.is_loading || this.state.filtered.length===0} onClick={this.clearFilter.bind(this)}>Clear</button>
                    </div>
                    <div className="col-2 text-right mt-3">
                    <button type="button" className="btn btn-outline-primary btn-sm" disabled={((tenantCheck===undefined || checkTenantDetails===undefined) && !this.props.settings.SophosIdTypeIsTenant) || clearTenantSelect} onClick={this.getEndpointsData.bind(this,true)}><i className="fa fa-refresh"/> Force Sync</button>
                        <button type="button" className="btn btn-primary round-help-btn ml-2 btn-sm" data-tip={this.props.endpoints.req_per_hour!==null?`Maximum of ${this.props.endpoints.req_per_hour}x per hour allowed. ${!this.props.settings.SophosIdTypeIsTenant ? "Select a tenant to enable Force Sync." : ""}`: `Maximum of 4x per hour allowed. ${!this.props.settings.SophosIdTypeIsTenant ? "Select a tenant to enable Force Sync." : ""}`} data-for='helpTime'><i className="fa fa-question"/></button>
                        <ReactTooltip id="helpTime" effect="solid" type="info" place="left"/>
                    </div>
                </div>
                <div className="row mt-2 mx-1">
                    <div className="col-12 text-right">
                        <b>{messageForForceSync}</b>
                    </div>
                </div>
                <div className="row m-1 card-content-height">
                    <div className="col-md-8 pr-1">
                        <TableGrid 
                            clearValue={this.state.clearFilterBool}
                            defaultSortedCol={this.state.defaultSorted}
                            columnStructure={this.state.columnDefs} 
                            responseData={this.filterData(this.state.rowData)} 
                            loading={this.props.endpoints.is_loading} 
                            stateObject={this.props.endpoints} 
                            pageSize={this.filterData(this.state.rowData).length<this.props.endpoints.page_size?this.filterData(this.state.rowData).length:this.props.endpoints.page_size}
                            filtered={this.state.filtered}
                            noDataText={this.tablegridMessage()} 
                            keyField="id"
                            moduleName="endpoints"
                            // rowClickEvent={(row)=>this.getEndpointSelectedId(row.id)}
                            clearSearchBox={this.state.clearSearchBox}
                            singleSelectedRow={(selectedId)=>this.getEndpointSelectedId(selectedId)}
                            pageWiseSelectedRowsFromTable={(currentPage, selectedRowData)=>this.getPageWiseSelectedRowsFromTable(currentPage,selectedRowData)}
                            onPageChangeEvent={(page)=>this.setTablePage(page)}
                            onSortChange={this.state.sortChangeBool}
                            onPerformAction={this.state.clearSelection}
                        />
                    </div>
                    <div className="col-md-4 pl-1">
                        <div className="row mr-2">
                            <div className="col pr-1">
                                <span>Action <a href className="text-muted" data-tip data-for="actionDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                                <ReactTooltip id="actionDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                                    <ol className="m-0">
                                        <li>Select an action.</li>
                                        <li>Select an endpoint(s) for which action needs to be taken.</li>
                                        <li>Click on submit.</li>
                                    </ol>
                                </ReactTooltip>
                                <div className="row">
                                    <div className="col pr-1">
                                        <select className="form-control form-control-sm" 
                                            disabled={this.state.loadingAction}
                                            onChange={(e)=>this.handleActionChange(e.target)} 
                                            value={this.state.selectedAction?this.state.selectedAction.value:""}>
                                            {ENDPOINT_ACTIONS.map((element)=>{
                                                return(<option key={element.value} permissioncheck={element.askPermission.toString()} labelvalue={element.label} value={element.value}>{element.label}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className="col pl-1">
                                        <button type="button" className="btn btn-primary btn-sm" disabled={this.state.selectedAction===null || this.state.loadingAction} onClick={this.sendEndpointAction}>
                                            Submit { this.state.loadingAction?
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            :''}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col">
                                <Card>
                                    <Card.Header className="p-1">{ !NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint)? !NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.hostname)? "Endpoint Name: "+selectedEndpoint.hostname : "Click on a row to view details" : "Click on a row to view details" }</Card.Header>
                                    <Scrollbars style={{ width: 'auto', height: 'calc(100vh - 266px)' }}>
                                    {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint)?
                                    <Card.Body>
                                        <h5 className="card-title">Endpoint Summary</h5>
                                        <div className="row">
                                            <div className="col-5">
                                                <strong>Endpoint Name:</strong>
                                            </div>
                                            <div className="col-6">
                                                {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.hostname)?selectedEndpoint.hostname:"-"}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <strong>Type:</strong>
                                            </div>
                                            <div className="col-6">
                                                {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.type)?selectedEndpoint.type:"-"}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <strong>Last Seen At:</strong>
                                            </div>
                                            <div className="col-6">
                                                
                                                {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.lastSeenAt)?moment(selectedEndpoint.lastSeenAt).format("DD-MMM-YYYY HH:mm"):"-"}
                                                
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <strong>Tamper Protection:</strong>
                                            </div>
                                            <div className="col-6">
                                                
                                                {BOOLEAN_CONSTANT_ARRAY.includes(selectedEndpoint.tamperProtectionEnabled)?selectedEndpoint.tamperProtectionEnabled.toString():"-"}
                                                
                                            </div>
                                        </div>
                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.tenant)?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Tenant ID:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.tenant.id)?selectedEndpoint.tenant.id:"-"}
                                                    </div>
                                                </div>
                                                {!this.props.settings.SophosIdTypeIsTenant && <div className="row">
                                                    <div className="col-5">
                                                        <strong>Tenant Name:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.tenant.name)?selectedEndpoint.tenant.name:"-"}
                                                    </div>
                                                </div>}
                                            </React.Fragment>
                                        :''}
                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.macAddresses)?
                                        <div className="row">
                                            <div className="col-5">
                                                <strong>MAC Addresses:</strong>
                                            </div>
                                            <div className="col-6">
                                                <ul style={{listStyleType:'none', padding:'0',marginBottom:'0'}} >
                                                    {selectedEndpoint.macAddresses.map((value, index)=>{
                                                        return(
                                                        <li key={index}>{value}</li>
                                                        )
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                        :''}
                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.ipv4Addresses)?
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-5">
                                                    <strong>IPv4 Addresses:</strong>
                                                </div>
                                                <div className="col-6">
                                                    <ul style={{listStyleType:'none', padding:'0',marginBottom:'0'}} >
                                                        {selectedEndpoint.ipv4Addresses.map((value, index)=>{
                                                            return(
                                                            <li key={index}>{value}</li>
                                                            )
                                                            }
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :''}

                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.ipv6Addresses)?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>IPv6 Addresses:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        <ul style={{listStyleType:'none', padding:'0',marginBottom:'0'}} >
                                                            {selectedEndpoint.ipv6Addresses.map((value, index)=>{
                                                                return(
                                                                <li key={index}>{value}</li>
                                                                )
                                                                }
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        :''}
                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.isolation) && !NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.isolation.status)?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Isolation Status :</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {selectedEndpoint.isolation?.status && selectedEndpoint.isolation?.status === "isolated"? 'Isolated' : 'Not Isolated'}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        :''}
                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.lockdown)?
                                            <React.Fragment>
                                                <h5 className="card-title card-title-drill-down">Lockdown</h5>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Status:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.lockdown.status)?selectedEndpoint.lockdown.status:"-"}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Update Status:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.lockdown.updateStatus)?selectedEndpoint.lockdown.updateStatus:"-"}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        :''}

                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.associatedPerson)?
                                            <React.Fragment>
                                                <h5 className="card-title card-title-drill-down">Associated Person</h5>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Name:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.associatedPerson.name)?selectedEndpoint.associatedPerson.name:"-"}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Via Login:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.associatedPerson.viaLogin)?selectedEndpoint.associatedPerson.viaLogin:"-"}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        :''}

                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.os) ?
                                            <React.Fragment>
                                                <h5 className="card-title card-title-drill-down">OS</h5>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Name:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.os.name)?selectedEndpoint.os.name:"-"}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Platform:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.os.platform)?selectedEndpoint.os.platform:"-"}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Build:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.os.build)?selectedEndpoint.os.build:"-"}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Is Server:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {BOOLEAN_CONSTANT_ARRAY.includes(selectedEndpoint.os.isServer)?selectedEndpoint.os.isServer.toString():"-"}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Major Version:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.os.majorVersion)?selectedEndpoint.os.majorVersion:"-"}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Minor Version:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.os.minorVersion) ?selectedEndpoint.os.minorVersion:"-"}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        :""}

                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.group)?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Group:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.group.name)?selectedEndpoint.group.name:"-"}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        :''}

                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.health)?
                                            <React.Fragment>
                                                <h5 className="card-title card-title-drill-down">Health</h5>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Overall Status:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.health.overall)?selectedEndpoint.health.overall:"-"}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Service Status:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.health.services)?!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.health.services.status)?selectedEndpoint.health.services.status:"-":"-"}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <strong>Threat Status:</strong>
                                                    </div>
                                                    <div className="col-6">
                                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.health.threats)?!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.health.threats.status)?selectedEndpoint.health.threats.status:"-":"-"}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        :''}

                                        {
                                        !NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.health)?!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.health.services)?!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.health.services.serviceDetails) && selectedEndpoint.health.services.serviceDetails.length ?
                                            <React.Fragment>
                                                <h6 className="card-title card-title-drill-down">Service Details</h6>
                                                <div className="row">
                                                            <div className="col-6"><strong>Name</strong></div>
                                                            <div className="col-4"><strong>Status</strong></div>
                                                            {selectedEndpoint.health.services.serviceDetails.map((value, index)=>{
                                                                return(
                                                                    <React.Fragment key={index}>
                                                                        <div className="col-6">{value.name}:</div>
                                                                        <div className="col-4">{value.status}</div>
                                                                    </React.Fragment>
                                                                )
                                                                }
                                                            )}
                                                </div>
                                            </React.Fragment>
                                        :'':'':''}

                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.assignedProducts)?
                                            <React.Fragment>
                                                <h5 className="card-title card-title-drill-down">Assigned Products</h5>
                                                <div className="row">
                                                            <div className="col-6"><strong>Code</strong></div>
                                                            <div className="col-3"><strong>Version</strong></div>
                                                            <div className="col-3"><strong>Status</strong></div>
                                                            {selectedEndpoint.assignedProducts.map((value, index)=>{
                                                                return(
                                                                    <React.Fragment key={index}>
                                                                        <div className="col-6">{value.code}</div>
                                                                        <div className="col-3">{value.version}</div>
                                                                        <div className="col-3">{value.status}</div>
                                                                    </React.Fragment>
                                                                )
                                                                }
                                                            )}
                                                </div>
                                            </React.Fragment>
                                        :''}

                                        {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.encryption)?
                                            <React.Fragment>
                                                <h5 className="card-title card-title-drill-down">Encryption State</h5>
                                                <div className="row">
                                                            <div className="col-6"><strong>Volume ID</strong></div>
                                                            <div className="col-4"><strong>Status</strong></div>

                                                            {!NULL_UNDEFINED_EMPTY_CHECK.includes(selectedEndpoint.encryption.volumes)?selectedEndpoint.encryption.volumes.map((value, index)=>{
                                                                return(
                                                                    <React.Fragment key={index}>
                                                                        <div className="col-6">{value.volumeId}</div>
                                                                        <div className="col-4">{value.status}</div>
                                                                    </React.Fragment>
                                                                )
                                                                }
                                                            ):''}
                                                </div>
                                            </React.Fragment>
                                        :''}
                                    </Card.Body>
                                    :''}
                                    </Scrollbars>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Endpoints)