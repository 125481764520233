import React from 'react';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import _ from "lodash";

let pathInput = "";
let selectedTenantOptionsArray = [];

class UpdateExploitMitigationItemModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: props.update_data && props.update_data.length ? props.update_data[0]["id"] : '',
            selectedTenant: props.update_data && props.update_data.length ? props.update_data[0]["tenant"]["id"] : '',
            selectedTenantOptionsArray: selectedTenantOptionsArray,
            paths: props.update_data && props.update_data.length ? props.update_data[0]["paths"] : '',
            pathValidation: '',
            validated: false,
        }
    }


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(prevProps.show)) {
            if (this.props.show) {
                if (this.props.update_data && this.props.update_data.length) {
                    selectedTenantOptionsArray = [{
                        value: this.props.update_data[0]["tenant"]["id"],
                        label: this.props.update_data[0]["tenant"]["name"]
                    }];

                }
                this.setState({
                    id: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["id"] : '',
                    selectedTenant: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["tenant"]["id"] : '',
                    selectedTenantOptionsArray: selectedTenantOptionsArray,
                    paths: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["paths"] : '',
                    pathValidation: '',
                }, this.validations)
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(nextProps.show)) {
            return true
        }
        if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
            return true
        }
        return false
    }

    resetModalOptions = () => {
        this.resetModal();
        this.props.closeModal();
    }

    resetModal = () => {
        this.setState({
            id: '',
            selectedTenant: '',
            selectedTenantOptionsArray: [],
            paths: ''
        });
    }

    handlePathChange = () => {
        this.setState({
            paths: pathInput ? pathInput.value : ''
        }, this.validations)
    }

    validations = () => {
        let validated = true
        validated &= pathInput && pathInput.value.trim().length !== 0
        validated &= this.state.selectedTenant.length !== 0
        this.setState({
            validated: validated
        }, () => { })
    }

    checkUpdateData = () => {
        this.validations()
        let data = {
            "paths": [this.state.paths.trim().toString()],
            "itemId": this.state.id
        }
        if(!this.props.settings.SophosIdTypeIsTenant) {
            data["sophosTenantId"] = this.state.selectedTenant
        }
        this.props.confirmModal(data)
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={this.resetModalOptions}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Update Exploit Mitigation</h5>
                </Modal.Header>
                <Modal.Body>
                    {!this.props.settings.SophosIdTypeIsTenant &&
                        <div className="row" style={{ margin: '0 0 5px 0' }}>
                            <div className="col">
                                <span>Tenant</span><span className="text-danger">*</span>
                                <MultiSelectTextbox
                                    options={_.orderBy(this.state.selectedTenantOptionsArray, [tenant => tenant.label.toLowerCase()])}
                                    customPlaceholder="Select Tenant"
                                    filteredSelectedValue={this.state.selectedTenantOptionsArray}
                                    isMulti={false}
                                    isLoading={this.state.tenantLoading}
                                    disabledSelect={true}
                                />
                            </div>
                        </div>
                    }
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Path<span className="text-danger">*</span> <a href className="text-muted" data-tip data-for="pathDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="pathDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                                Absolute path to an application file to exclude.
                            </ReactTooltip>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                paths={this.state.value}
                                ref={n => pathInput = n}
                                onChange={this.handlePathChange}
                                value={this.state.paths}
                                placeholder={this.state.value}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span className="text-danger">* fields are required.</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" variant="primary"
                        onClick={this.checkUpdateData}
                        disabled={!this.state.validated}
                    >
                        Update
                    </Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.resetModalOptions}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}



export default UpdateExploitMitigationItemModal

