/**
 * @author Vaibhav Chaudhary
 * @email vaibhav.chaudhary@sophos.com
 * @create date 22-05-2024
 * @description Below redux reducer is created for feature flags.
 */
import * as initialState from './initialState';
import * as actionTypes from '../services/types';

export default (state, action) => {
    if (state === undefined) {
        state = initialState.feature_flags;
    }
    switch (action.type) {
        case actionTypes.SET_FEATURE_FLAGS:
            return {
                ...state,
                flags: action.data,
                error: null
            };
        case actionTypes.SET_FEATURE_FLAGS_ERROR_RESPONSE:
            return {
                ...state,
                flags: null,
                error: action.data
            };
        default:
            return state;
    }
};