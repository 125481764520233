/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:26
 * @modify date 06-02-2020 12:26
 * @description This file configures the Redux store in React application
 */

import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import reducer from '../reducers';
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {}; // Initial state for the entire Redux store

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;