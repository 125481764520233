/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:55
 * @modify date 06-02-2020 12:55
 * @description This file contains the component of customized 404 - PAGE NOT FOUND
 */
import React from 'react';
import Card from "react-bootstrap/Card";
import Title from 'reactjs-title';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css'
const NotFound = () => (
    <React.Fragment>
        <Title render={previousTitle => `${previousTitle} - Not found`}/>
        <Card>
        {/* <Card.Header>404 - PAGE NOT FOUND</Card.Header> */}
        <div className="cover-container py-5">
            <center><FontAwesome className="fa" name="frown-o" size="5x" /></center>
            <center><h3>404 - PAGE NOT FOUND</h3></center>
            <center><h5>The page cannot be found</h5></center>
            <center><p>The page you are looking for might have been removed, had its name changed or is temporarily unavailable. Please click the below link for Home page.</p></center>
            <center><Link to="/overview">Return to Home Page</Link></center>
        </div>
    </Card>
    </React.Fragment>
);
export default NotFound;