/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:52
 * @modify date 28-02-2020 16:15
 * @description Below redux reducer is created for dashboard.
 */
import * as initialState from './initialState';
import * as actionTypes from '../services/types';

export default (state, action) => {
    if(state === undefined){
        state = initialState.dashboard
    }
    switch (action.type) {
        case actionTypes.SET_DASHBOARD_ALERT_DATA:
            return {
                ...state,
                dashboard_alert_data: action.data
            };
        case actionTypes.SET_DASHBOARD_ENDPOINT_DATA:
            return {
                ...state,
                dashboard_endpoint_data: action.data
            };
        case actionTypes.SET_DASHBOARD_ALERT_DATA_ERROR_RESPONSE:
            return {
                ...state,
                alert_error_response_message:action.data
            };
        case actionTypes.SET_DASHBOARD_ENDPOINT_DATA_ERROR_RESPONSE:
            return {
                ...state,
                endpoint_error_response_message:action.data
            };
        case actionTypes.CLEAR_DASHBOARD_ALERT_ERROR_RESPONSE:
            return {
                ...state,
                alert_error_response_message:null
            };
        case actionTypes.CLEAR_DASHBOARD_ENDPOINT_ERROR_RESPONSE:
            return {
                ...state,
                endpoint_error_response_message:null
            };
        case actionTypes.CLEAR_DASHBOARD_ALERT_DATA:
            return {
                ...state,
                dashboard_alert_data:[],
                alert_error_response_message:null
            }
        case actionTypes.CLEAR_DASHBOARD_ENDPOINT_DATA:
            return {
                ...state,
                dashboard_endpoint_data:{},
                endpoint_error_response_message:null
            }
        case actionTypes.START_LOADER_DASHBOARD_ALERT:
            return {
                ...state,
                is_loading_alert:true
            };
        case actionTypes.STOP_LOADER_DASHBOARD_ALERT:
            return {
                ...state,
                is_loading_alert:false
            };
        case actionTypes.START_LOADER_DASHBOARD_ENDPOINT:
            return {
                ...state,
                is_loading_endpoint:true
            };
        case actionTypes.STOP_LOADER_DASHBOARD_ENDPOINT:
            return {
                ...state,
                is_loading_endpoint:false
            };
        default:
    }
  
    return state;
  };