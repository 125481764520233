import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import _ from "lodash";
import { SCANNING_TYPE_OPTIONS, SCAN_MODE_ON_ACCESS_OPTION, SCAN_MODE_ON_DEMAND_ON_ACCESS_OPTION } from '../common/Constants';
import { getTenantsList } from '../../services/apiOperations';

let valueInput;
let commentInput;
let scanModeOptions = SCAN_MODE_ON_ACCESS_OPTION;

class AddScanningExclusionItemModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tenantLoading: false,
            selectedTenant: '',
            selectedTenantValidation: '',
            selectedTenantOptionsArray: [],
            selectedType: SCANNING_TYPE_OPTIONS[0].value,
            selectedTypeValidation: '',
            selectedTypeOptionsArray: SCANNING_TYPE_OPTIONS,
            selectedScanMode: SCAN_MODE_ON_ACCESS_OPTION[0].value,
            selectedScanModeValidation: '',
            selectedScanModeOptionsArray: SCAN_MODE_ON_ACCESS_OPTION,
            value: '',
            valueValidation: '',
            comment: '',
            validated: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(prevProps.show)) {
            if (this.props.show === true) {
                this.setState({
                    selectedTenant: '',
                    selectedTenantValidation: '',
                    selectedTenantOptionsArray: [],
                    selectedType: SCANNING_TYPE_OPTIONS[0].value,
                    selectedTypeValidation: '',
                    selectedTypeOptionsArray: SCANNING_TYPE_OPTIONS,
                    selectedScanMode: SCAN_MODE_ON_ACCESS_OPTION[0].value,
                    selectedScanModeValidation: '',
                    selectedScanModeOptionsArray: SCAN_MODE_ON_ACCESS_OPTION,
                    value: '',
                    valueValidation: '',
                    comment: '',
                    validated: false
                }, () => this.getTenantList())
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(nextProps.show)) {
            return true
        }
        if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
            return true
        }
        return false
    }

    getTenantList = () => {
        if(this.props.settings.SophosIdTypeIsTenant) {
            let tenantListArray=[{"value":this.props.settings.sophosClientId,"label":this.props.settings.sophosClientId}]
            this.setState({
                tenantLoading:false,
                selectedTenantOptionsArray:tenantListArray,
                selectedTenant: this.props.settings.sophosClientId
            })
        } else {
            getTenantsList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search, this.props.SophosTenantId)
                .then((res) => {
                    let tenantListArray = []
                    res.data.response.items.forEach((elementTenant) => {
                        tenantListArray.push({ "value": elementTenant.id, "label": elementTenant.name })
                    })
                    this.setState({
                        tenantLoading: false,
                        selectedTenantOptionsArray: tenantListArray
                    })
                })
                .catch((err) => {
                    let errMessage = ""
                    if (err.response) {
                        if (err.response.data.message !== undefined)
                            errMessage = err.response.data.message
                        else
                            errMessage = "Something went wrong while fetching tenant list."
                    }
                    else {
                        errMessage = "Something went wrong while fetching tenant list."
                    }

                    this.setState({
                        tenantLoading: false,
                        selectedTenantValidation: errMessage
                    })
                })
        }
    }

    resetModalOptions = () => {
        this.setState({
            selectedTenant: '',
            selectedTenantValidation: '',
            selectedTenantOptionsArray: [],
            selectedType: SCANNING_TYPE_OPTIONS[0].value,
            selectedTypeValidation: '',
            selectedTypeOptionsArray: SCANNING_TYPE_OPTIONS,
            selectedScanMode: SCAN_MODE_ON_ACCESS_OPTION[0].value,
            selectedScanModeValidation: '',
            selectedScanModeOptionsArray: SCAN_MODE_ON_ACCESS_OPTION,
            value: '',
            comment: '',
        }, () => this.props.closeModal())
    }

    handleTypeChange = (e) => {
        if (e === "amsi") {
            scanModeOptions = SCAN_MODE_ON_ACCESS_OPTION
        } 
        else if (e === "web") {
            scanModeOptions = SCAN_MODE_ON_ACCESS_OPTION
        }
        else if (e === "path") {
            scanModeOptions = SCAN_MODE_ON_DEMAND_ON_ACCESS_OPTION
        }
        else if (e === "posixPath") {
            scanModeOptions = SCAN_MODE_ON_DEMAND_ON_ACCESS_OPTION
        }
        else if (e === "process") {
            scanModeOptions = SCAN_MODE_ON_ACCESS_OPTION
        }
        else if (e === "pua") {
            scanModeOptions = SCAN_MODE_ON_ACCESS_OPTION
        }
        else if (e === "virtualPath") {
            scanModeOptions = SCAN_MODE_ON_DEMAND_ON_ACCESS_OPTION
        }
        this.setState({
            selectedType: e,
            selectedTypeValidation: '',
            selectedScanModeOptionsArray: scanModeOptions,
            selectedScanMode: scanModeOptions[0].value,
            selectedScanModeValidation: '',
        })
    }

    handleScanModeChange = (e) => {
        this.setState({
            selectedScanMode:e,
            selectedScanModeValidation:''
        }, this.validations)
    }

    handleValueChange = () => {
        this.setState({
            value: valueInput ? valueInput.value : ''
        }, this.validations)
    }

    handleCommentChange = () => {
        this.setState({
            comment: commentInput ? commentInput.value : ''
        }, this.validations)
    }

    handleTenantChange = (e) => {
        this.setState({
            selectedTenant: e,
            selectedTenantValidation: ''
        }, this.validations)
    }

    validations = () => {
        let validated = true
        validated &= valueInput && valueInput.value.trim().length !== 0
        validated &= this.state.selectedTenant.length !== 0
        validated &= this.state.selectedType.length !== 0
        this.setState({
            validated: validated
        }, () => { })
    }

    checkMandatorySelection = () => {
        this.validations()
        if (this.state.selectedTenant === "") {
            this.setState({
                selectedTenantValidation: 'Please select tenant.'
            })
            return
        }
        else {
            this.setState({
                selectedTenantValidation: ''
            })
        }
        if (this.state.selectedType === "") {
            this.setState({
                selectedTypeValidation: 'Please select type.'
            })
            return
        }
        else {
            this.setState({
                selectedTypeValidation: ''
            })
        }
        if (this.state.selectedScanMode === "") {
            this.setState({
                selectedScanModeValidation: 'Please select scan mode.'
            })
            return
        }
        else {
            this.setState({
                selectedScanModeValidation: ''
            })
        }
        let comment = this.state.comment? this.state.comment.trim() : ""
        let data = {
            "value": this.state.value.trim(),
            "type": this.state.selectedType,
            "scanMode": this.state.selectedScanMode,
            "comment": comment
        }
        if(!this.props.settings.SophosIdTypeIsTenant) {
            data["sophosTenantId"] = this.state.selectedTenant
        }
        this.props.confirmModal(data)
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={this.resetModalOptions}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Add Scanning Exclusion</h5>
                </Modal.Header>
                <Modal.Body>
                    {!this.props.settings.SophosIdTypeIsTenant &&
                        <div className="row" style={{ margin: '0 0 5px 0' }}>
                            <div className="col">
                                <span>Tenant</span><span className="text-danger">*</span>
                                <MultiSelectTextbox
                                    options={_.orderBy(this.state.selectedTenantOptionsArray, [tenant => tenant.label.toLowerCase()])}
                                    customPlaceholder="Select Tenant"
                                    filteredSelectedValue={[]}
                                    isMulti={false}
                                    onSelectOption={(value) => this.handleTenantChange(value)}
                                    isLoading={this.state.tenantLoading}
                                />
                                <span className="text-danger">{this.state.selectedTenantValidation}</span>
                            </div>
                        </div>
                    }
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Value</span><span className="text-danger">*</span>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                ref={n => valueInput = n}
                                onChange={this.handleValueChange}
                                placeholder="Enter Value"
                            />
                            <span className="text-danger">{this.state.valueValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Type</span><span className="text-danger">*</span>
                            <MultiSelectTextbox
                                options={_.orderBy(this.state.selectedTypeOptionsArray, [type => type.label.toLowerCase()])}
                                customPlaceholder="Select Type"
                                filteredSelectedValue={[this.state.selectedTypeOptionsArray[0]]}
                                isMulti={false}
                                onSelectOption={(value) => this.handleTypeChange(value)}
                            />
                            <span className="text-danger">{this.state.selectedItemTypeValidation}</span>
                        </div>
                    </div> 
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Scan Mode</span><span className="text-danger">*</span>
                            <MultiSelectTextbox
                                options={scanModeOptions}
                                customPlaceholder="Select Type"
                                filteredSelectedValue={[scanModeOptions[0]]}
                                isMulti={false}
                                onSelectOption={(value)=>this.handleScanModeChange(value)}
                            />
                            <span className="text-danger">{this.state.selectedScanModeValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Comment</span>
                            <input
                                className="form-control form-control-sm"
                                ref={n => commentInput = n}
                                type="text"
                                onChange={this.handleCommentChange}
                                placeholder="Enter Comment"
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span className="text-danger">* fields are required.</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" variant="primary"
                        onClick={this.checkMandatorySelection}
                        disabled={!this.state.validated}
                    >
                        Add
                    </Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.resetModalOptions}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AddScanningExclusionItemModal
