/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 28-02-2020 21:55
 * @modify date 28-02-2020 21:55
 * @description This file contains the component Dashboard and its layout.
 */
import React from 'react';
import Card from "react-bootstrap/Card";
import queryString from 'query-string';
import { connect } from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import {getTenantList,setTenantListErrorResponseMessage,clearTenantErrorResponseMessage,getDashboardAlertData,getDashboardEndpointData,clearDashboardAlertData,clearDashboardEndpointData,clearDashboardAlertErrorResponseMessage,clearDashboardEndpointErrorResponseMessage} from '../../services/apiOperations';
import {setInitStorage} from '../common/InitLocalStore';
import {sortingData} from '../common/Util'
import {CommonLayout} from '../common/CommonLayout';
import {ErrorModal} from '../common/ErrorModal';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import BarChart from './BarChart';
import DashboardCard , {
    // FILTER_TYPE,
    computerTypeOptions,
    // healthStatusOptions,osPlatformOptions,tamperedProtectionOptions
} from './DashboardCard';

const CONSTANT_TYPE={
    selectedTenant:"tenant_id",
    threatHistory:"threatHistory"
}

let last7Days = moment().subtract(7, "days")

let momentWeekRange=[]
for (let i = 1; i <= 7; i++) {
    momentWeekRange.push(moment(last7Days)
        .add(i, 'days')
        .format('DD-MMM-YYYY'));
}

let minute = new Date().getMinutes()%5;

let lastHour = moment().subtract(60, "minutes")

let momentHourRange=[]

for (let i = 0; i < 60/5; i++) {
    momentHourRange.push(moment(lastHour)
        .add(i*5, 'minutes')
        .subtract(minute, "minutes")
        .format('DD-MMM-YYYY HH:mm'));
}

let last24Hours = moment().subtract(24, "hours")

let momentDayRange=[]

for (let i = 0; i < 24; i++) {
    momentDayRange.push(moment(last24Hours)
        .add(i, 'hours')
        .subtract(minute, "minutes")
        .format('DD-MMM-YYYY HH:mm'));
}

let last30days = moment().subtract(30, "days")

let momentMonthRange=[]

for (let i = 1; i <= 30; i++) {
    momentMonthRange.push(moment(last30days)
        .add(i, 'days')
        .format('DD-MMM-YYYY'));
}

let threatHistoryOptions=[
    {value:"hour",label:"Last 1 hour",range:[
        'x',...momentHourRange
    ]},
    {value:"day",label:"Last 24 hours",range:[
        'x',...momentDayRange
    ]},
    {value:"week",label:"Last 7 days",range:[
        'x',...momentWeekRange
    ]},
    {value:"month",label:"Last 30 days",range:[
        'x',...momentMonthRange
    ]}
]

const NULL_UNDEFINED_EMPTY_CHECK = [null, undefined, ""]

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getTenantList: (referrer,queryFilterString,force) => dispatch(getTenantList(referrer,queryFilterString,force)),
    setTenantListErrorResponseMessage:(message)=>dispatch(setTenantListErrorResponseMessage(message)),
    clearTenantErrorResponseMessage:()=>dispatch(clearTenantErrorResponseMessage()),
    getDashboardAlertData: (referrer,queryFilterString) => dispatch(getDashboardAlertData(referrer,queryFilterString)),
    getDashboardEndpointData: (referrer,queryFilterString) => dispatch(getDashboardEndpointData(referrer,queryFilterString)),
    clearDashboardAlertData:() => dispatch(clearDashboardAlertData()),
    clearDashboardEndpointData:() => dispatch(clearDashboardEndpointData()),
    clearDashboardAlertErrorResponseMessage:() => dispatch(clearDashboardAlertErrorResponseMessage()),
    clearDashboardEndpointErrorResponseMessage:() => dispatch(clearDashboardEndpointErrorResponseMessage())
})
class Dashboard extends React.Component {
    constructor(props){
        super(props);
        setInitStorage(props.referrerHostname,queryString.parse(props.location.search))
        this.state={
            tenantList:[],
            filtered: [],
            dashboardEndpointData: {},
            dashboardAlertData: [],
            setToAllTenantBool: false,
        }
        this.componentWillMountFunction()
    }

    componentWillMountFunction=()=>{
        let queryFitlerString=queryString.parse(this.props.location.search)
        if(queryFitlerString!==""){
            this.props.getTenantList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),this.props.location.search);
        }
        else{
            this.props.getTenantList(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"));
        }
    }

    
    componentDidMount(){
        this.alertInterval = setInterval(
            ()=>{
                this.refreshTimeRange()
                this.getDashboardAlertData()
            }
        , 1000*60*5);
        this.endpointInterval = setInterval(
            this.getDashboardEndpointData.bind(this)
        , 1000*60*60);
    }

    componentDidUpdate(prevProps,prevState){
        if(JSON.stringify(prevState.dashboardEndpointData)!==JSON.stringify(this.props.dashboard.dashboard_endpoint_data)){
            this.setState({
                dashboardEndpointData:this.props.dashboard.dashboard_endpoint_data
            })
        }
        if(JSON.stringify(prevState.dashboardAlertData)!==JSON.stringify(this.props.dashboard.dashboard_alert_data)){
            this.setState({
                dashboardAlertData:this.props.dashboard.dashboard_alert_data
            })
        }
        if(JSON.stringify(this.props.tenants.tenant_list)!==JSON.stringify(prevProps.tenants.tenant_list)){
            let createTenantListPair=[];
            this.props.tenants.tenant_list.forEach((element)=>{
                createTenantListPair.push({ value: element.id, label:element.showAs})
            })
            this.setState({
                tenantList: createTenantListPair
            })
            this.selectAlertFilters("hour",CONSTANT_TYPE.threatHistory)
            this.getDashboardEndpointData()
        }
        if(this.props.settings.SophosIdTypeIsTenant && JSON.stringify(this.props.settings.SophosIdTypeIsTenant)!==JSON.stringify(prevProps.settings.SophosIdTypeIsTenant)){
            this.selectAlertFilters("hour",CONSTANT_TYPE.threatHistory);
            this.getDashboardEndpointData();
        }
    }

    componentWillUnmount(){
        this.props.clearDashboardAlertData()
        this.props.clearDashboardEndpointData()
        this.props.clearDashboardAlertErrorResponseMessage()
        this.props.clearDashboardEndpointErrorResponseMessage()
    }

    refreshTimeRange=()=>{
        last7Days = moment().subtract(7, "days")

        momentWeekRange=[]
        for (let i = 1; i <= 7; i++) {
            momentWeekRange.push(moment(last7Days)
                .add(i, 'days')
                .format('DD-MMM-YYYY'));
        }

        lastHour = moment().subtract(60, "minutes")

        momentHourRange=[]

        for (let i = 0; i < 60/5; i++) {
            momentHourRange.push(moment(lastHour)
                .add(i*5, 'minutes')
                .format('DD-MMM-YYYY HH:mm'));
        }

        last24Hours = moment().subtract(24, "hours")

        momentDayRange=[]

        for (let i = 0; i < 24; i++) {
            momentDayRange.push(moment(last24Hours)
                .add(i, 'hours')
                .format('DD-MMM-YYYY HH:mm'));
        }

        last30days = moment().subtract(30, "days")

        momentMonthRange=[]

        for (let i = 1; i <= 30; i++) {
            momentMonthRange.push(moment(last30days)
                .add(i, 'days')
                .format('DD-MMM-YYYY'));
        }

        threatHistoryOptions=[
            {value:"hour",label:"Last 1 Hour",range:[
                'x',...momentHourRange
            ]},
            {value:"day",label:"Last 24 Hours",range:[
                'x',...momentDayRange
            ]},
            {value:"week",label:"Last 7 days",range:[
                'x',...momentWeekRange
            ]},
            {value:"month",label:"Last 30 days",range:[
                'x',...momentMonthRange
            ]}
        ]
    }

    getDashboardAlertData(){
        if(this.props.location.search!==""){
            let isApiHostNotNull=false
            
            const queryParams=queryString.parse(this.props.location.search)
            queryParams.tz=encodeURI(moment(new Date()).format().toString())
            let filters=this.state.filtered
            if(filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))!==undefined)
            {
                let filteredObject=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))
                if(this.props.tenants.tenant_list.length>0){
                    let checkTenantDetails=this.props.tenants.tenant_list.find((tenantValue)=>tenantValue.id===filteredObject.value)
                    if(checkTenantDetails){
                        if(checkTenantDetails.apiHost!==null && checkTenantDetails.apiHost!=="" && checkTenantDetails.apiHost!=="NULL" && checkTenantDetails.apiHost!=="null" && checkTenantDetails.apiHost!==undefined){
                            queryParams.sophosTenantId=filteredObject.value
                            isApiHostNotNull=true
                            this.props.clearDashboardAlertData()
                        }
                        else{
                            this.setState({setToAllTenantBool:false});
                            this.props.setTenantListErrorResponseMessage('This account has not been activated; <br/>Please activate from <a target="_blank" href="https://cloud.sophos.com/">Sophos Central Administration Console</a>')
                        }
                    }
                }
            }
            else{
                if(this.props.tenants.tenant_list.length>0){
                    isApiHostNotNull=true
                    this.props.clearDashboardAlertData()
                }
            }
            if(filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.threatHistory))!==undefined)
            {
                let filteredObject=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.threatHistory))
                queryParams.threatHistory=filteredObject.value
                queryParams.now=moment().toISOString()
            }
            const queryFilter=queryString.stringify(queryParams)
            if(this.props.settings.SophosIdTypeIsTenant)
                isApiHostNotNull=true
            if(isApiHostNotNull)
                this.props.getDashboardAlertData(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),"?"+queryFilter)
        }
    }
    
    getDashboardEndpointData(){
        if(this.props.location.search!==""){
            let isApiHostNotNull=false

            const queryParams=queryString.parse(this.props.location.search)
            queryParams.tz=encodeURI(moment(new Date()).format().toString())
            let filters=this.state.filtered
            if(filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))!==undefined)
            {
                let filteredObject=filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))
                if(this.props.tenants.tenant_list.length>0){
                    let checkTenantDetails=this.props.tenants.tenant_list.find((tenantValue)=>tenantValue.id===filteredObject.value)
                    if(checkTenantDetails){
                        if(checkTenantDetails.apiHost!==null && checkTenantDetails.apiHost!=="" && checkTenantDetails.apiHost!=="NULL" && checkTenantDetails.apiHost!=="null" && checkTenantDetails.apiHost!==undefined){
                            queryParams.sophosTenantId=filteredObject.value
                            isApiHostNotNull=true
                            this.props.clearDashboardEndpointData()
                        }
                        else{
                            this.setState({setToAllTenantBool:false});
                            this.props.setTenantListErrorResponseMessage('This account has not been activated; <br/>Please activate from <a target="_blank" href="https://cloud.sophos.com/">Sophos Central Administration Console</a>')
                        }
                    }
                }
            }
            else{
                isApiHostNotNull=true
                this.props.clearDashboardEndpointData()
            }
            const queryFilter=queryString.stringify(queryParams)
            if(isApiHostNotNull)
                this.props.getDashboardEndpointData(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),"?"+queryFilter)
        }
    }

    findFilterValueByType(arrayValue,searchType){
        return arrayValue.type===searchType
    }

    selectFilters=(value,type)=>{
        let filters=this.state.filtered
        let newfilterObj={value:value,type:type}
        const obj =filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,newfilterObj.type))
        if(obj!==undefined){
            const index=filters.indexOf(obj)
            filters.splice(index,1)
        }
        if(value!=='')
            filters.push(newfilterObj)
        this.setState({
            filtered:filters
        })
        this.getDashboardAlertData()
        this.getDashboardEndpointData()
    }

    selectAlertFilters=(value,type)=>{
        let filters=this.state.filtered
        let newfilterObj={value:value,type:type}
        const obj =filters.find((arrayValue)=>this.findFilterValueByType(arrayValue,newfilterObj.type))
        if(obj!==undefined){
            const index=filters.indexOf(obj)
            filters.splice(index,1)
        }
        if(value!=='')
            filters.push(newfilterObj)
        this.setState({
            filtered:filters
        })
        this.getDashboardAlertData()
    }

    viewEndpoints=(passingObject)=>{
        let filteredObject=this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))

        if(filteredObject===undefined){
            this.props.history.push({
                pathname:"/endpoints",
                search:this.props.location.search,
                filtersObject:passingObject
            })
        }
        else{
            
            let checkTenantDetails=this.props.tenants.tenant_list.find((tenantValue)=>tenantValue.id===filteredObject.value)
            if(checkTenantDetails){
                if(checkTenantDetails.apiHost!==null && checkTenantDetails.apiHost!=="" && checkTenantDetails.apiHost!=="NULL" && checkTenantDetails.apiHost!=="null" && checkTenantDetails.apiHost!==undefined){
                    this.props.history.push({
                        pathname:"/endpoints",
                        search:this.props.location.search,
                        filtersObject:passingObject,
                        state:checkTenantDetails
                    })
                }
                else{
                    // let apiHost=null
                }
            }
        }
    }

    viewAlerts=(passingObject)=>{
        let filteredObject=this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.selectedTenant))
        if(filteredObject===undefined){
            this.props.history.push({
                pathname:"/alerts",
                search:this.props.location.search,
                filtersObject:passingObject
            })
        }
        else{
            let checkTenantDetails=this.props.tenants.tenant_list.find((tenantValue)=>tenantValue.id===filteredObject.value)
            if(checkTenantDetails){
                if(checkTenantDetails.apiHost!==null && checkTenantDetails.apiHost!=="" && checkTenantDetails.apiHost!=="NULL" && checkTenantDetails.apiHost!=="null" && checkTenantDetails.apiHost!==undefined){
                    this.props.history.push({
                        pathname:"/alerts",
                        search:this.props.location.search,
                        filtersObject:passingObject,
                        state:checkTenantDetails
                    })
                }
                else{
                    // let apiHost=null
                }
            }
        }
    }

    clearTenantErrorResponseToast=()=>{
        this.setState({setToAllTenantBool : true});
        this.props.clearTenantErrorResponseMessage()
    }

    clearDashboardErrorResponseToast=()=>{
        this.setState({setToAllTenantBool : true});
        this.props.clearDashboardAlertErrorResponseMessage()
        this.props.clearDashboardEndpointErrorResponseMessage()
    }

    render(){
        let endpointDataExist=this.state.dashboardEndpointData.endpoint?true:false
        let serverDataExist=this.state.dashboardEndpointData.server?true:false
        let alertDataExist=this.state.dashboardAlertData.length?true:false
        const columns = alertDataExist?this.state.dashboardAlertData:[];
        if(this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.threatHistory))!==undefined)
        {
            let filteredObject=this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.threatHistory))
            columns.push(threatHistoryOptions.find((obj)=>obj.value===filteredObject.value).range)
        }
        let endpointDonutTitleCount=endpointDataExist?
            ((this.state.dashboardEndpointData.endpoint.platformSummary.windows?this.state.dashboardEndpointData.endpoint.platformSummary.windows:0)+
            (this.state.dashboardEndpointData.endpoint.platformSummary.macOS?this.state.dashboardEndpointData.endpoint.platformSummary.macOS:0)+
            (this.state.dashboardEndpointData.endpoint.platformSummary.linux?this.state.dashboardEndpointData.endpoint.platformSummary.linux:0)):0

        let endpointDonutColumns = []

        if(endpointDataExist){
            endpointDonutColumns = [
                ['Windows',this.state.dashboardEndpointData.endpoint.platformSummary.windows?this.state.dashboardEndpointData.endpoint.platformSummary.windows:0],
                ['MacOS',this.state.dashboardEndpointData.endpoint.platformSummary.macOS?this.state.dashboardEndpointData.endpoint.platformSummary.macOS:0],
                ['Linux',this.state.dashboardEndpointData.endpoint.platformSummary.linux?this.state.dashboardEndpointData.endpoint.platformSummary.linux:0]
            ]
        }

        let serverDonutTitleCount=serverDataExist?
            (this.state.dashboardEndpointData.server.platformSummary.windows?this.state.dashboardEndpointData.server.platformSummary.windows:0)
            +
            (this.state.dashboardEndpointData.server.platformSummary.linux?this.state.dashboardEndpointData.server.platformSummary.linux:0):0
        
        let serverDonutColumns = []

        if(serverDataExist)
        {
            serverDonutColumns=[
                ['Windows',this.state.dashboardEndpointData.server.platformSummary.windows?this.state.dashboardEndpointData.server.platformSummary.windows:0],
                ['Linux',this.state.dashboardEndpointData.server.platformSummary.linux?this.state.dashboardEndpointData.server.platformSummary.linux:0]
            ]
        }

        const tenantsWithDataRegionNull = [];
        let clearTenantSelect = false;
        this.props.tenants.tenant_list.forEach((tenant) => {
            if (
                NULL_UNDEFINED_EMPTY_CHECK.includes(tenant.dataRegion) ||
                NULL_UNDEFINED_EMPTY_CHECK.includes(tenant.apiHost)
              ) {
                tenantsWithDataRegionNull.push(tenant.id);
              }
        });
        let selectedTenantId = this.state.filtered.find(
            (arrayValue) => arrayValue.type === CONSTANT_TYPE.selectedTenant
        );
        if (
            selectedTenantId &&
            tenantsWithDataRegionNull.length &&
            tenantsWithDataRegionNull.filter(
                (item) => item === selectedTenantId.value
            ).length
        ) {
            clearTenantSelect = true;
        }
        return(
            <CommonLayout title="Dashboard" displayTitle={true}>
                <ErrorModal 
                    show={this.props.tenants.error_response_message!==null} 
                    closeModal={()=>{
                            this.clearTenantErrorResponseToast()
                        }}
                    modalMessage={this.props.tenants.error_response_message}
                />
                {this.props.tenants.error_response_message===null?
                <ErrorModal 
                    show={this.props.dashboard.endpoint_error_response_message!==null || this.props.dashboard.alert_error_response_message!==null} 
                    closeModal={()=>{
                        this.clearDashboardErrorResponseToast()
                        }}
                    modalMessage={this.props.dashboard.endpoint_error_response_message!==null && this.props.dashboard.alert_error_response_message!==null?
                        "Something went wrong; Please try again later."
                        :(this.props.dashboard.endpoint_error_response_message!==null?this.props.dashboard.endpoint_error_response_message:this.props.dashboard.alert_error_response_message)}
                />
                :''}
                
                <div className="p-2">
                    {!this.props.settings.SophosIdTypeIsTenant && <div className="row my-2 mx-1 justify-content-between">
                        <div className="col-2 px-1">
                            <div className="input-group-append row">
                                <div className="col px-0 mx-1 text-left">
                                    <span>Tenants</span>
                                    <MultiSelectTextbox 
                                        options={this.state.tenantList.sort(sortingData)}     
                                        isMulti={false} 
                                        filteredSelectedValue={
                                            this.state.filtered.find((arrayValue)=>
                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.severity))?
                                            this.state.tenantList.filter((element)=>element.value=== this.state.filtered.find((arrayValue)=>
                                                this.findFilterValueByType(arrayValue,CONSTANT_TYPE.severity)).value)
                                            :[]
                                        } 
                                        onSelectOption={(value)=>this.selectFilters(value,CONSTANT_TYPE.selectedTenant)} 
                                        clearValue={this.state.filtered.length===0 || (clearTenantSelect && this.state.setToAllTenantBool) ?true:false}
                                        clearable={true} 
                                        isLoading={this.props.tenants.is_loading} 
                                        disabledSelect={this.props.dashboard.is_loading_alert || this.props.dashboard.is_loading_endpoint}                                                
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="row mx-1">
                        <div className="col px-2">
                            <Card className="border" bg="default">
                            <Card.Header>
                                <h5 className="m-0 text-center">Alerts 
                                    <i className="fa fa-info-circle btn px-1" 
                                        aria-hidden="true" 
                                        data-tip="Alerts data will refresh at an interval of 20 minutes" 
                                        data-for='alertHelpTime'></i>
                                    <ReactTooltip id="alertHelpTime" effect="solid" type="light" place="right" border={true} className="tooltip-fix-width-150"/>
                                </h5>
                            </Card.Header>
                                <Card.Body>
                                    <div className="input-group-append row">
                                        <div className="col my-1">
                                            <div className="col-2 px-0 mx-1 text-left pull-right">
                                                <span>Threat History</span>
                                                <select className="form-control form-control-sm" 
                                                    disabled={this.props.tenants.is_loading || this.props.dashboard.is_loading_alert}
                                                    onChange={(e)=>this.selectAlertFilters(e.target.value,CONSTANT_TYPE.threatHistory)} 
                                                    value={this.state.filtered.find((arrayValue)=>
                                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.threatHistory))!==undefined?this.state.filtered.find((arrayValue)=>
                                                            this.findFilterValueByType(arrayValue,CONSTANT_TYPE.threatHistory)).value:''}>
                                                    {threatHistoryOptions.map((element)=>{
                                                        return(<option key={element.value} value={element.value}>{element.label}</option>)
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group-append row">
                                        <div className="col">
                                            <BarChart 
                                                columns={columns}
                                                chartType="bar"
                                                chartId="stackedBarChart"
                                                isLoading={this.props.dashboard.is_loading_alert}
                                                viewAlertClick={(severity,index)=>this.viewAlerts({
                                                    severity: severity,
                                                    range: threatHistoryOptions.find((threatCheck)=>threatCheck.value===this.state.filtered.find((obj)=>this.findFilterValueByType(obj,CONSTANT_TYPE.threatHistory)).value).range[index],
                                                    typeRange: threatHistoryOptions.find((threatCheck)=>threatCheck.value===this.state.filtered.find((obj)=>this.findFilterValueByType(obj,CONSTANT_TYPE.threatHistory)).value).label
                                                })}
                                                rotateXaxisLabel={this.state.filtered.find((arrayValue)=>
                                                    this.findFilterValueByType(arrayValue,CONSTANT_TYPE.threatHistory))!==undefined?
                                                    this.state.filtered.find((arrayValue)=>this.findFilterValueByType(arrayValue,CONSTANT_TYPE.threatHistory)).value!=="week":false}
                                                xAxisLabel={
                                                    this.state.filtered.find((arrayValue)=>
                                                    this.findFilterValueByType(arrayValue,CONSTANT_TYPE.threatHistory))!==undefined?threatHistoryOptions.find((obj)=>obj.value===this.state.filtered.find((arrayValue)=>
                                                    this.findFilterValueByType(arrayValue,CONSTANT_TYPE.threatHistory)).value).label:''
                                                }
                                                colorPattern={['#dc3545','#ffc107', '#6c757d']}
                                            />
                                            <div id="loadingWrapper">
                                            {this.props.dashboard.is_loading_alert?
                                            <div className="loading-wrapper" style={{backgroundColor: '#757574',opacity:"30%",maxHeight:"320px"}}>
                                                <div className="spinner-loader">
                                                <Spinner animation="border" variant="light" />
                                                </div>
                                            </div>:
                                            ''}
                                            </div>
                                            <small id="defaultPageHelp" className="form-text text-muted"><strong>Note: </strong>Alerts will be displayed in some time if you have configured settings recently.</small>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <div className="row mx-1 mt-2">
                        <div className="col-6 px-2">
                            <DashboardCard 
                                cardTitle="Endpoint"
                                tooltipData="Endpoint data will refresh at an interval of 60 minutes"
                                endpointType={computerTypeOptions.computer}
                                isLoading={this.props.dashboard.is_loading_endpoint}
                                dataExist={endpointDataExist}
                                viewEndpointClick={(dataObject)=>this.viewEndpoints(dataObject)}
                                dataObject={this.state.dashboardEndpointData.endpoint}
                                donutChartColumnData={endpointDonutColumns}
                                donutChartTitleData={endpointDonutTitleCount}
                                donutChartId="endpointDonutChart"
                                donutCharColorPattern={['#1f77b4','#6c757d', '#ff7f0e']}
                            />
                        </div>
                        <div className="col-6 px-2">
                            <DashboardCard 
                                cardTitle="Server"
                                tooltipData="Server data will refresh at an interval of 60 minutes"
                                endpointType={computerTypeOptions.server}
                                isLoading={this.props.dashboard.is_loading_endpoint}
                                dataExist={serverDataExist}
                                viewEndpointClick={(dataObject)=>this.viewEndpoints(dataObject)}
                                dataObject={this.state.dashboardEndpointData.server}
                                donutChartColumnData={serverDonutColumns}
                                donutChartTitleData={serverDonutTitleCount}
                                donutChartId="serverDonutChart"
                                donutCharColorPattern={['#1f77b4', '#ff7f0e']}
                            />
                        </div>
                    </div>
                </div>
            </CommonLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)