/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:27
 * @modify date 06-02-2020 12:27
 * @description This file contains the all apiOperation list which is used in the application. Redux store dispatch function is also added to trigger Redux actions.
 */

import axios from 'axios';
import {
    moduleType,
    startLoader,
    stopLoader,
    setResponseTime,
    setSaveSettingsResponseMessage,
    getCurrentSettingResponse,
    getCurrentSettingErrorResponse,
    setTenantList,
    setEndpointList,
    setAssetList,
    setAlertList,
    clearSettingsResponse,
    clearEndpoints,
    clearAlerts,
    clearResponseTime,
    clearTenantErrorResponse,
    clearTenants,
    setDashboardAlertData,
    setDashboardEndpointData,
    clearDashboardAlert,
    clearDashboardEndpoint,
    setTenantListErrorResponse,
    clearEndpointErrorResponse,
    setEndpointListErrorResponse,
    setAlertListErrorResponse,
    clearAlertsErrorResponse,
    setDashboardAlertErrorResponse,
    setDashboardEndpointErrorResponse,
    clearDashboardAlertErrorResponse,
    clearDashboardEndpointErrorResponse,
    clearAssets,
    setAssetListErrorResponse,
    clearAssetErrorResponse,
    clearAuditLogData,
    setAuditLogData,
    setAuditLogErrorResponse,
    clearAuditLogErrorResponse,
    setAllowedBlockedList,
    setAllowedBlockedListErrorResponse,
    clearAllowedBlocked,
    clearAllowedBlockedErrorResponse,
    setExploitMitigationList,
    setExploitMitigationListErrorResponse,
    clearExploitMitigation,
    clearExploitMitigationErrorResponse,
    setIntrusionPreventionList,
    setIntrusionPreventionListErrorResponse,
    clearIntrusionPrevention,
    clearIntrusionPreventionErrorResponse,
    setScanningExclusionList,
    setScanningExclusionListErrorResponse,
    clearScanningExclusion,
    clearScanningExclusionErrorResponse,
    setWebsiteManagementList,
    setWebsiteManagementListErrorResponse,
    clearWebsiteManagement,
    clearWebsiteManagementErrorResponse,
    setFeatureFlags
} from './actions'
import queryString from 'query-string';
import axiosInstance from './interceptors'

const apiHost = process.env.REACT_APP_API_URL

/**
 * @param  {string} referrer
 */
const getHeaderParams = (referrer) => {
    let referrerData = referrer.split('_')
    let referrerName = (referrerData.length) ? referrerData[0] : ""
    let kaseyaSophosJwt = localStorage.getItem(referrerName + "_kaseya_sophos_jwt")
    kaseyaSophosJwt = kaseyaSophosJwt ? kaseyaSophosJwt : ''
    return { Pragma: 'no-cache', 'X-CUSTOM-REFERRER': referrer, Authorization: "Bearer " + kaseyaSophosJwt }
}

/**
 * @param  {string} referrer
 */
const getApiUrl = (referrer, queryFilterString, pathName) => {
    let referrerData = referrer.split('_')
    let referrerName = (referrerData.length) ? referrerData[0] : ""
    let adminId = localStorage.getItem(referrerName + "_kaseya_admin_id")
    let tenantId = localStorage.getItem(referrerName + "_kaseya_tenant_id")
    let apiUrl = queryFilterString !== "" ? (`${apiHost}/${pathName}${queryFilterString}`) : (adminId && tenantId ? `${apiHost}/${pathName}?adminId=${adminId}&tenantId=${tenantId}` : `${apiHost}/${pathName}`)
    return apiUrl;
}

/**
 * @param  {object} queryParamObject=""
 */
const jsonToQueryString = (json) => {
    return '?' + Object.keys(json).map(function (key) {
        return encodeURIComponent(key) + '=' +
            encodeURIComponent(json[key]);
    }).join('&');
}

/**
 * @param  {string} queryFilterString=""
 */
const removeKaseyaJwtFromQuery = (queryParams) => {
    let queryObj = queryString.parse(queryParams)
    delete queryObj.jwt;
    return jsonToQueryString(queryObj);
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getJwt = (referrer, queryFilterString = "") => {
    let queryObj = {}
    if (queryFilterString) {
        queryObj = queryString.parse(queryFilterString)
        queryFilterString = `?adminId=${queryObj.adminId}&tenantId=${queryObj.tenantId}`
    }
    let apiURL = getApiUrl(referrer, queryFilterString, 'auth/')
    return axios({
        url: apiURL,
        method: 'POST',
        headers: { Pragma: 'no-cache', 'X-CUSTOM-REFERRER': referrer + '_' + queryObj.tenantId, Authorization: "Bearer " + (queryObj.jwt ? queryObj.jwt : "") }
    })
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getSettingsAuth = (referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString)
    let apiURL = getApiUrl(referrer, queryFilterString, 'settings/')
    return axiosInstance({
        url: apiURL,
        method: 'get',
        headers: getHeaderParams(referrer)
    })
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 * @param  {boolean} force=false
 */
export const getTenantList = (referrer, queryFilterString = "", force = false) => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.tenants))
        if (!force)
            dispatch(clearTenants())
        let apiURL = getApiUrl(referrer, queryFilterString, 'tenants/')
        apiURL += `&force=${force}`
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                if (res.status === 200)
                    dispatch(setTenantList(res.data))
                else if (res.status === 203)
                    dispatch(clearTenants())
                else
                    dispatch(setResponseTime(res.data))
                dispatch(stopLoader(moduleType.tenants))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setTenantListErrorResponse(err.response.data.message))
                    else
                        dispatch(setTenantListErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    // if(err.message==="Network Error")
                    //     dispatch(setTenantListErrorResponse("Network Error"))
                    // else
                    dispatch(setTenantListErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.tenants))
            });
    }
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 * @param  {object} body
 */
export const setSettings = (referrer, body, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.settings))
        let apiURL = getApiUrl(referrer, queryFilterString, 'settings/')
        axiosInstance({
            url: apiURL,
            method: 'post',
            headers: getHeaderParams(referrer),
            data: body
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(getCurrentSettingResponse(res.data))
                    dispatch(setSaveSettingsResponseMessage("Saved successfully", res.status))
                }
                dispatch(stopLoader(moduleType.settings))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 401 || err.response.status === 400 || err.response.status === 500)
                        dispatch(setSaveSettingsResponseMessage(err.response ? err.response.data.message : err.message, err.response.status))
                    else {
                        dispatch(setSaveSettingsResponseMessage(err.message, err.response.status))
                    }
                }
                else {
                    dispatch(setSaveSettingsResponseMessage(err.message, err.status))
                }
                dispatch(stopLoader(moduleType.settings))
            });
    }
}


/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getSettings = (referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.settings))
        let apiURL = getApiUrl(referrer, queryFilterString, 'settings/')
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                if (res.status === 200)
                    dispatch(getCurrentSettingResponse(res.data))
                dispatch(stopLoader(moduleType.settings))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(getCurrentSettingErrorResponse(err.response.data))
                    }
                    else {
                        let pathName = new URL(document.URL).pathname;
                        if (pathName !== '/settings') {
                            dispatch(getCurrentSettingErrorResponse("Something went wrong; Please try again later."))
                        }
                    }
                }
                else {
                    dispatch(getCurrentSettingErrorResponse(err.message))
                }
                dispatch(stopLoader(moduleType.settings))
            });
    }
}


/**
 * @param  {string} referrer
 * @param  {string} sophosTenantId
 * @param  {string} queryFilterString=""
 * @param  {boolean} force=false
 */
export const getEndpointList = (referrer, queryFilterString = "", force = false) => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.endpoints))
        if (!force)
            dispatch(clearEndpoints())
        let apiURL = getApiUrl(referrer, queryFilterString, 'endpoints/')
        apiURL += `&force=${force}`
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                if (res.status === 200)
                    dispatch(setEndpointList(res.data))
                else
                    dispatch(setResponseTime(res.data))
                dispatch(stopLoader(moduleType.endpoints))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setEndpointListErrorResponse(err.response.data.message))
                    else
                        dispatch(setEndpointListErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    // if(err.message==="Network Error")
                    //     dispatch(setEndpointListErrorResponse("Network Error"))
                    // else
                    dispatch(setEndpointListErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.endpoints))
            });
    }
}

/**
 * 
 * @param {string} referrer 
 * @param {string} queryFilterString 
 */

export const getAssetList = (referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.assets))
        dispatch(clearAssets())
        let apiURL = getApiUrl(referrer, queryFilterString, 'assets/')
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                if (res.status === 200)
                    dispatch(setAssetList(res.data))
                dispatch(stopLoader(moduleType.assets))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setAssetListErrorResponse(err.response.data.message))
                    else
                        dispatch(setAssetListErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    // if(err.message==="Network Error")
                    //     dispatch(setEndpointListErrorResponse("Network Error"))
                    // else
                    dispatch(setAssetListErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.assets))
            });
    }
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getMachinegroupList = (referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'machinegroups/')
    return axiosInstance({
        url: apiURL,
        method: 'get',
        headers: getHeaderParams(referrer)
    })
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getAssetTenantsList = (referrer, queryFilterString = "", SophosTenantId = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'assets/tenants')
    return axiosInstance({
        url: apiURL,
        method: 'get',
        headers: getHeaderParams(referrer),
        params: { SophosTenantId }
    })
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getTenantsList = (referrer, queryFilterString = "", SophosTenantId = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'tenants/')
    return axiosInstance({
        url: apiURL,
        method: 'get',
        headers: getHeaderParams(referrer),
        params: { SophosTenantId }
    })
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const sendAssetAction = (requestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'assets/deploy')
    return axiosInstance({
        url: apiURL,
        method: 'post',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}


/**
 * @param  {string} referrer
 * @param  {object} body
 * @param  {string} queryFilterString=""
 */
export const sendRequestEndpointAction = (referrer, body, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'endpoints/action')
    return axiosInstance({
        url: apiURL,
        method: 'post',
        headers: getHeaderParams(referrer),
        data: body
    })
}

/**
 * @param  {string} referrer
 * @param  {object} body
 * @param  {string} queryFilterString=""
 */
export const sendRequestAllowedBlockedDelete = (referrer, body, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'allowed-blocked/')
    return axiosInstance({
        url: apiURL,
        method: 'delete',
        headers: getHeaderParams(referrer),
        data: body
    })
}

/**
 * @param  {object} requestBody
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const updateAllowedBlockedItem = (requestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'allowed-blocked/')
    return axiosInstance({
        url: apiURL,
        method: 'put',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}

/**
 * @param  {string} referrer
 * @param  {object} body
 * @param  {string} queryFilterString=""
 */
export const sendRequestScanningExclusionDelete = (referrer, body, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'scanning-exclusion/')
    return axiosInstance({
        url: apiURL,
        method: 'delete',
        headers: getHeaderParams(referrer),
        data: body
    })
}

/**
 * @param  {string} referrer
 * @param  {object} body
 * @param  {string} queryFilterString=""
 */
export const sendRequestExploitMitigationDelete = (referrer, body, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'exploit-mitigation/')
    return axiosInstance({
        url: apiURL,
        method: 'delete',
        headers: getHeaderParams(referrer),
        data: body
    })
}

/**
 * @param  {string} referrer
 * @param  {object} body
 * @param  {string} queryFilterString=""
 */
export const sendRequestWebsiteManagementDelete = (referrer, body, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'website-management/')
    return axiosInstance({
        url: apiURL,
        method: 'delete',
        headers: getHeaderParams(referrer),
        data: body
    })
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getAlertsList = (referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.alerts))
        dispatch(clearAlerts())
        let apiURL = getApiUrl(referrer, queryFilterString, 'alerts/')
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                if (res.status === 200)
                    dispatch(setAlertList(res.data))
                else
                    dispatch(setResponseTime(res.data))
                dispatch(stopLoader(moduleType.alerts))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setAlertListErrorResponse(err.response.data.message))
                    else
                        dispatch(setAlertListErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    // if(err.message==="Network Error")
                    //     dispatch(setAlertListErrorResponse("Network Error"))
                    // else    
                    dispatch(setAlertListErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.alerts))
            });
    }
}

/**
 * @param  {string} referrer
 * @param  {object} body
 * @param  {string} queryFilterString=""
 */
export const sendRequestAlertAction = (referrer, body, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'alerts/action')
    return axiosInstance({
        url: apiURL,
        method: 'post',
        headers: getHeaderParams(referrer),
        data: body
    })
}

/**
 * 
 * @param {} referrer 
 * @param {*} queryFilterString 
 */

export const getDashboardAlertData = (referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.dashboard_alert))
        dispatch(clearDashboardAlert())
        let apiURL = getApiUrl(referrer, queryFilterString, 'dashboard/alerts')
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                dispatch(setDashboardAlertData(res.data.response))
                dispatch(stopLoader(moduleType.dashboard_alert))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setDashboardAlertErrorResponse(err.response.data.message))
                    else
                        dispatch(setDashboardAlertErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    // if(err.message==="Network Error")
                    //     dispatch(setDashboardAlertErrorResponse("Network Error"))
                    // else    
                    dispatch(setDashboardAlertErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.dashboard_alert))
            });
    }
}

/**
 * @param {*} referrer 
 * @param {*} queryFilterString 
 */
export const getDashboardEndpointData = (referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.dashboard_endpoint))
        dispatch(clearDashboardEndpoint())
        let apiURL = getApiUrl(referrer, queryFilterString, 'dashboard/endpoints')
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                dispatch(setDashboardEndpointData(res.data.response))
                dispatch(stopLoader(moduleType.dashboard_endpoint))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setDashboardEndpointErrorResponse(err.response.data.message))
                    else
                        dispatch(setDashboardEndpointErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    // if(err.message==="Network Error")
                    //     dispatch(setDashboardEndpointErrorResponse("Network Error"))
                    // else    
                    dispatch(setDashboardEndpointErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.dashboard_endpoint))
            });
    }
}


/**
 * @param {*} referrer 
 * @param {*} queryFilterString 
 */
export const getAuditlogList = (referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.audit_log))
        dispatch(clearAuditLogData())
        let apiURL = getApiUrl(referrer, queryFilterString, 'auditlogs/')
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                dispatch(setAuditLogData(res.data.response))
                dispatch(stopLoader(moduleType.audit_log))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setAuditLogErrorResponse(err.response.data.message))
                    else
                        dispatch(setAuditLogErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    // if(err.message==="Network Error")
                    //     dispatch(setDashboardEndpointErrorResponse("Network Error"))
                    // else   
                    dispatch(setAuditLogErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.audit_log))
            });
    }
}


/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getAllowedBlockedList = (referrer, queryFilterString = "", force = false) => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.allowed_blocked))
        if (!force)
            dispatch(clearAllowedBlocked())
        let apiURL = getApiUrl(referrer, queryFilterString, 'allowed-blocked/')
        apiURL += `&force=${force}`
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(setAllowedBlockedList(res["data"]));
                    dispatch(stopLoader(moduleType.allowed_blocked))
                } else {
                    dispatch(setResponseTime(res.data))
                }
                dispatch(stopLoader(moduleType.allowed_blocked))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setAllowedBlockedListErrorResponse(err.response.data.message))
                    else
                        dispatch(setAllowedBlockedListErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    dispatch(setAllowedBlockedListErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.allowed_blocked))
            });
    }
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getScanningExclusionList = (referrer, queryFilterString = "", force = false) => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.scanning_exclusion))
        if (!force)
            dispatch(clearScanningExclusion())
        let apiURL = getApiUrl(referrer, queryFilterString, 'scanning-exclusion/')
        apiURL += `&force=${force}`
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(setScanningExclusionList(res["data"]));
                    dispatch(stopLoader(moduleType.scanning_exclusion))
                } else {
                    dispatch(setResponseTime(res.data))
                }
                dispatch(stopLoader(moduleType.scanning_exclusion))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setScanningExclusionListErrorResponse(err.response.data.message))
                    else
                        dispatch(setScanningExclusionListErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    dispatch(setScanningExclusionListErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.scanning_exclusion))
            });
    }
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getExploitMitigationList = (referrer, queryFilterString = "", force = false) => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.exploit_mitigation))
        if (!force)
            dispatch(clearExploitMitigation())
        let apiURL = getApiUrl(referrer, queryFilterString, 'exploit-mitigation/')
        apiURL += `&force=${force}`
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(setExploitMitigationList(res["data"]));
                    dispatch(stopLoader(moduleType.exploit_mitigation))
                } else {
                    dispatch(setResponseTime(res.data))
                }
                dispatch(stopLoader(moduleType.exploit_mitigation))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setExploitMitigationListErrorResponse(err.response.data.message))
                    else
                        dispatch(setExploitMitigationListErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    dispatch(setExploitMitigationListErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.exploit_mitigation))
            });
    }
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getWebsiteManagementList = (referrer, queryFilterString = "", force = false) => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.website_management))
        if (!force)
            dispatch(clearWebsiteManagement())
        let apiURL = getApiUrl(referrer, queryFilterString, 'website-management/')
        apiURL += `&force=${force}`
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(setWebsiteManagementList(res["data"]));
                    dispatch(stopLoader(moduleType.website_management))
                } else {
                    dispatch(setResponseTime(res.data))
                }
                dispatch(stopLoader(moduleType.website_management))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setWebsiteManagementListErrorResponse(err.response.data.message))
                    else
                        dispatch(setWebsiteManagementListErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    dispatch(setWebsiteManagementListErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.website_management))
            });
    }
}

/*
 * @param  {object} requestBody
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const updateScanningExclusionItem = (requestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'scanning-exclusion/')
    return axiosInstance({
        url: apiURL,
        method: 'put',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}

/*
 * @param  {object} requestBody
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const updateExploitMitigationItem = (requestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'exploit-mitigation/')
    return axiosInstance({
        url: apiURL,
        method: 'put',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}

/** @param  {object} requestBody
* @param  {string} referrer
* @param  {string} queryFilterString=""
*/
export const updateWebsiteManagementItem = (requestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'website-management/')
    return axiosInstance({
        url: apiURL,
        method: 'put',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}

/**
 * @param  {string} queryFilterString=""
 */
export const senduploadCSVData = (referrer, type, requestBody, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = queryFilterString !== "" ? `${apiHost}/deployment/upload${queryFilterString}&type=${type}` : `${apiHost}/deployment/upload?type=${type}`
    return axiosInstance({
        url: apiURL,
        method: 'post',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}

/**
 * @param  {object} reqestBody
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const sendDeploymentSettings = (reqestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'deployment/')
    return axiosInstance({
        url: apiURL,
        method: 'post',
        headers: getHeaderParams(referrer),
        data: reqestBody
    })
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const checkDeploymentExist = (referrer, queryFilterString = "", type = null) => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let typeParam = type ? `?type=${type}` : null;
    let apiURL = queryFilterString !== "" ? `${apiHost}/deployment/upload${queryFilterString}&type=${type}` : `${apiHost}/deployment/upload${typeParam}`
    return axiosInstance({
        url: apiURL,
        method: 'get',
        headers: getHeaderParams(referrer)
    })
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const sendRequestTenantTokenEdit = (referrer, body, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'tenants/')
    return axiosInstance({
        url: apiURL,
        method: 'put',
        headers: getHeaderParams(referrer),
        data: body
    })
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getDeploymentData = (referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'deployment/')
    return axiosInstance({
        url: apiURL,
        method: 'get',
        headers: getHeaderParams(referrer)
    })
}

/**
 * @param  {object} requestBody
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const sendAllowedBlockedItem = (requestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'allowed-blocked/')
    return axiosInstance({
        url: apiURL,
        method: 'post',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}

/**
 * @param  {object} requestBody
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const sendExploitMitigationItem = (requestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'exploit-mitigation/')
    return axiosInstance({
        url: apiURL,
        method: 'post',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}

/**
 * @param  {object} requestBody
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const sendScanningExclusionItem = (requestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'scanning-exclusion/')
    return axiosInstance({
        url: apiURL,
        method: 'post',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}

/**
 * @param  {object} requestBody
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const sendWebsiteManagementItem = (requestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'website-management/')
    return axiosInstance({
        url: apiURL,
        method: 'post',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}

/**
 * Below are operations for resetting redux store variables
 */
export const clearSettingsResponseMessage = () => {
    return dispatch => {
        dispatch(clearSettingsResponse())
    }
}

export const clearNextPossibleResponseMessage = () => {
    return dispatch => {
        dispatch(clearResponseTime())
    }
}

export const clearTenantErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearTenantErrorResponse())
    }
}

export const clearEndpointErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearEndpointErrorResponse())
    }
}


export const clearAssetErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearAssetErrorResponse())
    }
}

export const clearAlertsErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearAlertsErrorResponse())
    }
}

export const setTenantListErrorResponseMessage = (message) => {
    return dispatch => {
        dispatch(setTenantListErrorResponse(message))
    }
}

export const clearDashboardAlertErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearDashboardAlertErrorResponse())
    }
}

export const clearDashboardEndpointErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearDashboardEndpointErrorResponse())
    }
}

export const clearAuditLogListErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearAuditLogErrorResponse())
    }
}

export const setAuditLogListErrorResponseMessage = (message) => {
    return dispatch => {
        dispatch(setAuditLogErrorResponse(message))
    }
}

export const clearEndpointsTable = () => {
    return dispatch => {
        dispatch(clearEndpoints())
    }
}

export const clearAssetsTable = () => {
    return dispatch => {
        dispatch(clearAssets())
    }
}

export const clearAlertsTable = () => {
    return dispatch => {
        dispatch(clearAlerts())
    }
}

export const clearDashboardAlertData = () => {
    return dispatch => {
        dispatch(clearDashboardAlert())
    }
}

export const clearDashboardEndpointData = () => {
    return dispatch => {
        dispatch(clearDashboardEndpoint())
    }
}

export const setSettingsData = (data) => {
    return dispatch => {
        dispatch(getCurrentSettingResponse(data))
    }
}


export const clearAllowedBlockedErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearAllowedBlockedErrorResponse())
    }
}

export const clearAllowedBlockedTable = () => {
    return dispatch => {
        dispatch(clearAllowedBlocked())
    }
}


export const clearScanningExclusionErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearScanningExclusionErrorResponse())
    }
}

export const clearScanningExclusionTable = () => {
    return dispatch => {
        dispatch(clearScanningExclusion())
    }
}

export const clearWebsiteManagementErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearWebsiteManagementErrorResponse())
    }
}

export const clearWebsiteManagementTable = () => {
    return dispatch => {
        dispatch(clearWebsiteManagement())
    }
}

export const clearExploitMitigationErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearExploitMitigationErrorResponse())
    }
}

export const clearExploitMitigationTable = () => {
    return dispatch => {
        dispatch(clearExploitMitigation())
    }
}

export const setFlagsData = (data) => {
    return dispatch => {
        dispatch(setFeatureFlags(data))
    }
}
/**
 * @param  {string} referrer
 * @param  {object} body
 * @param  {string} queryFilterString=""
 */
export const sendRequestIntrusionPreventionDelete = (referrer, body, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'intrusion-prevention/')
    return axiosInstance({
        url: apiURL,
        method: 'delete',
        headers: getHeaderParams(referrer),
        data: body
    })
}

/**
 * @param  {object} requestBody
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const updateIntrusionPreventionItem = (requestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'intrusion-prevention/')
    return axiosInstance({
        url: apiURL,
        method: 'put',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getIntrusionPreventionList = (referrer, queryFilterString = "", force = false) => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    return dispatch => {
        dispatch(startLoader(moduleType.intrusion_prevention))
        if (!force)
            dispatch(clearIntrusionPrevention())
        let apiURL = getApiUrl(referrer, queryFilterString, 'intrusion-prevention/')
        apiURL += `&force=${force}`
        axiosInstance({
            url: apiURL,
            method: 'get',
            headers: getHeaderParams(referrer)
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(setIntrusionPreventionList(res["data"]));
                    dispatch(stopLoader(moduleType.intrusion_prevention))
                } else {
                    dispatch(setResponseTime(res.data))
                }
                dispatch(stopLoader(moduleType.intrusion_prevention))
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.message !== undefined)
                        dispatch(setIntrusionPreventionListErrorResponse(err.response.data.message))
                    else
                        dispatch(setIntrusionPreventionListErrorResponse("Something went wrong; Please try again later."))

                }
                else {
                    dispatch(setIntrusionPreventionListErrorResponse("Something went wrong; Please try again later."))
                }
                dispatch(stopLoader(moduleType.intrusion_prevention))
            });
    }
}

/**
 * @param  {object} requestBody
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const sendIntrusionPreventionItem = (requestBody, referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'intrusion-prevention/')
    return axiosInstance({
        url: apiURL,
        method: 'post',
        headers: getHeaderParams(referrer),
        data: requestBody
    })
}

export const clearIntrusionPreventionErrorResponseMessage = () => {
    return dispatch => {
        dispatch(clearIntrusionPreventionErrorResponse())
    }
}

export const clearIntrusionPreventionTable = () => {
    return dispatch => {
        dispatch(clearIntrusionPrevention())
    }
}

/**
 * @param  {string} referrer
 * @param  {string} queryFilterString=""
 */
export const getFeatureFlags = (referrer, queryFilterString = "") => {
    queryFilterString = removeKaseyaJwtFromQuery(queryFilterString);
    let apiURL = getApiUrl(referrer, queryFilterString, 'feature-flags/')
    return axiosInstance({
        url: apiURL,
        method: 'get',
        headers: getHeaderParams(referrer)
    })
}
