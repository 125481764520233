import React from 'react';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import _ from "lodash";
import { WEBSITE_CATEGORY_OPTIONS } from '../common/Constants';
import { getTenantsList } from '../../services/apiOperations';

let urlInput;
let tagsInput;
let commentInput;

class AddWebsiteManagementItemModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tenantLoading: false,
            selectedTenant: '',
            selectedTenantValidation: '',
            selectedTenantOptionsArray: [],
            selectedCategory: 0,
            selectedCategoryValidation: '',
            selectedCategoryOptionsArray: WEBSITE_CATEGORY_OPTIONS,
            tags: '',
            url: '',
            urlValidation: '',
            comment: '',
            validated: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(prevProps.show)) {
            if (this.props.show === true) {
                this.setState({
                    selectedTenant: '',
                    selectedTenantValidation: '',
                    selectedTenantOptionsArray: [],
                    selectedCategory: 0,
                    selectedCategoryValidation: '',
                    selectedCategoryOptionsArray: WEBSITE_CATEGORY_OPTIONS,
                    tags: '',
                    url: '',
                    urlValidation: '',
                    comment: '',
                    validated: false,
                    tenantLoading: true,
                }, () => this.getTenantList())
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(nextProps.show)) {
            return true
        }
        if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
            return true
        }
        return false
    }

    getTenantList = () => {
        if(this.props.settings.SophosIdTypeIsTenant) {
            let tenantListArray=[{"value":this.props.settings.sophosClientId,"label":this.props.settings.sophosClientId}]
            this.setState({
                tenantLoading:false,
                selectedTenantOptionsArray:tenantListArray,
                selectedTenant: this.props.settings.sophosClientId
            })
        } else {
            getTenantsList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search, this.props.SophosTenantId)
                .then((res) => {
                    let tenantListArray = []
                    res.data.response.items.forEach((elementTenant) => {
                        tenantListArray.push({ "value": elementTenant.id, "label": elementTenant.name })
                    })
                    this.setState({
                        tenantLoading: false,
                        selectedTenantOptionsArray: tenantListArray
                    })
                })
                .catch((err) => {
                    let errMessage = ""
                    if (err.response) {
                        if (err.response.data.message !== undefined)
                            errMessage = err.response.data.message
                        else
                            errMessage = "Something went wrong while fetching tenant list."
                    }
                    else {
                        errMessage = "Something went wrong while fetching tenant list."
                    }

                    this.setState({
                        tenantLoading: false,
                        selectedTenantValidation: errMessage
                    })
                })
        }
    }

    resetModalOptions = () => {
        this.setState({
            selectedTenant: '',
            selectedTenantValidation: '',
            selectedTenantOptionsArray: [],
            selectedCategory: 0,
            selectedCategoryValidation: '',
            selectedCategoryOptionsArray: WEBSITE_CATEGORY_OPTIONS,
            tags: '',
            url: '',
            comment: '',
        }, () => this.props.closeModal())
    }

    handleTenantChange = (e) => {
        this.setState({
            selectedTenant: e,
            selectedTenantValidation: ''
        }, this.validations)
    }

    handleCategoryChange = (e) => {
        this.setState({
            selectedCategory: e,
            selectedCategoryValidation: ''
        }, this.validations)
    }

    handleTagsChange = () => {
        this.setState({
            tags: tagsInput ? tagsInput.value : ''
        }, this.validations)
    }

    handleUrlChange = () => {
        this.setState({
            url: urlInput ? urlInput.value : ''
        }, this.validations)
    }

    handleCommentChange = () => {
        this.setState({
            comment: commentInput ? commentInput.value : ''
        }, this.validations)
    }

    validations = () => {
        let validated = true
        validated &= this.state.selectedTenant.length !== 0
        validated &= (parseInt(this.state.selectedCategory) !== 0 || this.state.tags.trim().length !== 0)
        validated &= urlInput && urlInput.value.trim().length !== 0
        this.setState({
            validated: validated
        }, () => { })
    }

    checkMandatorySelection = () => {
        this.validations()
        if (this.state.selectedTenant === "") {
            this.setState({
                selectedTenantValidation: 'Please select tenant.'
            })
            return
        }
        else {
            this.setState({
                selectedTenantValidation: ''
            })
        }
        if (this.state.selectedCategory === "") {
            this.setState({
                selectedCategoryValidation: 'Please select category.'
            })
            return
        }
        else {
            this.setState({
                selectedCategoryValidation: ''
            })
        }
        let comment = this.state.comment? this.state.comment.trim() : ""
        let data = {
            "categoryId": parseInt(this.state.selectedCategory),
            "tags": this.state.tags.trim().split(","),
            "url": this.state.url.trim(),
            "comment": comment
        }
        if(!this.props.settings.SophosIdTypeIsTenant) {
            data["sophosTenantId"] = this.state.selectedTenant
        }
        this.props.confirmModal(data)
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={this.resetModalOptions}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Add Website Management</h5>
                </Modal.Header>
                <Modal.Body>
                    {!this.props.settings.SophosIdTypeIsTenant &&
                        <div className="row" style={{ margin: '0 0 5px 0' }}>
                            <div className="col">
                                <span>Tenant</span><span className="text-danger">*</span>
                                <MultiSelectTextbox
                                    options={_.orderBy(this.state.selectedTenantOptionsArray, [tenant => tenant.label.toLowerCase()])}
                                    customPlaceholder="Select Tenant"
                                    filteredSelectedValue={[]}
                                    isMulti={false}
                                    onSelectOption={(value) => this.handleTenantChange(value)}
                                    isLoading={this.state.tenantLoading}
                                />
                                <span className="text-danger">{this.state.selectedTenantValidation}</span>
                            </div>
                        </div>
                    }
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Category Override</span>
                            <MultiSelectTextbox
                                options={_.orderBy(this.state.selectedCategoryOptionsArray, [category => category.label.toLowerCase()])}
                                customPlaceholder="Select Category Override"
                                filteredSelectedValue={[]}
                                isMulti={false}
                                onSelectOption={(value) => this.handleCategoryChange(value)}
                                isLoading={this.state.categoryLoading}
                            />
                            <span className="text-danger">{this.state.selectedTenantValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Tags <a href className="text-muted" data-tip data-for="tagsDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="tagsDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                                Multiple tags should be added comma separated.
                            </ReactTooltip>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                ref={n => tagsInput = n}
                                onChange={this.handleTagsChange}
                                placeholder="Enter Tags"
                            />

                            <span className="text-danger">{this.state.tagsValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>URL<span className="text-danger">*</span> <a href className="text-muted" data-tip data-for="urlDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="urlDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                                URL should not be more then 2048 characters.
                            </ReactTooltip>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                ref={n => urlInput = n}
                                onChange={this.handleUrlChange}
                                placeholder="Enter URL"
                            />
                            <span className="text-danger">{this.state.urlValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Comment <a href className="text-muted" data-tip data-for="commentDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="commentDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                                Comment should not be more then 300 characters.
                            </ReactTooltip>
                            <input
                                className="form-control form-control-sm"
                                ref={n => commentInput = n}
                                type="text"
                                onChange={this.handleCommentChange}
                                placeholder="Enter Comment"
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span className="text-danger">* fields are required.</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span className="text-danger">Please enter one tag or select a category override.</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" variant="primary"
                        onClick={this.checkMandatorySelection}
                        disabled={!this.state.validated}
                    >
                        Add
                    </Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.resetModalOptions}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AddWebsiteManagementItemModal
