/**
 * @author Vaibhav Chaudhary
 * @email vaibhav.chaudhary@sophos.com
 * @create date 1-05-2024 13:07
 * @description This component has the table view of deployment page.
 */
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { getDeploymentData } from "../../services/apiOperations";
import { ErrorModal } from "../common/ErrorModal";
import RadioButton from "../common/RadioButton";
import MultiSelectTextbox from "../common/MultiSelectTextbox";
import DeploymentSelectionRowV2, { ROW_TYPE } from "../deploymentSelectionRowV2/DeploymentSelectionRowV2";
import './deploymentSelectV2.scss';

class DeploymentSelectV2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tenantSelectionMode: "",
      selectionData: [],
      getDeploymentDataLoading: false,
      showErrorPopup: false,
      showErrorMessage: "",
      filtered: [],
      clearFilterBool: false,
      machineGroupSearchList: [],
      organizationSearchList: [],
      tenantSearchList: [],
      finishStepLoading: false,
      isRowUpdated: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fieldValues !== this.props.fieldValues) {
      this.setState({
        selectionData: this.props.fieldValues.selectionData,
        tenantSelectionMode: this.props.fieldValues.tenantSelectionMode,
        finishStepLoading: this.props.finishStepLoading,
        isRowUpdated: this.props.fieldValues.isRowUpdated
      });
    }
  }

  componentDidMount() {
    const machines = [];
    this.props.fieldValues.machineGroups.forEach((item) =>
      machines.push({ label: item.label, value: item.value })
    );
    const organizations = [];
    this.props.fieldValues.organizationGroups.forEach((item) =>
      organizations.push({ label: item.label, value: item.value })
    );
    const tenants = [];
    this.props.fieldValues.tenantList.forEach((item) =>
      tenants.push({ label: item.label, value: item.value })
    );
    this.setState({
      machineGroupSearchList: machines,
      organizationSearchList: organizations,
      tenantSearchList: tenants,
    });
    if (this.props.fieldValues !== null) {
      if (
        this.props.fieldValues.selectionData &&
        this.props.fieldValues.tenantSelectionMode
      ) {
        this.setState({
          selectionData: this.props.fieldValues.selectionData,
          tenantSelectionMode: this.props.fieldValues.tenantSelectionMode,
        });
      } else {
        this.getDeploymentSettings();
      }
    }
    this.resetMachineGroupOptions();
  }

  componentWillUnmount() {
    if (this.state.selectionData.length > 0 && !this.props.skipCurrentStep) {
      this.props.saveValues(this.state);
    }
  }

  getDeploymentSettings = () => {
    this.setState(
      {
        getDeploymentDataLoading: true,
        showErrorPopup: false,
      },
      () => {
        getDeploymentData(
          this.props.referrerHostname +
          "_" +
          localStorage.getItem(
            this.props.referrerHostname + "_kaseya_tenant_id"
          ),
          this.props.location.search
        )
          .then((res) => {
            let deploymentSelectionDataArray = [];
            if (res.data.response.autoDeploymentData) {
              res.data.response.autoDeploymentData.forEach((elementData) => {
                let dataObject = elementData;
                dataObject.id = (
                  +new Date() + Math.floor(Math.random() * 999999)
                ).toString(36);
                deploymentSelectionDataArray.push(dataObject);
              });
            }
            this.setState(
              {
                tenantSelectionMode: res.data.response.tenantSelectionMode
                  ? res.data.response.tenantSelectionMode
                  : "machineGroup",
                selectionData: deploymentSelectionDataArray,
                getDeploymentDataLoading: false,
              },
              () => {
                this.props.saveValues(this.state);
                this.markMachinGroupDisabled();
              }
            );
          })
          .catch((err) => {
            let errMessage = "";
            if (err.response) {
              if (err.response.data.message !== undefined)
                errMessage = err.response.data.message;
              else errMessage = "Something went wrong; Please try again later.";
            } else {
              errMessage = "Something went wrong; Please try again later.";
            }
            this.setState({
              showErrorPopup: true,
              showErrorMessage: errMessage,
              getDeploymentDataLoading: false,
            });
          });
      }
    );
  };

  radioChangeHandler = (event) => {
    if (this.state.selectionData.length > 0) {
      this.props.askResetConfirmation(event.target.value);
    } else {
      this.setState(
        {
          selectionData: [],
          tenantSelectionMode: event.target.value,
        },
        () => {
          this.props.saveValues(this.state);
          this.resetMachineGroupOptions();
        }
      );
    }
  };

  handleAddEvent = () => {
    this.clearFilter();
    let id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    let blankRow = {
      id: id,
      tenantId: null,
      machineGroupOrganizationId: null,
      macProducts: "all",
      windowsDesktopProducts: "all",
      windowsServerProducts: "all",
      deviceEncryption: true,
      removeCompetitiveProducts: false,
      validRow: true,
    };
    this.state.selectionData.push(blankRow);
    this.setState(
      {
        selectionData: this.state.selectionData,
        isRowUpdated: true
      },
      () => this.props.saveValues(this.state)
    );
  };

  handleRowDel = (deleteRow) => {
    let index = this.state.selectionData.indexOf(deleteRow);
    this.state.selectionData.splice(index, 1);
    /**Check for MachineGroups */
    this.props.fieldValues.machineGroups.forEach((element) => {
      if (element.value === deleteRow[ROW_TYPE.MachineGroupOrganization]) {
        element.disabled = false;
      }
    });
    /**Check for Organization */
    this.props.fieldValues.organizationGroups.forEach((element) => {
      if (element.value === deleteRow[ROW_TYPE.MachineGroupOrganization]) {
        element.disabled = false;
      }
    });
    this.setState(
      {
        selectionData: this.state.selectionData,
        isRowUpdated: true
      },
      () => this.props.saveValues(this.state)
    );
  };

  resetMachineGroupOptions = () => {
    if (this.props.fieldValues) {
      this.props.fieldValues.machineGroups.forEach((elementMachine) => {
        if (
          this.state.selectionData.filter(
            (elementCheck) =>
              elementCheck[ROW_TYPE.MachineGroupOrganization] ===
              elementMachine.value
          ).length === 0
        ) {
          elementMachine.disabled = false;
        }
      });
      this.props.fieldValues.organizationGroups.forEach(
        (elementOrganization) => {
          if (
            this.state.selectionData.filter(
              (elementCheck) =>
                elementCheck[ROW_TYPE.MachineGroupOrganization] ===
                elementOrganization.value
            ).length === 0
          ) {
            elementOrganization.disabled = false;
          }
        }
      );
    }
  };

  markMachinGroupDisabled = () => {
    this.props.fieldValues.machineGroups.forEach((elementMachine) => {
      if (
        this.state.selectionData.filter(
          (elementCheck) =>
            elementCheck[ROW_TYPE.MachineGroupOrganization] ===
            elementMachine.value
        ).length === 1
      ) {
        elementMachine.disabled = true;
      }
    });
    this.props.fieldValues.organizationGroups.forEach((elementOrganization) => {
      if (
        this.state.selectionData.filter(
          (elementCheck) =>
            elementCheck[ROW_TYPE.MachineGroupOrganization] ===
            elementOrganization.value
        ).length === 1
      ) {
        elementOrganization.disabled = true;
      }
    });
  };

  handleProductTable = (key, eventType, value) => {
    this.setState({
      isRowUpdated: true
    });
    this.state.selectionData.forEach((rowData) => {
      if (key === rowData.id) {
        rowData[eventType] = value;
      }
    });
    if (eventType === ROW_TYPE.MachineGroupOrganization) {
      /**Check for MachineGroup */
      this.props.fieldValues.machineGroups.forEach((elementMachine) => {
        if (elementMachine.value === value) {
          elementMachine.disabled = true;
        }
        if (
          this.state.selectionData.filter(
            (elementCheck) =>
              elementCheck[ROW_TYPE.MachineGroupOrganization] ===
              elementMachine.value
          ).length === 0
        ) {
          elementMachine.disabled = false;
        }
      });
      /**Check for Organization */
      this.props.fieldValues.organizationGroups.forEach((elementMachine) => {
        if (elementMachine.value === value) {
          elementMachine.disabled = true;
        }
        if (
          this.state.selectionData.filter(
            (elementCheck) =>
              elementCheck[ROW_TYPE.MachineGroupOrganization] ===
              elementMachine.value
          ).length === 0
        ) {
          elementMachine.disabled = false;
        }
      });
    }
    this.setState(
      {
        selectionData: this.state.selectionData,
      },
      () => this.props.saveValues(this.state)
    );
  };

  closeActionModal = () => {
    this.setState({
      showErrorPopup: false,
      showErrorMessage: "",
    });
  };

  findFilterValueByType(arrayValue, searchType) {
    return arrayValue.type === searchType;
  }

  selectFilters = (value, type) => {
    let filters = this.state.filtered;
    let newFilterObj = { value: value, type: type };
    const obj = filters.find((arrayValue) =>
      this.findFilterValueByType(arrayValue, newFilterObj.type)
    );
    if (obj !== undefined) {
      const index = filters.indexOf(obj);
      filters.splice(index, 1);
    }
    if (value !== "") {
      filters.push(newFilterObj);
    }

    this.setState({
      filtered: filters,
    });
  };

  clearFilter = () => {
    this.setState({
      filtered: [],
      clearFilterBool: !this.state.clearFilterBool,
    });
  };

  handleSave = () => {
    this.props.finishStep();
  }

  openDeploymentGuide = () => {
    this.props.openGuide();
  }

  render() {
    let filteredRow = [];
    this.state.selectionData.forEach((row) => {
      this.state.filtered.forEach((filter) => {
        if (filter.type === ROW_TYPE.MachineGroupOrganization) {
          filter.value
            .split(",")
            .forEach(
              (machineGroupOrganization) =>
                machineGroupOrganization === row.machineGroupOrganizationId &&
                filteredRow.push(row)
            );
        } else {
          filter.value
            .split(",")
            .forEach(
              (tenant) => tenant === row.tenantId && filteredRow.push(row)
            );
        }
      });
    });
    filteredRow = _.uniqBy(filteredRow, "id");
    let selectionRowsData = this.state.selectionData.map(
      (selectionRow, index) => {
        return (
          <DeploymentSelectionRowV2
            tenantSelectionMode={
              this.state.tenantSelectionMode === ""
                ? ""
                : this.state.tenantSelectionMode === "organization"
                  ? "Organization"
                  : "Machine Group"
            }
            onProductTableUpdate={this.handleProductTable}
            selectionRow={selectionRow}
            onDelEvent={this.handleRowDel}
            machineGroupOrganizationData={
              this.state.tenantSelectionMode === ""
                ? []
                : this.state.tenantSelectionMode === "organization"
                  ? this.props.fieldValues.organizationGroups
                  : this.props.fieldValues.machineGroups
            }
            tenantNameOptions={this.props.fieldValues.tenantList}
            macProductsOptions={this.props.macProductsOptions}
            windowsDesktopProductsOptions={
              this.props.windowsDesktopProductsOptions
            }
            windowsServerProductsOptions={
              this.props.windowsServerProductsOptions
            }
            key={selectionRow.id}
            indexNumner={index}
          />
        );
      }
    );
    let filteredRowsData = filteredRow.map((selectionRow, index) => {
      return (
        <DeploymentSelectionRowV2
          tenantSelectionMode={
            this.state.tenantSelectionMode === ""
              ? ""
              : this.state.tenantSelectionMode === "organization"
                ? "Organization"
                : "Machine Group"
          }
          onProductTableUpdate={this.handleProductTable}
          selectionRow={selectionRow}
          onDelEvent={this.handleRowDel}
          machineGroupOrganizationData={
            this.state.tenantSelectionMode === ""
              ? []
              : this.state.tenantSelectionMode === "organization"
                ? this.props.fieldValues.organizationGroups
                : this.props.fieldValues.machineGroups
          }
          tenantNameOptions={this.props.fieldValues.tenantList}
          macProductsOptions={this.props.macProductsOptions}
          windowsDesktopProductsOptions={
            this.props.windowsDesktopProductsOptions
          }
          windowsServerProductsOptions={this.props.windowsServerProductsOptions}
          key={selectionRow.id}
          indexNumner={index}
        />
      );
    });

    return (
      <React.Fragment>
        <ErrorModal
          show={this.state.showErrorPopup}
          closeModal={this.closeActionModal}
          modalMessage={this.state.showErrorMessage}
        />
        <div className="row mx-0">
          <div className="col text-center">
            <h4>Auto Deployment Settings</h4>
            <br />
            <div className="filter-container">
              <div className="radio-container">
                <label>Tenant selection on the basis of: </label>
                <div className="radio-buttons">
                  <RadioButton
                    changed={this.radioChangeHandler}
                    id="1"
                    isSelected={this.state.tenantSelectionMode === "machineGroup"}
                    label="Machine Group"
                    value="machineGroup"
                  />
                  <RadioButton
                    changed={this.radioChangeHandler}
                    id="2"
                    isSelected={this.state.tenantSelectionMode === "organization"}
                    label="Organization"
                    value="organization"
                  />
                </div>
              </div>
              <>
                {
                  this.state.tenantSelectionMode !== "" && (
                    <>
                      <section className="divider">
                        <div className="content"></div>
                      </section>
                      <i className="filter-icon fa fa-filter"
                        aria-hidden="true"></i>
                      <div className="text-filters">
                        <div className="multiselect-box">
                          <span>
                            {this.state.tenantSelectionMode === "organization"
                              ? "Organization"
                              : "Machine Group"}
                          </span>
                          <MultiSelectTextbox
                            className="label"
                            options={
                              this.state.tenantSelectionMode === ""
                                ? []
                                : this.state.tenantSelectionMode === "organization"
                                  ? _.orderBy(this.state.organizationSearchList, [
                                    organization => organization.label.toLowerCase()
                                  ])
                                  : _.orderBy(this.state.machineGroupSearchList, [
                                    machineGroup => machineGroup.label.toLowerCase()
                                  ])
                            }
                            isLoading={false}
                            isMulti={true}
                            filteredSelectedValue={
                              this.state.filtered.find((arrayValue) =>
                                this.findFilterValueByType(
                                  arrayValue,
                                  ROW_TYPE.MachineGroupOrganization
                                )
                              )
                                ? this.state.tenantSelectionMode === "organization"
                                  ? this.state.organizationSearchList.filter(
                                    (element) =>
                                      element.value ===
                                      this.state.filtered.find((arrayValue) =>
                                        this.findFilterValueByType(
                                          arrayValue,
                                          ROW_TYPE.MachineGroupOrganization
                                        )
                                      ).value
                                  )
                                  : this.state.machineGroupSearchList.filter(
                                    (element) =>
                                      element.value ===
                                      this.state.filtered.find((arrayValue) =>
                                        this.findFilterValueByType(
                                          arrayValue,
                                          ROW_TYPE.MachineGroupOrganization
                                        )
                                      ).value
                                  )
                                : []
                            }
                            onSelectOption={(value) =>
                              this.selectFilters(
                                value,
                                ROW_TYPE.MachineGroupOrganization
                              )
                            }
                            clearValue={this.state.clearFilterBool}
                          />
                        </div>
                        <div className="multiselect-box">
                          <span>Tenants</span>
                          <MultiSelectTextbox
                            className="label"
                            options={_.orderBy(this.props.fieldValues?.tenantList, [
                              tenant => tenant.label.toLowerCase()
                            ])}
                            isMulti={true}
                            filteredSelectedValue={
                              this.state.filtered.find((arrayValue) =>
                                this.findFilterValueByType(
                                  arrayValue,
                                  ROW_TYPE.Tenant
                                )
                              )
                                ? this.props.fieldValues.tenantList?.filter(
                                  (element) =>
                                    element.value ===
                                    this.state.filtered.find((arrayValue) =>
                                      this.findFilterValueByType(
                                        arrayValue,
                                        ROW_TYPE.Tenant
                                      )
                                    ).value
                                )
                                : []
                            }
                            onSelectOption={(value) =>
                              this.selectFilters(value, ROW_TYPE.Tenant)
                            }
                            clearValue={this.state.clearFilterBool}
                          />
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={this.clearFilter}
                          >
                            Clear
                          </button>
                        </div>
                      </div>

                      <div className="link-container">
                        <button type="button" className="btn link-button" onClick={this.openDeploymentGuide}>View Deployment Guide</button>
                      </div>
                    </>
                  )}
              </>
            </div>
          </div>
        </div>

        <Scrollbars
          style={{
            width: "auto",
            marginBottom: '35px',
            height: '100%',
            minHeight: '65vh',
            maxHeight: '70vh'
          }}
        >
          {this.state.getDeploymentDataLoading && (
            <div className="row mx-0 mt-3">
              <div className="col-md-12 text-center">
                <div className="jumbotron m-0">
                  <div className="container">
                    <h1 className="display-4">
                      <i className="fa fa-inbox"></i>
                    </h1>
                    <h5>Loading...</h5>
                  </div>
                </div>
                <div id="loadingWrapper">
                  <div
                    className="loading-wrapper"
                    style={{
                      backgroundColor: "#757574",
                      opacity: "30%",
                      maxHeight: "calc(100vh - 390px)",
                    }}
                  >
                    <div className="spinner-loader">
                      <Spinner animation="border" variant="light" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!this.state.getDeploymentDataLoading && !this.state.filtered.length ? (
            selectionRowsData.length ? (
              <React.Fragment>
                <div className="row mx-0 mt-3">
                  <div className="col-md-12">
                    <div className="table">{selectionRowsData}</div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className="row mx-0 mt-3">
                <div className="col-md-12 text-center">
                  <div className="jumbotron m-0">
                    <div className="container">
                      <h1 className="display-4">
                        <i className="fa fa-inbox"></i>
                      </h1>
                      <h5>No records added yet</h5>
                      <p>Please add rows for mapping.</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : filteredRowsData.length ? (
            <React.Fragment>
              <div className="row mx-0 mt-3">
                <div className="col-md-12">
                  <div className="table">{filteredRowsData}</div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            this.state.filtered.length && (
              <div className="row mx-0 mt-3">
                <div className="col-md-12 text-center">
                  <div className="jumbotron m-0">
                    <div className="container">
                      <h1 className="display-4">
                        <i className="fa fa-inbox"></i>
                      </h1>
                      <h5>No records found for given search</h5>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </Scrollbars>
        <div className="footer">
          <div className="button-container">
            <div className="">
              <button
                className="btn btn-secondary btn-padding"
                onClick={this.handleAddEvent}
                disabled={
                  this.state.tenantSelectionMode === ""
                    ? this.state.tenantSelectionMode === ""
                    : this.state.tenantSelectionMode === "organization"
                      ? this.props.fieldValues?.organizationGroups.length <=
                      selectionRowsData.length ||
                      this.props.fieldValues?.organizationGroups.length ===
                      selectionRowsData.length
                      : this.props.fieldValues?.machineGroups.length <=
                      selectionRowsData.length ||
                      this.props.fieldValues?.machineGroups.length ===
                      selectionRowsData.length
                }
              >
                Add Row
              </button>
            </div>
            <div>
              {
                <button className="btn btn-primary btn-padding" onClick={this.handleSave} disabled={!this.state.isRowUpdated || this.props.fieldValues.finishStepLoading}>
                  Save Configuration
                  {this.props.fieldValues.finishStepLoading ?
                    <div className="spinner-border spinner-border-sm align-spinner" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    : ""}
                </button>
              }
            </div>
          </div>
          <div className="notes">
            <label>
              <strong>Notes:</strong>{" "}
            </label>
            <ul className="align-list">
              <li>
                Tenant information from Sophos Central is automatically fetched via API every 24hrs.
              </li>
              <li>
                Sophos product&apos;s installation will be based on the
                machine&apos;s OS.
              </li>
              <li>
                Ensure that the Kesaya assets is online in order to deploy the
                Sophos products.
              </li>
            </ul>
          </div>
        </div>

      </React.Fragment>
    );
  }
}
export default DeploymentSelectV2;
