/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 04-03-2020 15:07
 * @modify date 20-04-2020 15:52
 * @description This component is modal for confirmation Sophos install repsonse modal.
 */

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ConfirmationInstallSophosResponseModal extends React.Component{

    constructor(props){
        super(props)
        this.state={
        }
    }

    render(){
        let messageString=`<ul class="mb-0">
        <li>Procedure action triggered: <strong>${this.props.selectedAction}</strong></li>
        <li>Action triggered on number of assets successfully: <strong>${this.props.success}</strong></li>
        <li>Action triggered on number of assets failed: <strong>${this.props.fail}</strong></li>
        </ul>
        <label class="m-1">View path in Kaseya for procedure related logs:</label>
        <p class="m-0"><strong>Agent > Agent Logs > [Select Agent] > Agent Admin Logs > Procedure History</strong></p>`
        return(
            <Modal
                // size="sm"
                show={this.props.show}
                onHide={this.props.closeModal}
                centered
                backdrop="static"
            >
                <Modal.Header className="hide-bottom-border-modal-header">
                    <h6 style={{marginTop:'0.5rem',marginBottom:'0'}} dangerouslySetInnerHTML={ { __html:
                        messageString
                    } }></h6>

                </Modal.Header>


                <Modal.Footer className="hide-top-border-modal-footer">
                    <Button className="btn-sm" variant="primary" onClick={this.props.viewLog}>View logs</Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.props.closeModal}>
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ConfirmationInstallSophosResponseModal