/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:58
 * @modify date 06-02-2020 12:58
 * @description This file contains the component of Date range picker textbox
 */
import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';

class DateTimeRangeTextbox extends React.Component {

    constructor(props){
        super(props);
        let now = new Date();
        // let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
        let end = moment(now);
        if(props.isDays){
            this.state = {
                start : moment(now).startOf('day'),
                end : moment(now),
                datepickerTextValue:'',
                dateFromDate: moment(now).startOf('day'),
                dateToDate: moment(now),

            }
        }
        else{
            this.state = {
                start : moment(end).subtract(24, "hours"),
                end : moment(now).subtract(1,"minutes"),
                datepickerTextValue:'',
                dateFromDate:moment(end).subtract(24, "hours"),
                dateToDate:moment(now)
            }
        }
        this.applyCallback = this.applyCallback.bind(this);
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps.clearValue)!==JSON.stringify(this.props.clearValue)){
            // if(this.props.clearValue){

                let now = new Date();
                if(this.props.isDays){
                    this.setState({
                        datepickerTextValue: "Today",
                        start : moment(now).startOf('day'),
                        end : moment(now),
                        dateFromDate: moment(now).startOf('day'),
                        dateToDate: moment(now),
                    },()=>this.props.dateRange({
                        fromDate: this.state.start,
                        toDate: this.state.end,
                        startInterval:true,
                        datepickerTextValue: this.state.datepickerTextValue
                    },true))
                }
                else{
                    let end = moment(now);
                    this.setState({
                        datepickerTextValue: "Last 24 hours",
                        start : moment(end).subtract(24, "hours"),
                        end : moment(now),
                        dateToDate:moment(end).subtract(24, "hours"),
                        dateFromDate:moment(now)
                    },()=>this.props.dateRange({
                        fromDate: this.state.start,
                        toDate: this.state.end,
                        startInterval:true
                    }))
                }
                
            // }
        }
        else{
            if(JSON.stringify(this.props.updateRangeAtInterval)!==JSON.stringify(prevProps.updateRangeAtInterval)){
                console.log("PROPS CHANGE FROM INTERVAL")
                let now = new Date();
                let end = moment(now);
                if(this.props.isDays){
                    if(this.state.datepickerTextValue==="Today")
                    {
                        this.setState({
                            start : moment(end).startOf('day'),
                            end : moment(now),
                        },()=>this.props.dateRange({
                            fromDate: this.state.start,
                            toDate: this.state.end,
                            startInterval:true
                        },true))
                    }
                    if(this.state.datepickerTextValue==="This Week")
                    {
                        this.setState({
                            start : moment(end).startOf("isoWeek"),
                            end : moment(now),
                        },()=>this.props.dateRange({
                            fromDate: this.state.start,
                            toDate: this.state.end,
                            startInterval:true
                        },true))
                    }
                    if(this.state.datepickerTextValue==="This Month")
                    {
                        this.setState({
                            start : moment(end).startOf('month'),
                            end : moment(now),
                        },()=>this.props.dateRange({
                            fromDate: this.state.start,
                            toDate: this.state.end,
                            startInterval:true
                        },true))
                    }
                }else{
                    if(this.state.datepickerTextValue==="Last 24 hours")
                    {
                        this.setState({
                            start : moment(end).subtract(24, "hours"),
                            end : moment(now),
                        },()=>this.props.dateRange({
                            fromDate: this.state.start,
                            toDate: this.state.end,
                            startInterval:true
                        },true))
                    }
                    if(this.state.datepickerTextValue==="Last 1 hour")
                    {
                        this.setState({
                            start : moment(end).subtract(1,"hour"),
                            end : moment(now),
                        },()=>this.props.dateRange({
                            fromDate: this.state.start,
                            toDate: this.state.end,
                            startInterval:true
                        },true))
                    }
                    if(this.state.datepickerTextValue==="Last 7 days"){
                        this.setState({
                            start : moment(end).subtract(7, 'days'),
                            end : moment(now),
                        },()=>this.props.dateRange({
                            fromDate: this.state.start,
                            toDate: this.state.end,
                            startInterval:true
                        },true))
                    }
                    if(this.state.datepickerTextValue==="Last 30 days"){
                        this.setState({
                            start : moment(end).subtract(30, 'days'),
                            end : moment(now),
                        },()=>this.props.dateRange({
                            fromDate: this.state.start,
                            toDate: this.state.end,
                            startInterval:true
                        },true))
                    }
                }
            }
        }
    }

    componentDidMount(){
        if(this.props.rangeFromDashboard!==undefined){
            // let now = new Date();
            console.log(this.props.rangeFromDashboard.range,"RANGE output")
            if(this.props.rangeFromDashboard.typeRange==="Last 1 hour"){
                let startMinutes=moment(this.props.rangeFromDashboard.range,"DD-MMM-YYYY, HH:mm")
                let endMinutes= moment(startMinutes).add((60/12) - 1,"minutes")
                this.setState({
                    start : startMinutes,
                    end : endMinutes,
                    datepickerTextValue: startMinutes.format("DD-MMM-YYYY, HH:mm")+" - "+endMinutes.format("DD-MMM-YYYY, HH:mm")
                },()=>this.props.dateRange({
                    fromDate: this.state.start,
                    toDate: this.state.end,
                    startInterval:false
                }))
            }
            if(this.props.rangeFromDashboard.typeRange==="Last 24 hours"){
                let startHour=moment(this.props.rangeFromDashboard.range,"DD-MMM-YYYY, HH:mm")
                let endHour=moment(startHour).add(1,"hour").subtract(1, 'minutes')
                this.setState({
                    start : startHour,
                    end : endHour,
                    datepickerTextValue: startHour.format("DD-MMM-YYYY, HH:mm")+" - "+endHour.format("DD-MMM-YYYY, HH:mm")
                },()=>this.props.dateRange({
                    fromDate: this.state.start,
                    toDate: this.state.end,
                    startInterval:false
                }))
            }
            if(this.props.rangeFromDashboard.typeRange==="Last 30 days" || this.props.rangeFromDashboard.typeRange==="Last 7 days"){
                let startDayHourMinutes=moment(this.props.rangeFromDashboard.range,"DD-MMM-YYYY").startOf('day')
                let endDayHourMinutes=moment(this.props.rangeFromDashboard.range,"DD-MMM-YYYY").endOf('day')
                this.setState({
                    start : startDayHourMinutes,
                    end : endDayHourMinutes,
                    datepickerTextValue: startDayHourMinutes.format("DD-MMM-YYYY, HH:mm")+" - "+endDayHourMinutes.format("DD-MMM-YYYY, HH:mm")
                },()=>this.props.dateRange({
                    fromDate: this.state.start,
                    toDate: this.state.end,
                    startInterval:false
                }))
            }
        }
        else{
            if(this.props.isDays){
                this.setState({
                    datepickerTextValue: "Today"
                },()=>this.props.dateRange({
                    fromDate: this.state.start,
                    toDate: this.state.end,
                    startInterval:true,
                    datepickerTextValue: this.state.datepickerTextValue
                },true))
            }
            else{
                this.setState({
                    datepickerTextValue:"Last 24 hours"
                },()=>this.props.dateRange({
                    fromDate:this.state.start,
                    toDate:this.state.end,
                    startInterval:true
                },true))
                
            }

        }
    }

    // UNSAFE_componentWillReceiveProps(nextProps){
    //     if(JSON.stringify(nextProps!==JSON.stringify(this.props))){
    //       if(nextProps.clearValue){
    //         this.setState({
    //             datepickerTextValue:"",
    //             dateToDate:null,
    //             dateFromDate:null
    //         })
    //       }
    //     }
    //   }

    applyCallback(startDate, endDate){
        this.setState({
                start: startDate,
                end : endDate
            }
        )
    }

    onApplyRange=(event,picker)=>{
        console.log(picker.startDate.toISOString(), "Apply Date Range")
        console.log(picker, "Apply Date Range")
        let dateLabel=picker.chosenLabel!=="Custom Range"?picker.chosenLabel:picker.startDate.format("DD-MMM-YYYY, HH:mm")+" - "+picker.endDate.format("DD-MMM-YYYY, HH:mm")
        let intervalBool=picker.chosenLabel!=="Custom Range"
        this.setState({
            dateFromDate: picker.startDate,
            dateToDate: picker.endDate,     
            datepickerTextValue: dateLabel
        },()=>{
            this.props.dateRange({
                fromDate:this.state.dateFromDate,
                toDate:this.state.dateToDate, 
                startInterval:intervalBool,
                datepickerTextValue: this.state.datepickerTextValue
            })
        })

    }

    clearRangeValue=()=>{
        this.setState({
            datepickerTextValue:"",
            dateToDate:null,
            dateFromDate:null
        },()=>{
            this.props.dateRange({fromDate:this.state.dateFromDate,toDate:this.state.dateToDate})
        })
    }

    render(){
            let now = new Date();
            // let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
            let end = moment(now)
            // console.log("SUBTRACT 1",moment(new Date()).subtract(1,"days").format("YYYY MM DD"))
            let ranges = {
                "Last 24 hours":[moment(end).subtract(24, "hours"),moment(end).subtract(1,"minutes")],
                "Last 1 hour":[moment(end).subtract(1,"hour"),moment(end).subtract(1,"minutes")],
                // "Today": [moment(end).startOf('day'), moment(end)],
                // "This Week":[moment(end).startOf("isoWeek"),moment(end)],
                // "Yesterday": [moment(start).subtract(1, "day"), moment(end).subtract(1, "day").endOf('day')],
                // "Last 3 Days": [moment(end).subtract(3, "days"), moment(end)],
                'Last 7 days': [moment(end).startOf('day').subtract(7 - 1, 'days'), moment(end)], // relative last 7 days from now exclude time
                'Last 30 days': [moment(end).startOf('day').subtract(30 - 1, 'days'), moment(end)], // relative last 30 days from now exclude time
                // 'This Month': [moment(end).startOf('month'), moment(end)],
                // 'Last Month': [moment(end).subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
            if(this.props.isDays){
                ranges = {
                    "Today": [moment(end).startOf('day'), moment(end)],
                    "This Week":[moment(end).startOf("isoWeek"),moment(end)],
                    'This Month': [moment(end).startOf('month'), moment(end)],
                }
            }
            let local = {
                "format":"DD-MMM-YYYY HH:mm",
                "sundayFirst" : true,
            }
            let maxDate = moment(end)
            return(
                <React.Fragment>
                    <DateRangePicker 
                        // containerClass={''}
                        containerStyles={{display: "block"}}
                        ranges={ranges}
                        startDate={this.state.start}
                        endDate={this.state.end}
                        // local={local}
                        maxDate={maxDate}
                        // applyCallback={this.applyCallback}
                        onApply={this.onApplyRange}
                        timePicker
                        timePicker24Hour
                        showDropdowns
                        locale={local}
                    >
                        {/* <div class="input-group"> */}
                            <input type="text" className="form-control" placeholder="Select Date Range" value={this.state.datepickerTextValue} disabled={this.props.disabledBox}/>
                        {/* </div> */}
                    </DateRangePicker>
                    {/* { this.state.datepickerTextValue!=='' && this.state.dateFromDate!==null && this.state.dateToDate!==null?
                        <button className="btn bg-transparent" style={{marginTop:"-34px",marginBottom:"-35px", zIndex: "100", float:"right"}} onClick={this.clearRangeValue}>
                            <i className="fa fa-times"></i>
                        </button>
                    :''} */}

                </React.Fragment>
            );
        }
}

export default DateTimeRangeTextbox