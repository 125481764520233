/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 04-03-2020 15:07
 * @modify date 04-03-2020 15:07
 * @description This component is modal for displaying error.
 */

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ConfirmationAuditLogModal extends React.Component{

    constructor(props){
        super(props)
        this.state={
        }
    }


    render(){
        let messageString=`<ul class="mb-0">
        <li>Action Performed: <strong>${this.props.selectedAction}</strong></li>
        <li>Number of succeeded action: <strong>${this.props.success}</strong> </li>
        <li>Number of failed action: <strong>${this.props.fail}</strong> </li>
        </ul>`
        return(
            <Modal
                // size="sm"
                show={this.props.show}
                onHide={this.props.closeModal}
                centered
                backdrop="static"
            >
                <Modal.Header className="hide-bottom-border-modal-header">
                    <h6 style={{marginTop:'0.5rem',marginBottom:'0'}} dangerouslySetInnerHTML={ { __html:
                        messageString
                    } }></h6>

                </Modal.Header>

                {(this.props.selectedAction!=="Tamper protection enable" && this.props.selectedAction!=="Tamper protection disable") ?
                <div className="px-3 mx-3">
                    <label><strong>Note: </strong>It could take up to 1 hour for actions to be reflected within this application.</label>
                </div> : ''}

                {(this.props.selectedAction==="Isolate Endpoint(s)" || this.props.selectedAction==="DeIsolate Endpoint(s)") ?
                <div className="px-3 mx-3">
                    <label><strong>Note: </strong>All endpoints are not eligible for Isolate/DeIsolate action</label>
                </div> : ''}

                <Modal.Footer className="hide-top-border-modal-footer">
                    <Button className="btn-sm" variant="primary" onClick={this.props.viewLog}>View logs</Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.props.closeModal}>
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ConfirmationAuditLogModal