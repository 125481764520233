/**
 * @author Vaibhav Chaudhary
 * @email vaibhav.chaudhary@sophos.com
 * @create date 1-05-2024 13:07
 * @description This component is the new version of the deployment settings page.
 */
import React from "react";
import queryString from 'query-string';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { setInitStorage } from '../common/InitLocalStore';
import { CommonLayout } from '../common/CommonLayout';
import { ErrorModal } from '../common/ErrorModal';
import ConfirmationModal from '../common/ConfirmationModal';
import { MAC_PRODUCTS_OPTIONS, WINDOWS_DESKTOP_PRODUCTS_OPTIONS, WINDOWS_SERVER_PRODUCTS_OPTIONS } from "../common/Constants";
import { sendDeploymentSettings } from "../../services/apiOperations";
import DeploymentSelectV2 from '../deploymentSelectV2/DeploymentSelectV2';
import { ROW_TYPE } from '../deploymentSettings/DeploymentSelectionRow';
import './deploymentSettingsV2.scss'
import TenantAccessError from '../multiTenancy/TenantAccessError';
import { checkDeploymentExist } from "../../services/apiOperations";
import Modal from 'react-bootstrap/Modal';
const mapStateToProps = state => ({
    ...state
})

class DeploymentSettingsV2 extends React.Component {
    constructor(props) {
        super(props);
        setInitStorage(props.referrerHostname, queryString.parse(props.location.search))
        this.state = {
            formData: null,
            fileUploadSuccess: false,
            skipThirdStep: false,
            showAskConfirmationModal: false,
            confirmationMessage: "",
            validationMessage: "",
            getDeploymentDataLoading: "",
            showValidationAlert: false,
            finishStepLoading: false,
            finishMessage: "",
            showGuide: false,
            isRowUpdated: false
        }
    }

    componentDidMount() {
        this.fetchDeploymentData();
        const newValue = {
            "label": "Automatic (license based)",
            "value": "all"
        }
        MAC_PRODUCTS_OPTIONS.splice(MAC_PRODUCTS_OPTIONS.length - 1, 0, newValue);
        WINDOWS_DESKTOP_PRODUCTS_OPTIONS.splice(WINDOWS_DESKTOP_PRODUCTS_OPTIONS.length - 1, 0, newValue);
        WINDOWS_SERVER_PRODUCTS_OPTIONS.splice(WINDOWS_SERVER_PRODUCTS_OPTIONS.length - 1, 0, newValue);
    }

    fetchDeploymentData = () => {
        this.setState({}, () => checkDeploymentExist(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search)
            .then((res) => {
                let machineGroupsNameArray = []
                if (Array.isArray(res.data.response.machineGroups)) {
                    res.data.response.machineGroups.forEach((elementMachine) => {
                        machineGroupsNameArray.push({ "value": elementMachine.id, "label": elementMachine.name, "disabled": false })
                    })
                }

                let organizationNameArray = []
                if (Array.isArray(res.data.response.orgs)) {
                    res.data.response.orgs.forEach((elementOrg) => {
                        organizationNameArray.push({ "value": elementOrg.id, "label": elementOrg.name, "disabled": false })
                    })
                }

                let tenantListArray = []
                if (Array.isArray(res.data.response.tenants)) {
                    res.data.response.tenants.forEach((elementTenant) => {
                        tenantListArray.push({ "value": elementTenant.tenantId, "label": elementTenant.tenantName })
                    })
                }
                this.setState({
                    machineGroups: machineGroupsNameArray,
                    organizationGroups: organizationNameArray,
                    tenantList: tenantListArray,
                }, () => {
                    this.saveValues(this.state)
                })
            })
            .catch((err) => {
                let errMessage = ""
                if (err.response) {
                    if (err.response.status !== 404) {
                        if (err.response) {
                            if (err.response.data.message !== undefined)
                                errMessage = err.response.data.message
                            else
                                errMessage = "Something went wrong; Please try again later."

                        }
                        else {
                            errMessage = "Something went wrong; Please try again later."
                        }
                        this.setState({
                            showValidationAlert: true,
                            validationMessage: errMessage
                        })
                    }
                    else {
                        this.setState({})
                    }
                }
                else {
                    errMessage = "Something went wrong; Please try again later."
                    this.setState({
                        showValidationAlert: true,
                        validationMessage: errMessage
                    })
                }
            })
        )
    }

    saveValues = (values) => {
        let currentFormData = {}
        if (this.state.formData !== null) {
            currentFormData = this.state.formData
        }
        let valuesKeys = Object.keys(values)
        valuesKeys.forEach((key) => {
            currentFormData[key] = values[key]
        })
        this.setState({
            formData: currentFormData
        })
    }

    markFileUploadSucces = () => {
        this.setState({
            fileUploadSuccess: true
        })
    }

    askResetConfirmation = (nextTenantSelectionMode) => {
        if (this.state.formData !== null) {
            if (this.state.formData.selectionData && this.state.formData.tenantSelectionMode !== "") {
                this.setState({
                    showAskConfirmationModal: true,
                    skipThirdStep: true,
                    nextTenantSelectionMode: nextTenantSelectionMode,
                    confirmationMessage: "The data of current step will be deleted. You sure want to change the tenant selection mode?"
                })
            }
        }
    }

    closeConfirmationModal = () => {
        this.setState({
            skipThirdStep: false,
            showAskConfirmationModal: false
        })
    }

    confirmResetAction = () => {
        let currentFormData = this.state.formData
        currentFormData.selectionData = []
        currentFormData.tenantSelectionMode = this.state.nextTenantSelectionMode;
        this.setState({}, () => {
            this.setState({
                formData: { ...currentFormData },
                showAskConfirmationModal: false,
            })
        })
    }

    prepareRequestBody = () => {
        let requestBody = {}
        if (this.state.formData.selectionData) {
            if (this.state.formData.selectionData.length > 0) {
                requestBody.selectionData = [...this.state.formData.selectionData]
            }
        }
        requestBody.tenantSelectionMode = this.state.formData.tenantSelectionMode
        return requestBody
    }

    finishStep = () => {
        let validatedData = true
        if (this.state.formData !== null) {
            if (this.state.formData.selectionData) {
                if (this.state.formData.selectionData.length > 0) {
                    this.state.formData.selectionData.forEach((selectionRowData) => {
                        if (selectionRowData[ROW_TYPE.MachineGroupOrganization] === null) {
                            selectionRowData.validRow = false
                            validatedData = false
                        }
                        if (selectionRowData[ROW_TYPE.Tenant] === null) {
                            selectionRowData.validRow = false
                            validatedData = false
                        }
                    })
                }
            }
            if (validatedData) {
                let requestBody = this.prepareRequestBody()
                let currentFormData = this.state.formData
                currentFormData.finishStepLoading = true;
                this.setState({
                    formData: { ...currentFormData },
                })
                sendDeploymentSettings(requestBody, this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search)
                    .then(() => {
                        let currentFormData = this.state.formData
                        currentFormData.finishStepLoading = false;
                        currentFormData.isRowUpdated = false;
                        this.setState({
                            formData: { ...currentFormData },
                            finishMessage: "Deployment Settings updated.",
                        }, () => {
                            this.showSuccessToast()
                        })
                    })
                    .catch((err) => {
                        let errMessage = ""
                        if (err.response) {
                            if (err.response.data.message !== undefined)
                                errMessage = err.response.data.message
                            else
                                errMessage = "Something went wrong; Please try again later."

                        }
                        else {
                            errMessage = "Something went wrong; Please try again later."
                        }
                        let currentFormData = this.state.formData;
                        currentFormData.finishStepLoading = false;
                        currentFormData.isRowUpdated = true;
                        this.setState({
                            formData: { ...currentFormData },
                            finishMessage: errMessage
                        }, () => this.showErrorToast())
                    })
            }
            else {
                this.setState({
                    showValidationAlert: true,
                    validationMessage: `<ul><li>Please fill the mandatory details.</li></ul>`
                })
            }
        }
    }

    clearMessageToast = () => {
        this.setState({
            finishMessage: ""
        })
    }

    showSuccessToast = () => {
        toast.success(this.state.finishMessage, { containerId: 'A', onClose: () => this.clearMessageToast() });
    }

    showErrorToast = () => {
        toast.error(this.state.finishMessage, { containerId: 'A', onClose: () => this.clearMessageToast() });
    }

    closeValidationModal = () => {
        this.setState({
            showValidationAlert: false,
            validationMessage: ""
        })
    }
    openGuide = () => {
        this.setState({
            showGuide: true,
            deploymentMessage: ""
        });
    }
    closeMessageModal = () => {
        this.setState({
            showGuide: false,
            deploymentMessage: ""
        });
    }

    render() {
        return (
            this.props.settings.SophosIdTypeIsTenant ?
                <TenantAccessError />
                : <CommonLayout title="Deployment Settings" displayTitle={true}>
                    <ConfirmationModal
                        show={this.state.showAskConfirmationModal}
                        confirmAction={() => this.confirmResetAction()}
                        closeModal={this.closeConfirmationModal}
                        modalMessage={this.state.confirmationMessage}
                    />
                    <ErrorModal
                        show={this.state.showValidationAlert}
                        closeModal={this.closeValidationModal}
                        modalMessage={this.state.validationMessage}
                    />
                    <Modal show={this.state.showGuide} onHide={this.closeMessageModal} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Auto Deployment Guide</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="deploymentGuide-body">
                                <p>Auto deployment lets you install Sophos® Endpoint agents on Kaseya Assets. You can also install them manually.</p>
                                <p><b> Auto deployment</b></p>
                                <ol>
                                    <li>Map Kaseya Machine groups and Organizations to Sophos tenants. </li>
                                    <li>Select Sophos Endpoint products to install on them.</li>
                                </ol>
                                <p>The <b>Assets</b> page shows Kaseya Assets and the status of the Sophos Endpoint agents installed. </p>
                                <p>Deployment works like this: </p>
                                <ul>
                                    <li> Sophos Endpoint agents are installed or updated on Kaseya Assets with matching hostnames. </li>
                                    <li> For both auto and manual deployments, Kaseya Procedures are used to install Sophos Endpoint agents.</li>
                                    <li>Tenants are automatically fetched via Sophos Central APIs, and you no longer need to upload CSV files.</li>
                                </ul>
                                <p><b>Manual deployment</b></p>
                                <ol>
                                    <li>On the <b>Assets</b> page, select assets. </li>
                                    <li>Click <b>Install Sophos &gt; Submit</b>, and then choose a tenant and products to install.</li>
                                </ol>

                                <p> <b>Troubleshooting &amp; Logging:</b></p>
                                <ul>
                                    <li>Check Audit logs under Sophos Security Solutions &gt; Logs &gt; Audit Logs &gt; Deployment. </li>
                                    <li>Kaseya deployment procedure logs are under: Agent &gt; Agents &gt; Agent Logs &gt; [agent name] &gt; Agent Admin Logs &gt; Procedure History. </li>
                                    <li>Sophos Central installation logs are created when the installation process is complete, which takes up to 45 minutes. The logs are under Agent Procedure &gt; File Transfer &gt; Get File &gt; [agent name] &gt; SophosCentralInstall.log. </li>
                                </ul>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-secondary btn-sm"
                                onClick={this.closeMessageModal}>
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <ToastContainer
                        position="top-right"
                        autoClose={7000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        draggable={false}
                        pauseOnHover={false}
                        enableMultiContainer
                        containerId={'A'}
                    />
                    <div className="row m-1 pt-3">
                        <div className="col">
                            <div >
                                {this.state.formData && (
                                    <DeploymentSelectV2 {...this.props} fieldValues={this.state.formData}
                                        skipCurrentStep={this.state.skipThirdStep}
                                        saveValues={(values) => this.saveValues(values)}
                                        macProductsOptions={MAC_PRODUCTS_OPTIONS}
                                        windowsDesktopProductsOptions={WINDOWS_DESKTOP_PRODUCTS_OPTIONS}
                                        windowsServerProductsOptions={WINDOWS_SERVER_PRODUCTS_OPTIONS}
                                        askResetConfirmation={(nextTenantSelectionMode) => this.askResetConfirmation(nextTenantSelectionMode)}
                                        finishStep={() => this.finishStep()}
                                        openGuide={() => this.openGuide()} />
                                )}
                            </div>
                        </div>
                    </div>
                </CommonLayout>
        )
    }
}

export default connect(mapStateToProps, null)(DeploymentSettingsV2)