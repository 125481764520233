/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 13:04
 * @modify date 06-02-2020 13:04
 * @description This file contains the component TabeGrid which creates table having pagination, columnToggle and Search
 */
import React from 'react';
import PropTypes from 'prop-types';
// import { Typeahead } from "react-bootstrap-typeahead";
import BootstrapTable from 'react-bootstrap-table-next';
import Spinner from "react-bootstrap/Spinner";
import paginationFactory,{PaginationProvider,PaginationTotalStandalone,PaginationListStandalone} from 'react-bootstrap-table2-paginator';
// import overlayFactory from 'react-bootstrap-table2-overlay';
import {NoTableData} from './NoTableData';
import ToolkitProvider,{
  // Search
} from 'react-bootstrap-table2-toolkit';
import MultiSelectCheckboxDropdown from './MultiSelectCheckboxDropdown';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

if(process.env.REACT_APP_HOST_ENV==="production"){
  console.log=function(){}
}

/**
 * @author Kashyap Ashara
 * @description This component is created using react-bootstrap-table-next and its other packages to develop pagination and column toggle functionality
 */

class TableGrid extends React.Component{

    constructor(props) {
      super(props);
      this.state = {
        data: props.responseData?props.responseData:null,
        pages: null,
        rowCount:props.responseData?props.responseData.length:0,
        pageSize:props.pageSize?props.pageSize:10,
        page:props.stateObject?props.stateObject.current_page:null,
        selectAll: false,
        searchValue:"",
        selection: [],
        pageWiseSelection:[],     
        paginationOption:{
          custom:true,
          hideSizePerPage:true,
        },
        toggleColumnSelection:false
      };
      this.tableElement=null
    }

    componentDidUpdate(prevProps){
      if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
        if(JSON.stringify(this.state.data)!==JSON.stringify(this.props.responseData)){
          const isRowSelected = this.props.keepSelectedRow && this.props.keepSelectedRow.length && this.props.keepSelectedRow[0].selectedRow.length;
          this.setState({
            data: this.props.responseData?this.props.responseData:null,
            pageSize: this.props.pageSize?this.props.pageSize:10,
            page:1,
            pageWiseSelection: this.props.keepSelectedRow ? this.props.keepSelectedRow : this.state.pageWiseSelection, 
            selection: isRowSelected ? this.state.selection : []
          },()=>{
            if(this.props.pageWiseSelectedRowsFromTable)
              this.props.pageWiseSelectedRowsFromTable(1,this.state.pageWiseSelection)
            if(this.props.singleSelectedRow){
              this.props.singleSelectedRow('')
            }
          })
        }
        if(JSON.stringify(this.state.data)!==JSON.stringify(this.props.responseData) && this.state.searchValue===""){
          this.setState({
            rowCount: this.props.responseData?this.props.responseData.length:0,
          })
        }
        if(JSON.stringify(this.props.clearValue) !== JSON.stringify(prevProps.clearValue)){
          this.setState({
            searchValue:""
          },()=>{
            if(this.props.singleSelectedRow){
              this.props.singleSelectedRow('')
            }
          })
        }
        if(JSON.stringify(this.props.clearSearchBox)!== JSON.stringify(prevProps.clearSearchBox)){
          this.setState({
            searchValue:""
          },()=>{
            if(this.props.singleSelectedRow){
              this.props.singleSelectedRow('')
            }
          })
        }
        if (
          JSON.stringify(this.props.onSortChange) !==
            JSON.stringify(prevProps.onSortChange) ||
          ("onPerformAction" in this.props &&
            "onPerformAction" in prevProps &&
            this.props.onPerformAction === prevProps.onPerformAction)
        ) {
          this.setState(
            {
              searchValue: "",
              pageWiseSelection: [],
              selection: [],
            },
            () => {
              if (this.props.pageWiseSelectedRowsFromTable)
                this.props.pageWiseSelectedRowsFromTable(
                  this.state.page,
                  this.state.pageWiseSelection
                );
              if (this.props.singleSelectedRow) {
                this.props.singleSelectedRow("");
              }
            }
          );
        }
        if(JSON.stringify(this.props.nonSelectableRows)!==JSON.stringify(prevProps.nonSelectableRows)){
          this.setState({  
            searchValue:"",          
            pageWiseSelection:[],
            selection:[],
          },()=>{
            if(this.props.pageWiseSelectedRowsFromTable)
              this.props.pageWiseSelectedRowsFromTable(this.state.page,this.state.pageWiseSelection)
            if(this.props.singleSelectedRow){
              this.props.singleSelectedRow('')
            }
          })
        }
      }
    }

    shouldComponentUpdate(nextProps,nextState){
      if(JSON.stringify(this.state.data)!==JSON.stringify(nextProps.responseData) || this.props.loading!==nextProps.loading){
        return true
      }
      if(JSON.stringify(this.props.nonSelectableRows)!==JSON.stringify(nextProps.nonSelectableRows)){
        return true
      }
      if(JSON.stringify(this.props.clearSearchBox)!==JSON.stringify(nextProps.clearSearchBox)){
        return true
      }
      if(JSON.stringify(this.props.onSortChange)!== JSON.stringify(nextProps.onSortChange)){
        return true
      }
      if(this.state.searchValue!==nextState.searchValue){
        return true
      }
      if(this.state.toggleColumnSelection!==nextState.toggleColumnSelection && this.state.selection.length>0){
        return true
      }
      if(JSON.stringify(this.state.selection)!== JSON.stringify(nextState.selection)){
        return true
      }
      if(JSON.stringify(this.state.page)!== JSON.stringify(nextState.page)){
        return true
      }
      return false
    }

    // UNSAFE_componentWillReceiveProps(nextProps){
    //   if(JSON.stringify(nextProps)!==JSON.stringify(this.props)){
    //     this.setState({
    //       data: nextProps.responseData?nextProps.responseData:null,
    //       pageSize:nextProps.pageSize,
    //       page:nextProps.stateObject?nextProps.stateObject.current_page:null,
    //       loading:nextProps.loading
    //     })
    //   }
    // }

    handlePageOnChange=(page)=>{
      this.setState({
        pageWiseSelection: [],
        selection: [],
        page:page
      },()=>{
        if(this.props.onPageChangeEvent)
          this.props.onPageChangeEvent(page)
        if(this.props.pageWiseSelectedRowsFromTable)
          this.props.pageWiseSelectedRowsFromTable(page,this.state.pageWiseSelection)
        if(this.props.singleSelectedRow)
            this.props.singleSelectedRow('')
      })
    }

    columnToggleCheck=()=>{
      this.setState({
        toggleColumnSelection: !this.state.toggleColumnSelection
      })
    }

    handleColumnToggleChange=(selectedOptions,toggles,onColumnToggle)=>{
      let excludedColumn=[];
      let includedColumn=[];
      let storeIncludedColumn=[];
      Object.keys(toggles).forEach((propsElement)=>{
        let availabelColumn=selectedOptions.find((e)=>e.dataField===propsElement)
        if(availabelColumn===undefined){
          excludedColumn.push(propsElement)
        }
        else{
          storeIncludedColumn.push(availabelColumn)
          includedColumn.push(propsElement)
        }
      })
      excludedColumn.forEach(e=>{
        if(toggles[e])
          onColumnToggle(e)
      })
      localStorage.setItem(this.props.moduleName+"_selectedColumns",JSON.stringify(storeIncludedColumn))
      includedColumn.forEach(e=>{
        if(!toggles[e])
          onColumnToggle(e)
      })
    }

    selectedColumnsList(columnsList){
      if(localStorage.getItem(this.props.moduleName+"_selectedColumns")){
        let selectedColumns=JSON.parse(localStorage.getItem(this.props.moduleName+"_selectedColumns"))
        let finalSelectedColumnLocalSaved=[];
        columnsList.forEach((column)=>{
          let availabelColumn=selectedColumns.find((e)=>e.dataField===column.dataField)
          if(availabelColumn!==undefined){
            finalSelectedColumnLocalSaved.push(column)
          }
        })
        return finalSelectedColumnLocalSaved
      }
      else{
        return columnsList
      }
    }

    handleDataChange=({dataSize})=>{
      const isRowSelected = this.props.keepSelectedRow && this.props.keepSelectedRow.length && this.props.keepSelectedRow[0].selectedRow.length;
      this.setState(
        {
          page: 1,
          rowCount: dataSize,
          selection: isRowSelected ? this.state.selection : [],
          pageWiseSelection: this.props.keepSelectedRow ? this.props.keepSelectedRow : this.state.pageWiseSelection,
        },
        () => {
          if (this.props.pageWiseSelectedRowsFromTable)
            this.props.pageWiseSelectedRowsFromTable(
              1,
              this.state.pageWiseSelection
            );
          if (this.props.singleSelectedRow) this.props.singleSelectedRow("");
        }
      );
    }

    handleOnSingleSelect=(row, isSelect) => {
      let pageWiseSelection=[...this.state.pageWiseSelection]
      let selectedIds=[...this.state.selection]
      
      if(isSelect){
        let pageData=pageWiseSelection.find((element)=>element.pageNumber===this.tableNode.paginationContext.currPage)!==undefined?
          pageWiseSelection.find((element)=>element.pageNumber===this.tableNode.paginationContext.currPage).selectedRow:[]
        if(pageData===undefined){
          pageData=[]
          pageData.push(row)
        }else{              
          pageData.push(row)
        }
        if(pageWiseSelection.find((element)=>element.pageNumber===this.tableNode.paginationContext.currPage)===undefined){
          pageWiseSelection.push({pageNumber:this.tableNode.paginationContext.currPage,selectedRow:pageData})
        }
        else{
          pageWiseSelection.find((element)=>element.pageNumber===this.tableNode.paginationContext.currPage).selectedRow=pageData
        }
        selectedIds.push(row[this.props.keyField])
      }
      else{
        let pageData=pageWiseSelection.find((element)=>element.pageNumber===this.tableNode.paginationContext.currPage).selectedRow
        pageData.splice(pageData.indexOf(row),1)
        pageWiseSelection.find((element)=>element.pageNumber===this.tableNode.paginationContext.currPage).selectedRow=pageData
        selectedIds.splice(selectedIds.indexOf(row[this.props.keyField]),1)
      }

      this.setState({
        pageWiseSelection:pageWiseSelection,
        selection:selectedIds
      },()=>{
        if(this.props.pageWiseSelectedRowsFromTable)
          this.props.pageWiseSelectedRowsFromTable(this.tableNode.paginationContext.currPage,this.state.pageWiseSelection)
      })
    }

    handleOnSelectAll=(isSelect, rows, e) => {
      let selectedIds=rows.map(r => r[this.props.keyField])
      if(isSelect){
        let selectedRowWithPage={pageNumber:this.tableNode.paginationContext.currPage,selectedRow:rows}
        let pageWiseSelection=[]
        pageWiseSelection.push(selectedRowWithPage)
        this.setState({
          pageWiseSelection:pageWiseSelection,
          selection:selectedIds
        },()=>{
          if(this.props.pageWiseSelectedRowsFromTable)
            this.props.pageWiseSelectedRowsFromTable(this.tableNode.paginationContext.currPage,this.state.pageWiseSelection)
          // if(this.props.singleSelectedRow)
          //   this.props.singleSelectedRow('')
        })
      }
      else{
        let pageWiseSelection=[]
        this.setState({
          pageWiseSelection: pageWiseSelection,
          selection:[]
        },()=>{
          if(this.props.pageWiseSelectedRowsFromTable)
            this.props.pageWiseSelectedRowsFromTable(this.tableNode.paginationContext.currPage,this.state.pageWiseSelection)
          // if(this.props.singleSelectedRow)
          //   this.props.singleSelectedRow('')
        })
      }
      console.log(e,"onSelectAllRow");
    }

    CustomSearch = (props) => {
      let input;
      props.onSearch(this.state.searchValue)
      if(this.state.searchValue === ""){
        this.showButton = false
      }

      const handleChange = () => {
        if(this.state.searchValue === ""){
          this.showButton = false
        }else{
          this.showButton = true
        }
        this.setState({searchValue: input.value,selection:[],pageWiseSelection:[]},()=>{
          if(this.props.pageWiseSelectedRowsFromTable)
            this.props.pageWiseSelectedRowsFromTable(1,this.state.pageWiseSelection)
          if(this.props.singleSelectedRow)
            this.props.singleSelectedRow('')
          this.showButton = true
          props.onSearch(this.state.searchValue)
        })
      };

      const handleClear = () => {
        input.value = ""
        this.showButton = false
        this.setState({searchValue: "",selection:[],pageWiseSelection:[]},()=>{
          if(this.props.pageWiseSelectedRowsFromTable)
            this.props.pageWiseSelectedRowsFromTable(1,this.state.pageWiseSelection)
          if(this.props.singleSelectedRow)
            this.props.singleSelectedRow('')
          props.onClear(this.state.searchValue)
        })
      };

      return (
        <div>
          <input
            className="form-control form-control-sm"
            value={this.state.searchValue}
            ref={ n => input = n }
            type="text"
            onChange={handleChange}
            placeholder="Search"
            style={{paddingRight:'21px'}}
          />
            {this.showButton?<button className="clear-search-icon" onClick={handleClear}><i className="fa fa-times fa-times-thin fa-lg fa-light" style={{"color": "#000"}}></i></button>:null}
           
        </div>
      );
    };

    CustomToggleList = ({
      columns,
      onColumnToggle,
      toggles
    }) => (
      <React.Fragment>
        {localStorage.getItem(this.props.moduleName+"_selectedColumns")!==null?
          <React.Fragment>
              {Object.keys(toggles).forEach((propsElement)=>{
                toggles[propsElement]=false
              })}
              {JSON.parse(localStorage.getItem(this.props.moduleName+"_selectedColumns")).forEach((selectedColumn)=>{
              toggles[selectedColumn.dataField]=true
              })}
          </React.Fragment>
        :null}
        <MultiSelectCheckboxDropdown
          options={columns}
          defaultSelected={this.selectedColumnsList(columns)}
          onChange={selectedOptions => {
            this.handleColumnToggleChange(selectedOptions,toggles,onColumnToggle);
            this.columnToggleCheck();
          }}
        />
        {/* <Typeahead
          emptyLabel={''}
          clearButton
          defaultSelected={this.selectedColumnsList(columns)}
          multiple
          id="dataField"
          labelKey="text"
          onChange={selectedOptions => {
            this.handleColumnToggleChange(selectedOptions,toggles,onColumnToggle);
          }}
          options={columns}
          placeholder="Select..."
        /> */}
      </React.Fragment>
    );

    render(){
      const { data } = this.state;
      const selectMultiRow = {
        mode: 'checkbox',
        // clickToSelect: true,
        // style: { backgroundColor: '#c8e6c9' },
        selected: this.state.selection,
        nonSelectable: this.props.nonSelectableRows?this.props.nonSelectableRows:[],
        onSelect: this.handleOnSingleSelect,
        onSelectAll: this.handleOnSelectAll
      };

      const paginationOption={
        page:this.state.page,
        custom:true,
        hideSizePerPage:true,
        sizePerPage:this.props.pageSize,
        pageSize:this.props.pageSize,
        totalSize:this.state.rowCount,
        alwaysShowAllBtns:true,
        onPageChange:this.handlePageOnChange,
        sizePerPageList:[ {
          text: '25', value: 25
        }, {
          text: '50', value: 50
        }, {
          text: '75', value: 75
        }, {
          text: '100', value: 100
        }, {
          text: '', value: this.props.responseData.length
        } ]
      };

      const rowEvents ={
        onClick: (e, row)=>{
          if(e.target.parentElement.localName==="tr"){
            e.target.parentElement.parentElement.childNodes.forEach((elementChild)=>{
              elementChild.style.backgroundColor=""
            })

            this.tableElement=e.target.parentElement.parentElement

            e.target.parentElement.style.backgroundColor="#c8e6c9"

            this.props.singleSelectedRow(row[this.props.keyField])
          }
        }
      }

      const selectSingleRow = {
        mode: 'radio',
        hideSelectColumn:true,
        clickToSelect: true,
        style: { backgroundColor: '#c8e6c9' },
        onSelect: (row, isSelect, rowIndex, e) => {
              this.props.rowClickEvent(row, e)
                  }
        };

      const nonselectableRows = {
        mode: 'radio',
        hideSelectColumn:true,
        clickToSelect: false,
        };

      // const { SearchBar, ClearSearchButton } = Search;
      if(this.tableElement!==null){
        this.tableElement.childNodes.forEach((elementChild)=>{
          elementChild.style.backgroundColor=""
        })
        if(this.props.singleSelectedRow)
          this.props.singleSelectedRow("")
      }
      return (
        <React.Fragment>
          <PaginationProvider
            pagination={ paginationFactory(paginationOption) }
            keyField={this.props.keyField}
            data={ data }
            columns={ this.props.columnStructure }
          >
          {
            ({
              paginationProps,
              paginationTableProps,
            }) => (
            <ToolkitProvider
              bootstrap4
              keyField={this.props.keyField}
              columnToggle
              data={ data }
              columns={ this.props.columnStructure }
              {...paginationTableProps}
              search
            >
            {
              props =>(
                <React.Fragment>
                  <div className="input-group-append row justify-content-between">
                    <div className="col-4 px-0 ml-1 mr-3 mb-2">
                      <span>Search</span>
                      {this.CustomSearch(props.searchProps)}
                    </div>
                    <div className="col-4 px-0 ml-1 mr-3 mb-2">
                      <span>Columns</span>
                      {console.log()}
                      {this.CustomToggleList(props.columnToggleProps)}
                    </div>
                  </div>
                  <div className="table-wrapper">
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      ref={ n => this.tableNode = n }
                      {...paginationTableProps}
                      {...props.baseProps}
                      // {...this.props}
                      striped
                      condensed
                      onDataSizeChange={ this.handleDataChange }
                      // maxHeight={'200px'}
                      //  overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: '#46464669',height:"375px !important"}) } }) }
                      selectRow={ !this.props.nonClickableRows?this.props.rowClickEvent?selectSingleRow:selectMultiRow:nonselectableRows }
                      // data={}
                      // columns={}
                      // loading={loading} // Display the loading overlay when we need it
                      rowEvents={!this.props.nonClickableRows?this.props.rowClickEvent?{}:rowEvents:{} }
                      defaultSorted={ this.props.defaultSortedCol } 
                      noDataIndication={ ()=>NoTableData(this.props.noDataText) }
                      keyField={this.props.keyField}
                    />
                    <div id="loadingWrapper">
                      {this.props.loading?
                      <div className="loading-wrapper" style={{backgroundColor: '#757574',opacity:"30%"}}>
                        <div className="spinner-loader">
                          <Spinner animation="border" variant="light" />
                        </div>
                      </div>:
                      ''}
                    </div>
                  </div>
                  <div className="row pt-1">
                    <div className="col">
                      <PaginationTotalStandalone
                        dataSize={this.state.rowCount}
                        { ...paginationProps }
                      />
                      {this.props.note !== undefined ?<small id="defaultPageHelp" className="form-text text-muted"><strong>Note: </strong>{this.props.note}</small>:""}
                    </div>
                    <div className="col">
                      {this.props.responseData.length>0?
                      <PaginationListStandalone
                        { ...paginationProps }
                      />
                      :''}
                    </div>
                  </div>
                </React.Fragment>
              )
            }
            </ToolkitProvider>
            )
          }
          </PaginationProvider>
        </React.Fragment> 
      );
    }
}

TableGrid.propTypes = {
  keyField: PropTypes.string,
  moduleName: PropTypes.string,
  defaultSortedCol: PropTypes.array,
  columnStructure: PropTypes.array,
  responseData: PropTypes.array,
  loading: PropTypes.bool,
  stateObject: PropTypes.object,
  pageSize: PropTypes.number,
  filtered: PropTypes.array,
  noDataText: PropTypes.object
}

export default TableGrid