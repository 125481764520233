/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 04-03-2020 15:07
 * @modify date 04-03-2020 15:07
 * @description This component is modal for displaying error.
 */

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import validator from 'validator';

class ConfirmationTenantKeyModal extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            initialToken: this.props.row?this.props.row.tenantToken:null,
            tenantToken: this.props.row?this.props.row.tenantToken:null,
            tenantTokenValidation: ''
        }

    }

    onChangeCustomerKey = (e)=>{
        if(validator.isUUID(e.target.value)){
            this.setState({
                tenantToken: e.target.value,
                tenantTokenValidation:''
            })
        }
        else{
            this.setState({
                tenantToken: e.target.value,
                tenantTokenValidation:'Customer Key must be in GUID format'
            })
        }
    }

    validateRequest = () =>{
        if(this.state.initialToken === this.state.tenantToken){
            this.props.closeModal()
            return
        }
        if(this.state.tenantToken === "")
        {
            this.setState({
                tenantTokenValidation: "Customer Key is required"
            })
            return
        }
        if(validator.isUUID(this.state.tenantToken)){
            this.props.confirmAction(this.props.row.id, this.state.tenantToken)
        }
        else{
            this.setState({
                tenantTokenValidation: "Customer Key must be in GUID format"
            })
        }
    }

    render(){
        return(
            <Modal
                // size="sm"
                show={this.props.show}
                onHide={this.props.closeModal}
                centered
                backdrop="static"
            >
                <Modal.Header>
                <h6 style={{marginTop:'0.5rem',marginBottom:'0'}}>Edit Tenant Details</h6>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="tenant_id">Tenant Id</label>
                        <input
                            type="text"
                            id="tenant_id"
                            className="form-control"
                            value={this.props.row?this.props.row.id:null}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="tenant_name">Tenant Name</label>
                        <input
                            type="text"
                            id="tenant_name"
                            className="form-control"
                            value={this.props.row?this.props.row.name:null}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="tenant_token_id">Customer Key*</label>
                        <input
                            type="text"
                            id="tenant_token_id"
                            className={this.state.tenantTokenValidation!==""?"form-control invalid":"form-control"}
                            value={this.state.tenantToken}
                            onChange={this.onChangeCustomerKey}
                        />
                        <span className="text-danger">{this.state.tenantTokenValidation}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-md" variant="primary" onClick={()=>this.validateRequest()}>
                    Save{ this.props.showUpdateLoading?
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                                            :''}
                    </Button>
                    <Button className="btn-md" variant="secondary" onClick={this.props.closeModal}>
                    Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ConfirmationTenantKeyModal