import React from 'react';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { WEBSITE_CATEGORY_OPTIONS } from '../common/Constants';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import _ from "lodash";

let tagsInput = "";
let urlInput = "";
let commentInput = "";

let selectedTenantOptionsArray = [];

class UpdateWebsiteManagementItemModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: props.update_data && props.update_data.length ? props.update_data[0]["id"] : '',
            selectedTenant: props.update_data && props.update_data.length ? props.update_data[0]["tenantId"] : '',
            selectedTenantOptionsArray: selectedTenantOptionsArray,
            selectedCategory: props.update_data && props.update_data.length ? props.update_data[0]["categoryId"] : '0',
            selectedCategoryOptionsArray: WEBSITE_CATEGORY_OPTIONS,
            filteredSelectedCategoryOptionsArray: WEBSITE_CATEGORY_OPTIONS[parseInt(props.update_data && props.update_data.length ? props.update_data[0]["categoryId"] : '0')],
            tags: props.update_data && props.update_data.length ? props.update_data[0]["tags"] : '',
            url: props.update_data && props.update_data.length ? props.update_data[0]["url"] : '',
            urlValidation: '',
            comment: props.update_data && props.update_data.length ? props.update_data[0]["comment"] : '',
            validated: false,
        }
    }


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(prevProps.show)) {
            if (this.props.show) {
                if (this.props.update_data && this.props.update_data.length) {
                    selectedTenantOptionsArray = [{
                        value: this.props.update_data[0]["tenantId"],
                        label: this.props.update_data[0]["tenantName"]
                    }];

                }
                this.setState({
                    id: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["id"] : '',
                    selectedTenant: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["tenantId"] : '',
                    selectedTenantOptionsArray: selectedTenantOptionsArray,
                    selectedCategory: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["categoryId"] : '0',
                    selectedCategoryOptionsArray: WEBSITE_CATEGORY_OPTIONS,
                    filteredSelectedCategoryOptionsArray: WEBSITE_CATEGORY_OPTIONS[parseInt(this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["categoryId"] : '0')],
                    tags: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["tags"] : '',
                    url: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["url"] : '',
                    urlValidation: '',
                    comment: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["comment"] : '',
                }, this.validations)
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(nextProps.show)) {
            return true
        }
        if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
            return true
        }
        return false
    }

    resetModalOptions = () => {
        this.resetModal();
        this.props.closeModal();
    }

    resetModal = () => {
        this.setState({
            id: '',
            selectedTenant: '',
            selectedTenantOptionsArray: [],
            selectedCategory: '',
            selectedCategoryOptionsArray: [],
            tags: '',
            url: '',
            comment: ''
        });
    }

    handleCategoryChange = (e) => {
        this.setState({
            selectedCategory: e,
            selectedCategoryValidation: ''
        }, this.validations)
    }

    handleTagsChange = () => {
        this.setState({
            tags: tagsInput ? tagsInput.value : ''
        }, this.validations)
    }

    handleUrlChange = () => {
        this.setState({
            url: urlInput ? urlInput.value : ''
        }, this.validations)
    }

    handleCommentChange = () => {
        this.setState({
            comment: commentInput ? commentInput.value : ''
        }, this.validations)
    }

    validations = () => {
        let validated = true
        validated &= urlInput && urlInput.value.trim().length !== 0
        validated &= this.state.selectedTenant.length !== 0
        validated &= (parseInt(this.state.selectedCategory) !== 0 || this.state.tags.trim().length !== 0)
        this.setState({
            validated: validated
        }, () => { })
    }

    checkUpdateData = () => {
        this.validations()
        let comment = this.state.comment? this.state.comment.trim() : ""
        let data = {
            "categoryId": parseInt(this.state.selectedCategory),
            "tags": this.state.tags.trim().split(","),
            "url": this.state.url.trim(),
            "comment": comment,
            "itemId": this.state.id
        }
        if(!this.props.settings.SophosIdTypeIsTenant) {
            data["sophosTenantId"] = this.state.selectedTenant
        }
        this.props.confirmModal(data)
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={this.resetModalOptions}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Update Website Management</h5>
                </Modal.Header>
                <Modal.Body>
                    {!this.props.settings.SophosIdTypeIsTenant &&
                        <div className="row" style={{ margin: '0 0 5px 0' }}>
                            <div className="col">
                                <span>Tenant</span><span className="text-danger">*</span>
                                <MultiSelectTextbox
                                    options={_.orderBy(this.state.selectedTenantOptionsArray, [tenant => tenant.label.toLowerCase()])}
                                    customPlaceholder="Select Tenant"
                                    filteredSelectedValue={this.state.selectedTenantOptionsArray}
                                    isMulti={false}
                                    isLoading={this.state.tenantLoading}
                                    disabledSelect={true}
                                />
                            </div>
                        </div>
                    }
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Category Override</span>
                            <MultiSelectTextbox
                                options={_.orderBy(this.state.selectedCategoryOptionsArray, [category => category.label.toLowerCase()])}
                                customPlaceholder="Select Category"
                                filteredSelectedValue={[this.state.filteredSelectedCategoryOptionsArray]}
                                isMulti={false}
                                isLoading={false}
                                onSelectOption={(value) => this.handleCategoryChange(value)}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Tags <a href className="text-muted" data-tip data-for="tagsDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="tagsDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                                Multiple tags should be added comma separated.
                            </ReactTooltip>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                tags={this.state.value}
                                ref={n => tagsInput = n}
                                onChange={this.handleTagsChange}
                                placeholder={this.state.value}
                                value={this.state.tags}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>URL<span className="text-danger">*</span> <a href className="text-muted" data-tip data-for="urlDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="urlDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                                URL should not be more then 2048 characters.
                            </ReactTooltip>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                url={this.state.value}
                                ref={n => urlInput = n}
                                onChange={this.handleUrlChange}
                                value={this.state.url}
                                placeholder={this.state.value}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Comment <a href className="text-muted" data-tip data-for="commentDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="commentDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                                Comment should not be more then 300 characters.
                            </ReactTooltip>
                            <input
                                className="form-control form-control-sm"
                                ref={n => commentInput = n}
                                type="text"
                                onChange={this.handleCommentChange}
                                value={this.state.comment}
                                placeholder={this.state.comment}
                            />
                            <span className="text-danger">{this.state.commentValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span className="text-danger">* fields are required.</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span className="text-danger">Please enter one tag or select a category override.</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" variant="primary"
                        onClick={this.checkUpdateData}
                        disabled={!this.state.validated}
                    >
                        Update
                    </Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.resetModalOptions}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}



export default UpdateWebsiteManagementItemModal