import { getFeatureFlags } from './apiOperations';
import store from '../store/index';
import { isEqual } from 'lodash';
import { compareVersions, VERSION_REQ_FOR_DEPLOYMENT_AUTOMATION } from '../components/common/Util';

const FIVE_MINUTES = 300000;
export const PARTNER_RMM_KASEYA_DEPLOYEMENT_AUTOMATION_ENABLED = 'partner.rmm.kaseya-deployment-automation.enabled';

let intervalId = null;
export const setFeatureFlags = (httpReferrerHostname, searchParams, stateUpdateCallBack, propsUpdateCallBack) => {
    getFlags(httpReferrerHostname, searchParams, stateUpdateCallBack, propsUpdateCallBack);
    intervalId = setInterval(() => getFlags(httpReferrerHostname, searchParams, stateUpdateCallBack, propsUpdateCallBack), FIVE_MINUTES);
}

export const clearFlagsAPIInterval = () => {
    if (intervalId) {
        clearInterval(intervalId);
    }
}

export const getFlagValueByKey = (flagName) => {
    const currentState = store.getState();
    const someData = currentState.feature_flags.flags;
    return someData[flagName] || false;
}

export const getFlagValues = () => {
    const currentState = store.getState();
    const someData = currentState.feature_flags.flags;
    return someData ? someData : {};
}

export const isDeploymentAutotmationEnabled = (current_version) => {
    if (current_version) {
        return compareVersions(current_version, VERSION_REQ_FOR_DEPLOYMENT_AUTOMATION) >= 0 && getFlagValueByKey(PARTNER_RMM_KASEYA_DEPLOYEMENT_AUTOMATION_ENABLED);
    } else {
        return false;
    }
}

const getFlags = (httpReferrerHostname, searchParams, stateUpdateCallBack, propsUpdateCallBack) => {
    getFeatureFlags(httpReferrerHostname + "_" + localStorage.getItem(httpReferrerHostname + "_kaseya_tenant_id"), searchParams)
        .then((res) => {
            const flags = getFlagValues();
            if (!isEqual(flags, res.data)) {
                propsUpdateCallBack(res.data);
                stateUpdateCallBack({ flags_loaded: true });
            }
        })
        .catch((err) => {
            stateUpdateCallBack({ flags_loaded: true });
        });
}
