/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 20-04-2020 15:52
 * @modify date 20-04-2020 15:52
 * @description This component has the deploymnet guide steps.
 */
import React from "react";
import { Scrollbars } from 'react-custom-scrollbars';
class DeploymentGuide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  goToDeployment = () => {
    this.props.history.push({
      pathname: "/deployment-settings-v2",
      search: this.props.location.search,
    });

  };

  render() {
    return (
      <React.Fragment>
        <div className="row mx-0">
          <div className="col text-center">
            <h4>Deployment Guide</h4>
          </div>
        </div>
        <Scrollbars style={{ width: 'auto', height: 'calc(100vh - 150px)' }}>
          <div className="row mx-0 mt-1">
            <div className="col-md-10 offset-md-1 text-justify">
              <p>This section describes the deployment strategy used by the application to install the Sophos&reg; Endpoint agent on Kaseya Assets. On the configuration screen partners can upload CSV file that are downloaded from Sophos Central Partner Dashboard and configure the Auto deployment.</p>

              <p>This application allows defining the auto-deployment strategy based on Kaseya Machine groups and Organization. Partners can map Kaseya Machine groups/organization with the Sophos Tenant and choose the Sophos Endpoint products that will be installed by Sophos Endpoint installer.</p>

              <p>Once configured, the application will install or update the Sophos Endpoint Agent if Kaseya Asset matches with the configured Machine Group/Organization for auto-deployment strategy.</p>

              <p>The application has the Assets tab which lists the Kaseya Assets and status of Sophos Endpoint agent installed. The application compares the hostname of Kaseya Assets and Sophos Endpoints to check if Sophos Endpoint agent is already installed.</p>

              <p>Partners can also install the Sophos Endpoint Agent manually on Assets from <strong>Sophos Security Solutions->Main->Assets</strong> tab. Select "Install Sophos" action then select the assets and click on Submit. This will ask for the Tenant and the products to be installed on the selected Kaseya Assets.</p>

              <p>For both Auto deploy and Manual deployment, this application uses Kaseya Procedures to install the Sophos Endpoint Agent.</p>
              <h6><strong>Configuration:</strong></h6>
              <ul>
                <li>
                  Login to Sophos Central Partner Dashboard and download "Windows CSV file". Additionally download "Mac OS X CSV file" and Mac OS X installer (SophosInstall.zip) to deploy Sophos Endpoint agent on Kaseya managed Mac OS X agents.</li>
                <li>
                  Login to Kaseya and upload SophosInstall.zip at <strong>Agent Procedures->Manage Procedures->Schedule/Create->Shared(Right Click)->Manage Files->Shared files</strong>. Skip this step if you do not want to deploy Sophos Endpoint agent on Kaseya managed Mac OS X agents.
                </li>
                <li>
                  Click Next to upload CSV files. This screen allows you to upload both the CSV files together. Click Skip if you have already uploaded files and do not want to upload it again.
                </li>
                <li>
                  Click Next will give you an option to map tenants by either Kaseya Machine Groups or the Kaseya Organizations.
                </li>
                <li>
                  Add the records and click on Finish to save the configuration.
                </li>
              </ul>
              <h6><strong>Troubleshooting & Logging:</strong></h6>
              <ul>
                <li>
                  To check Audit logs by this application: <strong>Sophos Security Solutions->Logs->Audit Logs->[select “Deployment” tab on the page]</strong>
                </li>
                <li>
                  To check the Kaseya deployment procedure logs: <strong>Agent-> Agents-> Agent Logs-> [click on the agent name] -> Agent Admin Logs->Procedure History</strong>.
                </li>
                <li>
                  To get the log file of Sophos Central installation: <strong>Agent Procedures-> File Transfer-> Get File-> [click on the agent name] -> Click on SophosCentralInstall.log</strong>. This log file will be created once the installation process is complete. It will take up to 45 mins to get this file.
                </li>
              </ul>
            </div>
          </div>
        </Scrollbars>
      </React.Fragment>
    )
  }
}

export default DeploymentGuide