/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 13:06
 * @modify date 06-02-2020 13:06
 * @description This file contains the component Customers and its layout.
 */
import React from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { getTenantList, clearNextPossibleResponseMessage, clearTenantErrorResponseMessage, setTenantListErrorResponseMessage, sendRequestTenantTokenEdit } from '../../services/apiOperations';
import { CommonLayout } from '../common/CommonLayout';
import { setInitStorage } from '../common/InitLocalStore';
import { ErrorModal } from '../common/ErrorModal'
import TableGrid from '../common/TableGrid';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import { getDataFromJson } from '../common/Util';
import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/tableGrid.scss';
import 'react-toastify/dist/ReactToastify.css';
import Badge from "react-bootstrap/Badge";
import Button from 'react-bootstrap/Button';
import ConfirmationTenantKeyModal from '../customers/ConfirmationTenantKeyModal';
import TenantAccessError from '../multiTenancy/TenantAccessError';
import { isDeploymentAutotmationEnabled } from '../../services/flagOperations';

if (process.env.REACT_APP_HOST_ENV === "production") {
    console.log = function () { }
}

const CONSTANT_TYPE = {
    dataGeography: "dataGeography",
    dataRegion: "dataRegion",
    billingType: "billingType",
    autoDeploy: "autoDeploy",
    tenantToken: "tenantToken",
    macUrl: "sophosMacServer",
}

const NULL_UNDEFINED_EMPTY_CHECK = [null, undefined, ""]
const BOOLEAN_CONSTANT_ARRAY = [true, false]


const billingTypeOptions = [
    { value: "subscription", label: "subscription" },
    { value: "term", label: "term" },
    { value: "trial", label: "trial" },
    { value: "usage", label: "usage" },
    { value: "-", label: "not defined" }
]

const autoDeployOptions = [
    { value: false, label: "disabled" },
    { value: true, label: "enabled" }
]

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getTenantList: (referrer, queryFilterString, force) => dispatch(getTenantList(referrer, queryFilterString, force)),
    clearNextPossibleResponseMessage: () => dispatch(clearNextPossibleResponseMessage()),
    clearTenantErrorResponseMessage: () => dispatch(clearTenantErrorResponseMessage()),
    setTenantListErrorResponseMessage: (message) => dispatch(setTenantListErrorResponseMessage(message))
})

class Customers extends React.Component {
    constructor(props) {
        super(props);
        setInitStorage(props.referrerHostname, queryString.parse(props.location.search))
        this.state = {
            queryParams: queryString.parse(props.location.search),
            kaseyaParams: queryString.parse(props.location.search),
            rowData: [],
            filtered: [],
            clearFilterBool: false,
            clearSearchBox: false,
            columnDefs: [
                { text: "Tenant ID", dataField: "id", sort: true, headerStyle: () => { return { width: '19%' }; }, fixed: true },
                {
                    text: "Tenant Name", dataField: "showAs", sort: true, headerStyle: () => { return { width: '13%' }; }, formatter: (cell, row) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                            if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row.showAs))
                                return row.showAs
                            else
                                return "-"
                        else
                            return "-"
                    }
                },
                {
                    text: "Location", dataField: CONSTANT_TYPE.dataGeography, sort: true, headerStyle: () => { return { width: '8%' }; }, formatter: (cell, row) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                            if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row.dataGeography))
                                return row.dataGeography
                            else
                                return "-"
                        else
                            return "-"
                    }
                },
                {
                    text: "Region", dataField: CONSTANT_TYPE.dataRegion, sort: true, headerStyle: () => { return { width: '8%' }; }, formatter: (cell, row) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                            if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row.dataRegion))
                                return row.dataRegion
                            else
                                return "-"
                        else
                            return "-"
                    }
                },
                {
                    text: "Billing Type", dataField: CONSTANT_TYPE.billingType, sort: true, width: 200, headerStyle: () => { return { width: '8%' }; }, formatter: (cell, row) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                            if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row.billingType))
                                return row.billingType
                            else
                                return "-"
                        else
                            return "-"
                    }
                },
                {
                    text: "Auto Deploy", dataField: CONSTANT_TYPE.autoDeploy, sort: true, headerStyle: () => { return { width: '8%' }; }, formatter: (cell, row) => {
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                            if (BOOLEAN_CONSTANT_ARRAY.includes(row.autoDeploy))
                                return row.autoDeploy ?
                                    <Badge variant="success">enabled</Badge>
                                    :
                                    <Badge pill variant="danger">disabled</Badge>
                            else
                                return "-"
                        else
                            return "-"
                    }

                }
            ],
            defaultSorted: [{
                dataField: 'name',
                order: 'asc'
            }],
            defaultColDef: {
                sortable: true,
                resizable: true,
                enablePivot: true,
                enableValue: true,
            },
            selectedIndexes: [],
            nextPossibleForce: null,
            tenantErrorResponse: this.props.tenants.error_response_message,
            showConfirmationTenantKeyModal: false,
            tenantKeyChangeRow: null,
            showUpdateLoading: false,
            tenantKeyUpdateErrorShow: false,
            tenantKeyUpdateErrorMessage: "",
            dataRegionOptions: [],
            dataGeographyOptions: []
        };
        if (!isDeploymentAutotmationEnabled(this.props.settings.current_version)) {
            const columndDefs = this.state.columnDefs;
            columndDefs.splice(columndDefs.length - 1, 0, {
                text: "Customer Key", dataField: CONSTANT_TYPE.tenantToken, sort: true, headerStyle: () => { return { width: '22%' }; }, formatter: (cell, row) => {
                    if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row.tenantToken))
                            return <div>
                                {row.tenantToken}
                                {row.tenantToken !== "-" ? <Button onclick={console.log("CLICKED ON EDIT")} style={{ float: "right" }} className="btn-sm" variant="primary"><i className="fa fa-pencil" aria-hidden="true"></i></Button> : null}
                            </div>
                        else
                            return "-"
                    else
                        return "-"
                }
            });
            columndDefs.splice(columndDefs.length - 1, 0, {
                text: "Management Server", dataField: CONSTANT_TYPE.macUrl, sort: true, headerStyle: () => { return { width: '14%' }; }, formatter: (cell, row) => {
                    if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row))
                        if (!NULL_UNDEFINED_EMPTY_CHECK.includes(row.sophosMacServer))
                            return row.sophosMacServer
                        else
                            return "-"
                    else
                        return "-"
                }

            });
            this.setState.columnDefs = columndDefs;
        }
        this.componentWillMountFunction()
    }

    componentWillMountFunction = () => {
        let queryFitlerString = queryString.parse(this.props.location.search)
        if (queryFitlerString !== "") {
            this.props.getTenantList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search);
        }
        else {
            this.props.getTenantList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.tenants.force_limit_reached || this.props.tenants.queued_job_exist || this.props.tenants.scheduled) {
            this.showToast();
            this.props.tenants.queued_job_exist = null
            this.props.tenants.force_limit_reached = null
            this.props.tenants.scheduled = null
            this.clearErrorResponseToast()
        }
        if (prevProps.tenants.tenant_list !== this.props.tenants.tenant_list) {
            let dataRegionList = [];
            let regionList = [];
            let geographyList = [];
            let dataGeographyList = [];
            this.props.tenants.tenant_list.forEach(tenantObj => {
                if (regionList.indexOf(tenantObj.dataRegion) < 0) {
                    regionList.push(tenantObj.dataRegion)
                    dataRegionList.push({ "value": tenantObj.dataRegion, "label": tenantObj.dataRegion })
                }
                if (geographyList.indexOf(tenantObj.dataGeography) < 0) {
                    geographyList.push(tenantObj.dataGeography)
                    dataGeographyList.push({ "value": tenantObj.dataGeography, "label": tenantObj.dataGeography })
                }
            });
            this.setState({ rowData: this.props.tenants.tenant_list, clearSearchBox: !this.state.clearSearchBox, dataRegionOptions: dataRegionList, dataGeographyOptions: dataGeographyList });
        }
        if (JSON.stringify(this.props.tenants.response_time) !== JSON.stringify(prevProps.tenants.response_time) && this.props.tenants.response_time !== null) {
            this.setState({
                nextPossibleForce: this.props.tenants.response_time
            })
        }
        if (JSON.stringify(this.props.tenants.error_response_message) !== JSON.stringify(prevProps.tenants.error_response_message)) {
            this.setState({
                tenantErrorResponse: this.props.tenants.error_response_message
            })
        }
    }

    componentWillUnmount() {
        this.clearNextpossibleToast()
        this.clearErrorResponseToast()
    }

    clearNextpossibleToast = () => {
        this.props.clearNextPossibleResponseMessage()
    }

    showToast = () => {
        if (this.props.tenants.force_limit_reached) {
            let last_sync_time = new Date(this.props.tenants.last_force_update_time * 1000)
            let req_per_hour_diff = Math.round(60 / this.props.tenants.req_per_hour)
            let next_response_time = new Date((this.props.tenants.last_force_update_time * 1000) + (1000 * 60 * req_per_hour_diff))
            toast.error(`Last sync ran at ${last_sync_time.toLocaleTimeString()}. Next available sync at ${next_response_time.toLocaleTimeString()}`, { containerId: 'A', onClose: () => this.clearNextpossibleToast() });
        }
        else if (this.props.tenants.queued_job_exist) {
            toast.error("Job already exist in queue", { containerId: 'A', onClose: () => this.clearNextpossibleToast() });
        }
        else if (this.props.tenants.scheduled) {
            toast.success("Force sync job scheduled successfuly", { containerId: 'A', onClose: () => this.clearNextpossibleToast() });
        }
    }

    viewEndpoints = (row, event) => {
        if (event.target.nodeName === "I" || event.target.nodeName === "BUTTON") {
            this.setState({
                showConfirmationTenantKeyModal: true,
                tenantKeyChangeRow: row,
            })
            return null
        }
        if (row.apiHost !== null && row.apiHost !== "" && row.apiHost !== "NULL" && row.apiHost !== "null" && row.apiHost !== undefined) {
            this.props.history.push({
                pathname: "/endpoints",
                search: this.props.location.search,
                state: row
            })
        }
        else {
            this.props.setTenantListErrorResponseMessage('This account has not been activated; <br/>Please activate from <a target="_blank" href="https://cloud.sophos.com/">Sophos Central Administration Console</a>')
        }
    }

    filterData = (data) => {

        let result = []
        for (let i = 0; i < data.length; i++) {
            let flag = true
            for (let j = 0; j < this.state.filtered.length; j++) {
                if (this.state.filtered[j].type !== CONSTANT_TYPE.selectedTenant) {
                    let apiValue;
                    try {
                        apiValue = getDataFromJson(data[i], this.state.filtered[j].type.split("_")).toString()
                    }
                    catch (err) {
                        apiValue = "-"
                    }

                    if (this.state.filtered[j].value !== "all" && this.state.filtered[j].value.split(",").indexOf(apiValue) === -1) {
                        flag = false
                        break
                    }
                }

            }
            if (flag) {
                result.push(data[i])
            }

        }
        return result
    }

    findFilterValueByType(arrayValue, searchType) {
        return arrayValue.type === searchType
    }

    selectFilters = (value, type) => {
        let filters = this.state.filtered
        let newfilterObj = { value: value, type: type }
        const obj = filters.find((arrayValue) => this.findFilterValueByType(arrayValue, newfilterObj.type))
        if (obj !== undefined) {
            const index = filters.indexOf(obj)
            filters.splice(index, 1)
        }
        if (value !== '')
            filters.push(newfilterObj)
        this.setState({
            filtered: filters
        })
        // this.getTenantData()
    }


    clearFilter() {
        this.setState({ filtered: [], clearFilterBool: !this.state.clearFilterBool })
        this.props.getTenantList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), this.props.location.search);
    }

    getTenantData(forceBoolean = false) {
        if (this.props.location.search !== "") {
            const queryParams = queryString.parse(this.props.location.search)
            const queryFilter = queryString.stringify(queryParams)
            this.props.getTenantList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), "?" + queryFilter, forceBoolean)
        }
        else {
            this.props.getTenantList(this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"), forceBoolean)
        }
    }

    clearNextpossibleToast = () => {
        this.props.clearNextPossibleResponseMessage()
    }

    clearErrorResponseToast = () => {
        this.props.clearTenantErrorResponseMessage()
    }

    clearTenantKeyUpdatePopup = () => {
        this.setState({
            tenantKeyUpdateErrorShow: false,
            tenantKeyUpdateErrorMessage: ""
        })
    }

    tablegridMessage() {
        if (!this.props.tenants.is_loading) {
            return { message: "No tenants found to match your search", type: "info" }
        }
        else {
            return { message: "Loading....", type: "info" }
        }
    }

    confirmTenantKeyChange = (tenant_id, tenant_token) => {
        let requestBody = {
            "x_tenant_id": tenant_id,
            "tenant_key": tenant_token
        }
        this.setState({
            showUpdateLoading: true
        }, () => {
            sendRequestTenantTokenEdit(
                this.props.referrerHostname + "_" + localStorage.getItem(this.props.referrerHostname + "_kaseya_tenant_id"),
                requestBody,
                this.props.location.search
            )
                .then(() => {
                    toast.success("Tenant record updated successfully", { containerId: 'A' });
                    this.setState({
                        showConfirmationTenantKeyModal: false,
                        showUpdateLoading: false
                    }, () => {
                        this.getTenantData()
                    })

                })
                .catch(err => {
                    let errMessage = ""
                    if (err.response) {
                        if (err.response.data.message !== undefined)
                            errMessage = err.response.data.message
                        else
                            errMessage = "Something went wrong; Please try again later."

                    }
                    else {
                        errMessage = "Something went wrong; Please try again later."
                    }
                    this.setState({
                        showConfirmationTenantKeyModal: false,
                        showUpdateLoading: false,
                        tenantKeyUpdateErrorShow: true,
                        tenantKeyUpdateErrorMessage: errMessage
                    })
                })
        })

    }

    closeConfirmationTenantKeyModal = () => {
        this.setState({
            showConfirmationTenantKeyModal: false,
            tenantKeyChangeRow: null
        })
    }

    render() {
        return (
            this.props.settings.SophosIdTypeIsTenant ?
                <TenantAccessError />
                : <CommonLayout title="Tenants" displayTitle={true}>
                    <ErrorModal
                        show={this.props.tenants.error_response_message !== null}
                        closeModal={() => {
                            this.clearErrorResponseToast()
                        }}
                        modalMessage={this.props.tenants.error_response_message}
                    />
                    <ErrorModal
                        show={this.state.tenantKeyUpdateErrorShow}
                        closeModal={() => {
                            this.clearTenantKeyUpdatePopup()
                        }}
                        modalMessage={this.state.tenantKeyUpdateErrorMessage}
                    />
                    {this.state.showConfirmationTenantKeyModal ? <ConfirmationTenantKeyModal
                        show={this.state.showConfirmationTenantKeyModal}
                        showUpdateLoading={this.state.showUpdateLoading}
                        row={this.state.tenantKeyChangeRow.tenantToken === "-" ? { ...this.state.tenantKeyChangeRow, tenantToken: "" } : this.state.tenantKeyChangeRow}
                        confirmAction={(tenant_id, tenant_token) => this.confirmTenantKeyChange(tenant_id, tenant_token)}
                        closeModal={this.closeConfirmationTenantKeyModal}
                    /> : null}

                    <ToastContainer
                        position="top-right"
                        autoClose={7000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        draggable={false}
                        pauseOnHover={false}
                        enableMultiContainer
                        containerId={'A'}
                    />

                    <div className="row mt-2 mx-1">
                        <div className="col-6">
                            <div className="input-group-append row">
                                <div className="col px-0 mx-1 text-left">
                                    <span>Location</span>
                                    <MultiSelectTextbox
                                        options={this.state.dataGeographyOptions}
                                        isMulti={true}
                                        filteredSelectedValue={
                                            this.state.filtered.find((arrayValue) =>
                                                this.findFilterValueByType(arrayValue, CONSTANT_TYPE.dataGeography))
                                        }
                                        onSelectOption={(value) => this.selectFilters(value, CONSTANT_TYPE.dataGeography)}
                                        clearValue={this.state.clearFilterBool}
                                    />
                                </div>
                                <div className="col px-0 mx-1 text-left">
                                    <span>Region</span>
                                    <MultiSelectTextbox
                                        options={this.state.dataRegionOptions}
                                        isMulti={true}
                                        filteredSelectedValue={
                                            this.state.filtered.find((arrayValue) =>
                                                this.findFilterValueByType(arrayValue, CONSTANT_TYPE.dataRegion)) ? this.state.filtered.find((arrayValue) =>
                                                    this.findFilterValueByType(arrayValue, CONSTANT_TYPE.dataRegion).value) : []
                                        }
                                        onSelectOption={(value) => this.selectFilters(value, CONSTANT_TYPE.dataRegion)}
                                        clearValue={this.state.clearFilterBool}
                                    />
                                </div>
                                <div className="col px-0 mx-1 text-left">
                                    <span>Billing Type</span>
                                    <MultiSelectTextbox
                                        options={billingTypeOptions}
                                        isMulti={true}
                                        filteredSelectedValue={
                                            this.state.filtered.find((arrayValue) =>
                                                this.findFilterValueByType(arrayValue, CONSTANT_TYPE.billingType))
                                        }
                                        onSelectOption={(value) => this.selectFilters(value, CONSTANT_TYPE.billingType)}
                                        clearValue={this.state.clearFilterBool}
                                    />
                                </div>
                                <div className="col px-0 mx-1 text-left">
                                    <span>Auto Deploy</span>
                                    <MultiSelectTextbox
                                        options={autoDeployOptions}
                                        isMulti={false}
                                        filteredSelectedValue={
                                            this.state.filtered.find((arrayValue) =>
                                                this.findFilterValueByType(arrayValue, CONSTANT_TYPE.autoDeploy))
                                        }
                                        onSelectOption={(value) => this.selectFilters(value, CONSTANT_TYPE.autoDeploy)}
                                        clearable={true}
                                        clearValue={this.state.clearFilterBool}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-2 pl-1 text-left mt-3">
                            {/* <button type="button" className="btn btn-primary btn-sm" onClick={this.setFiltersParams.bind(this)}>Filter</button> */}
                            <button type="button" className="btn btn-secondary btn-sm ml-2" disabled={this.state.filtered.length === 0} onClick={this.clearFilter.bind(this)}>Clear</button>
                        </div>
                        <div className="col-4 text-right mt-3">
                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={this.getTenantData.bind(this, true)}><i className="fa fa-refresh" /> Force Sync</button>
                            <button type="button" className="btn btn-primary round-help-btn ml-2 btn-sm " data-tip={this.props.tenants.req_per_hour !== null ? "Maximum of " + this.props.tenants.req_per_hour + "x per hour allowed" : "Maximum of 4x per hour allowed"} data-for='helpTime'><i className="fa fa-question" /></button>
                            <ReactTooltip id="helpTime" effect="solid" type="info" place="left" />
                        </div>
                        <div className="row mt-2 mx-1">
                            <div className="col-12 text-right">
                                <b>{this.props.tenants.last_updated_times && this.props.tenants.last_updated_times ? 'Last Force Sync Time: ' + moment.unix(this.props.tenants.last_updated_times).format('YYYY-MM-DD HH:mm:ss') : ''}</b>
                            </div>
                        </div>
                    </div>
                    <div className="row m-1 card-content-height">
                        <div className="col-md-12">

                            <TableGrid
                                clearValue={this.state.clearFilterBool}
                                defaultSortedCol={this.state.defaultSorted}
                                columnStructure={this.state.columnDefs}
                                responseData={this.filterData(this.state.rowData)}
                                loading={this.props.tenants.is_loading}
                                stateObject={this.props.tenants}
                                pageSize={this.filterData(this.state.rowData).length < this.props.tenants.page_size ? this.filterData(this.state.rowData).length : this.props.tenants.page_size}
                                filtered={this.state.filtered}
                                rowClickEvent={(row, e) => this.viewEndpoints(row, e)}
                                noDataText={this.tablegridMessage()}
                                keyField="id"
                                moduleName="customers"
                                note="Click on a row to view endpoints of tenants."
                                clearSearchBox={this.state.clearSearchBox}
                            />

                        </div>
                    </div>
                </CommonLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers)