/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 29-02-2020 12:20
 * @modify date 29-02-2020 12:20
 * @description This file contains the component of Dashboard's Card section of Endpoint and Server skeleton.
 */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Card from "react-bootstrap/Card";
import {NumberLoader} from '../common/NumberLoader';
import DonutChart from './DonutChart';

export const FILTER_TYPE={
    health_overall:"health_overall",
    tamperProtectionEnabled:"tamperProtectionEnabled",
    os_platform:"os_platform",
    offlineStatus:"offlineStatus",
}

export const computerTypeOptions={
    computer:"computer",
    server:["server","securityVm"]
}

export const healthStatusOptions={
    bad:"bad",
    good:"good",
    suspicious:"suspicious",
    notDefined:"-"
}

export const offlineStatusOptions={
    plus30:"+30"
}

export const osPlatformOptions=[
    {label:"Windows",value:"windows"},
    {label:"Linux",value:"linux"},
    {label:"MacOS",value:"macOS"}
]

export const tamperedProtectionOptions={
    enabled:true,
    disabled:false,
}

class DashboardCard extends React.Component{

    abbreviateNumber(value) {
        let newValue = value;
        if (value >= 1000) {
            let suffixes = ["", "k", "m", "b","t","q"];
            let suffixNum = Math.floor( (""+value).length/3 );
            let shortValue = '';
            for (let precision = 2; precision >= 1; precision--) {
                shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value));
                let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
                if (dotLessShortValue.length <= 2) { break; }
            }
            if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(2);
            newValue = shortValue+suffixes[suffixNum];
        }
        return newValue;
    }

    render(){
        return(
            <Card className="border" bg="default">
                <Card.Header>
                    <h5 className="m-0 text-center">{this.props.cardTitle}
                    <i className="fa fa-info-circle btn px-1" 
                        aria-hidden="true" 
                        data-tip={this.props.tooltipData}
                        data-for={'tooltip'+this.props.cardTitle}></i>
                    <ReactTooltip id={'tooltip'+this.props.cardTitle} effect="solid" type="light" place="right" border={true} multiline className="tooltip-fix-width-150"/> 
                    </h5>   
                </Card.Header>
                <Card.Body>
                    <h6>Health Status</h6>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col text-center">
                                    <button className="btn container bg-card-tile rounded shadow" 
                                        onClick={()=>this.props.viewEndpointClick({
                                            type:FILTER_TYPE.health_overall, 
                                            value:healthStatusOptions.bad, 
                                            computerType: this.props.endpointType
                                        })}>
                                        {this.props.isLoading?
                                            <NumberLoader className="dashboard-widget-count-text text-danger"/>
                                        :
                                            <h2 className="dashboard-widget-count-text text-danger">
                                                <strong>
                                                    {this.props.dataExist?this.abbreviateNumber(this.props.dataObject.securitySummary.bad?this.props.dataObject.securitySummary.bad:"0"):"0"}
                                                </strong>
                                            </h2>
                                        }
                                        <h6 className="dashboard-widget-title text-danger p-2"><strong>Bad</strong></h6>
                                    </button>
                                </div>
                                <div className="col text-center">
                                    <button className="btn container bg-card-tile rounded shadow" 
                                        onClick={()=>this.props.viewEndpointClick({
                                            type:FILTER_TYPE.health_overall, 
                                            value:healthStatusOptions.suspicious, 
                                            computerType: this.props.endpointType
                                        })}>
                                        {this.props.isLoading?
                                            <NumberLoader className="dashboard-widget-count-text text-warning"/>
                                        :
                                            <h2 className="dashboard-widget-count-text text-warning">
                                                <strong>
                                                    {this.props.dataExist?this.abbreviateNumber(this.props.dataObject.securitySummary.suspicious?this.props.dataObject.securitySummary.suspicious:"0"):"0"}
                                                </strong>
                                            </h2>
                                        }
                                        <h6 className="dashboard-widget-title text-warning p-2"><strong>Suspicious</strong></h6>
                                    </button> 
                                </div>
                                <div className="col text-center">
                                    <button className="btn container bg-card-tile rounded shadow" 
                                        onClick={()=>this.props.viewEndpointClick({
                                            type:FILTER_TYPE.health_overall, 
                                            value:healthStatusOptions.good, 
                                            computerType: this.props.endpointType
                                        })}>
                                        {this.props.isLoading?
                                            <NumberLoader className="dashboard-widget-count-text text-success"/> 
                                        :
                                            <h2 className="dashboard-widget-count-text text-success">
                                                <strong>
                                                    {this.props.dataExist?this.abbreviateNumber(this.props.dataObject.securitySummary.good?this.props.dataObject.securitySummary.good:"0"):"0"}
                                                </strong>
                                            </h2>
                                        }
                                        <h6 className="dashboard-widget-title text-success p-2"><strong>Good</strong></h6>
                                    </button> 
                                </div>
                                <div className="col text-center">
                                        <button className="btn container bg-card-tile rounded shadow"
                                            onClick={()=>this.props.viewEndpointClick({
                                                type:FILTER_TYPE.offlineStatus, 
                                                value:offlineStatusOptions.plus30, 
                                                computerType: this.props.endpointType
                                            })}
                                        >
                                            {this.props.isLoading?
                                                <NumberLoader className="dashboard-widget-count-text text-secondary"/>
                                            :
                                                <h2 className="dashboard-widget-count-text text-secondary">
                                                    <strong>
                                                        {this.props.dataExist?this.abbreviateNumber(this.props.dataObject.securitySummary.offline?this.props.dataObject.securitySummary.offline:"0"):"0"}
                                                    </strong>
                                                </h2>
                                            }
                                            <h6 className="dashboard-widget-title text-secondary p-2"><strong>Offline 30+ days</strong></h6>
                                        </button> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        {/* <div className="col">
                            <h6 style={{textAlign:"center"}}>Deployment Status</h6>
                            <div className="row">
                                <div className="col-6 text-center">
                                    <div className="container bg-danger rounded shadow">
                                        <h1 className="dashboard-widget-count-text">10</h1>
                                    </div>
                                    <h6 className="dashboard-widget-title pb-2">Unprotected</h6>
                                </div>
                                <div className="col-6 text-center">
                                    <div className="container bg-success rounded shadow">
                                        <h1 className="dashboard-widget-count-text">190</h1>
                                    </div>
                                    <h6 className="dashboard-widget-title pb-2">Protected</h6>
                                </div>
                            </div>
                        </div> */}
                        <div className="col">
                            <h6>Tampered Protection Status</h6>
                            <div className="row">
                                <div className="col-6 text-center">
                                        <button className="btn container bg-card-tile rounded shadow" 
                                            onClick={()=>this.props.viewEndpointClick({
                                                type:FILTER_TYPE.tamperProtectionEnabled, 
                                                value:tamperedProtectionOptions.disabled, 
                                                computerType: this.props.endpointType
                                            })}>
                                            {this.props.isLoading?
                                                <NumberLoader className="dashboard-widget-count-text text-danger"/>
                                            : 
                                                <h2 className="dashboard-widget-count-text text-danger">
                                                    <strong>
                                                        {this.props.dataExist?this.abbreviateNumber(this.props.dataObject.tamperProtectionSummary.false?this.props.dataObject.tamperProtectionSummary.false:"0"):"0"}
                                                    </strong>        
                                                </h2>
                                            }
                                            <h6 className="dashboard-widget-title text-danger p-2"><strong>Disabled</strong></h6>
                                        </button> 
                                </div>
                                <div className="col-6 text-center">
                                        <button className="btn container bg-card-tile rounded shadow" 
                                            onClick={()=>this.props.viewEndpointClick({
                                                type:FILTER_TYPE.tamperProtectionEnabled, 
                                                value:tamperedProtectionOptions.enabled, 
                                                computerType: this.props.endpointType
                                            })}>
                                            {this.props.isLoading?
                                                <NumberLoader className="dashboard-widget-count-text text-success"/>
                                            :
                                                <h2 className="dashboard-widget-count-text text-success">
                                                    <strong>
                                                        {this.props.dataExist?this.abbreviateNumber(this.props.dataObject.tamperProtectionSummary.true?this.props.dataObject.tamperProtectionSummary.true:"0"):"0"}
                                                    </strong>
                                                </h2>
                                            }   
                                            <h6 className="dashboard-widget-title text-success p-2"><strong>Enabled</strong></h6>
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col text-center">
                            <h6 className="text-left">{this.props.cardTitle} by OS</h6>
                                <DonutChart 
                                    columns={this.props.donutChartColumnData}
                                    chartType="donut"
                                    totalTitle={this.props.donutChartTitleData}
                                    chartId={this.props.donutChartId}
                                    colorPattern={this.props.donutCharColorPattern}
                                    onClickEvent={(osPlatform)=>this.props.viewEndpointClick({ 
                                        type: FILTER_TYPE.os_platform, 
                                        value: osPlatformOptions.find((e)=>e.label===osPlatform).value, 
                                        computerType: this.props.endpointType 
                                    })}
                                />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

export default DashboardCard