import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import _ from "lodash";
import { SCAN_MODE_ON_ACCESS_OPTION, SCAN_MODE_ON_DEMAND_ON_ACCESS_OPTION } from '../common/Constants';


let commentInput = "";
let valueInput = "";
let selectedScanModeOptionsArray = []
let selectedTypeOptionsArray = []
let selectedTenantOptionsArray = []

class UpdateScanningExclusionItemModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: props.update_data && props.update_data.length ? props.update_data[0]["id"] : '',
            selectedTenant: props.update_data && props.update_data.length ? props.update_data[0]["tenant"]["id"] : '',
            selectedTenantOptionsArray: selectedTenantOptionsArray,
            selectedType: props.update_data && props.update_data.length ? props.update_data[0]['type'] : '',
            selectedTypeValidation: '',
            selectedTypeOptionsArray: selectedTypeOptionsArray,
            selectedScanMode: props.update_data && props.update_data.length ? props.update_data[0]['scanMode'] : '',
            selectedScanModeValidation: '',
            selectedScanModeOptionsArray: selectedScanModeOptionsArray,
            value: props.update_data && props.update_data.length ? props.update_data[0]["value"] : '',
            valueValidation: '',
            comment: props.update_data && props.update_data.length ? props.update_data[0]["comment"] : '',
            validated: false,
        }
    }


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(prevProps.show)) {
            if (this.props.show) {
                if (this.props.update_data && this.props.update_data.length) {
                    if (this.props.update_data[0]['type'] === 'amsi') {
                        selectedTypeOptionsArray = [
                            {
                                "label": "AMSI",
                                "value": "amsi"
                            }
                        ]
                        selectedScanModeOptionsArray = SCAN_MODE_ON_ACCESS_OPTION;
                    } else if (this.props.update_data[0]['type'] === 'web') {
                        selectedTypeOptionsArray = [
                            {
                                "label": "Web",
                                "value": "web"
                            }
                        ]
                        selectedScanModeOptionsArray = SCAN_MODE_ON_ACCESS_OPTION;
                    } else if (this.props.update_data[0]['type'] === 'path') {
                        selectedTypeOptionsArray = [
                            {
                                "label": "Path",
                                "value": "path"
                            }
                        ]
                        selectedScanModeOptionsArray = SCAN_MODE_ON_DEMAND_ON_ACCESS_OPTION
                    } else if (this.props.update_data[0]['type'] === 'posixPath') {
                        selectedTypeOptionsArray = [
                            {
                                "label": "Posix Path",
                                "value": "posixPath"
                            }
                        ]
                        selectedScanModeOptionsArray = SCAN_MODE_ON_DEMAND_ON_ACCESS_OPTION
                    } else if (this.props.update_data[0]['type'] === 'process') {
                        selectedTypeOptionsArray = [
                            {
                                "label": "Process",
                                "value": "process"
                            }
                        ]
                        selectedScanModeOptionsArray = SCAN_MODE_ON_ACCESS_OPTION
                    } else if (this.props.update_data[0]['type'] === 'pua') {
                        selectedTypeOptionsArray = [
                            {
                                "label": "Pua",
                                "value": "pua"
                            }
                        ]
                        selectedScanModeOptionsArray = SCAN_MODE_ON_ACCESS_OPTION
                    } else if (this.props.update_data[0]['type'] === 'virtualPath') {
                        selectedTypeOptionsArray = [
                            {
                                "label": "Virtual Path",
                                "value": "virtualPath"
                            }
                        ]
                        selectedScanModeOptionsArray = SCAN_MODE_ON_DEMAND_ON_ACCESS_OPTION
                    } else {
                        selectedTypeOptionsArray = []
                        selectedScanModeOptionsArray = []
                    }
                    selectedTenantOptionsArray = [{
                        value: this.props.update_data[0]["tenant"]["id"],
                        label: this.props.update_data[0]["tenant"]["name"]
                    }];
                }
                this.setState({
                    id: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["id"] : '',
                    selectedTenant: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["tenant"]["id"] : '',
                    selectedTenantOptionsArray: selectedTenantOptionsArray,
                    selectedType: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]['type'] : '',
                    selectedTypeValidation: '',
                    selectedTypeOptionsArray: selectedTypeOptionsArray,
                    selectedScanMode: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]['scanMode'] : '',
                    selectedScanModeValidation: '',
                    selectedScanModeOptionsArray: selectedScanModeOptionsArray,
                    value: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["value"] : '',
                    valueValidation: '',
                    comment: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["comment"] : '',
                }, this.validations)
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(nextProps.show)) {
            return true
        }
        if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
            return true
        }
        return false
    }

    resetModalOptions = () => {
        this.resetModal();
        this.props.closeModal();
    }

    resetModal = () => {
        this.setState({
            id: '',
            selectedTenant: '',
            selectedTenantOptionsArray: [],
            selectedType: [],
            selectedTypeValidation: '',
            selectedTypeOptionsArray: [],
            selectedScanMode: '',
            selectedScanModeValidation: '',
            selectedScanModeOptionsArray: [],
            value: '',
            comment: '',
        });
    }

    handleScanModeChange = (e) => {
        this.setState({
            selectedScanMode: e,
            selectedScanModeValidation: ''
        }, this.validations)
    }

    handleValueChange = () => {
        this.setState({
            value: valueInput ? valueInput.value : ''
        }, this.validations)
    }

    handleCommentChange = () => {
        this.setState({
            comment: commentInput ? commentInput.value : ''
        }, this.validations)
    }

    validations = () => {
        let validated = true
        validated &= valueInput && valueInput.value.trim().length !== 0
        validated &= this.state.selectedTenant.length !== 0
        validated &= this.state.selectedType.length !== 0
        this.setState({
            validated: validated
        }, () => { })
    }

    checkUpdateData = () => {
        this.validations()
        let comment = this.state.comment? this.state.comment.trim() : ""
        let data = {
            "value": this.state.value.trim(),
            "scanMode": this.state.selectedScanMode,
            "comment": comment,
            "itemId": this.state.id
        }
        if(!this.props.settings.SophosIdTypeIsTenant) {
            data["sophosTenantId"] = this.state.selectedTenant
        }
        this.props.confirmModal(data)
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={this.resetModalOptions}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Update Scanning Exclusion</h5>
                </Modal.Header>
                <Modal.Body>
                    {!this.props.settings.SophosIdTypeIsTenant &&
                        <div className="row" style={{ margin: '0 0 5px 0' }}>
                            <div className="col">
                                <span>Tenant</span><span className="text-danger">*</span>
                                <MultiSelectTextbox
                                    options={_.orderBy(this.state.selectedTenantOptionsArray, [tenant => tenant.label.toLowerCase()])}
                                    customPlaceholder="Select Tenant"
                                    filteredSelectedValue={this.state.selectedTenantOptionsArray}
                                    isMulti={false}
                                    isLoading={this.state.tenantLoading}
                                    disabledSelect={true}
                                />
                            </div>
                        </div>
                    }
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Value</span><span className="text-danger">*</span>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                value={this.state.value}
                                ref={n => valueInput = n}
                                onChange={this.handleValueChange}
                                placeholder={this.state.value}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Type</span><span className="text-danger">*</span>
                            <MultiSelectTextbox
                                options={_.orderBy(this.state.selectedTypeOptionsArray, [type => type.label.toLowerCase()])}
                                customPlaceholder="Select Type"
                                filteredSelectedValue={this.state.selectedTypeOptionsArray}
                                isMulti={false}
                                disabledSelect={true}
                            />
                            <span className="text-danger">{this.state.selectedItemTypeValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Scan Mode</span><span className="text-danger">*</span>
                            <MultiSelectTextbox
                                options={this.state.selectedScanModeOptionsArray}
                                customPlaceholder="Select Type"
                                filteredSelectedValue={
                                    this.state.selectedScanMode ? 
                                    [
                                        {
                                            label: (this.state.selectedScanMode === "onDemandAndOnAccess" ? "On Demand & On Access" : (this.state.selectedScanMode === "onDemand" ? "On Demand" : "On Access")),
                                            value: this.state.selectedScanMode
                                        }
                                    ] : 
                                    []
                                }
                                isMulti={false}
                                onSelectOption={(value) => this.handleScanModeChange(value)}
                            />
                            <span className="text-danger">{this.state.selectedScanModeValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Comment</span>
                            <input
                                className="form-control form-control-sm"
                                ref={n => commentInput = n}
                                type="text"
                                onChange={this.handleCommentChange}
                                value={this.state.comment}
                                placeholder={this.state.comment}
                            />
                            <span className="text-danger">{this.state.commentValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span className="text-danger">* fields are required.</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" variant="primary"
                        onClick={this.checkUpdateData}
                        disabled={!this.state.validated}
                    >
                        Update
                    </Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.resetModalOptions}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}



export default UpdateScanningExclusionItemModal