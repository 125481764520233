/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:42
 * @modify date 06-02-2020 12:42
 * @description This file contains the action method to trigger redux actions.
 */

import * as actionTypes from './types';

export const moduleType = {
    settings: "settings",
    tenants: "tenants",
    endpoints: "endpoints",
    assets: "assets",
    alerts: "alerts",
    dashboard_alert: "dashboard_alert",
    dashboard_endpoint: "dashboard_endpoint",
    auditlog: "audit_log",
    allowed_blocked: "allowed_blocked",
    scanning_exclusion: "scanning_exclusion",
    website_management: "website_management",
    exploit_mitigation: "exploit_mitigation",
    intrusion_prevention: "intrusion_prevention",
}
/**
 * Below are Tenants actions methods.
 */
export const setTenantList = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_TENANT_LIST, data: data })
}

export const setTenantListErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_TENANT_LIST_ERROR_RESPONSE, data: data })
}

export const clearTenantErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_TENANT_ERROR_RESPONSE })
}

export const setResponseMessage = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_RESPONSE_MESSAGE, data: data })
}

export const clearTenants = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_TENANTS_DATA })
}

/**
 * Below are Settings action methods
 */

export const setSaveSettingsResponseMessage = (data, status) => dispatch => {
    return dispatch({ type: actionTypes.SAVE_SETTINGS_RESPONSE_MESSAGE, data: data, status: status })
}

export const getCurrentSettingResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.GET_SETTINGS_RESPONSE, data: data })
}

export const getCurrentSettingErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.GET_SETTINGS_ERROR_RESPONSE, data: data })
}

export const clearSettingsResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_SETTINGS_RESPONSE })
}

/**
 * Below are Endpoints actions methods
 */

export const setEndpointList = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_COMPUTERS_LIST, data: data })
}

export const setEndpointListErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_ENDPOINT_LIST_ERROR_RESPONSE, data: data })
}

export const clearEndpointErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_ENDPOINT_ERROR_RESPONSE })
}

export const clearEndpoints = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_COMPUTERS_DATA })
}

/**
 * Below are Assets actions methods
 */

export const setAssetList = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_ASSET_LIST, data: data })
}

export const setAssetListErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_ASSET_LIST_ERROR_RESPONSE, data: data })
}

export const clearAssetErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_ASSET_ERROR_RESPONSE })
}

export const clearAssets = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_ASSET_DATA })
}

/**
 * Below are Alerts action methods
 */

export const setAlertList = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_ALERTS_LIST, data: data })
}

export const setAlertListErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_ALERT_LIST_ERROR_RESPONSE, data: data })
}

export const clearAlertsErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_ALERT_ERROR_RESPONSE })
}

export const clearAlerts = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_ALERTS_DATA })
}

/**
 * Below are Dashboard action methods
 */

export const setDashboardAlertData = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_DASHBOARD_ALERT_DATA, data: data })
}

export const setDashboardEndpointData = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_DASHBOARD_ENDPOINT_DATA, data: data })
}

export const clearDashboardAlert = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_DASHBOARD_ALERT_DATA })
}

export const clearDashboardEndpoint = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_DASHBOARD_ENDPOINT_DATA })
}

export const setDashboardAlertErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_DASHBOARD_ALERT_DATA_ERROR_RESPONSE, data: data })
}

export const setDashboardEndpointErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_DASHBOARD_ENDPOINT_DATA_ERROR_RESPONSE, data: data })
}

export const clearDashboardAlertErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_DASHBOARD_ALERT_ERROR_RESPONSE })
}

export const clearDashboardEndpointErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_DASHBOARD_ENDPOINT_ERROR_RESPONSE })
}


/**
 * Below are Audit Log action methods
 */

export const setAuditLogData = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_AUDIT_LOG_LIST, data: data })
}

export const clearAuditLogData = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_AUDIT_LOG_DATA })
}

export const setAuditLogErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_AUDIT_LOG_LIST_ERROR_RESPONSE, data: data })
}

export const clearAuditLogErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_AUDIT_LOG_ERROR_RESPONSE })
}

/**
 * Below are Allowed & Blocked action methods
 */

export const setAllowedBlockedList = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_ALLOWED_BLOCKED_LIST, data: data })
}

export const setAllowedBlockedListErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_ALLOWED_BLOCKED_LIST_ERROR_RESPONSE, data: data })
}

export const clearAllowedBlockedErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_ALLOWED_BLOCKED_ERROR_RESPONSE })
}

export const clearAllowedBlocked = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_ALLOWED_BLOCKED_DATA })
}

/**
 * Below are Scanning Exclusion action methods
 */

export const setScanningExclusionList = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_SCANNING_EXCLUSION_LIST, data: data })
}

export const setScanningExclusionListErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_SCANNING_EXCLUSION_LIST_ERROR_RESPONSE, data: data })
}

export const clearScanningExclusionErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_SCANNING_EXCLUSION_ERROR_RESPONSE })
}

export const clearScanningExclusion = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_SCANNING_EXCLUSION_DATA })
}

/**
 * Below are Exploit Mitigation action methods
 */

export const setExploitMitigationList = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_EXPLOIT_MITIGATION_LIST, data: data })
}

export const setExploitMitigationListErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_EXPLOIT_MITIGATION_LIST_ERROR_RESPONSE, data: data })
}

export const clearExploitMitigationErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_EXPLOIT_MITIGATION_ERROR_RESPONSE })
}

export const clearExploitMitigation = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_EXPLOIT_MITIGATION_DATA })
}

/**
 * Below are Website Management action methods
 */

export const setWebsiteManagementList = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_WEBSITE_MANAGEMENT_LIST, data: data })
}

export const setWebsiteManagementListErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_WEBSITE_MANAGEMENT_LIST_ERROR_RESPONSE, data: data })
}

export const clearWebsiteManagementErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_WEBSITE_MANAGEMENT_ERROR_RESPONSE })
}

export const clearWebsiteManagement = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_WEBSITE_MANAGEMENT_DATA })
}

/**
 * Below are Intrusion Prevention action methods
 */

export const setIntrusionPreventionList = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_INTRUSION_PREVENTION_LIST, data: data })
}

export const setIntrusionPreventionListErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_INTRUSION_PREVENTION_LIST_ERROR_RESPONSE, data: data })
}

export const clearIntrusionPreventionErrorResponse = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_INTRUSION_PREVENTION_ERROR_RESPONSE })
}

export const clearIntrusionPrevention = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_INTRUSION_PREVENTION_DATA })
}

/**
 * Below are common action methods used in applications
 */

export const setResponseTime = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_RESPONSE_TIME, data: data })
}

export const clearResponseTime = () => dispatch => {
    return dispatch({ type: actionTypes.CLEAR_RESPONSE_TIME })
}

export const startLoader = (moduleName) => dispatch => {
    if (moduleName === moduleType.settings) {
        return dispatch({ type: actionTypes.START_LOADER_SETTINGS })
    }
    if (moduleName === moduleType.tenants) {
        return dispatch({ type: actionTypes.START_LOADER_TENANTS })
    }
    if (moduleName === moduleType.endpoints) {
        return dispatch({ type: actionTypes.START_LOADER_ENDPOINTS })
    }
    if (moduleName === moduleType.assets) {
        return dispatch({ type: actionTypes.START_LOADER_ASSETS })
    }
    if (moduleName === moduleType.alerts) {
        return dispatch({ type: actionTypes.START_LOADER_ALERTS })
    }
    if (moduleName === moduleType.dashboard_alert) {
        return dispatch({ type: actionTypes.START_LOADER_DASHBOARD_ALERT })
    }
    if (moduleName === moduleType.dashboard_endpoint) {
        return dispatch({ type: actionTypes.START_LOADER_DASHBOARD_ENDPOINT })
    }
    if (moduleName === moduleType.audit_log) {
        return dispatch({ type: actionTypes.START_LOADER_AUDIT_LOGS })
    }
    if (moduleName === moduleType.allowed_blocked) {
        return dispatch({ type: actionTypes.START_LOADER_ALLOWED_BLOCKED })
    }
    if (moduleName === moduleType.scanning_exclusion) {
        return dispatch({ type: actionTypes.START_LOADER_SCANNING_EXCLUSION })
    }
    if (moduleName === moduleType.exploit_mitigation) {
        return dispatch({ type: actionTypes.START_LOADER_EXPLOIT_MITIGATION })
    }
    if (moduleName === moduleType.website_management) {
        return dispatch({ type: actionTypes.START_LOADER_WEBSITE_MANAGEMENT })
    }
    if (moduleName === moduleType.intrusion_prevention) {
        return dispatch({ type: actionTypes.START_LOADER_INTRUSION_PREVENTION })
    }
}

export const stopLoader = (moduleName) => dispatch => {
    if (moduleName === moduleType.settings) {
        return dispatch({ type: actionTypes.STOP_LOADER_SETTINGS })
    }
    if (moduleName === moduleType.tenants) {
        return dispatch({ type: actionTypes.STOP_LOADER_TENANTS })
    }
    if (moduleName === moduleType.endpoints) {
        return dispatch({ type: actionTypes.STOP_LOADER_ENDPOINTS })
    }
    if (moduleName === moduleType.assets) {
        return dispatch({ type: actionTypes.STOP_LOADER_ASSETS })
    }
    if (moduleName === moduleType.alerts) {
        return dispatch({ type: actionTypes.STOP_LOADER_ALERTS })
    }
    if (moduleName === moduleType.dashboard_alert) {
        return dispatch({ type: actionTypes.STOP_LOADER_DASHBOARD_ALERT })
    }
    if (moduleName === moduleType.dashboard_endpoint) {
        return dispatch({ type: actionTypes.STOP_LOADER_DASHBOARD_ENDPOINT })
    }
    if (moduleName === moduleType.audit_log) {
        return dispatch({ type: actionTypes.STOP_LOADER_AUDIT_LOGS })
    }
    if (moduleName === moduleType.allowed_blocked) {
        return dispatch({ type: actionTypes.STOP_LOADER_ALLOWED_BLOCKED })
    }
    if (moduleName === moduleType.scanning_exclusion) {
        return dispatch({ type: actionTypes.STOP_LOADER_SCANNING_EXCLUSION })
    }
    if (moduleName === moduleType.exploit_mitigation) {
        return dispatch({ type: actionTypes.STOP_LOADER_EXPLOIT_MITIGATION })
    }
    if (moduleName === moduleType.website_management) {
        return dispatch({ type: actionTypes.STOP_LOADER_WEBSITE_MANAGEMENT })
    }
    if (moduleName === moduleType.intrusion_prevention) {
        return dispatch({ type: actionTypes.STOP_LOADER_INTRUSION_PREVENTION })
    }
}

/**
 * Below is the action method for setting feature flags
 */

 export const setFeatureFlags = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_FEATURE_FLAGS, data: data })
}

export const setFeatureFlagsErrorResponse = (data) => dispatch => {
    return dispatch({ type: actionTypes.SET_FEATURE_FLAGS_ERROR_RESPONSE, data: data })
}