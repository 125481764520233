/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 16:54
 * @modify date 06-02-2020 16:54
 * @description This file contains the component SettingsDetails and its layout which will be disabled if settings are not in editable mode.
 */
import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import validator from 'validator';
import {setSettings} from '../../services/apiOperations'


if(process.env.REACT_APP_HOST_ENV==="production"){
    console.log=function(){}
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setSettings: (referrer,body,queryParams) => dispatch(setSettings(referrer,body,queryParams))
})

class SettingsDetail extends React.Component{
    constructor(props){
        super(props);
        this.state={
            clientId: props.settings.settings_data && props.settings.settings_data.clientId?props.settings.settings_data.clientId:'',
            clientSecret: '',
            selectedPageSize: props.settings.settings_data && props.settings.settings_data.pageSize?props.settings.settings_data.pageSize:50,
            clientIdValidation:'',
            clientSecretValidation:'',
            formDisabled:true,
        }
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps.settings)!==JSON.stringify(this.props.settings)){
            if(this.props.settings.response_status===200){
                this.resetAll()
            }
        }
    }

    onChangeClientID=(e)=>{
        if(validator.isUUID(e.target.value)){
            this.setState({
                clientId: e.target.value,
                clientIdValidation:''
            })
        }
        else{
            this.setState({
                clientId: e.target.value,
                clientIdValidation:'Client ID must be in GUID format'
            })
        }
    }

    onChangeClientSecret=(e)=>{
        this.setState({
            clientSecret: e.target.value,
            clientSecretValidation:''
        })
    }

    onChangeSelectedPageSize=(e)=>{
        this.setState({
            selectedPageSize:e.target.value
        })
    }

    resetAll=()=>{
        this.setState({
            clientId: this.props.settings.settings_data.clientId?this.props.settings.settings_data.clientId:'',
            clientSecret: '',
            selectedPageSize: this.props.settings.settings_data.pageSize,
            formDisabled:true,
            clientIdValidation:'',
            clientSecretValidation:'',
        })
    }

    saveData=()=>{
        let requestBody={}
        let validateData=false
        if(this.state.clientId===""){
            this.setState({
                clientIdValidation:"Client ID is required"
            })
        }
        if(this.state.clientSecret===""){
            this.setState({
                clientSecretValidation:"Client Secret is required"
            })
        }
        requestBody.sophos={}
        requestBody.sophos.clientId=this.state.clientId
        requestBody.sophos.clientSecret=this.state.clientSecret
        requestBody.sophos.pageSize=parseInt(this.state.selectedPageSize)
        if(localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id")){
            requestBody.kaseya_http_referer_tenant_id=this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id")
        }
        if(this.state.clientId!=="" && this.state.clientIdValidation==="" && this.state.clientSecret!=="" && this.state.clientSecretValidation===""){
            validateData=true
        }
        if(validateData){ 
            this.props.setSettings(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),requestBody,"?"+queryString.stringify(this.props.queryParams))
        }
    }

    doFormEnabled=()=>{
        this.setState({
            formDisabled:false
        })
    }
    setShowPrincipalCredWarning=(boolValue)=>{
        this.setState({
            showCredsWarning: boolValue
        })
    }

    render(){
        return(
            <React.Fragment>
                <div className="form-group">
                    <label htmlFor="clientId">Client ID*</label>
                    <input 
                        type="text" 
                        className={this.state.clientIdValidation!==""?"form-control invalid":"form-control"}
                        id="clientId" 
                        placeholder="Client ID" 
                        onChange={this.onChangeClientID} 
                        value={this.state.clientId}
                        disabled={this.state.formDisabled}
                    />
                    <small id="clientIdHelp" className="form-text text-muted">Enter Client ID for your Sophos Central account</small>
                    <span className="text-danger">{this.state.clientIdValidation}</span>
                </div>
                {this.state.formDisabled===false?
                <div className="form-group">
                    <label htmlFor="clientSecret">Client Secret*</label>
                    <input
                        type="password" 
                        className={this.state.clientSecretValidation!==""?"form-control invalid":"form-control"} 
                        id="clientSecret" 
                        placeholder="Client Secret" 
                        onChange={this.onChangeClientSecret} 
                        value={this.state.clientSecret}
                    />
                    <small id="clientSecretHelp" className="form-text text-muted">Enter Client Secret for your Sophos Central account</small>
                    <span className="text-danger">{this.state.clientSecretValidation}</span>
                </div>
                :''}
                <div className="form-group">
                    <label htmlFor="defaultPage">Default Page Size</label>
                    <select 
                        id="defaultPage" 
                        className="form-control" 
                        onChange={this.onChangeSelectedPageSize} 
                        value={this.state.selectedPageSize} 
                        disabled={this.state.formDisabled}
                    >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                    </select>
                    <small id="defaultPageHelp" className="form-text text-muted">Number of listing in single page</small>
                </div>
                <div className="form-group">
                    <div className="row">
                        {
                        this.props.settings.is_loading?
                            <div className="col-lg-4 offset-lg-6 col-6">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        :
                        this.state.formDisabled?
                            <div className="col-lg-4 offset-lg-4 col-6">
                                <button className="btn btn-lg btn-primary btn-block" onClick={this.doFormEnabled.bind(this)}>Edit</button>
                            </div>
                        :
                        <React.Fragment>
                            <div className="col-lg-2 offset-lg-4 col-6">
                                <button className="btn btn-lg btn-primary btn-block" onClick={this.saveData.bind(this)}>Save</button>
                            </div>
                            <div className="col-lg-2 col-6">
                                <button className="btn btn-lg btn-outline-secondary btn-block" onClick={this.resetAll}>Cancel</button>
                            </div>
                        </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDetail)