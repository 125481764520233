/**
 * @author Anil vaza
 * @email anil.vaza@crestdatasys.com
 * @create date 21-05-2021 04:27
 * @modify date 21-05-2021 04:27
 * @description This file contains the interceptors for each api request. in this file we refresh the kaseya sophos jwt before 10 mins of expiry
 */

import axios from 'axios';
import { getJwt } from './apiOperations';
import { setInitStorage } from '../components/common/InitLocalStore';

const axiosInstance = axios.create();
const environment = process.env.REACT_APP_HOST_ENV


axiosInstance.interceptors.request.use(async (config) => {
  let httpReferrerHostname = ""
  if (document.referrer !== "" && environment === "production")
    httpReferrerHostname = new URL(document.referrer).hostname
  // else//NTC - The commented code is needed for the local development testing
  //   httpReferrerHostname = process.env.REACT_APP_DEV_REFERRER

  if (httpReferrerHostname) {
    setInitStorage("referrer", { referrer: httpReferrerHostname })
  } else {
    httpReferrerHostname = localStorage.getItem("referrer")
  }
  let kaseyaSophosJwtExpiry = localStorage.getItem(httpReferrerHostname + "_kaseya_sophos_jwt_expiry") - 10 * 60000;
  let currentTime = Date.now();
  if (currentTime > kaseyaSophosJwtExpiry) {
    let response = await getJwt(httpReferrerHostname, document.location.search)
    if (response.status === 200) {
      setInitStorage(httpReferrerHostname, { kaseyaSophosJwt: response.data.response.access_token })
      setInitStorage(httpReferrerHostname, { kaseyaSophosJwtExpiry: response.data.response.exp ? response.data.response.exp * 1000 : 0 })
      config.headers['Authorization'] = 'Bearer ' + response.data.response.access_token
    }
    return config
  } else {
    return config;
  }
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


export default axiosInstance
