/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 16:55
 * @modify date 06-02-2020 16:55
 * @description This file contains the component SettingsForm and its layout for new Settings details.
 */
import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import validator from 'validator';
import {setSettings} from '../../services/apiOperations'


if(process.env.REACT_APP_HOST_ENV==="production"){
    console.log=function(){}
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setSettings: (referrer,body,queryParams) => dispatch(setSettings(referrer,body,queryParams))
})

class SettingsForms extends React.Component{
    constructor(props){
        super(props);
        this.state={
            clientId:'',
            clientSecret:'',
            selectedPageSize:50,
            clientIdValidation:'',
            clientSecretValidation:'',
        }
    }

    onChangeClientID=(e)=>{
        if(validator.isUUID(e.target.value)){
            this.setState({
                clientId: e.target.value,
                clientIdValidation:''
            })
        }
        else{
            this.setState({
                clientId: e.target.value,
                clientIdValidation:'Client ID must be in GUID format'
            })
        }
    }

    onChangeClientSecret=(e)=>{
        this.setState({
            clientSecret: e.target.value,
            clientSecretValidation:''
        })
    }

    onChangeSelectedPageSize=(e)=>{
        this.setState({
            selectedPageSize:e.target.value
        })
    }

    resetAll=()=>{
        this.setState({
            clientId:'',
            clientSecret:'',
            selectedPageSize:50,
        })
    }

    saveData=()=>{
        let requestBody={}
        let validateData=false
        if(this.state.clientId===""){
            this.setState({
                clientIdValidation:"Client ID is required"
            })
        }
        if(this.state.clientSecret===""){
            this.setState({
                clientSecretValidation:"Client Secret is required"
            })
        }
        requestBody.sophos={}
        requestBody.sophos.clientId=this.state.clientId
        requestBody.sophos.clientSecret=this.state.clientSecret
        requestBody.sophos.pageSize=parseInt(this.state.selectedPageSize)
        if(localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id")){
            requestBody.kaseya_http_referer_tenant_id=this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id")
        }
        if(this.state.clientId!=="" && this.state.clientIdValidation==="" && this.state.clientSecret!=="" && this.state.clientSecretValidation===""){
            validateData=true
        }
        if(validateData){ 
            this.props.setSettings(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),requestBody,"?"+queryString.stringify(this.props.queryParams))
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="form-group">
                    <label htmlFor="clientId">Client ID*</label>
                    <input 
                        type="text" 
                        className={this.state.clientIdValidation!==""?"form-control invalid":"form-control"}
                        id="clientId" 
                        placeholder="Client ID" 
                        onChange={this.onChangeClientID} 
                        value={this.state.clientId}
                    />
                    <small id="clientIdHelp" className="form-text text-muted">Enter Client ID for your Sophos Central account</small>
                    <span className="text-danger">{this.state.clientIdValidation}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="clientSecret">Client Secret*</label>
                    <input
                        type="password" 
                        className={this.state.clientSecretValidation!==""?"form-control invalid":"form-control"} 
                        id="clientSecret" 
                        placeholder="Client Secret" 
                        onChange={this.onChangeClientSecret} 
                        value={this.state.clientSecret}
                    />
                    <small id="clientSecretHelp" className="form-text text-muted">Enter Client Secret for your Sophos Central account</small>
                    <span className="text-danger">{this.state.clientSecretValidation}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="defaultPage">Default Page Size</label>
                    <select id="defaultPage" className="form-control" onChange={this.onChangeSelectedPageSize} value={this.state.selectedPageSize}>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                    </select>
                    <small id="defaultPageHelp" className="form-text text-muted">Number of listing in single page</small>
                </div>

                <div className="form-group">
                    <div className="row">
                        {
                        this.props.settings.is_loading?
                            <div className="col-lg-4 offset-lg-6 col-6">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        :
                            <React.Fragment>
                                <div className="col-lg-2 offset-lg-4 col-6">
                                    <button className="btn btn-lg btn-primary btn-block" onClick={this.saveData.bind(this)}>Save</button>
                                </div>
                                <div className="col-lg-2 col-6">
                                    <button className="btn btn-lg btn-outline-secondary btn-block" onClick={this.resetAll}>Reset</button>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForms)