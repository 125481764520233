/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 16:53
 * @modify date 06-02-2020 16:53
 * @description This file contains the component Settings and its layout.
 */
import React from 'react';
import Alert from "react-bootstrap/Alert";
import queryString from 'query-string';
import { connect } from 'react-redux';
import {getSettings,clearSettingsResponseMessage} from '../../services/apiOperations'
import {setInitStorage} from '../common/InitLocalStore';
import {CommonLayout} from '../common/CommonLayout';
import SettingsForm from './SettingsForm';
import SettingsDetail from './SettingsDetail';
import './settings.scss';


if(process.env.REACT_APP_HOST_ENV==="production"){
    console.log=function(){}
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getSettings: (referrer,queryParams) => dispatch(getSettings(referrer,queryParams)),
    clearSettingsResponseMessage: () => dispatch(clearSettingsResponseMessage())
})

class Settings extends React.Component {
    
    constructor(props){
        super(props);
        setInitStorage(props.referrerHostname,queryString.parse(props.location.search))
        this.state={
            queryParams:queryString.parse(props.location.search),
            showAlert:false
        }
        this.props.getSettings(this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),props.location.search)
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps)!==JSON.stringify(this.props)){
            if(this.props.settings.response_status===200 && this.props.settings.response_message!==null && prevProps.location.state ===undefined){
                this.onShowAlert()
            }
            else if(this.props.settings.response_status !== 200 && this.props.settings.response_message!==null && prevProps.location.state ===undefined){                
                this.onShowAlertAndStay()
            }
            else if(this.props.settings.response_status !== 200 && this.props.settings.response_message!==null && prevProps.location.state !==undefined){                
                this.onShowAlertAndStay()
            }
            else if(this.props.settings.response_status===200 && prevProps.location.state !==undefined){
                this.onShowAlertAndRedirect()
            }
        }
    }
    
    onShowAlertAndRedirect = ()=>{
        this.setState({showAlert:true},()=>{
          setTimeout(()=>{
            this.setState({showAlert:false})
            this.props.clearSettingsResponseMessage()
            this.props.authCheckFunction()
            this.props.history.push({pathname:this.props.location.state.from.pathname,search:this.props.location.search})
          },4000)
        });
    }

    onShowAlert = ()=>{
        this.setState({showAlert:true},()=>{
          setTimeout(()=>{
            this.setState({showAlert:false})
            this.props.clearSettingsResponseMessage() 
          },4000)
        });
    }
    onShowAlertAndStay = ()=>{
        this.setState({showAlert:true});
    }

    closeAlert=()=>{
        this.setState({
            showAlert:false
        })
        this.props.clearSettingsResponseMessage()
    }

    render(){
        return(
            <CommonLayout title="Sophos Settings" displayTitle={true}>
                <div className="cover-container form-signin py-5">
                <h3 className="text-center">Sophos Plugin Settings</h3>
                    {this.props.settings.settings_data?
                        <SettingsDetail {...this.props} queryParams={this.state.queryParams}/>
                    :
                        <SettingsForm {...this.props} queryParams={this.state.queryParams}/>
                    }
                    {this.props.settings.response_message!==null?<Alert show={this.state.showAlert} onClose={this.closeAlert.bind(this)} variant={this.props.settings.response_status===200?"success":"danger"} dismissible><center><div dangerouslySetInnerHTML={ { __html: this.props.settings.response_message } }></div></center></Alert>:''}
                </div>
            </CommonLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)