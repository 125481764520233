import React from 'react';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MultiSelectTextbox from '../common/MultiSelectTextbox';
import _ from "lodash";
import { DIRECTION_OPTION } from '../common/Constants';


let localPortInput;
let remotePortInput;
let remoteAddressInput;
let commentInput;
let directionOptions = DIRECTION_OPTION;
let selectedTenantOptionsArray = [];

class UpdateIntrusionPreventionItemModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedTenant: props.update_data && props.update_data.length ? props.update_data[0]["tenant"]["id"] : '',
            selectedTenantOptionsArray: selectedTenantOptionsArray,
            selectedTenantValidation: '',
            selectedDirection: props.update_data && props.update_data.length ? props.update_data[0]["direction"] : '',
            selectedDirectionValidation: '',
            selectedDirectionOptionsArray: DIRECTION_OPTION,
            localPorts: props.update_data && props.update_data.length ? props.update_data[0]["localPorts"] : '',
            localPortsValidation: '',
            remotePorts: props.update_data && props.update_data.length ? props.update_data[0]["remotePorts"] : '',
            remotePortsValidation: '',
            remoteAddresses: props.update_data && props.update_data.length ? props.update_data[0]["remoteAddresses"] : '',
            remoteAddressesValidation: '',
            comment: props.update_data && props.update_data.length ? props.update_data[0]["comment"] : '',
            validated: false,
        }
    }


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(prevProps.show)) {
            if (this.props.show) {
                selectedTenantOptionsArray = [{
                    value: this.props.update_data[0]["tenant"]["id"],
                    label: this.props.update_data[0]["tenant"]["name"]
                }];
                this.setState({
                    id: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["id"] : '',
                    selectedTenant: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["tenant"]["id"] : '',
                    selectedTenantOptionsArray: selectedTenantOptionsArray,
                    selectedTenantValidation: '',
                    selectedDirection: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["direction"] : '',
                    selectedDirectionValidation: '',
                    selectedDirectionOptionsArray: DIRECTION_OPTION,
                    localPorts: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["localPorts"] : '',
                    localPortsValidation: '',
                    remotePorts: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["remotePorts"] : '',
                    remotePortsValidation: '',
                    remoteAddresses: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["remoteAddresses"] : '',
                    remoteAddressesValidation: '',
                    comment: this.props.update_data && this.props.update_data.length ? this.props.update_data[0]["comment"] : ''
                }, this.validations)
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props.show) !== JSON.stringify(nextProps.show)) {
            return true
        }
        if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
            return true
        }
        return false
    }

    resetModalOptions = () => {
        this.resetModal();
        this.props.closeModal();
    }

    resetModal = () => {
        this.setState({
            id: '',
            selectedTenant: '',
            selectedTenantValidation: '',
            selectedTenantOptionsArray: [],
            selectedDirection: DIRECTION_OPTION[0].value,
            selectedDirectionValidation: '',
            selectedDirectionOptionsArray: DIRECTION_OPTION,
            localPorts: '',
            localPortsValidation: '',
            remotePorts: '',
            remotePortsValidation: '',
            remoteAddresses: '',
            remoteAddressesValidation: '',
            comment: '',
        });
    }

    handleDirectionChange = (e) => {
        this.setState({
            selectedDirection: e,
            selectedDirectionValidation: ''
        }, this.validations)
    }

    handleLocalPortsChange = () => {
        this.setState({
            localPorts: localPortInput ? localPortInput.value : ''
        }, this.validations)
    }

    handleRemotePortsChange = () => {
        this.setState({
            remotePorts: remotePortInput ? remotePortInput.value : ''
        }, this.validations)
    }

    handleRemoteAddressChange = () => {
        this.setState({
            remoteAddresses: remoteAddressInput ? remoteAddressInput.value : ''
        }, this.validations)
    }

    handleCommentChange = () => {
        this.setState({
            comment: commentInput ? commentInput.value : ''
        }, this.validations)
    }

    handleTenantChange = (e) => {
        this.setState({
            selectedTenant: e,
            selectedTenantValidation: ''
        }, this.validations)
    }

    validations = () => {
        let validated = true
        validated &= localPortInput && this.validatePort(localPortInput.value)
        validated &= remotePortInput && this.validatePort(remotePortInput.value)
        validated &= remoteAddressInput && (/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(remoteAddressInput.value))
        validated &= this.state.selectedTenant.length !== 0
        validated &= this.state.selectedDirection.length !== 0
        this.setState({
            validated: validated,
            localPortsValidation: (localPortInput && !this.validatePort(localPortInput.value)) ? "Local ports should be in range 1-65535" : "",
            remotePortsValidation: (remotePortInput && !this.validatePort(remotePortInput.value)) ? "Remote ports should be in range 1-65535" : "",
            remoteAddressesValidation: (remoteAddressInput && remoteAddressInput.value && (!(/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(remoteAddressInput.value)))) ? "Remote Address should be an IPv4 Address" : ""
        }, () => { })
    }

    validatePort = (port) => {
        let isValidValue = true;
        if (port!=='' && (isNaN(port) || port < 1 || port > 65535 || +port===35536)) {
            isValidValue = false
        }
        return isValidValue
    }

    checkUpdateData = () => {
        this.validations()
        if (this.state.selectedTenant === "") {
            this.setState({
                selectedTenantValidation: 'Please select tenant.'
            })
            return
        }
        else {
            this.setState({
                selectedTenantValidation: ''
            })
        }
        if (this.state.selectedDirection === "") {
            this.setState({
                selectedDirectionValidation: 'Please select direction.'
            })
            return
        }
        else {
            this.setState({
                selectedDirectionValidation: ''
            })
        }
        if (this.state.localPorts === "" || !this.validatePort(this.state.localPorts)) {
            this.setState({
                localPortsValidation: 'Please enter valid local ports.'
            })
            return
        }
        else {
            this.setState({
                localPortsValidation: ''
            })
        }
        if (this.state.remotePorts === "" || !this.validatePort(this.state.remotePorts)) {
            this.setState({
                remotePortsValidation: 'Please enter valid remote ports.'
            })
            return
        }
        else {
            this.setState({
                remotePortsValidation: ''
            })
        }
        if (this.state.remoteAddresses === "") {
            this.setState({
                remoteAddressesValidation: 'Please enter remote address.'
            })
            return
        }
        else {
            this.setState({
                remoteAddressesValidation: ''
            })
        }
        let data = {
            "localPorts": this.convertPortValueToArray(this.state.localPorts),
            "remotePorts": this.convertPortValueToArray(this.state.remotePorts),
            "remoteAddresses": [this.state.remoteAddresses],
            "direction": this.state.selectedDirection,
            "comment": this.state.comment ? this.state.comment.trim() : "",
            "itemId": this.state.id
        }
        if(!this.props.settings.SophosIdTypeIsTenant) {
            data["sophosTenantId"] = this.state.selectedTenant
        }
        this.props.confirmModal(data)
    }

    convertPortValueToArray = (port) => {
        let arr = []
        if(port) {
            port = port.split(',');
            arr = port.map(data => +data)
        }
        return arr;
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={this.resetModalOptions}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Update Intrusion Prevention</h5>
                </Modal.Header>
                <Modal.Body>
                    {!this.props.settings.SophosIdTypeIsTenant &&
                        <div className="row" style={{ margin: '0 0 5px 0' }}>
                            <div className="col">
                                <span>Tenant</span><span className="text-danger">*</span>
                                <MultiSelectTextbox
                                    options={_.orderBy(this.state.selectedTenantOptionsArray, [tenant => tenant.label.toLowerCase()])}
                                    customPlaceholder="Select Tenant"
                                    filteredSelectedValue={this.state.selectedTenantOptionsArray}
                                    isMulti={false}
                                    isLoading={this.state.tenantLoading}
                                    disabledSelect={true}
                                />
                            </div>
                        </div>
                    }
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Local Ports<span className="text-danger">*</span><a href className="text-muted" data-tip data-for="localPortDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="localPortDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                            Local ports should be in range 1-65535
                            </ReactTooltip>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                value={this.state.localPorts}
                                ref={n => localPortInput = n}
                                onChange={this.handleLocalPortsChange}
                                placeholder="Enter local port"
                            />
                            <span className="text-danger">{this.state.localPortsValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Remote Ports<span className="text-danger">*</span><a href className="text-muted" data-tip data-for="remotePortDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="remotePortDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                            Remote ports should be in range 1-65535
                            </ReactTooltip>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                value={this.state.remotePorts}
                                ref={n => remotePortInput = n}
                                onChange={this.handleRemotePortsChange}
                                placeholder="Enter remote port"
                            />
                            <span className="text-danger">{this.state.remotePortsValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Direction</span><span className="text-danger">*</span>
                            <MultiSelectTextbox
                                options={directionOptions}
                                customPlaceholder="Select Type"
                                value={this.state.direction}
                                filteredSelectedValue={this.state.selectedDirection ? DIRECTION_OPTION.filter(direction=>direction.value === this.props.update_data[0]['direction']) : [directionOptions[0]]}
                                isMulti={false}
                                onSelectOption={(value) => this.handleDirectionChange(value)}
                            />
                            <span className="text-danger">{this.state.selectedDirectionValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Remote Address<span className="text-danger">*</span><a href className="text-muted" data-tip data-for="remoteAddressDetails"><i className="fa fa-question-circle" aria-hidden="true"></i></a></span>
                            <ReactTooltip id="remoteAddressDetails" place="right" effect="solid" type="dark" className="tooltip-fix-width-200">
                            Remote Address should be an IPv4 Address
                            </ReactTooltip>
                            <input
                                className="form-control form-control-sm"
                                type="text"
                                value={this.state.remoteAddresses}
                                ref={n => remoteAddressInput = n}
                                onChange={this.handleRemoteAddressChange}
                                placeholder="Enter remote address"
                            />
                            <span className="text-danger">{this.state.remoteAddressesValidation}</span>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span>Comment</span>
                            <input
                                className="form-control form-control-sm"
                                ref={n => commentInput = n}
                                value={this.state.comment}
                                type="text"
                                onChange={this.handleCommentChange}
                                placeholder="Enter Comment"
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '0 0 5px 0' }}>
                        <div className="col">
                            <span className="text-danger">* fields are required.</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" variant="primary"
                        onClick={this.checkUpdateData}
                        disabled={!this.state.validated}
                    >
                        Update
                    </Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.resetModalOptions}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}



export default UpdateIntrusionPreventionItemModal