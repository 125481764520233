/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 04-03-2020 15:07
 * @modify date 04-03-2020 15:07
 * @description This component is modal for displaying error.
 */

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ConfirmationModal extends React.Component{

    render(){
        return(
            <Modal
                // size="sm"
                show={this.props.show}
                onHide={this.props.closeModal}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5>Confirmation</h5>  
                </Modal.Header>
                <Modal.Body>
                    <p dangerouslySetInnerHTML={ { __html:this.props.modalMessage } }></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-sm" variant="primary" onClick={this.props.confirmAction}>
                    Yes
                    </Button>
                    <Button className="btn-sm" variant="secondary" onClick={this.props.closeModal}>
                    Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ConfirmationModal