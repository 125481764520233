/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 06-02-2020 12:59
 * @modify date 06-02-2020 12:59
 * @description This file contains the component of setInitStorage for storing data in localstorage.
 */

export function setInitStorage(referrer,currentParamsQueryString) {
  if(currentParamsQueryString.tenantId){
    localStorage.setItem(referrer+"_kaseya_tenant_id", currentParamsQueryString.tenantId);
  }
  if(currentParamsQueryString.tenantId){
    localStorage.setItem(referrer+"_kaseya_admin_id", currentParamsQueryString.adminId);
  }
  if(currentParamsQueryString.kaseyaSophosJwt){
    localStorage.setItem(referrer+"_kaseya_sophos_jwt", currentParamsQueryString.kaseyaSophosJwt);
  }
  if(currentParamsQueryString.kaseyaSophosJwtExpiry){
    localStorage.setItem(referrer+"_kaseya_sophos_jwt_expiry", currentParamsQueryString.kaseyaSophosJwtExpiry);
  }
  if(currentParamsQueryString.referrer){
    localStorage.setItem("referrer", currentParamsQueryString.referrer);
  }
}

export function clearInitStorage(referrer){
  localStorage.removeItem(referrer+"_kaseya_tenant_id");
  localStorage.removeItem(referrer+"_kaseya_admin_id");
  localStorage.removeItem(referrer+"_kaseya_sophos_jwt");
  localStorage.removeItem(referrer+"_kaseya_sophos_jwt_expiry");
  localStorage.removeItem("referrer");
}