/**
 * @author Kashyap Ashara
 * @email kashyap.ashara@crestdatasys.com
 * @create date 20-04-2020 15:51
 * @modify date 20-04-2020 15:51
 * @description This component Deployment settings main page which have step nvaigation buttons.
 */
import React from "react";
import queryString from 'query-string';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import {setInitStorage} from '../common/InitLocalStore';
import {CommonLayout} from '../common/CommonLayout';
import {ErrorModal} from '../common/ErrorModal';
import ConfirmationModal from '../common/ConfirmationModal';
import {MAC_PRODUCTS_OPTIONS, WINDOWS_DESKTOP_PRODUCTS_OPTIONS, WINDOWS_SERVER_PRODUCTS_OPTIONS}from "../common/Constants";
import {sendDeploymentSettings} from "../../services/apiOperations";
import DeploymentGuide from './DeploymentGuide';
import UploadTenants from './UploadTenants';
import DeploymentSelect from './DeploymentSelect';
import {ROW_TYPE} from './DeploymentSelectionRow';
import './deploymentSettings.scss'
import TenantAccessError from '../multiTenancy/TenantAccessError';


const mapStateToProps = state => ({
    ...state
})

class DeployementSettings extends React.Component{
    constructor(props){
        super(props);
        setInitStorage(props.referrerHostname,queryString.parse(props.location.search))
        this.state={
            step: 1,
            formData:null,
            fileUploadSuccess:false,
            skipThirdStep:false,
            showAskConfirmationModal:false,
            confirmationMessage:"",
            validationMessage:"",
            getDeploymentDataLoading:"",
            showValidationAlert:false,
            finishStepLoading:false,
            finishMessage:""
        }
    }

    saveValues=(values)=>{
        let currentFormData={}
        if(this.state.formData!==null){
            currentFormData=this.state.formData
        }
        console.log(currentFormData,"CHECK formData")
        let valuesKeys=Object.keys(values)
        valuesKeys.forEach((key)=>{
            currentFormData[key]=values[key]
        })
        this.setState({
            formData: currentFormData
        })
    }

    markFileUploadSucces=()=>{
        this.setState({
            fileUploadSuccess:true
        })
    }

    nextStep=()=>{
        this.setState({
          step : this.state.step + 1
        })
    }

    askResetConfirmation=(nextTenantSelectionMode)=>{
        if(this.state.formData!==null){
            if(this.state.formData.selectionData && this.state.formData.tenantSelectionMode!==""){
                this.setState({
                    showAskConfirmationModal:true,
                    skipThirdStep:true,
                    nextTenantSelectionMode:nextTenantSelectionMode,
                    confirmationMessage:"The data of current step will be deleted. You sure want to change the tenant selection mode?"
                })
            }
        }
    }

    closeConfirmationModal=()=>{
        this.setState({
            skipThirdStep:false,
            showAskConfirmationModal:false
        })
    }

    confirmResetAction=()=>{
        let currentFormData=this.state.formData
        currentFormData.selectionData=[]
        currentFormData.tenantSelectionMode=this.state.nextTenantSelectionMode
        console.log("confirmData",currentFormData)
        this.setState({
            step : this.state.step - 1
        },()=>{
            this.setState({
                step : this.state.step + 1,
                formData: currentFormData,
                showAskConfirmationModal:false,
            })
        })
    }

    prepareRequestBody=()=>{
        let requestBody={}
        if(this.state.formData.selectionData){
            if(this.state.formData.selectionData.length>0){
                requestBody.selectionData=[...this.state.formData.selectionData]
                // requestBody.selectionData.forEach((selectedRowData)=>{
                //     if(selectedRowData[ROW_TYPE.MacProducts]===""){
                //         selectedRowData[ROW_TYPE.MacProducts]="all"
                //     }
                //     if(selectedRowData[ROW_TYPE.WindowsDesktopProducts]===""){
                //         selectedRowData[ROW_TYPE.WindowsDesktopProducts]="all"
                //     }
                //     if(selectedRowData[ROW_TYPE.WindowsServerProducts]===""){
                //         selectedRowData[ROW_TYPE.WindowsServerProducts]="all"
                //     }
                // })
            }
        }
        requestBody.tenantSelectionMode=this.state.formData.tenantSelectionMode
        return requestBody
    }

    finishStep=()=>{
        let validatedData=true
        if(this.state.formData!==null){
            if(this.state.formData.selectionData){
                if(this.state.formData.selectionData.length>0){
                    this.state.formData.selectionData.forEach((selectionRowData)=>{
                        if(selectionRowData[ROW_TYPE.MachineGroupOrganization]===null){
                            selectionRowData.validRow=false
                            validatedData=false
                        }
                        if(selectionRowData[ROW_TYPE.Tenant]===null){
                            selectionRowData.validRow=false
                            validatedData=false
                        }
                    })
                }
            }
            if(validatedData){
                let requestBody=this.prepareRequestBody()
                console.log(requestBody,"CHECK REQUEST BODY")
                this.setState({
                    finishStepLoading: true
                })
                sendDeploymentSettings(requestBody,this.props.referrerHostname+"_"+localStorage.getItem(this.props.referrerHostname+"_kaseya_tenant_id"),this.props.location.search)
                .then(()=>{
                    this.setState({
                        finishMessage:"Deployment Settings updated.",
                        finishStepLoading: false,
                        fileUploadSuccess:false,
                        step:1,
                    },()=>{
                        this.setState({
                            formData:null
                        })
                        this.showSuccessToast()
                    })
                })
                .catch((err)=>{
                    let errMessage=""
                    if(err.response){
                        if(err.response.data.message!==undefined)
                            errMessage=err.response.data.message
                        else
                            errMessage="Something went wrong; Please try again later."
                        
                    }
                    else{    
                        errMessage="Something went wrong; Please try again later."
                    }
                    this.setState({
                        finishStepLoading: false,
                        finishMessage: errMessage
                    },()=>this.showErrorToast())
                })
            }
            else{
                this.setState({
                    showValidationAlert:true,
                    validationMessage:`<ul>
                    <li>Please fill the mandatory details.</li>
                    </ul> `
                })
            }
        }
    }

    // Same as nextStep, but decrementing
    previousStep=()=> {
        this.setState({
          step : this.state.step - 1
        })
    }

    clearMessageToast=()=>{
        this.setState({
            finishMessage:""
        })
    }
    
    showSuccessToast=()=>{
        toast.success(this.state.finishMessage,{containerId: 'A',onClose: () => this.clearMessageToast()});
    }

    showErrorToast=()=>{
        toast.error(this.state.finishMessage,{containerId: 'A',onClose: () => this.clearMessageToast()});
    }

    closeValidationModal=()=>{
        this.setState({
            showValidationAlert:false,
            validationMessage:""
        })
    }

    renderCurrentStep=()=>{
        switch (this.state.step) {
            case 1:
                return <DeploymentGuide {...this.props} fieldValues={this.state.formData}
                            saveValues={(values)=>this.saveValues(values)} />
            case 2:
                return <UploadTenants {...this.props} fieldValues={this.state.formData}
                            saveValues={(values)=>this.saveValues(values)} 
                            uploadSuccess={this.markFileUploadSucces} />
            case 3:
                return <DeploymentSelect {...this.props} fieldValues={this.state.formData}
                            skipCurrentStep={this.state.skipThirdStep}
                            saveValues={(values)=>this.saveValues(values)}
                            macProductsOptions={MAC_PRODUCTS_OPTIONS}
                            windowsDesktopProductsOptions={WINDOWS_DESKTOP_PRODUCTS_OPTIONS}
                            windowsServerProductsOptions={WINDOWS_SERVER_PRODUCTS_OPTIONS}
                            askResetConfirmation={(nextTenantSelectionMode)=>this.askResetConfirmation(nextTenantSelectionMode)} />
            default:
        }
    }
    render(){
        return(
            this.props.settings.SophosIdTypeIsTenant ?
                <TenantAccessError />
            : <CommonLayout title="Deployment Settings" displayTitle={true}>
                <ConfirmationModal
                    show={this.state.showAskConfirmationModal}
                    confirmAction={()=>this.confirmResetAction()} 
                    closeModal={this.closeConfirmationModal}
                    modalMessage={this.state.confirmationMessage}
                />
                <ErrorModal 
                    show={this.state.showValidationAlert} 
                    closeModal={this.closeValidationModal}
                    modalMessage={this.state.validationMessage}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={7000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable={false}
                    pauseOnHover={false}
                    enableMultiContainer 
                    containerId={'A'}
                />
                <div className="row m-1 pt-3">
                    <div className="col">
                        <ul id="step-wizard-bar">
                            <li className={this.state.step>=1?"active":""}>Guide</li>
                            <li className={this.state.step>=2?"active":""}>Upload</li>
                            <li className={this.state.step>=3?"active":""}>Auto Deployment Settings</li>
                        </ul>
                        <div style={this.state.step===3 ? {width:'auto',height:'100%'} : {width:'auto',height:'70%'}} >
                            {this.renderCurrentStep()}
                        </div>
                        <br/>
                        <div className="form-group">
                            <div className="row m-0">
                                <div className="col-6 text-left">
                                    {this.state.step>1?
                                        <button className="btn btn-outline-secondary btn-labeled-left" onClick={this.previousStep}><span className="btn-label"><i className="fa fa-chevron-left"></i></span>Back</button>
                                    :
                                        ""
                                    }
                                </div>
                                <div className="col-6 text-right">
                                    {this.state.step<=3?
                                        this.state.step===3?
                                            <button className="btn btn-primary" onClick={this.finishStep} disabled={this.state.finishStepLoading}>
                                                Finish
                                                {this.state.finishStepLoading?
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                :""}
                                            </button>
                                        :
                                            <button className="btn btn-primary btn-labeled-right" onClick={this.nextStep} disabled={this.state.step===2 && !this.state.fileUploadSuccess}>Next<span className="btn-label"><i className="fa fa-chevron-right"></i></span></button>
                                    :
                                        ""
                                    }   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonLayout>
        )
    }
}

export default connect(mapStateToProps,null)(DeployementSettings)